import { getNumericDate } from 'utils/dateTimeFormat';
import Application from 'core/models/application';

export const getApplicationLabel = (application: Application, t: any): string => {
    const { job, company } = application;

    return `${job} ${t('common.for')} ${company}`;
};

export const getApplicationSource = (application: Application, t: any): string => {
    const { source } = application;

    return t(`application.source.enum.${source}`);
};

export const getApplicationDateOfPublication = (application: Application): string => {
    const { dateOfPublication } = application;

    return dateOfPublication ? getNumericDate(dateOfPublication) : '';
};
