/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import { RootState } from 'core/store';
import { applicationByIdSelector } from 'core/useCases/applications/selectors';

type UpdateApplicationButtonProps = {
    applicationId: string;
};

const UpdateApplicationButton: FC<UpdateApplicationButtonProps> = ({ applicationId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const application = useSelector((state: RootState) => applicationByIdSelector(state, applicationId));

    const openApplicationModal = () => {
        dispatch(
            OpenModal({
                defaultValues: application,
                title: t('application.update.label'),
                type: ModalType.Application,
            }),
        );
    };

    return (
        <button onClick={() => openApplicationModal()} type="button">
            <Edit />
        </button>
    );
};

export default UpdateApplicationButton;
