import React, { FC } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contactsFetcher } from 'contexts/ContactsProvider';
import { DELETE } from 'utils/http';
import { DeleteContact } from 'core/useCases/contacts/contactActions';
import { ModalType } from 'core/useCases/modal/types';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ReactComponent as Delete } from 'images/icons/Delete.svg';
import { ReactComponent as DeleteDisable } from 'images/icons/DeleteDisable.svg';
import BACKEND_URL from 'constants/backendUrl';
import Contact from 'core/models/contact';
import ContactStatus from 'core/enums/contactStatus';

type DeleteContactButtonProps = {
    contact: Contact;
    postAction?: () => void;
};

const DeleteContactButton: FC<DeleteContactButtonProps> = ({ contact, postAction }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const openConfirmationModal = () => {
        dispatch(
            OpenModal({
                confirmAction: async () => {
                    try {
                        await DELETE(`${BACKEND_URL}/api/contact/${contact.id}`);
                        dispatch(DeleteContact(contact.id));

                        // TODO: for contact deletion button recheck, fetch only updated data
                        await contactsFetcher(dispatch, t);

                        if (postAction) {
                            postAction();
                        }

                        toast.success(t('contact.remove-contact-success'));
                    } catch (e) {
                        toast.error(t('common.error-occured'));
                    }
                },
                defaultValues: contact,
                title: t('contact.ask-to-delete-contact'),
                type: ModalType.Confirmation,
            }),
        );
    };

    return contact.isDeletable ? (
        <button onClick={openConfirmationModal} title={t('common.delete')} type="button">
            <Delete />
        </button>
    ) : (
        <button
            title={t(
                `contact.delete-disabled-${
                    [ContactStatus.Incomplete, ContactStatus.New].includes(contact.status) ? 'open' : 'closed'
                }-status`,
            )}
            type="button"
            disabled
        >
            <DeleteDisable />
        </button>
    );
};

export default DeleteContactButton;
