import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Title } from 'components/Typography';
import UserInformationForm from './UserInformationForm';
import CardWithTitle from 'components/CardWithTitle';
import { Civility } from 'core/enums/civility';
import { userSelector } from 'core/useCases/session/selectors';
import { SWrapper, SAvatar, SRight } from './styles';
import Male from 'images/icons/Male.svg';
import Female from 'images/icons/Female.svg';
import { UserRole } from '../../core/enums/userRole';
import LicencesVisualisation from './LicencesVisualisation';
import DashboardTresholdsEditing from './DashboardTresholdsEditing';
import CandidateSubscriptionView from './CandidateSubscriptionView';
import Candidate from '../../core/models/candidate';
import User from '../../core/models/user';
import CandidateLicenseView from './CandidateLicenseView';

const Profile: FC = () => {
    const { t } = useTranslation();
    const user = useSelector(userSelector) as User | Candidate | null;

    if (!user) {
        return null;
    }

    const displaySubscription = UserRole.Candidate === user.role && user.hasPaid;
    const displayLicense = UserRole.Candidate === user.role && !(user as Candidate).hasFreeAccess;

    return (
        <>
            <Title text={t('page.profile.title')} />
            <SWrapper>
                <SAvatar alt="avatar" src={Civility.Female === user.civility ? Female : Male} />
                <SRight>
                    <CardWithTitle label={t('profile.account-information')}>
                        <UserInformationForm />
                    </CardWithTitle>
                    {UserRole.AgencyAdmin === user.role && (
                        <CardWithTitle label={t('agency.my-licences')}>
                            <LicencesVisualisation />
                        </CardWithTitle>
                    )}
                    {UserRole.Candidate === user.role && (
                        <div id="dashboardTresholds">
                            <CardWithTitle label={t('dashboard-treshold.title')}>
                                <DashboardTresholdsEditing />
                            </CardWithTitle>
                        </div>
                    )}
                    {!user.hasFreeAccess && (displayLicense || displaySubscription) && (
                        <div>
                            {displaySubscription ? (
                                <CardWithTitle label={t('profile.subscription.title')}>
                                    <CandidateSubscriptionView />
                                </CardWithTitle>
                            ) : (
                                <CardWithTitle label={t('profile.license.title')}>
                                    <CandidateLicenseView />
                                </CardWithTitle>
                            )}
                        </div>
                    )}
                </SRight>
            </SWrapper>
        </>
    );
};

export default Profile;
