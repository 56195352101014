import { RootState } from 'core/store';
import { isAfter } from 'date-fns';
import Event from 'core/models/event';
import EventStatus from 'core/enums/eventStatus';

export const eventsSelector = (state: RootState): Event[] => state.events.list;

export const eventByIdSelector = (state: RootState, id: string): Event | undefined =>
    state.events.list.find((event: Event) => event.id === id);

export const eventsSortedByDateSelector = (state: RootState): Event[] =>
    state.events.list.sort((a: Event, b: Event) => {
        if (isAfter(new Date(a.date), new Date(b.date))) {
            return 1;
        }
        return -1;
    });

export const notStartedEventsSortedByDateSelector = (state: RootState): Event[] =>
    state.events.list
        .sort((a: Event, b: Event) => {
            if (isAfter(new Date(a.date), new Date(b.date))) {
                return 1;
            }
            return -1;
        })
        .filter((a: Event) => EventStatus.NotStarted === a.status);

export const eventsByContactIdSortedByDateSelector = (state: RootState, id: string): Event[] =>
    state.events.list
        .filter((a: Event) => a.contact && a.contact.id === id)
        .sort((a: Event, b: Event) => {
            if (isAfter(new Date(a.date), new Date(b.date))) {
                return 1;
            }
            return -1;
        });

export const eventsByApplicationIdSortedByDateSelector = (state: RootState, id: string): Event[] =>
    state.events.list
        .filter((a: Event) => a.application?.id === id)
        .sort((a: Event, b: Event) => {
            if (isAfter(new Date(a.date), new Date(b.date))) {
                return 1;
            }
            return -1;
        });

export const eventsAlreadyFetchedSelector = (state: RootState): boolean => state.events.alreadyFetched;

export const eventsCount = (state: RootState): number => state.events.list.length;
