import styled from 'styled-components';
import { SColumn, SRow } from 'styles/layout';

export const SActions = styled(SRow)`
    gap: 1rem;
`;

export const SWrapper = styled(SColumn)`
    gap: 3rem;
`;

export const SCard = styled.div`
    background-color: ${({ theme }) => theme.color.grey.lighter};
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const SCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const SCardHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

export const SCardLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SCardLineLeft = styled.span`
    color: ${({ theme }) => theme.color.grey.light};
    font-weight: bold;
`;

export const SCardLineRight = styled.span`
    text-align: right;
`;
