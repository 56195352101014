/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { SetLicense } from 'core/useCases/subscription/actions';
import { candidatesAlreadyFetchedSelector } from 'core/useCases/candidates/selectors';
import { userSelector } from 'core/useCases/session/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';
import Candidate from 'core/models/candidate';
import License from 'core/models/license';

const WithSubscription: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const candidatesAlreadyFetched = useSelector(candidatesAlreadyFetchedSelector);
    const user = useSelector(userSelector) as Candidate;

    const licenseFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/candidates/${user.id}/license`)) as License;
            if (Object.keys(response).length > 0) {
                dispatch(SetLicense(response));
            } else {
                dispatch(SetLicense(null));
            }

            return response;
        } catch (error) {
            toast.error(t('common.error-occured'));

            return [];
        }
    };

    useEffect(() => {
        if (!candidatesAlreadyFetched) {
            setLoading(true);
            licenseFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithSubscription;
