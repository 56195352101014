import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';
import { SColumn } from 'styles/layout';

export const SOverlay = styled.div`
    position: fixed;
    z-index: 2000;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: rgba(0, 116, 151, 0.95);
    padding-top: 5rem;
    padding-bottom: 2rem;

    @media (min-width: ${deviceSize.mobile}px) {
        padding-top: 15rem;
        justify-content: center;
        align-items: center;
    }
`;

export const SWrapper = styled(SColumn)`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ theme }) => theme.modal.width};
    padding: ${({ theme }) => `${theme.modal.padding.bottom} ${theme.modal.padding.horizontal}`};
    padding-top: ${({ theme }) => theme.modal.padding.top};
    border-radius: ${({ theme }) => theme.modal.borderRadius};
    background-color: ${({ theme }) => theme.color.white};
    @media (max-width: ${deviceSize.mobile}px) {
        position: absolute;
        width: 100%;
    }
`;

export const SImageWrapper = styled.div`
    position: absolute;
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8rem;
    height: 8rem;
    top: -4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.white};
`;

export const SCloseButton = styled.button`
    display: flex;
    padding: 6px;
    position: absolute;
    right: 2rem;
    top: 2rem;
`;

export const SInner = styled(SColumn)`
    width: 100%;
    @media (max-width: ${deviceSize.mobile}px) {
        position:relative;
        overflow:auto;
        height:100%;
    }
`;

export const SButtons = styled.div`
    display: flex;
    justify-content: right;
    gap: 2rem;
`;
