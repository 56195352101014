import styled from 'styled-components';
import deviceSize from 'constants/deviceSize';

export const SColumn = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SSpacedRow = styled(SRow)`
    justify-content: space-between;
    width: 100%;
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: row;
    }
`;

export const SSpacedColumn = styled(SRow)`
    justify-content: space-between;
    width: 100%;
    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;
    }
`;

export const SSpacedRowResponsive = styled(SSpacedRow)`
    @media (max-width: ${deviceSize.mobile}px) {
        flex-wrap: wrap;
        gap: 1rem;
    }
`;

export const SSpacedRowWrapped = styled(SSpacedRow)`
    flex-wrap: wrap;
`;

export const SCenteredColumn = styled(SColumn)`
    align-items: center;
    text-align: center;
`;
