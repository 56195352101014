import Button from 'components/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SDeleteConfirmation } from './styles';

interface DeleteConfirmationProps {
    confirmAction: (() => void) | undefined;
    closeModal(): void;
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({ closeModal, confirmAction }) => {
    const { t } = useTranslation();

    if (!confirmAction) return null;

    const handleConfirm = () => {
        confirmAction();
        closeModal();
    };

    return (
        <div>
            <div
                style={{
                    backgroundColor: 'yellow',
                    fontSize: '20px',
                    marginBottom: 15,
                    padding: 20,
                    textAlign: 'center',
                }}
            >
                {t('candidate.delete-button.warning-details')}
            </div>
            <h1 style={{ textAlign: 'center' }}>{t('candidate.delete-button.warning')}</h1>
            <SDeleteConfirmation>
                <Button onClick={handleConfirm} variant="outlinedRed" large>
                    {t('candidate.delete-button.confirm')}
                </Button>
                <Button onClick={() => closeModal()} large>
                    {t('candidate.delete-button.reject')}
                </Button>
            </SDeleteConfirmation>
        </div>
    );
};

export default DeleteConfirmation;
