enum EventStatus {
    NotStarted = 'not-started',
    Completed = 'completed',
    Mobile = 'mobile',
    Email = 'email',
    Inmail = 'inmail',
    Phone = 'phone',
    Other = 'other',
    Normal = 'normal',
    High = 'high',
    Urgent = 'urgent',
}

export default EventStatus;
