import { DateRange, getEndOfWeekDate, getStartOfWeekDate } from 'utils/dateTimeFormat';
import { ResetDateRangeSelectedAction, SetDateRangeSelectedAction } from './dateRangeSelectedAction';
import DateRangeSelectedActionTypes from './types';

const currentDate = new Date();
const currentDateRange = {
    endDate: getEndOfWeekDate(currentDate),
    startDate: getStartOfWeekDate(currentDate),
};

type Action = SetDateRangeSelectedAction | ResetDateRangeSelectedAction;

const DateRangeSelectedReducer = (state: DateRange = currentDateRange, action: Action): DateRange => {
    switch (action.type) {
        case DateRangeSelectedActionTypes.SET_DATE_RANGE_SELECTED:
            return action.payload;
        case DateRangeSelectedActionTypes.RESET_DATE_RANGE_SELECTED:
            return currentDateRange;
        default:
            return state;
    }
};

export default DateRangeSelectedReducer;
