import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SList, SLink } from './styles';
import { userSelector } from 'core/useCases/session/selectors';
import { UserRole } from 'core/enums/userRole';
import { ReactComponent as Chart } from 'images/icons/Chart.svg';
import { ReactComponent as Network } from 'images/icons/Network.svg';
import { ReactComponent as File } from 'images/icons/File.svg';
import CandidateStatus from 'core/enums/candidateStatus';

interface ActionsProps {
    userRole: UserRole;
    userStatus: CandidateStatus;
    userHasPaid: boolean;
    userHasFreeAccess: boolean;
}

const CandidateActions: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <li>
                <SLink title={t('header.dashboard')} to="/dashboard">
                    <Chart />
                    <span>{t('header.dashboard')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('header.contact')} to="/contacts">
                    <Network />
                    <span>{t('header.contact')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('common.activities')} to="/activities">
                    <File />
                    <span>{t('common.activities')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('common.applications')} to="/applications">
                    <File />
                    <span>{t('common.applications')}</span>
                </SLink>
            </li>
        </>
    );
};

const AdminActions: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <li>
                <SLink title={t('header.candidates')} to="/candidates">
                    <Network />
                    <span>{t('header.candidates')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('header.agencies')} to="/agencies">
                    <Network />
                    <span>{t('header.agencies')}</span>
                </SLink>
            </li>
        </>
    );
};

const AgencyAdminActions: FC = () => {
    const { t } = useTranslation();
    return (
        <li>
            <SLink title={t('header.candidates')} to="/candidates">
                <Network />
                <span>{t('header.candidates')}</span>
            </SLink>
        </li>
    );
};

const PaymentActions: FC = () => {
    const { t } = useTranslation();
    return (
        <li>
            <SLink title={t('header.activate')} to="/">
                <File />
                <span>{t('header.activate')}</span>
            </SLink>
        </li>
    );
};

const Actions: FC<ActionsProps> = ({ userRole, userStatus, userHasPaid, userHasFreeAccess }) => {
    if (
        !userHasFreeAccess &&
        !userHasPaid &&
        (userStatus === CandidateStatus.WithoutLicenseAfterTrialPeriod ||
            userStatus === CandidateStatus.WithExpiredLicense)
    ) {
        return <PaymentActions />;
    }

    switch (userRole) {
        case UserRole.Candidate:
            return <CandidateActions />;
        case UserRole.AgencyAdmin:
            return <AgencyAdminActions />;
        case UserRole.Admin:
            return <AdminActions />;
        default:
            throw Error('Wrong user role type enum in header actions');
    }
};

const ConnectedActions: FC = () => {
    const user = useSelector(userSelector);

    if (!user) {
        return null;
    }

    return (
        <SList>
            <Actions
                userHasFreeAccess={Boolean(user.hasFreeAccess)}
                userHasPaid={Boolean(user.hasPaid)}
                userRole={user.role as UserRole}
                userStatus={user.status as CandidateStatus}
            />
        </SList>
    );
};

export default ConnectedActions;
