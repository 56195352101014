import React, { FC } from 'react';
import { STagContainer } from './styles';

interface TagProps {
    color: string;
}

const Tag: FC<TagProps> = ({ color, children }) => <STagContainer color={color}>{children}</STagContainer>;

export default Tag;
