import styled from "styled-components";

export const SWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export const SCard = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 1rem;
    padding: 1rem;
    gap: 0.5rem;
`;

export const SCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SCardHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    a, div:last-child {
        padding-bottom: 0.5rem;
    }
`;

export const SCardLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SCardLineLeft = styled.span`
    color: ${({ theme }) => theme.color.grey.light};
    font-weight: bold;
`;

export const SCardLineRight = styled.span``;
