import styled from 'styled-components';
import { SColumn } from 'styles/layout';
import { SBoldPrimaryText } from 'styles/components';

export const SWrapper = styled(SColumn)`
    margin-bottom: 4rem;
    margin-top: 2rem;
`;

type SSubjectProps = {
    isCompleted: boolean;
};

export const SSubject = styled(SBoldPrimaryText)<SSubjectProps>`
    ${({ isCompleted }) => (isCompleted ? 'text-decoration: line-through;' : '')}
`;

export const SCard = styled.div`
    background-color: ${({ theme }) => theme.color.grey.lighter};
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

export const SCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`;

export const SCardHeaderLeft = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;

    button {
        display: flex;
        align-items: center;
    }
`;

export const SCardHeaderStatus = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

export const SCardLine = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const STwoLines = styled.span`
    align-items: end;
    flex-direction: column;
    display: flex;
`;

export const SCardLineLeft = styled.span`
    color: ${({ theme }) => theme.color.grey.light};
    font-weight: bold;
`;

export const SCardLineRight = styled.span`
    text-align: right;
`;
