import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SItemsWrapper = styled(SRow)`
    position: relative;
    flex: auto;
`;

type SItemProps = {
    ratio: number;
    backgroundColor: string;
};

export const SItem = styled.div<SItemProps>`
    display: flex;
    align-items: center;
    padding-left: 1rem;
    height: 2.5rem;
    width: ${({ ratio }) => ratio}%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-left: solid 2px ${({ theme }) => theme.color.white};
    border-right: solid 2px ${({ theme }) => theme.color.white};
`;

type SIndicatorProps = {
    offset: number;
};

export const SIndicator = styled.span<SIndicatorProps>`
    position: absolute;
    bottom: -20px;
    left: calc(${({ offset }) => offset}% - 3px);
`;

export const STriangle = styled.div<SIndicatorProps>`
    position: absolute;
    bottom: 26px;
    left: calc(${({ offset }) => offset}% - 5px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 5px 0 5px;
    border-color: ${({ theme }) => theme.color.black} transparent transparent transparent;
`;
