import Application from 'core/models/application';
import { RootState } from 'core/store';

export const applicationsSelector = (state: RootState): Application[] => state.applications.list;

export const applicationByIdSelector = (state: RootState, id: string): Application | undefined =>
    state.applications.list.find((application: Application) => application.id === id);

export const applicationByContactIdSelector = (state: RootState, contactId: string): Application[] =>
    state.applications.list.filter((application: Application) => {
        const { contacts } = application;

        if (!contacts || !contacts.length) {
            return false;
        }

        return contacts.map((a) => a.id).includes(contactId);
    });

export const contactIdsWithApplicationSelector = (state: RootState): string[] => {
    const contactIds: string[] = [];
    state.applications.list.forEach(({ contacts }) => {
        if (contacts && contacts.length) {
            contacts.forEach(({ id }) => {
                if (!contactIds.includes(id)) {
                    contactIds.push(id);
                }
            });
        }
    });

    return contactIds;
};

export const applicationsAlreadyFetchedSelector = (state: RootState): boolean => state.applications.alreadyFetched;
