import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
    SButtons,
    SInfo,
    SSecondaryInformation,
    SSubCards,
    SWrapper,
    SImageWrapper,
    SContactStatusWrapper,
    SMale,
    SFemale,
} from './styles';
import Contact from 'core/models/contact';
import { ReactComponent as Mail } from 'images/icons/Mail.svg';
import { ReactComponent as Mobile } from 'images/icons/Mobile.svg';
import { ReactComponent as Phone } from 'images/icons/Phone.svg';
import { ReactComponent as PlusOne } from 'images/icons/PlusOne.svg';
import { ReactComponent as Informations } from 'images/icons/Informations.svg';
import ContactStatus from '../../ContactStatus';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import MainInformation from './MainInformation';
import { getParentOfContact, getRankOfContact } from 'core/useCases/contacts/utils';
import { Civility } from 'core/enums/civility';
import { ModalType } from 'core/useCases/modal/types';
import { OpenModal } from 'core/useCases/modal/modalAction';
import ResourceInformation from 'components/DetailsPage/ResourceInformation';
import DeleteContactButton from 'components/Contact/DeleteContactButton';

type InformationProps = {
    contact: Contact;
};

const Information: FC<InformationProps> = ({ contact }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        email,
        phoneNumber,
        mobileNumber,
        status,
        civility,
        parent,
        comments,
        resources: persistedResources,
    } = contact;
    const parentLabel = getParentOfContact(contact, t);
    const resources = persistedResources ? JSON.parse(persistedResources) : [];

    const openContactModal = () => {
        dispatch(
            OpenModal({
                defaultValues: contact,
                title: t('contact-details.update-contact'),
                type: ModalType.Contact,
            }),
        );
    };

    return (
        <SWrapper>
            <SButtons>
                <button onClick={openContactModal} title={t('contact-details.update-contact')} type="button">
                    <Edit />
                </button>
                <DeleteContactButton contact={contact} postAction={() => history.push('/contacts')} />
            </SButtons>
            {Civility.Male === civility ? <SMale /> : <SFemale />}
            <SSubCards>
                <MainInformation contact={contact} />
                <SContactStatusWrapper>
                    <ContactStatus status={status} />
                </SContactStatusWrapper>
                <SSecondaryInformation>
                    {email && (
                        <SInfo>
                            <SImageWrapper>
                                <Mail />
                            </SImageWrapper>
                            <span>{email}</span>
                        </SInfo>
                    )}
                    {mobileNumber && (
                        <SInfo>
                            <SImageWrapper>
                                <Mobile />
                            </SImageWrapper>
                            <span>{mobileNumber}</span>
                        </SInfo>
                    )}
                    {phoneNumber && (
                        <SInfo>
                            <SImageWrapper>
                                <Phone />
                            </SImageWrapper>
                            <span>{phoneNumber}</span>
                        </SInfo>
                    )}
                    <SInfo>
                        <SImageWrapper>
                            <PlusOne />
                        </SImageWrapper>
                        <span>{`${getRankOfContact(contact)}, `}</span>
                        {parent ? (
                            <Link to={`/contact/${parent.id}`}>
                                <span>{parentLabel}</span>
                            </Link>
                        ) : (
                            <span>{parentLabel}</span>
                        )}
                    </SInfo>
                    {comments && (
                        <SInfo>
                            <SImageWrapper>
                                <Informations />
                            </SImageWrapper>
                            <span>{comments}</span>
                        </SInfo>
                    )}
                </SSecondaryInformation>
                <ResourceInformation resources={resources} />
            </SSubCards>
        </SWrapper>
    );
};

export default Information;
