import styled from 'styled-components';
import { SRow } from 'styles/layout';

type SWrapperProps = {
    color: string;
};

export const SWrapper = styled(SRow)<SWrapperProps>`
    width: fit-content;
    align-items: center;
    height: 1.8rem;
    gap: 0.8rem;
    padding: 0 0.5rem;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 2px;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ color }) => color};
    cursor: default;
`;

export const SWrapperIcon = styled.span`
    height: 1.6rem;

    svg {
        max-height: 1.4rem;
        width: auto;
    }
`;
