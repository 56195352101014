enum InterviewType {
    Presentation = 'presentation',
    JobInvestigation = 'job-investigation',
    MarketAppropriateness = 'market-appropriateness',
    Targeting = 'targeting',
    JobInterviewPreparation = 'job-interview-preparation',
    PreSelection = 'pre-selection',
    Recruitment = 'recruitment',
    Meal = 'meal',
    Other = 'other',
}

export default InterviewType;
