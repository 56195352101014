import License from 'core/models/license';
import LicenseActionsType from './actionsTypes';
import { SetLicenseAction } from './actions';

export interface LicenseState {
    license: License | null;
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    license: null,
};

type Action = SetLicenseAction;

const subscriptionsReducer = (state: LicenseState = init, action: Action): LicenseState => {
    switch (action.type) {
        case LicenseActionsType.SET_LICENSE:
            return { ...state, alreadyFetched: true, license: action.payload };
        default:
            return state;
    }
};

export default subscriptionsReducer;
