import React, { FC } from 'react';
import { SColumn } from 'styles/layout';
import QUOTES, { QuoteProps } from './quotes';
import { SAuthor, SQuote } from './styles';

const QuoteContent: FC = () => {
    const choosenQuote: QuoteProps = QUOTES[Math.trunc(Math.random() * QUOTES.length)];

    return (
        <SColumn>
            <SQuote>&laquo; {choosenQuote.quote} &raquo;</SQuote>
            <SAuthor>{choosenQuote.author}</SAuthor>
        </SColumn>
    );
};

export default QuoteContent;
