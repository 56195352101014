import React, { FC } from 'react';
import { SDiagramPart, SLabel } from './style';

export type DiagramPartProps = {
    color: string;
    max: number;
    progress: number;
    cumul: number;
    zindex: number;
};

const DiagramPart: FC<DiagramPartProps> = ({ color, cumul, zindex, max, progress }) => {
    const progressInDegree = progress * 180;
    const cumulInDegree = cumul * 180;

    return (
        <SDiagramPart color={color} cumul={cumulInDegree} deg={progressInDegree} zindex={zindex}>
            <SLabel cumul={cumulInDegree}>{max}</SLabel>
        </SDiagramPart>
    );
};

export default DiagramPart;
