import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import DiagramPart from './DiagramPart';
import { SDiagram } from './style';

export type DiagramData = {
    key: string;
    rangeMin: number;
    rangeMax: number;
    color: string;
};

type DiagramProps = {
    data: DiagramData[];
    value: number;
};

const DiagramSemiCircle: FC<DiagramProps> = ({ data, value }) => {
    const { t } = useTranslation();
    if (!data) {
        return null;
    }

    // Dont display diagram if no values
    if (!data.find((d) => d.rangeMax > 0)) {
        return <span>{t('dashboard.no-tresholds')}</span>;
    }

    const max = data[data.length - 1]?.rangeMax;
    const valueInDegree = Math.min(value / max, 1) * 180;

    return (
        <SDiagram value={valueInDegree}>
            {data.map((d, i) => (
                <DiagramPart
                    key={d.key}
                    color={d.color}
                    cumul={d.rangeMax / max}
                    max={d.rangeMax}
                    progress={(d.rangeMax - d.rangeMin) / max}
                    zindex={data.length - i}
                />
            ))}
        </SDiagram>
    );
};

export default DiagramSemiCircle;
