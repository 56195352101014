enum SessionActionTypes {
    SET_SESSION_USER = 'SET_SESSION_USER',
    CLEAR_SESSION_USER = 'CLEAR_SESSION_USER',
}

export interface UserData {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export default SessionActionTypes;
