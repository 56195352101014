import React, { FC, ReactNode } from 'react';
import Container from 'components/layouts/common/Container';
import { SWrapper, SContainer } from './styles';

interface TopBannerProps {
    children: ReactNode;
}

const TopBanner: FC<TopBannerProps> = ({ children }) => (
    <SWrapper>
        <Container>
            <SContainer>{children}</SContainer>
        </Container>
    </SWrapper>
);

export default TopBanner;
