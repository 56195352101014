import React, { FC, useState, ReactNode } from 'react';
import { SParentButton, SButtons, SButtonsContainer, SWrapper } from './styles';
import Button from 'components/Button';

export type ButtonProps = {
    parentButtonContent?: ReactNode;
    iconButton?: boolean;
};

const MultiActionsButton: FC<ButtonProps> = ({ parentButtonContent, iconButton = false, children }) => {
    const [isActive, setActive] = useState(false);

    return (
        <SWrapper onMouseLeave={() => setActive(false)}>
            <SParentButton>
                {iconButton ? (
                    <button onClick={() => setActive(!isActive)} type="button">
                        {parentButtonContent}
                    </button>
                ) : (
                    <Button onClick={() => setActive(!isActive)} withShadow>
                        {parentButtonContent}
                    </Button>
                )}
            </SParentButton>
            {isActive && (
                <SButtonsContainer iconButton={iconButton}>
                    <SButtons>{children}</SButtons>
                </SButtonsContainer>
            )}
        </SWrapper>
    );
};

export default MultiActionsButton;
