export type QueryParam = {
    name: string;
    value: string;
};

export const computeUrlWithQueryParams = (baseUrl: string, params: Array<QueryParam>) =>
    `${baseUrl}?${params.map((param: QueryParam) => `${param.name}=${param.value}`).join('&')}`;

export const computeOutlookUrlWithQueryParams = (baseUrl: string, params: Array<QueryParam>) =>
    `${baseUrl}?${params.map((param: QueryParam) => `${param.name}=${param.value}`).join('&')}`;
