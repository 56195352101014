import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SText, SFormGroup, SImage, SLabel, SRadioImageWrapper } from './styles';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from '../ErrorMessageText';
import HintText from '../HintText';
import Label from '../Label';

interface RadioImageFieldChoice {
    image: string;
    title: string;
    value: string;
}

interface RadioImageProps {
    name: string;
    label?: string;
    hint?: string;
    choices: RadioImageFieldChoice[];
    validate?(value: string): boolean | string;
    disabled?: boolean;
    required?: boolean;
}

const RadioImageField: FC<RadioImageProps> = ({
    name,
    label,
    hint,
    choices,
    disabled = false,
    required = false,
    validate,
}) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        register,
        watch,
    } = useFormContext();
    const checkedValue = watch(name);

    return (
        <SFormGroup>
            {label && <Label name={name} required={required} text={label} />}
            <SRadioImageWrapper>
                {choices.map(({ image, title, value }) => {
                    const checked = checkedValue === value;

                    return (
                        <SLabel key={value} checked={checked} disabled={disabled} htmlFor={value}>
                            <SImage alt={title} src={image} />
                            <input
                                id={value}
                                type="radio"
                                value={value}
                                {...register(name, {
                                    required: required ? `${t('common.required-field')}` : false,
                                    validate,
                                })}
                                disabled={disabled}
                            />
                            <SText>{title}</SText>
                        </SLabel>
                    );
                })}
            </SRadioImageWrapper>
            {hint && <HintText text={hint} />}
            {errors[name] && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorMessageText text={message} />}
                />
            )}
        </SFormGroup>
    );
};

export default RadioImageField;
