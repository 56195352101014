import styled from 'styled-components';

import Button from 'components/Button';

export const SContactListSearchContainer = styled.div`
    display: flex;
    justify-content: center;
`;

export const SContactListSearch = styled.input`
    border-radius: 20px;
    border: ${({ theme }) => `1px solid ${theme.color.secondary.light}`};
    font-size: 14px;
    outline: none;
    padding: 9px 45px 9px 15px;
    width: 50%;

    &::placeholder {
        color: ${({ theme }) => theme.color.text.light};
    }
`;

export const SContactListSearchClearContainer = styled.div`
    margin-left: -40px;
`;

export const SContactListSelectButtonContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 8px 10px;
`;

export const SContactListSelectButton = styled(Button)`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

export const SContactListSelectButtonContentContainer = styled.div`
    align-content: center;
    color: ${({ theme }) => theme.color.secondary.main};
    display: flex;
    gap: 1rem;
`;

export const SContactListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    height: 45vh;
    overflow-y: scroll;
    margin-bottom: 10px;
    padding-right: 10px;
`;

export const SContactListElement = styled.div`
    align-items: center;
    border-radius: 6px;
    border: ${({ theme }) => `1px solid ${theme.color.grey.light2}`};
    box-shadow: 0px 1px 2px rgba(0, 116, 151, 0.1), -1px 1px 5px rgba(0, 116, 151, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    font-size: 12px;
    gap: 30px;
    margin: 3px;
    padding: 12px 14px;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey.lighter};
    }
`;

export const SContactListElementCheckBoxContainer = styled.div`
    display: flex;
`;

export const SContactListElementContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    min-width: 0;
    width: 100%;
`;

export const SContactListElementText = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const SContactListElementName = styled(SContactListElementText)`
    font-weight: 700;
`;

export const SContactListElementCompany = styled(SContactListElementText)`
    color: ${({ theme }) => theme.color.secondary.dark};
    font-weight: 700;
`;

export const SContactListFooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin: 30px 10px 0;
`;

export const SActionButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 2rem;
`;

export const SImportEmptyInfo = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    text-align: center;
`;
