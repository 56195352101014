/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Application from 'core/models/application';
import { SetApplications } from 'core/useCases/applications/applicationActions';
import { applicationsAlreadyFetchedSelector } from 'core/useCases/applications/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';

export interface ResponseData {
    applications: Application[];
}

const WithApplications: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const applicationsAlreadyFetched = useSelector(applicationsAlreadyFetchedSelector);

    const applicationsFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/applications`)) as ResponseData;

            dispatch(SetApplications(response.applications));

            return response.applications;
        } catch (error) {
            toast.error(t('common.error-occured'));

            dispatch(SetApplications([]));

            return [];
        }
    };

    useEffect(() => {
        if (!applicationsAlreadyFetched) {
            setLoading(true);
            applicationsFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithApplications;
