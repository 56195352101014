import styled from 'styled-components';
import { ReactComponent as Talk } from 'images/illustrations/Talk.svg';
import { STitle as SGenericTitle } from '../../Typography/Title/styles';

export const STitle = styled(SGenericTitle)`
    font-size: 3rem;
    color: ${({ theme }) => theme.color.white};
`;

export const STalk = styled(Talk)`
    width: 180px;
    height: 180px;
`;
