import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskMediaType } from 'core/enums/taskMediaType';
import EventMediaType from 'core/enums/eventMediaType';
import { SWrapper, SWrapperIcon } from './styles';
import { defaultTheme } from 'styles/theme';
import { ReactComponent as Mail } from 'images/whiteIcons/Mail.svg';
import { ReactComponent as Linkedin } from 'images/whiteIcons/Linkedin.svg';
import { ReactComponent as FaceToFace } from 'images/whiteIcons/FaceToFace.svg';
import { ReactComponent as Visioconferencing } from 'images/whiteIcons/Visioconferencing.svg';
import { ReactComponent as Phone } from 'images/whiteIcons/Phone.svg';
import { ReactComponent as Mobile } from 'images/whiteIcons/Mobile.svg';
import { ReactComponent as Other } from 'images/whiteIcons/Other.svg';

type ComputeMediaType = (mediaType: TaskMediaType | EventMediaType) => { color: string; icon: ReactNode };

export const computeActivityMediaTypeIconAndColor: ComputeMediaType = (mediaType) => {
    switch (mediaType) {
        case TaskMediaType.Email:
            return {
                color: defaultTheme.color.typeMedia.email,
                icon: <Mail />,
            };
        case TaskMediaType.Phone:
            return {
                color: defaultTheme.color.typeMedia.phone,
                icon: <Phone />,
            };
        case TaskMediaType.Mobile:
            return {
                color: defaultTheme.color.typeMedia.mobile,
                icon: <Mobile />,
            };
        case EventMediaType.Phone:
            return {
                color: defaultTheme.color.typeMedia.phone,
                icon: <Phone />,
            };
        case EventMediaType.Mobile:
            return {
                color: defaultTheme.color.typeMedia.mobile,
                icon: <Mobile />,
            };
        case TaskMediaType.InMail:
            return {
                color: defaultTheme.color.typeMedia.inmail,
                icon: <Linkedin />,
            };
        case EventMediaType.FaceToFace:
            return {
                color: defaultTheme.color.typeMedia.faceToFace,
                icon: <FaceToFace />,
            };
        case EventMediaType.VideoConferencing:
            return {
                color: defaultTheme.color.typeMedia.videoConferencing,
                icon: <Visioconferencing />,
            };
        case TaskMediaType.Other:
            return {
                color: defaultTheme.color.typeMedia.other,
                icon: <Other />,
            };
        default:
            throw Error('Wrong media type enum in compute icon method');
    }
};

type ActivityMediaTypeProps = {
    mediaType: TaskMediaType | EventMediaType;
};

const ActivityMediaType: FC<ActivityMediaTypeProps> = ({ mediaType }) => {
    const { t } = useTranslation();
    const computedIcon = computeActivityMediaTypeIconAndColor(mediaType);

    return (
        <SWrapper color={computedIcon?.color}>
            <SWrapperIcon>{computedIcon?.icon}</SWrapperIcon>
            <span>{t(`common.media-type.enum.${mediaType}`)}</span>
        </SWrapper>
    );
};

export default ActivityMediaType;
