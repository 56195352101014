import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { contactsCSVBuffer } from 'core/useCases/contacts/selectors';
import { CSVLink } from 'react-csv';
import { SExportButtonResponsive } from './styles';
import { ReactComponent as Export } from 'images/icons/Export.svg';

const ExportContact: FC = () => {
    const { t } = useTranslation();
    const csvData = useSelector(contactsCSVBuffer);

    return (
        <CSVLink data={csvData}>
            <SExportButtonResponsive>
                <Export />
                {t('contact.export-contacts')}
            </SExportButtonResponsive>
        </CSVLink>
    );
};

export default ExportContact;
