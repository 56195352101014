import { combineReducers, Reducer } from 'redux';
import { RootState } from '../store';
import sessionReducer from './session/reducer';
import contactsReducer from './contacts/reducer';
import tasksReducer from './tasks/reducer';
import agenciesReducer from './agencies/reducer';
import candidatesReducer from './candidates/reducer';
import SessionActionTypes from './session/types';
import modalReducer from './modal/reducer';
import eventsReducer from './events/reducer';
import DateRangeSelectedReducer from './dateRangeSelected/reducer';
import applicationsReducer from './applications/reducer';
import dashboardTresholdsReducer from './dashboardTresholds/reducer';
import subscriptionReducer from "./subscription/reducer";

export const appReducer = combineReducers({
    agencies: agenciesReducer,
    applications: applicationsReducer,
    candidates: candidatesReducer,
    contacts: contactsReducer,
    dashboardTresholds: dashboardTresholdsReducer,
    dateRangeSelected: DateRangeSelectedReducer,
    events: eventsReducer,
    modal: modalReducer,
    session: sessionReducer,
    subscription: subscriptionReducer,
    tasks: tasksReducer,
});

const rootReducer: Reducer = (state: RootState | undefined, action: any): RootState => {
    if (action.type === SessionActionTypes.CLEAR_SESSION_USER) {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
