import BACKEND_URL from 'constants/backendUrl';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { POST } from 'utils/http';
import { toast } from 'react-toastify';
import { SColumn } from 'styles/layout';
import TextInput from '../lib/TextInput';
import Button from 'components/Button';
import { REGEXP } from 'constants/validationsForm';

type Inputs = {
    email: string;
};

interface ResetPasswordFormProps {
    closeModal(): void;
}

const resetPasswordRequest = async (email: string) => {
    await POST(`${BACKEND_URL}/api/users/reset-password`, {
        email,
    });
};

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ closeModal }) => {
    const { t } = useTranslation();

    const methods = useForm<Inputs>();
    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        const { email } = values;
        try {
            await resetPasswordRequest(email);
            toast.success(t('reset-password-form.success'));
            closeModal();
        } catch (error) {
            toast.error(t('common.error-occured'));
        }
    });

    return (
        <SColumn>
            <span>{t('reset-password-form.explanation')}</span>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={t('common.email.label')}
                        name="email"
                        pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                        placeholder={t('common.email.placeholder')}
                        type="email"
                        required
                    />
                    <Button large submit>
                        {t('reset-password-form.submit')}
                    </Button>
                </form>
            </FormProvider>
        </SColumn>
    );
};

export default ResetPasswordForm;
