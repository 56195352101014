import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SResources = styled(SRow)`
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 2rem;

    a {
        text-decoration: underline;
    }
`;

export const SLabel = styled.div`
    margin-bottom: 3px;
    font-weight: bold;
    color: ${({ theme }) => theme.color.text.main};
`;

export const SWrapper = styled.div`
    width: 100%;
    border-radius: ${({ theme }) => theme.form.input.borderRadius};
    border: 1px solid ${({ theme }) => theme.color.grey.light};
    font-family: ${({ theme }) => theme.font};
    font-size: 14px;
    color: ${({ theme }) => theme.color.text.main};
    padding: 8px;
    box-sizing: border-box;
    margin-bottom: 2rem;
`;
