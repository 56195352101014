import styled from 'styled-components';
import deviceSize from 'constants/deviceSize';

interface ContainerProps {
    withMargin?: boolean;
}

export const SContainer = styled.div<ContainerProps>`
    width: 100%;
    max-width: ${(props) => props.theme.layout.width};
    margin: ${({ withMargin }) => (withMargin ? '30px auto 0' : 'auto')};
    padding: 0 20px;

    .bold {
        font-weight: bold;
    }

    @media (max-width: ${deviceSize.mobile}px) {
        padding: 0 10px;
    }
`;
