import AuthInitializer from 'components/authInitializer';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import DataProvider from './contexts/data';

import { defaultTheme } from './styles/theme';
import './styles/global.css';
import Toast from 'components/Toast';
import Router from './pages/Router';
import 'react-toastify/dist/ReactToastify.css';

const App = () => (
    <ThemeProvider theme={defaultTheme}>
        <DataProvider>
            <AuthInitializer>
                <Toast />
                <Router />
            </AuthInitializer>
        </DataProvider>
    </ThemeProvider>
);
export default App;
