import React, { FC } from 'react';

interface YoutubeEmbedProps {
    embedId: string;
    height?: number;
    width?: number;
}

const YoutubeEmbed: FC<YoutubeEmbedProps> = ({ embedId, height = 396, width = 704 }) => (
    <div className="video-responsive">
        <iframe
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            height={height}
            src={`https://www.youtube.com/embed/${embedId}`}
            title="Embedded youtube"
            width={width}
            allowFullScreen
        />
    </div>
);

export default YoutubeEmbed;
