import React, { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../Header';
import Container from '../layouts/common/Container';
import Footer from '../Footer';
import { SHeader, SMain } from './styles';
import useResponsive from 'hooks/responsive';
import MobileHeader from 'components/MobileHeader';

type LayoutProps = {
    children: ReactNode;
    pageTitle: string;
    stateBar?: ReactNode;
    topBanner?: ReactNode;
};

const Layout: FC<LayoutProps> = ({ pageTitle, stateBar, topBanner, children }) => {
    const { isMobile } = useResponsive();
    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <SHeader>
                {isMobile ? <MobileHeader /> : <Header />}
            </SHeader>
            <SMain>
                {stateBar && stateBar}
                {topBanner && topBanner}
                <Container withMargin>{children}</Container>
            </SMain>
            <Footer />
        </>
    )
};

export default Layout;
