import styled from 'styled-components';
import { SColumn, SRow } from 'styles/layout';

export const SSubCards = styled(SColumn)`
    width: 100%;
    gap: 1rem;
`;

export const SAddButton = styled(SRow)`
    gap: 1rem;
    line-height: 1.8;
    justify-content: unset;
`;
