/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import AgencyAdmin from 'core/models/agencyAdmin';
import Candidate from 'core/models/candidate';
import { SetCandidates } from 'core/useCases/candidates/candidateActions';
import { candidatesAlreadyFetchedSelector } from 'core/useCases/candidates/selectors';
import { userSelector } from 'core/useCases/session/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';

const WithAgencyCandidates: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const candidatesAlreadyFetched = useSelector(candidatesAlreadyFetchedSelector);
    const user = useSelector(userSelector) as AgencyAdmin;

    const candidatesFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/agency/${user.agency.id}/candidates`)) as Candidate[];

            dispatch(SetCandidates(response));

            return response;
        } catch (error) {
            toast.error(t('common.error-occured'));

            dispatch(SetCandidates([]));

            return [];
        }
    };

    useEffect(() => {
        if (!candidatesAlreadyFetched) {
            setLoading(true);
            candidatesFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithAgencyCandidates;
