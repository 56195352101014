import styled from 'styled-components';
import { SColumn, SSpacedRow } from 'styles/layout';
import deviceSize from 'constants/deviceSize';

export const SWrapper = styled(SSpacedRow)`
    margin-top: 3.5rem;
    gap: 6.5rem;

    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;
        gap: 1rem;
    }
`;

export const SAvatar = styled.img`
    width: 180px;
    height: 180px;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 200px;
        height: 200px;
        margin: 0 auto;
    }
`;

export const SRight = styled(SColumn)`
    flex: auto;
    gap: 2rem;
`;

export const SInputWrapper = styled.div`
    width: 30%;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;
