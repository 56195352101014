import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationStatusEnum from 'core/enums/applicationStatus';
import { SWrapper } from './styles';
import { defaultTheme } from 'styles/theme';
import { ReactComponent as Lost } from 'images/icons/Negative.svg';
import { ReactComponent as Engaged } from 'images/icons/EngagedContact.svg';
import { ReactComponent as New } from 'images/icons/NewContact.svg';
import { ReactComponent as Win } from 'images/icons/Send.svg';

type ApplicationStatusProps = {
    status: ApplicationStatusEnum;
};

type ComputeStatus = (status: ApplicationStatusEnum) => { color: string; icon: ReactNode };

export const computeApplicationIconAndColorStatus: ComputeStatus = (status) => {
    switch (status) {
        case ApplicationStatusEnum.New:
            return {
                color: defaultTheme.color.applicationStatus.new,
                icon: <New />,
            };
        case ApplicationStatusEnum.Engaged:
            return {
                color: defaultTheme.color.applicationStatus.engaged,
                icon: <Engaged />,
            };
        case ApplicationStatusEnum.Win:
            return {
                color: defaultTheme.color.applicationStatus.win,
                icon: <Win />,
            };
        case ApplicationStatusEnum.Lost:
            return {
                color: defaultTheme.color.applicationStatus.lost,
                icon: <Lost />,
            };
        default:
            throw Error('Wrong application status enum in compute color method');
    }
};

const ApplicationStatus: FC<ApplicationStatusProps> = ({ status }) => {
    const { t } = useTranslation();
    const computedStatus = computeApplicationIconAndColorStatus(status);

    return (
        <SWrapper color={computedStatus?.color}>
            {computedStatus?.icon}
            {t(`application.status.enum.${status}`)}
        </SWrapper>
    );
};

export default ApplicationStatus;
