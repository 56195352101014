import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Button from 'components/Button';
import FloatingButton from 'components/FloatingButton';
import TabButton from 'components/TabButton';
import TabButtonsGroup from 'components/TabButtonsGroup';
import { SSpacedRowResponsive } from 'styles/layout';
import TaskList from 'components/Task/TaskList';
import EventList from 'components/Event/EventList';
import { useDispatch, useSelector } from 'react-redux';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import { tasksSortedByDeadlineSelector } from 'core/useCases/tasks/selectors';
import EventType from 'core/enums/eventType';
import ActivityType from 'core/enums/activityType';
import WeekPicker from 'components/WeekPicker';
import { isDateInDateRange } from 'utils/dateTimeFormat';
import Task from 'core/models/task';
import Event from 'core/models/event';
import { eventsSortedByDateSelector } from 'core/useCases/events/selectors';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import SelectFilters from 'components/SelectFilters';
import { SRight, SRightResponsive, SSearchInput, SPremiumDiv } from './styles';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import EventStatusEnum from 'core/enums/eventStatus';
import EventStatus from 'components/Event/EventStatus';
import { getContactLabelWithoutCivility } from 'core/useCases/contacts/utils';
import { useIsPremium } from 'hooks/userIsPremium';

const ActivityList: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { search: searchLocation } = useLocation();
    const queryParams = new URLSearchParams(searchLocation);
    const activityType = queryParams.get('type');
    const [activityTypeSelected, setActivityTypeSelected] = useState<ActivityType>(
        ActivityType.Event === activityType ? activityType : ActivityType.Task,
    );
    const [filteredTasks, setFilteredTasks] = useState<Task[]>([]);
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const [search, setSearch] = useState<string>('');
    const [filterOptionsValues, setFilterOptionsValues] = useState<string[]>([]);
    const tasksSorted = useSelector(tasksSortedByDeadlineSelector);
    const eventsSorted = useSelector(eventsSortedByDateSelector);
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const history = useHistory();
    const isPremium = useIsPremium();

    const filterTasks = () => {
        let localTasks = [...tasksSorted];

        if (dateRangeSelected) {
            localTasks = localTasks.filter((task: Task) =>
                isDateInDateRange(new Date(task.deadline), dateRangeSelected),
            );
        }

        if (search) {
            const computedSearch = search.toLowerCase();
            localTasks = localTasks.filter(
                (task: Task) =>
                    task.contact &&
                    (getContactLabelWithoutCivility(task.contact).toLowerCase().includes(computedSearch) ||
                        task.contact.company?.toLowerCase().includes(computedSearch) ||
                        task.contact.email?.toLowerCase().includes(computedSearch) ||
                        task.contact.mobileNumber?.toLowerCase().includes(computedSearch)),
            );
        }

        if (filterOptionsValues.length > 0) {
            localTasks = localTasks.filter((task: Task) => {
                const statusFilters = filterOptionsValues.filter((value) =>
                    [EventStatusEnum.Completed.toString(), EventStatusEnum.NotStarted.toString()].includes(value),
                );
                const mediaTypeFilters = filterOptionsValues.filter((value) =>
                    ['mobile', 'phone', 'email', 'inmail', 'other'].includes(value),
                );
                const priorityFilters = filterOptionsValues.filter((value) =>
                    ['normal', 'high', 'urgent'].includes(value),
                );

                const matchesStatus = statusFilters.length > 0 ? statusFilters.includes(task.status) : true;
                const matchesMediaType =
                    mediaTypeFilters.length > 0
                        ? task.mediaType !== undefined && mediaTypeFilters.includes(task.mediaType)
                        : true;
                const matchesPriority = priorityFilters.length > 0 ? priorityFilters.includes(task.priority) : true;

                return matchesStatus && matchesMediaType && matchesPriority;
            });
        }

        return localTasks;
    };

    const filterEvents = () => {
        if (!dateRangeSelected) return [];
        let localEvents = [...eventsSorted];

        if (dateRangeSelected) {
            localEvents = localEvents.filter((event: Event) =>
                isDateInDateRange(new Date(event.date), dateRangeSelected),
            );
        }

        if (search) {
            const computedSearch = search.toLowerCase();
            localEvents = localEvents.filter(
                (event: Event) =>
                    event.contact &&
                    (getContactLabelWithoutCivility(event.contact).toLowerCase().includes(computedSearch) ||
                        event.contact.company?.toLowerCase().includes(computedSearch) ||
                        event.contact.email?.toLowerCase().includes(computedSearch) ||
                        event.contact.mobileNumber?.toLowerCase().includes(computedSearch)),
            );
        }

        if (filterOptionsValues.length > 0) {
            localEvents = localEvents.filter((event: Event) => filterOptionsValues.includes(event.status));
        }

        return localEvents;
    };

    useEffect(() => {
        setFilteredTasks(filterTasks());
        setFilteredEvents(filterEvents());
    }, [search, dateRangeSelected, tasksSorted, eventsSorted, filterOptionsValues]);

    const openInterviewModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    type: EventType.Interview,
                },
                title: t('activities.add-rendez-vous'),
                type: ModalType.Event,
            }),
        );
    };

    const openTaskModal = () => {
        dispatch(
            OpenModal({
                title: t('task-list.add-task'),
                type: ModalType.Task,
            }),
        );
    };

    const filterOptions = [
        {
            component: <EventStatus status={EventStatusEnum.Completed} />,
            value: EventStatusEnum.Completed.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.NotStarted} />,
            value: EventStatusEnum.NotStarted.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Normal} />,
            value: EventStatusEnum.Normal.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.High} />,
            value: EventStatusEnum.High.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Urgent} />,
            value: EventStatusEnum.Urgent.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Mobile} />,
            value: EventStatusEnum.Mobile.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Phone} />,
            value: EventStatusEnum.Phone.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Email} />,
            value: EventStatusEnum.Email.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Inmail} />,
            value: EventStatusEnum.Inmail.toString(),
        },
        {
            component: <EventStatus status={EventStatusEnum.Other} />,
            value: EventStatusEnum.Other.toString(),
        },
    ];

    const handleSearchClick = () => {
        if (!isPremium) {
            history.push('/ask-subscription');
        }
    };

    const SearchInput = (
        <SSearchInput>
            <input
                onChange={(event) => setSearch(event.target.value)}
                onClick={handleSearchClick}
                placeholder={t('activity.search-for-a-activity')}
                type="text"
                value={search}
            />
            {!isPremium && (
                <SPremiumDiv>
                    <Premium />
                </SPremiumDiv>
            )}
        </SSearchInput>
    );

    return (
        <>
            <SSpacedRowResponsive>
                <WeekPicker />
                <SRightResponsive>
                    {SearchInput}
                    <SRight>
                        <SelectFilters
                            isPremium={isPremium}
                            options={filterOptions}
                            selectedOptionsValues={filterOptionsValues}
                            setSelectedOptionsValues={setFilterOptionsValues}
                        />
                    </SRight>
                    <TabButtonsGroup>
                        <TabButton
                            onClick={() => setActivityTypeSelected(ActivityType.Task)}
                            variant={ActivityType.Task === activityTypeSelected ? 'plain' : 'text'}
                        >
                            {t('activities.tab.tasks')} {filteredTasks.length}
                        </TabButton>
                        <TabButton
                            onClick={() => setActivityTypeSelected(ActivityType.Event)}
                            variant={ActivityType.Event === activityTypeSelected ? 'plain' : 'text'}
                        >
                            {t('activities.tab.rendez-vous')} {filteredEvents.length}
                        </TabButton>
                    </TabButtonsGroup>
                </SRightResponsive>
            </SSpacedRowResponsive>
            {ActivityType.Task === activityTypeSelected ? (
                <TaskList tasks={filteredTasks} />
            ) : (
                <EventList events={filteredEvents} />
            )}
            <FloatingButton>
                <Button onClick={openInterviewModal} withShadow>
                    {t('activities.add-rendez-vous')}
                </Button>
                <Button onClick={openTaskModal} withShadow>
                    {t('task-list.add-task')}
                </Button>
            </FloatingButton>
        </>
    );
};

export default ActivityList;
