/* eslint-disable max-len */
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import EventMood from 'core/enums/eventMood';
import Mood1  from 'images/icons/Mood1.svg';
import Mood2 from 'images/icons/Mood2.svg';
import Mood3 from 'images/icons/Mood3.svg';
import Mood4 from 'images/icons/Mood4.svg';
import { SCenteredColumn } from 'styles/layout';
import { SLabel } from './styles';

const computeEventMoodIcon = (mood: EventMood, moodName: string): ReactNode => {
    switch (mood) {
        case EventMood.Missed:
            return <img alt={moodName} src={Mood1} />;
        case EventMood.Poor:
            return <img alt={moodName} src={Mood2} />;
        case EventMood.Satisfying:
            return <img alt={moodName} src={Mood3} />;
        case EventMood.Excellent:
            return <img alt={moodName} src={Mood4} />;
        default:
            throw Error('Wrong event mood enum in compute event mood icon method');
    }
};

type EventMoodVisualisationProps = {
    mood: EventMood;
};

const EventMoodVisualisation: FC<EventMoodVisualisationProps> = ({ mood }) => {
    const { t } = useTranslation();

    return (
        <SCenteredColumn>
            {computeEventMoodIcon(mood, t(`common.mood.enum.${mood}`))}
            <SLabel>{t(`common.mood.enum.${mood}`)}</SLabel>
        </SCenteredColumn>
    );
};

export default EventMoodVisualisation;
