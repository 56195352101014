import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SActions = styled(SRow)`
    gap: 1rem;

    &:hover {
        fill: ${({ theme }) => theme.color.secondary.main};
    }
`;
