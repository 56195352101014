import styled from 'styled-components';
import { SBoldPrimaryText } from 'styles/components';
import { SRow } from 'styles/layout';

export const SName = styled(SBoldPrimaryText)`
    font-size: 1.6rem;
    line-height: 0.7;
`;

export const SWrapper = styled(SRow)`
    gap: 1rem;
`;
