/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Contact from 'core/models/contact';
import { SetContacts } from 'core/useCases/contacts/contactActions';
import { contactsAlreadyFetchedSelector } from 'core/useCases/contacts/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';
import { Dispatch } from 'redux';

export interface ResponseData {
    contacts: Contact[];
}

export const contactsFetcher = async (dispatch: Dispatch, t: TFunction) => {
    try {
        const response = (await GET(`${BACKEND_URL}/api/contacts`)) as ResponseData;

        dispatch(SetContacts(response.contacts));

        return response.contacts;
    } catch (error) {
        toast.error(t('common.error-occured'));

        dispatch(SetContacts([]));

        return [];
    }
};

const WithContacts: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const contactsAlreadyFetched = useSelector(contactsAlreadyFetchedSelector);

    useEffect(() => {
        if (!contactsAlreadyFetched) {
            setLoading(true);
            contactsFetcher(dispatch, t);
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithContacts;
