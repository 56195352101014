import React, { FC } from 'react';
import { STipBox, SNumberCircle, SText } from './styles';

type TipProps = {
    number: number;
    content: string;
};

const Tip: FC<TipProps> = ({ number, content }) => (
    <STipBox>
        <SNumberCircle>{number}</SNumberCircle>
        <SText dangerouslySetInnerHTML={{ __html: content }} />
    </STipBox>
);

export default Tip;
