import React, { FC } from 'react';
import { SError } from './styles';

interface ErrorTextProps {
    text: string;
}

const ErrorMessageText: FC<ErrorTextProps> = ({ text }) => <SError>{text}</SError>;

export default ErrorMessageText;
