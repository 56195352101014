import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import BACKEND_URL from 'constants/backendUrl';
import { POST } from 'utils/http';
import { TextInput } from '../lib';
import { useDispatch } from 'react-redux';
import Task from 'core/models/task';
import Event from 'core/models/event';
import { UpdateTask } from 'core/useCases/tasks/taskActions';
import { CloseModal } from 'core/useCases/modal/modalAction';
import ActivityType from 'core/enums/activityType';
import { UpdateEvent } from 'core/useCases/events/eventActions';

type Inputs = {
    comments?: string;
    resources?: string;
};

interface AdditionalInformationFormProps {
    closeModal(): void;
    defaultValues: { id: string; comments?: string; resources?: string };
    activityType: ActivityType;
}

type AddOrUpdateActivityRequest = {
    activity: Event | Task;
};

const addOrUpdateActivityRequest = async (
    inputs: Inputs,
    id: string,
    activityType: ActivityType,
): Promise<Task | Event> => {
    const response = (await POST<AddOrUpdateActivityRequest>(
        `${BACKEND_URL}/api/activity/update-additional-information`,
        { activityType, id, inputs },
    )) as AddOrUpdateActivityRequest;

    return response.activity;
};

const AdditionalInformationForm: FC<AdditionalInformationFormProps> = ({ closeModal, defaultValues, activityType }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { id, comments, resources } = defaultValues;

    const methods = useForm<Inputs>({
        defaultValues: { comments, resources },
    });
    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        const updatedValues = { ...values, resources: defaultValues.resources };
        try {
            const activity = await addOrUpdateActivityRequest(updatedValues, id, activityType);
            if (ActivityType.Task === activityType) {
                dispatch(UpdateTask(activity as unknown as Task));
            } else {
                dispatch(UpdateEvent(activity as unknown as Event));
            }
            dispatch(CloseModal());
            toast.success(t(`activity.additional-information.${comments ? 'update' : 'add'}.success`));
        } catch (e) {
            toast.error(t(`activity.additional-information.${comments ? 'update' : 'add'}.error`));
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <TextInput
                    label={t('common.comment')}
                    name="comments"
                    placeholder={t('common.write-a-comment')}
                    isTextArea
                />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{comments ? t('common.update') : t('common.add')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default AdditionalInformationForm;
