import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import CandidateList from 'components/admin/Candidate/CandidateList';

const Candidates: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.dashboard.meta-title')}>
            <CandidateList />
        </Layout>
    );
};

export default Candidates;
