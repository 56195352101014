enum MeetingType {
    Afterwork = 'afterwork',
    Workshop = 'workshop',
    Conference = 'conference',
    Exhibition = 'exhibition',
    Training = 'training',
    Webinar = 'webinar',
}

export default MeetingType;
