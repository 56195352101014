import Contact from 'core/models/contact';
import { OptionsProps } from 'components/Forms/lib/SelectInput';
import EventMediaType from 'core/enums/eventMediaType';
import { TaskMediaType } from 'core/enums/taskMediaType';
import ContactStatus from 'core/enums/contactStatus';
import TaskStatus from 'core/enums/taskStatus';
import Task from 'core/models/task';

export const getContactLabel = (contact: Contact, t: any): string =>
    `${t(`common.civility.enum.${contact.civility}`)} ${contact.firstName} ${contact.lastName}`;

export const getContactLabelWithoutCivility = (contact: Contact): string => `${contact.firstName} ${contact.lastName}`;

export const getJobAndCompanyOfContact = (contact: Contact, t: any): string => {
    const { job, company } = contact;

    return `${job}${job && company ? ` ${t('common.for')} ` : ''}${company}`;
};

export const getParentOfContact = (contact: Contact, t: any): string => {
    const { parent } = contact;

    return parent ? `${parent.firstName} ${parent.lastName}` : t('common.you');
};

export const getRankOfContact = (contact: Contact): number => {
    const { rank } = contact;

    return rank;
};

export const isMobileAvailableForContact = (contact: Contact): boolean => !!contact.mobileNumber;
export const isPhoneAvailableForContact = (contact: Contact): boolean => !!contact.phoneNumber;
export const isEmailAvailableForContact = (contact: Contact): boolean => !!contact.email;

export const getTaskMediaOptionsForContact = (contact: Contact, t: any): OptionsProps => {
    const mediaTypeOptionsComputed: OptionsProps = [];
    const computeOption = (mediaType: TaskMediaType) => ({
        label: t(`common.media-type.enum.${mediaType}`),
        value: mediaType,
    });

    if (isMobileAvailableForContact(contact)) {
        mediaTypeOptionsComputed.push(computeOption(TaskMediaType.Mobile));
    }
    if (isPhoneAvailableForContact(contact)) {
        mediaTypeOptionsComputed.push(computeOption(TaskMediaType.Phone));
    }
    if (isEmailAvailableForContact(contact)) {
        mediaTypeOptionsComputed.push(computeOption(TaskMediaType.Email));
    }

    mediaTypeOptionsComputed.push(computeOption(TaskMediaType.InMail));

    return mediaTypeOptionsComputed;
};

export const getEventMediaOptionsForContact = (contact: Contact, t: any): OptionsProps => {
    const mediaTypeOptionsComputed: OptionsProps = [];
    const computeOption = (mediaType: EventMediaType) => ({
        label: t(`common.media-type.enum.${mediaType}`),
        value: mediaType,
    });

    if (isMobileAvailableForContact(contact)) {
        mediaTypeOptionsComputed.push(computeOption(EventMediaType.Mobile));
    }
    if (isPhoneAvailableForContact(contact)) {
        mediaTypeOptionsComputed.push(computeOption(EventMediaType.Phone));
    }
    mediaTypeOptionsComputed.push(computeOption(EventMediaType.FaceToFace));
    mediaTypeOptionsComputed.push(computeOption(EventMediaType.VideoConferencing));

    return mediaTypeOptionsComputed;
};

export const getEngagementDateOfContacts = (
    contacts: Contact[],
    tasks: Task[],
): Array<{ contactId: String; date: Date }> => {
    const firstTasks: Array<{ contactId: String; date: Date }> = [];
    const filteredContactIds = contacts
        .filter((contact) => [ContactStatus.Engaged, ContactStatus.Thanked].includes(contact.status))
        .map((contact) => contact.id);

    filteredContactIds.forEach((id) => {
        const firstTaskOfContact = tasks
            .filter((task) => task?.contact)
            .find((task) => (task.contact as Contact).id === id && TaskStatus.Completed === task.status);
        if (firstTaskOfContact) {
            firstTasks.push({
                contactId: id,
                date: firstTaskOfContact.deadline,
            });
        }
    });

    return firstTasks;
};

export const getContactDetailsLabel = (contact: Contact, t: any): string =>
    [getContactLabel(contact, t), contact.email, contact.phoneNumber, contact.mobileNumber]
        .filter((d) => d)
        .join(' - ');
