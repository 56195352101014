import React, { FC, useState } from 'react';
import { isAfter, isBefore } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import Contact from 'core/models/contact';
import MultiActionsButton from 'components/MultiActionsButton';
import Plus from 'images/icons/Plus.svg';
import Button from 'components/Button';
import { SButton, SSubCard, SWrapper } from './styles';
import EventType from 'core/enums/eventType';
import TabButtonsGroup from 'components/TabButtonsGroup';
import TabButton from 'components/TabButton';
import { RootState } from 'core/store';
import { eventsByContactIdSortedByDateSelector } from 'core/useCases/events/selectors';
import { tasksByContactIdSortedByDeadlineSelector } from 'core/useCases/tasks/selectors';
import { computeActivitiesFromTasksAndEvents } from 'core/useCases/activities/utils';
import Event from 'core/models/event';
import Task from 'core/models/task';
import InProgressActivitiesSummary from 'components/Activity/InProgressActivitiesSummary';
import FinishedActivitiesSummary from 'components/Activity/FinishedActivitiesSummary';
import { SBoldPrimaryText } from 'styles/components';
import CardWithTitle from 'components/CardWithTitle';
import useResponsive from 'hooks/responsive';

type ActivitiesProps = {
    contact: Contact | null;
};

const Activities: FC<ActivitiesProps> = ({ contact }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile } = useResponsive();
    const [inProgressOptionSelected, setInProgressOptionSelected] = useState<boolean>(true);
    const contactId = contact?.id;
    const createdDate = contact?.createdDate || new Date();
    const creationDetails = {
        date: createdDate,
        label: t('contact.contact-added'),
    };

    const openTaskModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    contact,
                },
                disabledFields: [DisabledField.ContactId],
                title: t('task-list.add-task'),
                type: ModalType.Task,
            }),
        );
    };

    const openInterviewModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    contact,
                    type: EventType.Interview,
                },
                disabledFields: [DisabledField.ContactId],
                title: t('activities.add-rendez-vous'),
                type: ModalType.Event,
            }),
        );
    };

    const events: Event[] = useSelector((state: RootState) =>
        contactId ? eventsByContactIdSortedByDateSelector(state, contactId) : [],
    );
    const tasks: Task[] = useSelector((state: RootState) =>
        contactId ? tasksByContactIdSortedByDeadlineSelector(state, contactId) : [],
    );

    const { finishedActivities, inProgressActivities } = computeActivitiesFromTasksAndEvents(tasks, events, t);

    finishedActivities.sort((a, b): number => {
        if (isBefore(new Date(a.date), new Date(b.date))) {
            return 1;
        }
        return -1;
    });

    inProgressActivities.sort((a, b): number => {
        if (isAfter(new Date(a.date), new Date(b.date))) {
            return 1;
        }
        return -1;
    });

    const Card = () => (
        <>
            <SSubCard>
                <TabButtonsGroup>
                    <TabButton
                        onClick={() => setInProgressOptionSelected(true)}
                        variant={inProgressOptionSelected ? 'plain' : 'text'}
                    >
                        {t('common.in-progress')}
                    </TabButton>
                    <TabButton
                        onClick={() => setInProgressOptionSelected(false)}
                        variant={inProgressOptionSelected ? 'text' : 'plain'}
                    >
                        {t('common.finished')}
                    </TabButton>
                </TabButtonsGroup>
                {inProgressOptionSelected ? (
                    <InProgressActivitiesSummary activities={inProgressActivities} />
                ) : (
                    <FinishedActivitiesSummary activities={finishedActivities} creationDetails={creationDetails} />
                )}
            </SSubCard>
            <SButton>
                <MultiActionsButton
                    parentButtonContent={
                        <>
                            <img alt="" src={Plus} width="20" />
                            {t('common.add')}
                        </>
                    }
                >
                    <Button onClick={openTaskModal} variant="popinText">
                        {t('task.add')}
                    </Button>
                    <Button onClick={openInterviewModal} variant="popinText">
                        {t('interview.add-rendez-vous')}
                    </Button>
                </MultiActionsButton>
            </SButton>
        </>
    );
    return isMobile ? (
        <>
            <SBoldPrimaryText>{t('common.activities-details')}</SBoldPrimaryText>
            <Card />
        </>
    ) : (
        <SWrapper>
            <CardWithTitle label={t('common.activities')}>
                <Card />
            </CardWithTitle>
        </SWrapper>
    );
};

export default Activities;
