import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import CardWithTitle from 'components/CardWithTitle';
import Contact from 'core/models/contact';
import ContactSubCard from 'components/Contact/ContactSubCard';

type LinekdContactProps = {
    contact: Contact;
};

const LinkedContact: FC<LinekdContactProps> = ({ contact }) => {
    const { t } = useTranslation();

    return (
        <CardWithTitle label={t('common.linked-contacts')}>
            <ContactSubCard contact={contact} isRankAndParentDisplayed />
        </CardWithTitle>
    );
};

export default LinkedContact;
