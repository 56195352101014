/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE } from 'utils/http';
import BACKEND_URL from 'constants/backendUrl';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { ReactComponent as Delete } from 'images/icons/Delete.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { DeleteEvent } from 'core/useCases/events/eventActions';

const deleteEventRequest = async (id: string): Promise<void> => {
    await DELETE(`${BACKEND_URL}/api/event/${id}`);
};

type DeleteEventButtonProps = {
    eventId: string;
};

const DeleteEventButton: FC<DeleteEventButtonProps> = ({ eventId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const isContactPage = pathname.split('/')[1] === 'contact';

    const deleteEvent = async (id: string): Promise<void> => {
        try {
            await deleteEventRequest(id);
            dispatch(DeleteEvent(id));
            toast.success(t('event.remove.success'));
            // No redirection if on contact page
            if (!isContactPage) {
                history.push('/activities');
            }
        } catch (error) {
            toast.error(t('event.remove.error'));
        }
    };

    return (
        <button onClick={() => deleteEvent(eventId)} title={t('event.remove.label')} type="button">
            <Delete />
        </button>
    );
};

export default DeleteEventButton;
