import styled from 'styled-components';
import deviceSize from 'constants/deviceSize';

export const SWrapper = styled.div`
    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;

        button {
            width: 100%;
        }
    }
`;

export const SFilterButtonLabel = styled.div`
    align-items: center;
    display: flex;
    gap: 8px;
    width: 100px;
    position: relative;
`;

interface SFilterMenuProps {
    isOpen: boolean;
}

export const SFilterMenu = styled.div<SFilterMenuProps>`
    background-color: white;
    border-radius: 2px;
    box-shadow: -1px 2px 4px rgba(0, 0, 0, 0.1), -4px 5px 8px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 4px;
    padding: 20px 18px;
    position: absolute;
    visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
    width: 350px;
    z-index: 1;

    & > button {
        color: ${({ theme }) => theme.color.secondary.main};
    }
`;

export const SFilterOption = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction; row;
    gap: 10px;
`;

export const SFilterColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const SButtonWrapper = styled.div`
    grid-column: span 2;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
        color: ${({ theme }) => theme.color.secondary.main};
    }
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-100%, 15%);
    display: flex;
    justify-content: center;
    align-items: center;
`;
