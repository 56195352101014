import React, { FC, ReactNode } from 'react';
import Portal from './Portal';
import { SButtons, SImageWrapper, SInner, SOverlay, SWrapper, SCloseButton } from './style';
import { ReactComponent as Close } from 'images/icons/Close.svg';

interface BaseModalProps {
    closeModal(): void;
    title: string;
    image: ReactNode;
    BottomActions?: ReactNode;
}

const BottomActions: FC = ({ children }) => <SButtons>{children}</SButtons>;

const BaseModal: FC<BaseModalProps> & { BottomActions?: any } = ({ children, closeModal, title, image }) => (
    <Portal>
        <SOverlay>
            <SWrapper>
                <SImageWrapper>{image}</SImageWrapper>
                <SCloseButton onClick={() => closeModal()} type="button">
                    <Close />
                </SCloseButton>
                <h2>{title}</h2>
                <SInner>{children}</SInner>
            </SWrapper>
        </SOverlay>
    </Portal>
);

BaseModal.BottomActions = BottomActions;

export default BaseModal;
