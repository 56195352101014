import styled from 'styled-components';
import { SColumn } from 'styles/layout';
import deviceSize from 'constants/deviceSize';

export const SStepBox = styled(SColumn)`
    width: 24%;
    background-color: ${({ theme }) => theme.color.grey.lighter};
    padding: 0 2rem 3rem 2rem;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
        margin-bottom: 2rem;
    }
`;

export const SNumberCircle = styled.div`
    width: 40px;
    height: 40px;
    margin-top: -1.2rem;
    padding: 5px 0;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.secondary.main};
    border: 3px solid ${({ theme }) => theme.color.primary};
    border-radius: 50px;
    z-index: 1;
`;

export const SActions = styled(SColumn)`
    margin-top: auto;
    gap: 1rem;
`;

export const STitle = styled.h2`
    margin-top: 1.5rem;
`;
