/* eslint-disable curly */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import DashboardTresholds, { TRESHOLD_MAX, TRESHOLD_MIN } from 'core/models/dashboardTresholds';
import Button from 'components/Button';
import { SColumn, SSpacedRowWrapped } from 'styles/layout';
import { SInputWrapper } from '../styles';
import { STitle } from './styles';
import { dashboardTresholdsSelector } from 'core/useCases/dashboardTresholds/selectors';
import { POST } from 'utils/http';
import BACKEND_URL from 'constants/backendUrl';
import { SetDashboardTresholds } from 'core/useCases/dashboardTresholds/actions';
import { capitalizeFirstLetter } from 'utils/common';
import NumberInput from 'components/Forms/lib/NumberInput';
import useResponsive from 'hooks/responsive';

const LABELS = ['newContact', 'firstTask', 'completedInterview', 'engagedApplication'];
const LEVELS = ['low', 'medium', 'high'];

type InputName =
    | 'newContactLow'
    | 'newContactMedium'
    | 'newContactHigh'
    | 'completedInterviewLow'
    | 'completedInterviewMedium'
    | 'completedInterviewHigh'
    | 'firstTaskLow'
    | 'firstTaskMedium'
    | 'firstTaskHigh'
    | 'engagedApplicationLow'
    | 'engagedApplicationMedium'
    | 'engagedApplicationHigh';

type SetDasboardTresholdsRequest = {
    dashboardSetting: DashboardTresholds;
};

const setDashboardTresholdsRequest = async (inputs: DashboardTresholds): Promise<DashboardTresholds> => {
    const response = (await POST<SetDasboardTresholdsRequest>(
        `${BACKEND_URL}/api/dashboard-setting/`,
        inputs,
    )) as SetDasboardTresholdsRequest;

    return response.dashboardSetting;
};

const DashboardTresholdsEditing: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dashboardTresholds = useSelector(dashboardTresholdsSelector);
    const { isMobile } = useResponsive();

    if (!dashboardTresholds) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const methods = useForm<DashboardTresholds>({
        defaultValues: dashboardTresholds,
    });

    const { handleSubmit, watch } = methods;

    const fieldsWatched = watch();

    const onSubmit = handleSubmit(async (values: DashboardTresholds) => {
        try {
            const newDashboardTresholds = await setDashboardTresholdsRequest(values);
            dispatch(SetDashboardTresholds(newDashboardTresholds));
            toast.success(t('dashboard-treshold.update.success'));
        } catch {
            toast.error(t('dashboard-treshold.update.error'));
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SColumn>
                    {LABELS.map((label: string) => (
                        <div key={label}>
                            <STitle>{t(`dashboard-treshold.label.${label}`)}</STitle>
                            <SSpacedRowWrapped>
                                {LEVELS.map((level: string, index: number) => (
                                    <SInputWrapper key={level}>
                                        <NumberInput
                                            label={t(`dashboard-treshold.level.${level}`)}
                                            max={
                                                index < LEVELS.length - 1
                                                    ? fieldsWatched[
                                                          `${label}${capitalizeFirstLetter(
                                                              LEVELS[index + 1],
                                                          )}` as InputName
                                                      ]
                                                    : TRESHOLD_MAX
                                            }
                                            min={
                                                index > 0
                                                    ? fieldsWatched[
                                                          `${label}${capitalizeFirstLetter(
                                                              LEVELS[index - 1],
                                                          )}` as InputName
                                                      ]
                                                    : TRESHOLD_MIN
                                            }
                                            name={`${label}${capitalizeFirstLetter(level)}`}
                                        />
                                    </SInputWrapper>
                                ))}
                            </SSpacedRowWrapped>
                        </div>
                    ))}
                </SColumn>
                <Button large={isMobile} submit>{t('common.save')}</Button>
            </form>
        </FormProvider>
    );
};

export default DashboardTresholdsEditing;
