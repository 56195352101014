import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';
import { SColumn } from 'styles/layout';

export const SButtonContainer = styled.div`
    &:hover {
        fill: ${({ theme }) => theme.color.secondary.main};
    }
`;

export const SWrapper = styled(SColumn)`
    flex: auto;
    gap: 2rem;
`;

export const SEmptyAddInfoWrapper = styled(SColumn)`
    align-items: center;
    margin: 5rem 0;
    gap: 2.5rem;
`;

export const SButtonWrapper = styled.div`
    margin-top: 2rem;

    @media (min-width: ${deviceSize.mobile}px) {
        align-self: center;
    }
`;
