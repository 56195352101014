import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { PUT } from 'utils/http';
import { useDispatch, useSelector } from 'react-redux';
import User from 'core/models/user';
import { setSessionUserAction } from 'core/useCases/session/logInAction';
import SelectInput, { OptionsProps } from 'components/Forms/lib/SelectInput';
import { enumKeys } from 'utils/common';
import { Civility } from 'core/enums/civility';
import { SSpacedRowWrapped } from 'styles/layout';
import { TextInput } from 'components/Forms/lib';
import { REGEXP } from 'constants/validationsForm';
import { userIdSelector, userProfileSelector } from 'core/useCases/session/selectors';
import { SInputWrapper } from '../styles';
import { sessionFetcher } from 'components/authInitializer';
import useResponsive from 'hooks/responsive';
import { omit } from 'lodash';

type Inputs = Omit<User, 'id' | 'role'>;

type UpdateUserInformationRequest = {
    user: User;
};

const updateUserInformationRequest = async (inputs: Inputs, id: string): Promise<User> => {
    const response = (await PUT<UpdateUserInformationRequest>(`${BACKEND_URL}/api/users/${id}`, {
        ...inputs,
        id,
    })) as UpdateUserInformationRequest;

    return response.user;
};

const UserInformationForm: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const defaultValues = useSelector(userProfileSelector);
    const id = useSelector(userIdSelector);
    const [civilityOptions, setCivilityOptions] = useState<OptionsProps>([]);
    const { isMobile } = useResponsive();

    if (!defaultValues || !id) {
        return null;
    }

    const methods = useForm<Inputs>({
        // Remove from default values licences if candidate / agency if agency admin
        defaultValues,
    });
    const { handleSubmit, control } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            await updateUserInformationRequest(omit(values, ['hasPaid', 'status']), id);
            const updatedUser = await sessionFetcher();
            dispatch(setSessionUserAction(updatedUser));
            toast.success(t('profile.update.success'));
        } catch (e) {
            toast.error(t('profile.update.error'));
        }
    });

    useEffect(() => {
        const civilityOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(Civility)) {
            civilityOptionsComputed.push({
                label: t(`common.civility.enum.${Civility[value]}`),
                value: Civility[value],
            });
        }
        setCivilityOptions(civilityOptionsComputed);
    }, [setCivilityOptions]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SSpacedRowWrapped>
                    <SInputWrapper>
                        <SelectInput
                            control={control}
                            label={t('common.civility.label')}
                            name="civility"
                            options={civilityOptions}
                            placeholder={t('common.civility.placeholder')}
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput
                            label={t('common.first-name.label')}
                            name="firstName"
                            placeholder={t('common.first-name.placeholder')}
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput
                            label={t('common.last-name.label')}
                            name="lastName"
                            placeholder={t('common.last-name.placeholder')}
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput
                            label={t('common.mobile-number.label')}
                            name="mobileNumber"
                            pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                            placeholder={t('common.mobile-number.placeholder')}
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput
                            label={t('common.phone-number.label')}
                            name="phoneNumber"
                            pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                            placeholder={t('common.phone-number.placeholder')}
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput
                            label={t('common.email.label')}
                            name="email"
                            pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                            placeholder={t('common.email.placeholder')}
                            type="email"
                            disabled
                        />
                    </SInputWrapper>
                    <SInputWrapper>
                        <TextInput label={t('common.job.label')} name="job" placeholder={t('common.job.placeholder')} />
                    </SInputWrapper>
                </SSpacedRowWrapped>
                <Button large={isMobile} submit>
                    {t('common.save')}
                </Button>
            </form>
        </FormProvider>
    );
};

export default UserInformationForm;
