import React, { FC } from 'react';
import { SContainer } from './styles';

interface ContainerProps {
    withMargin?: boolean;
}

const Container: FC<ContainerProps> = ({ withMargin = false, children }) => (
    <SContainer withMargin={withMargin}>{children}</SContainer>
);

export default Container;
