import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
    SWrapper,
    SSubject,
    SCard,
    SCardHeader,
    SCardHeaderLeft,
    SCardLine,
    SCardLineLeft,
    SCardLineRight,
    STwoLines,
} from './styles';
import { SBoldPrimaryText, STable } from 'styles/components';
import { getContactLabel } from 'core/useCases/contacts/utils';
import { renderEventMediaText } from 'core/useCases/events/utils';
import Event from 'core/models/event';
import EventActions from '../EventActions';
import EventStatus from 'core/enums/eventStatus';
import MarkEventAsCompleteButton from '../MarkEventAsCompleteButton';
import { ReactComponent as Checked } from 'images/icons/Checked.svg';
import { computeDateWithoutTime, getCompleteDate, getTime } from 'utils/dateTimeFormat';
import ActivityMediaType from 'components/Activity/ActivityMediaType';
import ApplicationSubjectItem from 'components/Application/ApplicationSubjectItem';
import useResponsive from 'hooks/responsive';

type EventsByDate = {
    events: Event[];
    date: Date;
};

type EventListProps = {
    events: Event[];
};

const EventList: FC<EventListProps> = ({ events }) => {
    const { t } = useTranslation();
    const [eventsByDateList, setEventsByDateList] = useState<EventsByDate[]>([]);
    const { isMobile } = useResponsive();

    useEffect(() => {
        const eventsByDateListCompute = _(
            events.map((e: Event) => ({ ...e, dateWithoutTime: computeDateWithoutTime(new Date(e.date)) })),
        )
            .groupBy('dateWithoutTime')
            .map((items: Array<Event & { dateWithoutTime: Date }>, date) => ({
                date: new Date(date),
                events: items.map((item: Event & { dateWithoutTime: Date }) => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const { dateWithoutTime, ...rest } = item;

                    return rest;
                }),
            }))
            .value();
        setEventsByDateList(eventsByDateListCompute);
    }, [events, setEventsByDateList]);

    return (
        <div>
            {eventsByDateList?.map((eventsByDate: EventsByDate) =>
                isMobile ? (
                    <SWrapper key={String(eventsByDate.date)}>
                        <h2>{getCompleteDate(eventsByDate.date, t)}</h2>
                        {eventsByDate.events.map((event) => {
                            const { id, subject, mediaType, contact, date, type, status, application } = event;

                            return (
                                <SCard key={id}>
                                    <SCardHeader>
                                        <SCardHeaderLeft>
                                            {/* eslint-disable-next-line max-len */}
                                            {EventStatus.NotStarted === status && (
                                                <MarkEventAsCompleteButton eventId={id} />
                                            )}
                                            {EventStatus.Completed === status && <Checked />}
                                            <SSubject isCompleted={EventStatus.Completed === status}>
                                                {t(`common.event-type.enum.${type}`)}
                                            </SSubject>
                                        </SCardHeaderLeft>
                                        <EventActions eventId={id} />
                                    </SCardHeader>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.subject.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {/* eslint-disable-next-line max-len */}
                                            <Link
                                                title={t(`common.${type}-subject.enum.${subject}`)}
                                                to={`/event/${id}`}
                                            >
                                                <SBoldPrimaryText>
                                                    {t(`common.${type}-subject.enum.${subject}`)}
                                                </SBoldPrimaryText>
                                            </Link>
                                            <br />
                                            {getTime(date)}
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.media-type.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            <STwoLines>
                                                <ActivityMediaType mediaType={mediaType} />
                                                {renderEventMediaText(event)}
                                            </STwoLines>
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('application.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            <ApplicationSubjectItem application={application} />
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.contact')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {contact && (
                                                <>
                                                    <Link to={`/contact/${contact.id}`}>
                                                        <SBoldPrimaryText>
                                                            {getContactLabel(contact, t)}
                                                        </SBoldPrimaryText>
                                                    </Link>
                                                    <br />
                                                    <span>{contact.company}</span>
                                                </>
                                            )}
                                        </SCardLineRight>
                                    </SCardLine>
                                    <SCardLine>
                                        <SCardLineLeft>{t('common.intermediary.label')}</SCardLineLeft>
                                        <SCardLineRight>
                                            {contact?.parent ? (
                                                <Link to={`/contact/${contact.parent.id}`}>
                                                    {getContactLabel(contact.parent, t)}
                                                </Link>
                                            ) : (
                                                <span>{t('common.you')}</span>
                                            )}
                                        </SCardLineRight>
                                    </SCardLine>
                                </SCard>
                            );
                        })}
                    </SWrapper>
                ) : (
                    <SWrapper key={String(eventsByDate.date)}>
                        <h2>{getCompleteDate(eventsByDate.date, t)}</h2>
                        <STable>
                            <thead>
                                <tr>
                                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                    <th />
                                    <th>{t('common.type')}</th>
                                    <th>{t('common.subject.label')}</th>
                                    <th>{t('application.label')}</th>
                                    <th>{t('common.media-type.label')}</th>
                                    <th>{t('common.contact')}</th>
                                    <th>{t('common.intermediary.label')}</th>
                                </tr>
                            </thead>
                            <tbody className="table--event">
                                {eventsByDate.events.map((event) => {
                                    const { id, subject, mediaType, contact, date, type, status, application } = event;

                                    return (
                                        <tr key={id}>
                                            <td>
                                                {/* eslint-disable-next-line max-len */}
                                                {EventStatus.NotStarted === status && (
                                                    <MarkEventAsCompleteButton eventId={id} />
                                                )}
                                                {EventStatus.Completed === status && <Checked />}
                                            </td>
                                            <td>
                                                <SSubject isCompleted={EventStatus.Completed === status}>
                                                    {t(`common.event-type.enum.${type}`)}
                                                </SSubject>
                                            </td>
                                            <td>
                                                {/* eslint-disable-next-line max-len */}
                                                <Link
                                                    title={t(`common.${type}-subject.enum.${subject}`)}
                                                    to={`/event/${id}`}
                                                >
                                                    <SBoldPrimaryText>
                                                        {t(`common.${type}-subject.enum.${subject}`)}
                                                    </SBoldPrimaryText>
                                                </Link>
                                                <br />
                                                {getTime(date)}
                                            </td>
                                            <td>
                                                <ApplicationSubjectItem application={application} />
                                            </td>
                                            <td>
                                                <ActivityMediaType mediaType={mediaType} />
                                                {renderEventMediaText(event)}
                                            </td>
                                            <td>
                                                {contact && (
                                                    <>
                                                        <Link to={`/contact/${contact.id}`}>
                                                            <SBoldPrimaryText>
                                                                {getContactLabel(contact, t)}
                                                            </SBoldPrimaryText>
                                                        </Link>
                                                        <br />
                                                        <span>{contact.company}</span>
                                                    </>
                                                )}
                                            </td>
                                            <td>
                                                {contact?.parent ? (
                                                    <Link to={`/contact/${contact.parent.id}`}>
                                                        {getContactLabel(contact.parent, t)}
                                                    </Link>
                                                ) : (
                                                    <span>{t('common.you')}</span>
                                                )}
                                            </td>
                                            <td>
                                                <div className="showOnRowHover">
                                                    <EventActions eventId={id} />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </STable>
                    </SWrapper>
                ),
            )}
        </div>
    );
};

export default EventList;
