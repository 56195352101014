import React, { FC, ReactNode } from 'react';
import { SBoldPrimaryText, SCard } from 'styles/components';
import { STitle } from './styles';

type CardWithTitleProps = {
    label?: string;
    actions?: ReactNode;
    className?: any;
};

const CardWithTitle: FC<CardWithTitleProps> = ({ label, actions, className, children }) => (
    <SCard className={className}>
        {label && (
            <STitle>
                <SBoldPrimaryText>{label}</SBoldPrimaryText>
                {actions}
            </STitle>
        )}
        {children}
    </SCard>
);

export default CardWithTitle;
