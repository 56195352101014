import BACKEND_URL from 'constants/backendUrl';
import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { POST } from 'utils/http';
import { toast } from 'react-toastify';
import TextInput from '../lib/TextInput/index';
import Button from '../../Button';
import { REGEXP } from 'constants/validationsForm';
import { useHistory } from 'react-router-dom';
import CheckboxInput from './CheckboxInput';

type Inputs = {
    newPassword: string;
    confirmNewPassword: string;
};

type NewPasswordFormProps = {
    id: number;
    token: string;
    hasCGUCheckbox: boolean;
};

const NewPasswordFetcher = async (id: number, token: string, password: string) =>
    POST(`${BACKEND_URL}/api/users/new-password`, {
        id,
        password,
        token,
    });

const validatePasswordConfirmation = (
    password: string | undefined,
    confirmation: string | undefined,
    message: string,
): boolean | string => (!!confirmation && password === confirmation ? true : message);

const NewPasswordForm: FC<NewPasswordFormProps> = ({ id, token, hasCGUCheckbox }) => {
    const { t } = useTranslation();
    const methods = useForm<Inputs>();
    const history = useHistory();

    const { handleSubmit, getValues } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        const { newPassword } = values;
        try {
            await NewPasswordFetcher(id, token, newPassword);
            toast.success(t('new-password-form.success'));
            history.push('/login');
        } catch (error) {
            toast.error(t('new-password-form.error'));
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <TextInput
                    hint={t('common.password-rules')}
                    label={t('new-password-form.new-password.label')}
                    name="newPassword"
                    pattern={{ message: t(REGEXP.PASSWORD.message), value: REGEXP.PASSWORD.value }}
                    placeholder={t('new-password-form.new-password.placeholder')}
                    type="password"
                    required
                />
                <TextInput
                    label={t('new-password-form.confirm-new-password.label')}
                    name="confirmNewPassword"
                    placeholder={t('new-password-form.confirm-new-password.placeholder')}
                    type="password"
                    validate={(value) =>
                        validatePasswordConfirmation(
                            getValues('newPassword'),
                            value,
                            t('common.wrong-password-confirmation'),
                        )
                    }
                    required
                />
                {hasCGUCheckbox && <CheckboxInput />}
                <Button large submit>
                    {t('new-password-form.submit')}
                </Button>
            </form>
        </FormProvider>
    );
};

export default NewPasswordForm;
