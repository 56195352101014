import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import ApplicationList from 'components/Application/ApplicationList';
import ApplicationStateBar from 'components/Application/ApplicationStateBar';

const Applications: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.contact.meta-title')} stateBar={<ApplicationStateBar />}>
            <ApplicationList />
        </Layout>
    );
};

export default Applications;
