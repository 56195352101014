import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import '../../../../styles/react-toggle.css';
import {
    candidatesInTrialPeriodSelector,
    candidatesNeverConnectedSelector,
    candidatesSelector,
    candidatesWithExpiredLicenseSelector,
    candidatesWithoutLicenseAfterTrialPeriodSelector,
    candidatesWithPendingLicenseSelector,
    candidatesWithValidLicenseSelector,
} from 'core/useCases/candidates/selectors';
import Title from 'components/Typography/Title';
import FloatingButton from 'components/FloatingButton';
import Button from 'components/Button';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { getUserLabel } from 'core/useCases/session/utils';
import { ModalType } from 'core/useCases/modal/types';
import { getNumericDate } from 'utils/dateTimeFormat';
import { STable } from 'styles/components';
import { SColumn, SSpacedRowResponsive } from 'styles/layout';
import {
    SActions,
    SCard,
    SCardHeader,
    SCardHeaderLeft,
    SCardLine,
    SCardLineLeft,
    SCardLineRight,
    SSearchInput,
    SWrapper,
} from './styles';
import Candidate from 'core/models/candidate';
import { HttpError, PATCH, POST, DELETE } from 'utils/http';
import BACKEND_URL from 'constants/backendUrl';
import { userSelector } from 'core/useCases/session/selectors';
import AgencyAdmin from 'core/models/agencyAdmin';
import { UpdateCandidate } from 'core/useCases/candidates/candidateActions';
import { ReactComponent as Add } from 'images/icons/Add.svg';
import MultiActionsButton from 'components/MultiActionsButton';
import TabButtonsGroup from 'components/TabButtonsGroup';
import TabButton from 'components/TabButton';
import CandidateStatus from 'core/enums/candidateStatus';
import useResponsive from 'hooks/responsive';
import { UserRole } from 'core/enums/userRole';
import Tag from 'components/Tag';
import {
    UpdateSelectedCandidates,
    DeleteSelectedCandidates,
} from '../../../../core/useCases/candidates/candidateActions';
import { selectedCandidatesSelector } from '../../../../core/useCases/candidates/selectors';

const allocateLicenseRequest = async (agencyId: string, candidateId: string): Promise<Candidate> => {
    const response = (await POST<Candidate>(`${BACKEND_URL}/api/agency/${agencyId}/allocate-license-to-candidate`, {
        candidateId,
    })) as Candidate;

    return response;
};

const grantFreeAccessRequest = async (candidateId: string): Promise<Candidate> => {
    const result = (await PATCH<Candidate>(`${BACKEND_URL}/api/candidates/${candidateId}/grant-free-access`)) as any;

    return {
        ...result,
        canBeSentAgainEmailInvitation: result.actions.sendInvitationEmail,
    };
};

const revokeFreeAccessRequest = async (candidateId: string): Promise<Candidate> => {
    const result = (await PATCH<Candidate>(`${BACKEND_URL}/api/candidates/${candidateId}/revoke-free-access`)) as any;

    return {
        ...result,
        canBeSentAgainEmailInvitation: result.actions.sendInvitationEmail,
    };
};

const defineAsB2CRequest = async (candidateId: string): Promise<Candidate> => {
    const result = (await PATCH<Candidate>(`${BACKEND_URL}/api/candidates/${candidateId}/define-as-b2c`)) as any;

    return {
        ...result,
        canBeSentAgainEmailInvitation: result.actions.sendInvitationEmail,
    };
};

const defineAsB2BRequest = async (candidateId: string): Promise<Candidate> => {
    const result = (await PATCH<Candidate>(`${BACKEND_URL}/api/candidates/${candidateId}/define-as-b2b`)) as any;

    return {
        ...result,
        canBeSentAgainEmailInvitation: result.actions.sendInvitationEmail,
    };
};

const sendNewEmailInvitationRequest = async (agencyId: string, candidateId: string): Promise<void> => {
    await POST<void>(`${BACKEND_URL}/api/agency/${agencyId}/send-new-email-invitation`, { candidateId });
};

const deleteCandidateRequest = async (candidateId: string): Promise<void> => {
    await DELETE(`${BACKEND_URL}/api/users/${candidateId}`);
};

const bulkDeleteCandidatesRequest = async (selectedCandidatesIds: string[]) => {
    const bulkDeleteActions = selectedCandidatesIds.map((id) => deleteCandidateRequest(id));
    await Promise.all(bulkDeleteActions);
};

const tabButtons: Array<{ type: CandidateStatus | null; labelKey: string }> = [
    { labelKey: 'all', type: null },
    { labelKey: 'never-connected', type: CandidateStatus.NeverConnected },
    { labelKey: 'in-trial-period', type: CandidateStatus.InTrialPeriod },
    { labelKey: 'without-license', type: CandidateStatus.WithoutLicenseAfterTrialPeriod },
    { labelKey: 'with-pending-license', type: CandidateStatus.WithPendingLicense },
    { labelKey: 'with-valid-license', type: CandidateStatus.WithValidLicense },
    { labelKey: 'with-expired-license', type: CandidateStatus.WithExpiredLicense },
];

const CandidateList: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const candidates = useSelector(candidatesSelector);
    const candidatesNeverConnected = useSelector(candidatesNeverConnectedSelector);
    const candidatesInTrialPeriod = useSelector(candidatesInTrialPeriodSelector);
    const candidatesWithoutLicenseAfterTrialPeriod = useSelector(candidatesWithoutLicenseAfterTrialPeriodSelector);
    const candidatesWithPendingLicense = useSelector(candidatesWithPendingLicenseSelector);
    const candidatesWithValidLicense = useSelector(candidatesWithValidLicenseSelector);
    const candidatesWithExpiredLicense = useSelector(candidatesWithExpiredLicenseSelector);
    const user = useSelector(userSelector) as AgencyAdmin;
    const [search, setSearch] = useState<string>('');
    const [filteredCandidates, setFilteredCandidates] = useState<Candidate[]>([]);
    const [candidateStatus, setCandidateStatus] = useState<CandidateStatus | null>(null);
    const { isMobile } = useResponsive();
    const selectedCandidatesIds = useSelector(selectedCandidatesSelector);

    const isAdmin = UserRole.Admin === user.role;

    const openCandidateModal = () => {
        dispatch(
            OpenModal({
                title: t('candidate.creation.label'),
                type: ModalType.Candidate,
            }),
        );
    };

    const openCandidateDeleteModal = () => {
        if (selectedCandidatesIds.length === 0) {
            toast.error(t('candidate.delete.error-no-candidate'));
            return;
        }
        dispatch(
            OpenModal({
                confirmAction: () => {
                    bulkDeleteCandidatesRequest(selectedCandidatesIds)
                        .then(() => {
                            dispatch(DeleteSelectedCandidates(selectedCandidatesIds));
                            toast.success(t('candidate.delete.success'));
                        })
                        .catch(() => toast.error(t('candidate.delete.error')));
                },
                title: t('candidate.delete-button.label'),
                type: ModalType.DeleteCandidate,
            }),
        );
    };

    const allocateLicence = async (candidateId: string) => {
        try {
            const updatedCandidate = await allocateLicenseRequest(user.agency.id, candidateId);
            dispatch(UpdateCandidate(updatedCandidate));
            toast.success(t('license.allocation.success'));
        } catch (error) {
            toast.error(t('license.allocation.error'));
        }
    };

    const grantFreeAccess = async (candidateId: string) => {
        try {
            const updatedCandidate = await grantFreeAccessRequest(candidateId);
            dispatch(UpdateCandidate(updatedCandidate));
            toast.success(t('candidate.grant-free-access.success'));
        } catch (error) {
            toast.error(t((error as HttpError).message));
        }
    };

    const revokeFreeAccess = async (candidateId: string) => {
        try {
            const updatedCandidate = await revokeFreeAccessRequest(candidateId);
            dispatch(UpdateCandidate(updatedCandidate));
            toast.success(t('candidate.revoke-free-access.success'));
        } catch (error) {
            toast.error('Error');
        }
    };

    const defineAsB2C = async (candidateId: string) => {
        try {
            const updatedCandidate = await defineAsB2CRequest(candidateId);
            dispatch(UpdateCandidate(updatedCandidate));
            toast.success(t('candidate.define-as-b2c.success'));
        } catch (error) {
            toast.error(t((error as HttpError).message));
        }
    };

    const defineAsB2B = async (candidateId: string) => {
        try {
            const updatedCandidate = await defineAsB2BRequest(candidateId);
            dispatch(UpdateCandidate(updatedCandidate));
            toast.success(t('candidate.define-as-b2b.success'));
        } catch (error) {
            toast.error('Error');
        }
    };

    const sendNewEmailInvitation = async (candidate: Candidate) => {
        try {
            const agencyId = user.agency?.id ? user.agency.id : candidate.agency.id;
            await sendNewEmailInvitationRequest(agencyId, candidate.id);
            toast.success(t('candidate.send-new-email-invitation.success'));
        } catch (error) {
            toast.error(t('candidate.send-new-email-invitation.error'));
        }
    };

    const handleCheckboxChange = (id: string) => {
        const selectedUsers = selectedCandidatesIds.includes(id)
            ? [...selectedCandidatesIds].filter((userId) => userId !== id)
            : [...selectedCandidatesIds, id];
        dispatch(UpdateSelectedCandidates(selectedUsers));
    };

    const getSelectedCandidates = (type: CandidateStatus | null): Array<Candidate> => {
        switch (type) {
            case CandidateStatus.InTrialPeriod:
                return candidatesInTrialPeriod;
            case CandidateStatus.NeverConnected:
                return candidatesNeverConnected;
            case CandidateStatus.WithoutLicenseAfterTrialPeriod:
                return candidatesWithoutLicenseAfterTrialPeriod;
            case CandidateStatus.WithPendingLicense:
                return candidatesWithPendingLicense;
            case CandidateStatus.WithValidLicense:
                return candidatesWithValidLicense;
            case CandidateStatus.WithExpiredLicense:
                return candidatesWithExpiredLicense;
            default:
                return candidates;
        }
    };

    useEffect(() => {
        const selectedCandidates = getSelectedCandidates(candidateStatus);

        let localCandidates = [...selectedCandidates];

        if (search) {
            const computedSearch = search.toLowerCase();
            localCandidates = localCandidates.filter(
                (candidate) =>
                    getUserLabel(candidate, t).toLowerCase().includes(computedSearch) ||
                    candidate.id === computedSearch,
            );
        }

        localCandidates = localCandidates.sort(
            (candidateA, candidateB) =>
                new Date(candidateB.createdDate).getTime() - new Date(candidateA.createdDate).getTime(),
        );

        setFilteredCandidates(localCandidates);
    }, [search, candidates, candidateStatus]);

    return (
        <SWrapper>
            <SSpacedRowResponsive>
                <SColumn>
                    <Title text={t('page.candidates.title')} />
                    <span>
                        {candidates.length} {t('common.candidates')}
                    </span>
                </SColumn>
                <SSearchInput>
                    <input
                        onChange={(event) => setSearch(event.target.value)}
                        placeholder={t('candidate.search-for-candidate')}
                        type="text"
                        value={search}
                    />
                </SSearchInput>
            </SSpacedRowResponsive>
            <TabButtonsGroup>
                {tabButtons.map(({ type, labelKey }) => (
                    <TabButton
                        key={labelKey}
                        onClick={() => setCandidateStatus(type)}
                        variant={type === candidateStatus ? 'plain' : 'text'}
                    >
                        {t(`candidates.${labelKey}`)} {getSelectedCandidates(type).length}
                    </TabButton>
                ))}
            </TabButtonsGroup>
            {isMobile ? (
                filteredCandidates.map((candidate: Candidate) => {
                    const {
                        id,
                        email,
                        job,
                        mobileNumber,
                        phoneNumber,
                        license,
                        createdDate,
                        firstLoginDate,
                        lastLoginDate,
                        canBeReAllocatedLicense,
                        canBeSentAgainEmailInvitation,
                    } = candidate;
                    const userLabel = getUserLabel(candidate, t);
                    return (
                        <SCard key={id}>
                            <SCardHeader>
                                <SCardHeaderLeft>{userLabel}</SCardHeaderLeft>
                                {(canBeReAllocatedLicense || canBeSentAgainEmailInvitation) && (
                                    <MultiActionsButton parentButtonContent={<Add />} iconButton>
                                        {canBeReAllocatedLicense && (
                                            <Button onClick={() => allocateLicence(id)} variant="popinText">
                                                {t('license.allocation.label')}
                                            </Button>
                                        )}
                                        {canBeSentAgainEmailInvitation && (
                                            <Button
                                                onClick={() => sendNewEmailInvitation(candidate)}
                                                variant="popinText"
                                            >
                                                {t('candidate.send-new-email-invitation.label')}
                                            </Button>
                                        )}
                                    </MultiActionsButton>
                                )}
                            </SCardHeader>
                            <SCardLine>
                                <SCardLineLeft>{t('common.job.label')}</SCardLineLeft>
                                <SCardLineRight>{job}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.email.label')}</SCardLineLeft>
                                <SCardLineRight>{email}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.phone')}</SCardLineLeft>
                                <SCardLineRight>
                                    {mobileNumber} <br />
                                    {phoneNumber}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>
                                    {t('candidate.created-date')}
                                    <br />
                                    {t('candidate.first-login')}
                                </SCardLineLeft>
                                <SCardLineRight>
                                    {getNumericDate(createdDate)}
                                    <br />
                                    {firstLoginDate && getNumericDate(firstLoginDate)}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('candidate.last-login')}</SCardLineLeft>
                                <SCardLineRight>{lastLoginDate && getNumericDate(lastLoginDate)}</SCardLineRight>
                            </SCardLine>
                            {isAdmin && (
                                <SCardLine>
                                    <SCardLineLeft>{t('candidate.hasFreeAccess')}</SCardLineLeft>
                                    <SCardLineRight>
                                        {candidate.hasFreeAccess && (
                                            <Toggle
                                                aria-labelledby="candidate-free-access"
                                                defaultChecked={candidate.hasFreeAccess}
                                                id="candidate-free-access"
                                                onChange={() => revokeFreeAccess(id)}
                                            />
                                        )}
                                        {!candidate.hasFreeAccess && (
                                            <Toggle
                                                aria-labelledby="candidate-free-access"
                                                defaultChecked={candidate.hasFreeAccess}
                                                id="candidate-free-access"
                                                onChange={() => grantFreeAccess(id)}
                                            />
                                        )}
                                    </SCardLineRight>
                                </SCardLine>
                            )}
                            {isAdmin && (
                                <td>
                                    {candidate.isB2C && (
                                        <Toggle
                                            aria-labelledby="candidate-is-b2c"
                                            defaultChecked={candidate.isB2C}
                                            id="candidate-is-b2c"
                                            onChange={() => defineAsB2B(id)}
                                        />
                                    )}
                                    {!candidate.isB2C && (
                                        <Toggle
                                            aria-labelledby="candidate-is-b2c"
                                            defaultChecked={candidate.isB2C}
                                            id="candidate-is-b2c"
                                            onChange={() => defineAsB2C(id)}
                                        />
                                    )}
                                </td>
                            )}
                            <SCardLine>
                                <SCardLineLeft>{t('license.allocation-date')}</SCardLineLeft>
                                <SCardLineRight>
                                    {license?.invitationDate && getNumericDate(license.invitationDate)}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('license.activation-date')}</SCardLineLeft>
                                <SCardLineRight>
                                    {license?.activationDate && getNumericDate(license.activationDate)}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>
                                    {t('license.activation-date')}
                                    <br />
                                    {t('license.expiration-date')}
                                </SCardLineLeft>
                                <SCardLineRight>
                                    {license?.activationDate && getNumericDate(license.activationDate)}
                                    <br />
                                    {license?.expirationDate && getNumericDate(license.expirationDate)}
                                </SCardLineRight>
                            </SCardLine>
                        </SCard>
                    );
                })
            ) : (
                <STable>
                    <thead>
                        <tr>
                            {isAdmin && <th>{t('common.label')}</th>}
                            <th>{t('common.last-name.label')}</th>
                            <th>{t('common.email.label')}</th>
                            <th>{t('common.phone')}</th>
                            <th>{t('common.origin.label')}</th>
                            {isAdmin && <th>{t('common.agency.label')}</th>}
                            <th>{t('candidate.created-date')}</th>
                            <th>{t('candidate.first-login')}</th>
                            <th>{t('candidate.last-login')}</th>
                            <th>{t('license.allocation-date')}</th>
                            <th>{t('license.activation-date')}</th>
                            <th>{t('license.expiration-date')}</th>
                            {isAdmin && <th>{t('candidate.hasFreeAccess')}</th>}
                            {isAdmin && <th>{t('candidate.isB2C')}</th>}
                            {isAdmin && <th>{t('candidate.hasPaid')}</th>}
                            <th aria-label={t('common.action')} />
                        </tr>
                    </thead>
                    <tbody className="table--candidates">
                        {filteredCandidates.map((candidate: Candidate) => {
                            const {
                                id,
                                email,
                                mobileNumber,
                                phoneNumber,
                                origin,
                                license,
                                createdDate,
                                firstLoginDate,
                                lastLoginDate,
                                canBeReAllocatedLicense,
                                canBeSentAgainEmailInvitation,
                                agency,
                            } = candidate;
                            const userLabel = getUserLabel(candidate, t);
                            return (
                                <tr key={id}>
                                    {isAdmin && (
                                        <td>
                                            <input
                                                checked={selectedCandidatesIds.includes(id)}
                                                onChange={() => handleCheckboxChange(id)}
                                                type="checkbox"
                                            />
                                        </td>
                                    )}
                                    <td>{userLabel}</td>
                                    <td>{email}</td>
                                    <td>
                                        {mobileNumber} <br />
                                        {phoneNumber}
                                    </td>
                                    <td>{origin}</td>
                                    {isAdmin && <td>{agency?.name || ''}</td>}
                                    <td>{getNumericDate(createdDate)}</td>
                                    <td>{firstLoginDate && getNumericDate(firstLoginDate)}</td>
                                    <td>{lastLoginDate && getNumericDate(lastLoginDate)}</td>
                                    <td>{license?.invitationDate && getNumericDate(license.invitationDate)}</td>
                                    <td>{license?.activationDate && getNumericDate(license.activationDate)}</td>
                                    <td>{license?.expirationDate && getNumericDate(license.expirationDate)}</td>
                                    {isAdmin && (
                                        <td>
                                            {candidate.hasFreeAccess && (
                                                <Toggle
                                                    aria-labelledby="candidate-free-access"
                                                    defaultChecked={candidate.hasFreeAccess}
                                                    id="candidate-free-access"
                                                    onChange={() => revokeFreeAccess(id)}
                                                />
                                            )}
                                            {!candidate.hasFreeAccess && (
                                                <Toggle
                                                    aria-labelledby="candidate-free-access"
                                                    defaultChecked={candidate.hasFreeAccess}
                                                    id="candidate-free-access"
                                                    onChange={() => grantFreeAccess(id)}
                                                />
                                            )}
                                        </td>
                                    )}
                                    {isAdmin && (
                                        <td>
                                            {candidate.isB2C && (
                                                <Toggle
                                                    aria-labelledby="candidate-is-b2c"
                                                    defaultChecked={candidate.isB2C}
                                                    id="candidate-is-b2c"
                                                    onChange={() => defineAsB2B(id)}
                                                />
                                            )}
                                            {!candidate.isB2C && (
                                                <Toggle
                                                    aria-labelledby="candidate-is-b2c"
                                                    defaultChecked={candidate.isB2C}
                                                    id="candidate-is-b2c"
                                                    onChange={() => defineAsB2C(id)}
                                                />
                                            )}
                                        </td>
                                    )}
                                    {isAdmin && (
                                        <td>
                                            {candidate.isB2C &&
                                                (candidate.hasPaid ? (
                                                    <Tag color="#19AB27">{t('candidate.paid')}</Tag>
                                                ) : (
                                                    <Tag color="#E94A41">{t('candidate.not-paid')}</Tag>
                                                ))}
                                        </td>
                                    )}
                                    <td>
                                        <SActions className="showOnRowHover">
                                            {(canBeReAllocatedLicense || canBeSentAgainEmailInvitation) && (
                                                <MultiActionsButton parentButtonContent={<Add />} iconButton>
                                                    {canBeReAllocatedLicense && (
                                                        <Button onClick={() => allocateLicence(id)} variant="popinText">
                                                            {t('license.allocation.label')}
                                                        </Button>
                                                    )}
                                                    {canBeSentAgainEmailInvitation && (
                                                        <Button
                                                            onClick={() => sendNewEmailInvitation(candidate)}
                                                            variant="popinText"
                                                        >
                                                            {t('candidate.send-new-email-invitation.label')}
                                                        </Button>
                                                    )}
                                                </MultiActionsButton>
                                            )}
                                        </SActions>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </STable>
            )}

            <FloatingButton>
                <Button onClick={openCandidateModal} large withShadow>
                    {t('candidate.creation.label')}
                </Button>

                {isAdmin && (
                    <Button onClick={openCandidateDeleteModal} variant="outlinedRed" withShadow>
                        {t('candidate.delete-button.label')}
                    </Button>
                )}
            </FloatingButton>
        </SWrapper>
    );
};

export default CandidateList;
