import Event from '../../models/event';
import EventsActionTypes from './types';

export interface AddEventAction {
    type: EventsActionTypes.ADD_EVENT;
    payload: Event;
}

export const AddEvent = (event: Event): AddEventAction => ({
    payload: event,
    type: EventsActionTypes.ADD_EVENT,
});
export interface SetEventsAction {
    type: EventsActionTypes.SET_EVENTS;
    payload: Event[];
}

export const SetEvents = (events: Event[]): SetEventsAction => ({
    payload: events,
    type: EventsActionTypes.SET_EVENTS,
});

export interface UpdateEventAction {
    type: EventsActionTypes.UPDATE_EVENT;
    payload: Event;
}

export const UpdateEvent = (event: Event): UpdateEventAction => ({
    payload: event,
    type: EventsActionTypes.UPDATE_EVENT,
});
export interface DeleteEventAction {
    type: EventsActionTypes.DELETE_EVENT;
    payload: string;
}

export const DeleteEvent = (id: string): DeleteEventAction => ({
    payload: id,
    type: EventsActionTypes.DELETE_EVENT,
});
