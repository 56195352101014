export enum TaskMediaType {
    Phone = 'phone',
    Mobile = 'mobile',
    InMail = 'inmail',
    Email = 'email',
    Other = 'other',
    VideoConferencing = 'videoConferencing',
    FaceToFace = 'faceToFace',
    Webinar = 'webinar',
}
