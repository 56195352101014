import styled, { keyframes } from 'styled-components';
import deviceSize from 'constants/deviceSize';

export interface DiagramProps {
    value: number;
}

const rotateAnimation = (value: number) => keyframes`
    0% {
      transform: translateX(calc(-50% - 6.2rem/2)) rotate(0);
    }
    100% {
      transform: translateX(calc(-50% - 6.2rem/2)) rotate(${value}deg);
    }
  }
`;

export const SDiagram = styled.div<DiagramProps>`
    position: relative;
    width: 280px;
    height: 140px;
    overflow: hidden;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: 0.5rem;
        animation-name: ${({ value }) => rotateAnimation(value)};
        animation-fill-mode: forwards;
        animation-duration: 1.3s;
        animation-timing-function: ease;
        transform-origin: 103% 60%;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.4rem 5.7rem 0.4rem 0;
        border-color: transparent ${({ theme }) => theme.color.black} transparent transparent;
    }

    &::before {
        content: '';
        position: absolute;
        left: calc(50% - 1.6rem / 2);
        bottom: 0px;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100px;
        background-color: ${({ theme }) => theme.color.black};
    }

    @media (max-width: ${deviceSize.mobile}px) {
        width: 220px;
        height: 110px;
    }
`;
