import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Activity from 'core/models/activity';
import { SBoldPrimaryText, STable } from 'styles/components';
import ActivityType from 'core/enums/activityType';
import { TaskPriority } from 'core/enums/taskPriority';
import TaskPriorityIcon from 'components/Task/TaskPriorityIcon';
import TaskActions from 'components/Task/TaskActions';
import EventActions from 'components/Event/EventActions';
import { computeActivityDate } from 'core/useCases/activities/utils';
import MarkEventAsCompleteButton from 'components/Event/MarkEventAsCompleteButton';
import MarkTaskAsCompleteButton from 'components/Task/MarkTaskAsCompleteButton';
import useResponsive from 'hooks/responsive';
import { SCard, SCardHeader, SCardHeaderLeft, SCardLine, SCardLineLeft, SCardLineRight, SWrapper } from './styles';

type InProgressActivitiesSummaryProps = {
    activities: Activity[];
};

const InProgressActivitiesSummary: FC<InProgressActivitiesSummaryProps> = ({ activities }) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    const getResponsiveActivities = () => {
        if (isMobile) {
            return activities.map((activity: Activity) => {
                    const { id, subject, mediaDetails, type, priority } = activity;

                    return (
                        <SCard key={id}>
                            <SCardHeader>
                                <SCardHeaderLeft>
                                    {ActivityType.Task === type ? (
                                        <MarkTaskAsCompleteButton taskId={id} />
                                    ) : (
                                        <MarkEventAsCompleteButton eventId={id} />
                                    )}
                                    <Link
                                        title={subject}
                                        to={`/${ActivityType.Task === type ? 'task' : 'event'}/${id}`}
                                    >
                                        <SBoldPrimaryText>{subject}</SBoldPrimaryText>
                                    </Link>
                                    {/* An event has no priority but display all events with an high priority */}
                                    {/* eslint-disable-next-line max-len */}
                                    <TaskPriorityIcon
                                        priority={
                                            ActivityType.Task === type
                                                ? (priority as TaskPriority)
                                                : TaskPriority.High
                                        }
                                    />
                                </SCardHeaderLeft>
                                {ActivityType.Task === type ? (
                                    <TaskActions taskId={id} />
                                ) : (
                                    <EventActions eventId={id} />
                                )}
                            </SCardHeader>
                            <SCardLine>
                                <SCardLineLeft>
                                    {t('common.media-details.label')}
                                </SCardLineLeft>
                                <SCardLineRight>
                                    {mediaDetails}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>
                                    {t('common.end-date')}
                                </SCardLineLeft>
                                <SCardLineRight>
                                    {computeActivityDate(activity)}
                                </SCardLineRight>
                            </SCardLine>
                        </SCard>
                    );
                }
            );
        }

        return (
            <STable>
                <thead>
                    <tr>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th />
                        <th>{t('common.subject.label')}</th>
                        <th>{t('common.media-details.label')}</th>
                        <th>{t('common.end-date')}</th>
                        <th>{t('common.task-priority.short-label')}</th>
                        <th aria-label={t('common.action')} />
                    </tr>
                </thead>
                <tbody>
                    {activities.map((activity: Activity) => {
                        const { id, subject, mediaDetails, type, priority } = activity;

                        return (
                            <tr key={id}>
                                <td>
                                    {ActivityType.Task === type ? (
                                        <MarkTaskAsCompleteButton taskId={id} />
                                    ) : (
                                        <MarkEventAsCompleteButton eventId={id} />
                                    )}
                                </td>
                                <td>
                                    <Link
                                        title={subject}
                                        to={`/${ActivityType.Task === type ? 'task' : 'event'}/${id}`}
                                    >
                                        <SBoldPrimaryText>{subject}</SBoldPrimaryText>
                                    </Link>
                                </td>
                                <td>{mediaDetails}</td>
                                <td>{computeActivityDate(activity)}</td>
                                <td>
                                    {/* An event has no priority but display all events with an high priority */}
                                    {/* eslint-disable-next-line max-len */}
                                    <TaskPriorityIcon
                                        priority={
                                            ActivityType.Task === type
                                                ? (priority as TaskPriority)
                                                : TaskPriority.High
                                        }
                                    />
                                </td>
                                <td>
                                    {ActivityType.Task === type ? (
                                        <TaskActions taskId={id} />
                                    ) : (
                                        <EventActions eventId={id} />
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </STable>
        );
    };

    return (
        <SWrapper>
            {activities.length ? getResponsiveActivities() : (
                <span>{t('contact.no-in-progress-activity')}</span>
            )}
        </SWrapper>
    );
};

export default InProgressActivitiesSummary;
