import styled from 'styled-components';
import { ReactComponent as QuoteOpen } from 'images/QuoteOpen.svg';
import { STitle as SGenericTitle } from 'components/Typography/Title/styles';

export const STitle = styled(SGenericTitle)`
    font-size: 3rem;
    color: ${({ theme }) => theme.color.white};
`;

export const SSubTitle = styled.div`
    margin-top: 2.5rem;
    color: ${({ theme }) => theme.color.white};
    font-weight: 500;
    font-size: 1.8rem;
`;

export const SQuoteOpen = styled(QuoteOpen)`
    position: absolute;
    top: -11rem;
    left: 0;
`;

export const SQuoteClose = styled(QuoteOpen)`
    position: absolute;
    bottom: -11rem;
    right: 0;
    transform: rotate(180deg);
`;

export const SQuoteContentWrapper = styled.div`
    max-width: 70%;
    margin: auto;
    color: ${({ theme }) => theme.color.secondary.main};
`;
