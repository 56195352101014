import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import ActivityDetails from 'components/Activity/ActivityDetails';
import ActivityType from 'core/enums/activityType';

const EventPage: FC = () => {
    const { t } = useTranslation();
    const { id } = useParams<Record<string, string>>();

    return (
        <Layout pageTitle={t('page.task-details.meta-title')}>
            <ActivityDetails activityType={ActivityType.Event} id={id} />
        </Layout>
    );
};

export default EventPage;
