import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { tasksSelector } from 'core/useCases/tasks/selectors';
import { isDateInDateRange } from 'utils/dateTimeFormat';
import TaskStatus from 'core/enums/taskStatus';
import { SCardWithTitleResponsive, SEyeIcon } from './styles';
import { Link } from 'react-router-dom';
import { ReactComponent as Eye } from 'images/icons/Eye.svg';

const TaskStatusDiagram: FC = () => {
    const { t } = useTranslation();
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const { startDate } = dateRangeSelected;
    const tasks = useSelector(tasksSelector);
    const [totalTasks, setTotalTasks] = useState<number>(0);
    const [tasksCompleted, setTasksCompleted] = useState<number>(0);
    const [tasksNotStarted, setTasksNotStarted] = useState<number>(0);

    const queryParam = isDateInDateRange(new Date(), dateRangeSelected) ? '' : `?time=${startDate.getTime()}`;

    useEffect(() => {
        const tasksInDateRange = tasks.filter((task) => isDateInDateRange(new Date(task.deadline), dateRangeSelected));

        const tasksCompletedInRange = tasksInDateRange.filter((task) => task.status === TaskStatus.Completed);

        const tasksNoStartedInRange = tasksInDateRange.filter((task) => task.status === TaskStatus.NotStarted);

        setTasksCompleted(tasksCompletedInRange.length);
        setTasksNotStarted(tasksNoStartedInRange.length);
        setTotalTasks(tasksInDateRange.length);
    }, [tasks, dateRangeSelected]);

    const options = {
        plugins: {
            legend: {
                labels: {
                    boxWidth: 7,
                    padding: 20,
                    usePointStyle: true,
                },
                position: 'bottom',
            },
        },
    };

    const data = {
        datasets: [
            {
                backgroundColor: ['rgba(124, 210, 171, 1)', 'rgba(255, 127, 107, 1)'],
                borderWidth: 1,
                data: [tasksCompleted, tasksNotStarted],
            },
        ],
        labels: [t('dashboard.task-status.completed'), t('dashboard.task-status.not-started')],
    };

    return (
        <SCardWithTitleResponsive label={t('dashboard.status-of-tasks')}>
            {totalTasks > 0 && (
                <SEyeIcon>
                    <Link to={`/activities${queryParam}`}>
                        <Doughnut data={data} options={options} />
                        <Eye />
                    </Link>
                </SEyeIcon>
            )}
        </SCardWithTitleResponsive>
    );
};

export default TaskStatusDiagram;
