import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import DetailsPage from 'components/DetailsPage';
import Breadcrumb from 'components/DetailsPage/Breadcrumb';
import TaskInformation from 'components/Task/TaskDetails/Information';
import EventInformation from 'components/Event/EventDetails/Information';
import LinkedContact from 'components/DetailsPage/LinkedContact';
import { eventByIdSelector } from 'core/useCases/events/selectors';
import ActivityCommentAndReport from 'components/Activity/ActivityCommentAndReport';
import ActivityType from 'core/enums/activityType';
import EventStatus from 'core/enums/eventStatus';
import { taskByIdSelector } from 'core/useCases/tasks/selectors';
import Event from 'core/models/event';
import Task from 'core/models/task';

type ActivityDetailsProps = {
    id: string;
    activityType: ActivityType;
};

const ActivityDetails: FC<ActivityDetailsProps> = ({ id, activityType }) => {
    const { t } = useTranslation();
    const activity =
        ActivityType.Task === activityType
            ? useSelector((state: RootState) => taskByIdSelector(state, id))
            : useSelector((state: RootState) => eventByIdSelector(state, id));
    if (!activity) {
        return null;
    }

    const isTask = ActivityType.Task === activityType;

    const { comments, markAsCompleteComment, status } = activity;
    const customSubject = isTask ? (activity as Task).customSubject : undefined;
    const mood = isTask ? undefined : (activity as Event).mood;
    const resources = isTask ? (activity as Task).resources : (activity as Event).resources;

    let breadcrumbSecondLabel;

    if (!isTask) {
        breadcrumbSecondLabel = `${t(`common.event-type.enum.${(activity as Event).type}`)}: ${t(
            `common.${(activity as Event).type}-subject.enum.${activity.subject}`,
        )}`;
    } else if (isTask && !customSubject) {
        breadcrumbSecondLabel = t(`common.task-subject.enum.${activity.subject}`);
    } else if (isTask && customSubject) {
        breadcrumbSecondLabel = customSubject;
    }

    return (
        <DetailsPage
            activities={
                <ActivityCommentAndReport
                    activityType={activityType}
                    comments={comments}
                    id={id}
                    isAlreadyCompleted={EventStatus.Completed === status}
                    markAsCompleteComment={markAsCompleteComment}
                    mood={mood}
                    resources={resources}
                />
            }
            breadcrumb={
                <Breadcrumb
                    linkLabel={t('common.activities')}
                    linkPath="/activities"
                    secondLabel={breadcrumbSecondLabel}
                />
            }
            information={
                isTask ? <TaskInformation task={activity as Task} /> : <EventInformation event={activity as Event} />
            }
            secondaryInformation={activity.contact && <LinkedContact contact={activity.contact} />}
        />
    );
};

export default ActivityDetails;
