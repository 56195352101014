import React, { FC } from 'react';
import { SColumn } from 'styles/layout';
import QuoteContent from './QuoteContent';
import { SWrapper, SQuoteClose, SQuoteOpen, SQuoteContentWrapper } from './styles';

const Quotes: FC = () => (
    <SWrapper>
        <SColumn>
            <SQuoteOpen />
            <SQuoteContentWrapper>
                <QuoteContent />
            </SQuoteContentWrapper>
            <SQuoteClose />
        </SColumn>
    </SWrapper>
);

export default Quotes;
