import styled from 'styled-components';
import { SColumn } from 'styles/layout';

export const STimeline = styled.div`
    display: flex;
    justify-content: center;
    width: 4rem;
`;

export const SRectangle = styled.div`
    position: relative;
    width: 2px;
    height: 100%;
    background: ${({ theme }) => theme.color.primary.main};
`;

export const SAddedContactRectangle = styled(SRectangle)`
    height: 50%;
`;

export const SCircle = styled.div`
    position: absolute;
    top: 50%;
    left: -7px;
    width: 16px;
    height: 16px;
    border: solid 2px ${({ theme }) => theme.color.white};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.color.primary.main};
`;

export const SAddedContactCircle = styled(SCircle)`
    top: 100%;
`;

export const SCardWrapper = styled.div`
    width: 100%;
    padding: 0.5rem 0;
`;

export const SContactAddedCard = styled(SColumn)`
    width: 100%;
    padding-top: 3rem;
`;
