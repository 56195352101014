import React, { FC } from 'react';
import { SMoodWrapper } from './styles';
import { SSubCard } from 'styles/components';
import EventMood from 'core/enums/eventMood';
import EventMoodVisualisation from 'components/Event/EventMoodVisualisation';

type ActivityReportVisualisationProps = {
    comment?: string;
    mood?: EventMood;
};

const ActivityReportVisualisation: FC<ActivityReportVisualisationProps> = ({ comment, mood }) => {
    if (!comment && !mood) {
        return null;
    }

    return (
        <SSubCard>
            {mood && (
                <SMoodWrapper>
                    <EventMoodVisualisation mood={mood} />
                </SMoodWrapper>
            )}
            {comment && <p>{comment}</p>}
        </SSubCard>
    );
};

export default ActivityReportVisualisation;
