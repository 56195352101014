import styled, { keyframes } from 'styled-components';

export interface DiagramPartProps {
    deg: number;
    cumul: number;
    zindex: number;
    color: string;
}

export interface DiagramLabelProps {
    cumul: number;
}

const rotatePart = (cumul: number) => keyframes`
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(${cumul}deg);
    }
  }
`;

export const SDiagramPart = styled.div<DiagramPartProps>`
    position: absolute;
    top: 100%;
    left: 0;
    width: inherit;
    height: inherit;
    border: 55px solid;
    border-top: none;
    border-bottom-left-radius: 175px;
    border-bottom-right-radius: 175px;
    transform-origin: 50% 0;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    animation-fill-mode: forwards;
    animation-duration: 0.7s;
    animation-timing-function: ease;
    z-index: ${({ zindex }) => zindex};
    border-color: ${({ color }) => color};
    animation-name: ${({ cumul }) => rotatePart(cumul)};
`;

export const SLabel = styled.span<DiagramLabelProps>`
    position: absolute;
    top: 0;
    left: 1rem;
    font-size: 1.2rem;
    line-height: 1;
    transform: rotate(${({ cumul }) => `-${cumul}deg`});
`;
