import React, { FC } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StateBar from 'components/StateBar';
import Gauge, { GaugeItem } from 'components/Gauge';
import { tasksSelector } from 'core/useCases/tasks/selectors';
import { eventsSelector } from 'core/useCases/events/selectors';
import Event from 'core/models/event';
import EventStatus from 'core/enums/eventStatus';
import TaskStatus from 'core/enums/taskStatus';
import Task from 'core/models/task';
import { computeActivityFromEvent, computeActivityFromTask } from 'core/useCases/activities/utils';
import { TaskMediaType } from 'core/enums/taskMediaType';
import EventMediaType from 'core/enums/eventMediaType';
import Activity from 'core/models/activity';
import { computeActivityMediaTypeIconAndColor } from '../ActivityMediaType';
import { STitleContainer, SToolbar } from './styles';
import { SSpacedRow } from 'styles/layout';

const ActivityStateBar: FC = () => {
    const { t } = useTranslation();
    const events = useSelector(eventsSelector).filter((event: Event) => EventStatus.Completed !== event.status);
    const tasks = useSelector(tasksSelector).filter((task: Task) => TaskStatus.Completed !== task.status);

    const activities: Activity[] = [];
    events.forEach((event: Event) => activities.push(computeActivityFromEvent(event, t)));
    tasks.forEach((task: Task) => activities.push(computeActivityFromTask(task, t)));

    // Phone and Mobile is in the same gauge section
    const mediaTypeOrder: Array<TaskMediaType | EventMediaType> = [
        TaskMediaType.Phone,
        TaskMediaType.InMail,
        TaskMediaType.Email,
        EventMediaType.VideoConferencing,
        EventMediaType.FaceToFace,
        TaskMediaType.Other,
    ];

    const items: GaugeItem[] = mediaTypeOrder
        .map((mediaType: TaskMediaType | EventMediaType) => {
            const label =
                TaskMediaType.Phone === mediaType ? t('common.phone') : t(`common.media-type.enum.${mediaType}`);
            const filteredActivities = activities.filter((activity) => {
                if (activity?.mediaType) {
                    const mediaTypeArray = [mediaType];
                    if (TaskMediaType.Phone === mediaType) {
                        mediaTypeArray.push(TaskMediaType.Mobile);
                    }

                    return mediaTypeArray.includes(activity.mediaType);
                }

                return false;
            });

            const activitiesBySubject: Array<{ subject: string; count: number }> = _(filteredActivities)
                .groupBy('subject')
                .map((concernedActivities, subject) => ({
                    // eslint-disable-next-line no-mixed-operators
                    count: concernedActivities.length,

                    subject,
                }))
                .value();

            const tooltip = (
                <SToolbar>
                    <STitleContainer>
                        <span>{label}</span>
                        <span>{filteredActivities.length}</span>
                    </STitleContainer>
                    {activitiesBySubject.map((e) => (
                        <SSpacedRow key={e.subject}>
                            <span>{e.subject}</span>
                            <span>{e.count}</span>
                        </SSpacedRow>
                    ))}
                </SToolbar>
            );

            return {
                label,
                ...computeActivityMediaTypeIconAndColor(mediaType),
                count: filteredActivities.length,
                tooltip,
            };
        })
        .filter((item: GaugeItem) => item.count);

    return (
        <StateBar title={t('activities-state-bar.title')}>
            <Gauge items={items} />
        </StateBar>
    );
};

export default ActivityStateBar;
