import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SBoldSecondaryText } from 'styles/components';
import { SBreadcrumb } from './styles';
import { ReactComponent as Chevron } from 'images/icons/ChevronRight.svg';

type BreadcrumbProps = {
    linkLabel: string;
    linkPath: string;
    secondLabel: string | undefined;
};

const Breadcrumb: FC<BreadcrumbProps> = ({ linkLabel, linkPath, secondLabel }) => (
    <SBreadcrumb>
        <Link title={linkLabel} to={linkPath}>
            <SBoldSecondaryText>{linkLabel}</SBoldSecondaryText>
        </Link>
        <Chevron />
        <SBoldSecondaryText>{secondLabel}</SBoldSecondaryText>
    </SBreadcrumb>
);

export default Breadcrumb;
