import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SWrapper, SContentHeader } from './style';
import { isLoggedIn } from 'core/useCases/session/selectors';
import ConnectedActions from './ConnectedActions';
import Container from 'components/layouts/common/Container';
import logo from 'images/Logo-MyJobBooster-white.png';
import DisconnectedActions from './DisconnectedActions';

const Header: FC = () => {
    const { t } = useTranslation();
    const isLogged = useSelector(isLoggedIn);

    return (
        <SWrapper>
            <Container>
                <SContentHeader>
                    <Link title={t('header.dashboard')} to="/">
                        <img alt={t('header.logo-title')} src={logo} width="173" />
                    </Link>
                    {isLogged ? <ConnectedActions /> : <DisconnectedActions />}
                </SContentHeader>
            </Container>
        </SWrapper>
    );
};

export default Header;
