import { DateRange } from 'utils/dateTimeFormat';
import DateRangeSelectedActionTypes from './types';

export interface SetDateRangeSelectedAction {
    type: DateRangeSelectedActionTypes.SET_DATE_RANGE_SELECTED;
    payload: DateRange;
}

export interface ResetDateRangeSelectedAction {
    type: DateRangeSelectedActionTypes.RESET_DATE_RANGE_SELECTED;
}

export const SetDateRangeSelected = (dateRange: DateRange): SetDateRangeSelectedAction => ({
    payload: dateRange,
    type: DateRangeSelectedActionTypes.SET_DATE_RANGE_SELECTED,
});

export const ResetDateRangeSelected = (): ResetDateRangeSelectedAction => ({
    type: DateRangeSelectedActionTypes.RESET_DATE_RANGE_SELECTED,
});
