/* eslint-disable no-underscore-dangle,no-void,no-unused-expressions */
import { launchAmplitude, launchHotjar } from './tracking';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _axcb: any;
        axeptioSettings: {
            clientId: string;
            cookiesVersion: string;
        };
    }
}

export const loadTrackingScriptsIfAllowed = (amplitudeId: string, hotjarId: string): void => {
    void 0 === window._axcb && (window._axcb = []);

    window._axcb.push((axeptio: { on: (arg0: string, arg1: (choices: any) => Promise<void>) => void }) => {
        axeptio.on('cookies:complete', async (choices) => {
            if (choices.amplitude) {
                await launchAmplitude(amplitudeId);
            }
            if (choices.hotjar) {
                await launchHotjar(hotjarId);
            }
        });
    });
};
