/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import Step from './Step';
import { SWrapper, SCardWithTitle, SList, STitle, SCongratsImg, SVideoLinkContainer } from './styles';
import { SBoldSecondaryText } from 'styles/components';
import { SAddButton } from 'components/Contact/ContactDetails/RecommendedContacts/styles';
import { ReactComponent as Plus } from 'images/blueIcons/Plus.svg';
import { ReactComponent as Congrats } from 'images/illustrations/Congrats.svg';

const Explanations: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const openModal = (type: ModalType, title: string) => {
        dispatch(
            OpenModal({
                title,
                type,
            }),
        );
    };

    return (
        <SCardWithTitle>
            <STitle>{t('home.how-it-works.title')}</STitle>
            <span>{t('home.how-it-works.speech.intro')}</span>
            <SList>
                <li>{t('home.how-it-works.speech.asset1')}</li>
                <li>{t('home.how-it-works.speech.asset2')}</li>
                <li>{t('home.how-it-works.speech.asset3')}</li>
            </SList>
            <SVideoLinkContainer>
                <a href="https://www.youtube.com/watch?v=udIEg9XoJbE" rel="noopener noreferrer" target="_blank">
                    {t('home.how-it-works.video-link')}
                </a>
            </SVideoLinkContainer>
            <SWrapper>
                <Step
                    content={t('home.how-it-works.step1.content')}
                    number={1}
                    title={t('home.how-it-works.step1.title')}
                >
                    <button onClick={() => openModal(ModalType.Contact, t('contact.add-contact'))} type="button">
                        <SAddButton>
                            <Plus />
                            <SBoldSecondaryText>{t('contact.add-contact')}</SBoldSecondaryText>
                        </SAddButton>
                    </button>
                </Step>
                <Step
                    content={t('home.how-it-works.step2.content')}
                    number={2}
                    title={t('home.how-it-works.step2.title')}
                >
                    <>
                        <button onClick={() => openModal(ModalType.Task, t('task-list.add-task'))} type="button">
                            <SAddButton>
                                <Plus />
                                <SBoldSecondaryText>{t('task-list.add-task')}</SBoldSecondaryText>
                            </SAddButton>
                        </button>
                        <button onClick={() => openModal(ModalType.Event, t('activities.add-interview'))} type="button">
                            <SAddButton>
                                <Plus />
                                <SBoldSecondaryText>{t('activities.add-rendez-vous')}</SBoldSecondaryText>
                            </SAddButton>
                        </button>
                    </>
                </Step>
                <Step
                    content={t('home.how-it-works.step3.content')}
                    number={3}
                    title={t('home.how-it-works.step3.title')}
                >
                    <button
                        onClick={() => openModal(ModalType.Application, t('application.creation.label'))}
                        type="button"
                    >
                        <SAddButton>
                            <Plus />
                            <SBoldSecondaryText>{t('application.add-application')}</SBoldSecondaryText>
                        </SAddButton>
                    </button>
                </Step>
                <Step
                    content={t('home.how-it-works.step4.content')}
                    number={4}
                    title={t('home.how-it-works.step4.title')}
                >
                    <SCongratsImg>
                        <Congrats />
                    </SCongratsImg>
                </Step>
            </SWrapper>
        </SCardWithTitle>
    );
};

export default Explanations;
