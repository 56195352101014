import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import { SBoldPrimaryText, STable } from 'styles/components';
import Button from 'components/Button';
import MultiActionsButton from 'components/MultiActionsButton';
import FloatingButton from 'components/FloatingButton';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import ContactStatusComponent from '../ContactStatus';
import { ReactComponent as Add } from 'images/icons/Add.svg';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import ContactStatus from 'core/enums/contactStatus';
import Contact from 'core/models/contact';
import { getContactLabel, getContactLabelWithoutCivility, getParentOfContact } from 'core/useCases/contacts/utils';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import {
    SActions,
    SColumnResponsive,
    SRight,
    SRightResponsive,
    SSearchInput,
    SSpacedRowResponsive,
    STitle,
    SWrapper,
    SCard,
    SCardHeader,
    SCardHeaderContact,
    SCardLineLeft,
    SCardLine,
    SCardLineRight,
    SMale,
    SFemale,
    SCardHeaderLeft,
    SPremiumDiv,
    SPremiumButtonDiv,
} from './styles';
import EventType from 'core/enums/eventType';
import { SCenteredColumn } from 'styles/layout';
import { contactIdsWithApplicationSelector } from 'core/useCases/applications/selectors';
import ExportContact from './ExportContact';
import DeleteContactButton from '../DeleteContactButton';
import SelectFilters from 'components/SelectFilters';
import useResponsive from 'hooks/responsive';
import { Civility } from 'core/enums/civility';
import { useIsPremium } from 'hooks/userIsPremium';

const ContactList: FC = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const contacts = useSelector(contactsSelector);
    const contactIdsWithApplication = useSelector(contactIdsWithApplicationSelector);
    const dispatch = useDispatch();
    const [search, setSearch] = useState<string>('');
    const [filteredContact, setFilteredContact] = useState<Contact[]>([]);
    const [filterOptionsValues, setFilterOptionsValues] = useState<string[]>([]);
    const history = useHistory();
    const isPremium = useIsPremium();

    const filterOptions = [
        {
            component: <ContactStatusComponent status={ContactStatus.Engaged} />,
            value: ContactStatus.Engaged.toString(),
        },
        {
            component: <ContactStatusComponent status={ContactStatus.Incomplete} />,
            value: ContactStatus.Incomplete.toString(),
        },
        {
            component: <ContactStatusComponent status={ContactStatus.New} />,
            value: ContactStatus.New.toString(),
        },
        {
            component: <ContactStatusComponent status={ContactStatus.Thanked} />,
            value: ContactStatus.Thanked.toString(),
        },
    ];

    contacts.sort((a: Contact, b: Contact): number => {
        const label1 = `${a.lastName} ${a.firstName}`;
        const label2 = `${b.lastName} ${b.firstName}`;
        if (label1.toLowerCase() > label2.toLowerCase()) {
            return 1;
        }
        return -1;
    });

    const openContactModal = () => {
        dispatch(
            OpenModal({
                title: t('contact.add-contact'),
                type: ModalType.Contact,
            }),
        );
    };

    const openContactsModal = () => {
        dispatch(
            OpenModal({
                title: t('contact.import-contacts'),
                type: ModalType.Contacts,
            }),
        );
    };

    const openEditContactModal = (contact: Contact) => {
        dispatch(
            OpenModal({
                defaultValues: contact,
                title: t('contact-details.update-contact'),
                type: ModalType.Contact,
            }),
        );
    };

    const openTaskModal = (contact: Contact) => {
        dispatch(
            OpenModal({
                defaultValues: {
                    contact,
                },
                disabledFields: [DisabledField.ContactId],
                title: t('task-list.add-task'),
                type: ModalType.Task,
            }),
        );
    };

    const openInterviewModal = (contact: Contact) => {
        dispatch(
            OpenModal({
                defaultValues: {
                    contact,
                    type: EventType.Interview,
                },
                disabledFields: [DisabledField.ContactId],
                title: t('activities.add-interview'),
                type: ModalType.Event,
            }),
        );
    };

    useEffect(() => {
        let localContacts = [...contacts];

        if (filterOptionsValues.length > 0) {
            localContacts = contacts.filter((contact) => filterOptionsValues.includes(contact.status));
        }

        if (search) {
            const computedSearch = search.toLowerCase();
            setFilteredContact(
                localContacts.filter(
                    (contact) =>
                        getContactLabelWithoutCivility(contact).toLowerCase().includes(computedSearch) ||
                        contact.company?.toLowerCase().includes(computedSearch),
                ),
            );
        } else {
            setFilteredContact(localContacts);
        }
    }, [contacts, search, filterOptionsValues]);

    const handleSearchClick = () => {
        if (!isPremium) {
            history.push('/ask-subscription');
        }
    };

    const SearchInput = (
        <SSearchInput>
            <input
                onChange={(event) => setSearch(event.target.value)}
                onClick={handleSearchClick}
                placeholder={t('contact.search-for-a-contact')}
                type="text"
                value={search}
            />
            {!isPremium && (
                <SPremiumDiv>
                    <Premium />
                </SPremiumDiv>
            )}
        </SSearchInput>
    );

    const redirectToAskSubscription = () => {
        history.push('/ask-subscription');
    };

    const ImportButton = (
        <Button onClick={!isPremium ? redirectToAskSubscription : openContactsModal} withShadow>
            {!isPremium && (
                <SPremiumButtonDiv>
                    <Premium />
                </SPremiumButtonDiv>
            )}
            {t('contact.import-contacts')}
        </Button>
    );

    return (
        <SWrapper>
            <SSpacedRowResponsive>
                <SColumnResponsive>
                    <STitle>{t('page.contact.title')}</STitle>
                    <span>
                        {contacts.length} {t('common.contacts')}
                    </span>
                </SColumnResponsive>
                <SRightResponsive>
                    {SearchInput}
                    <SRight>
                        <SelectFilters
                            isPremium={isPremium}
                            options={filterOptions}
                            selectedOptionsValues={filterOptionsValues}
                            setSelectedOptionsValues={setFilterOptionsValues}
                        />
                        <ExportContact />
                    </SRight>
                </SRightResponsive>
            </SSpacedRowResponsive>
            {isMobile ? (
                filteredContact.map((contact: Contact, index: number) => {
                    const { id, company, mobileNumber, phoneNumber, email, rank, status, parent, civility } = contact;
                    const parentLabel = getParentOfContact(contact, t);
                    const contactLabel = getContactLabel(contact, t);

                    return (
                        <SCard key={id} isEven={index % 2 === 0}>
                            <SCardHeader>
                                <SCardHeaderLeft>
                                    {Civility.Male === civility ? <SMale /> : <SFemale />}
                                    <SCardHeaderContact>
                                        <Link title={contactLabel} to={`/contact/${id}`}>
                                            <SBoldPrimaryText>{contactLabel}</SBoldPrimaryText>
                                        </Link>
                                        <ContactStatusComponent status={status} />
                                    </SCardHeaderContact>
                                </SCardHeaderLeft>
                                <SActions className="showOnRowHover">
                                    <MultiActionsButton parentButtonContent={<Add />} iconButton>
                                        <Button onClick={() => openTaskModal(contact)} variant="popinText">
                                            {t('contact-details.popin-button-task')}
                                        </Button>
                                        <Button onClick={() => openInterviewModal(contact)} variant="popinText">
                                            {t('contact-details.popin-button-interview')}
                                        </Button>
                                    </MultiActionsButton>
                                    <button
                                        onClick={() => openEditContactModal(contact)}
                                        title={t('common.edit')}
                                        type="button"
                                    >
                                        <Edit />
                                    </button>
                                    <DeleteContactButton contact={contact} />
                                </SActions>
                            </SCardHeader>
                            <SCardLine>
                                <SCardLineLeft>{t('common.company.label')}</SCardLineLeft>
                                <SCardLineRight>{company}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.phone')}</SCardLineLeft>
                                <SCardLineRight>
                                    {mobileNumber} <br />
                                    {phoneNumber}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.email.label')}</SCardLineLeft>
                                <SCardLineRight>{email}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.rank')}</SCardLineLeft>
                                <SCardLineRight>
                                    <SBoldPrimaryText>{rank}</SBoldPrimaryText>
                                    {' - '}
                                    {/* eslint-disable-next-line max-len */}
                                    {parent ? (
                                        <Link title={parentLabel} to={`/contact/${parent.id}`}>
                                            {parentLabel}
                                        </Link>
                                    ) : (
                                        <span>{parentLabel}</span>
                                    )}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.label')}</SCardLineLeft>
                                <SCardLineRight>
                                    {contactIdsWithApplication.includes(id) && <SCenteredColumn>X</SCenteredColumn>}
                                </SCardLineRight>
                            </SCardLine>
                        </SCard>
                    );
                })
            ) : (
                <STable>
                    <thead>
                        <tr>
                            <th>{t('common.last-name.label')}</th>
                            <th>{t('application.label')}</th>
                            <th>{t('common.company.label')}</th>
                            <th>{t('common.phone')}</th>
                            <th>{t('common.email.label')}</th>
                            <th>{t('common.rank')}</th>
                            <th>{t('common.status')}</th>
                            <th aria-label={t('common.action')} />
                        </tr>
                    </thead>
                    <tbody className="table--contact">
                        {filteredContact.map((contact: Contact) => {
                            const { id, company, mobileNumber, phoneNumber, email, rank, status, parent } = contact;
                            const parentLabel = getParentOfContact(contact, t);
                            const contactLabel = getContactLabel(contact, t);

                            return (
                                <tr key={id}>
                                    <td>
                                        <Link title={contactLabel} to={`/contact/${id}`}>
                                            <SBoldPrimaryText>{contactLabel}</SBoldPrimaryText>
                                        </Link>
                                    </td>
                                    <td>
                                        {contactIdsWithApplication.includes(id) && <SCenteredColumn>X</SCenteredColumn>}
                                    </td>
                                    <td>{company}</td>
                                    <td>
                                        {mobileNumber} <br />
                                        {phoneNumber}
                                    </td>
                                    <td>{email}</td>
                                    <td>
                                        <SBoldPrimaryText>{rank}</SBoldPrimaryText>
                                        <br />
                                        {/* eslint-disable-next-line max-len */}
                                        {parent ? (
                                            <Link title={parentLabel} to={`/contact/${parent.id}`}>
                                                {parentLabel}
                                            </Link>
                                        ) : (
                                            <span>{parentLabel}</span>
                                        )}
                                    </td>
                                    <td>
                                        <ContactStatusComponent status={status} />
                                    </td>
                                    <td>
                                        <SActions className="showOnRowHover">
                                            <MultiActionsButton parentButtonContent={<Add />} iconButton>
                                                <Button onClick={() => openTaskModal(contact)} variant="popinText">
                                                    {t('contact-details.popin-button-task')}
                                                </Button>
                                                <Button onClick={() => openInterviewModal(contact)} variant="popinText">
                                                    {t('contact-details.popin-button-interview')}
                                                </Button>
                                            </MultiActionsButton>
                                            <button
                                                onClick={() => openEditContactModal(contact)}
                                                title={t('common.edit')}
                                                type="button"
                                            >
                                                <Edit />
                                            </button>
                                            <DeleteContactButton contact={contact} />
                                        </SActions>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </STable>
            )}
            <FloatingButton>
                <Button onClick={openContactModal} withShadow>
                    {t('contact.add-contact')}
                </Button>
                {ImportButton}
            </FloatingButton>
        </SWrapper>
    );
};

export default ContactList;
