export const DEFAULT_TRESHOLDS = {
    completedInterviewHigh: 6,
    completedInterviewLow: 2,
    completedInterviewMedium: 4,
    engagedApplicationHigh: 2,
    engagedApplicationLow: 0,
    engagedApplicationMedium: 1,
    firstTaskHigh: 20,
    firstTaskLow: 6,
    firstTaskMedium: 12,
    newContactHigh: 15,
    newContactLow: 8,
    newContactMedium: 12,
};

export const TRESHOLD_MAX = 30;

export const TRESHOLD_MIN = 0;

type DashboardTresholds = {
    newContactLow: number;
    newContactMedium: number;
    newContactHigh: number;
    completedInterviewLow: number;
    completedInterviewMedium: number;
    completedInterviewHigh: number;
    firstTaskLow: number;
    firstTaskMedium: number;
    firstTaskHigh: number;
    engagedApplicationLow: number;
    engagedApplicationMedium: number;
    engagedApplicationHigh: number;
};

export default DashboardTresholds;
