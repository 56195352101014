import styled from 'styled-components';

export const SContact = styled('p')`
    font-weight: bold;
    text-align: center;

    a {
        text-decoration: underline;
    }
`;

export const SVideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3rem;
`;
