import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { SList, SLink } from './styles';

const DisconnectedActions: FC = () => {
    const { t } = useTranslation();

    return (
        <SList>
            <li>
                <SLink title={t('header.login')} to="/login">
                    <span>{t('header.login')}</span>
                </SLink>
            </li>
        </SList>
    );
};

export default DisconnectedActions;
