/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import { useHistory } from 'react-router-dom';
import { SCenteredColumn } from 'styles/layout';
import { SBoldPrimaryText, SBoldSecondaryText } from 'styles/components';

const PaymentSuccessPage = () => {
    const { t } = useTranslation();
    const [delayBeforeRedirect, setDelayBeforeRedirect] = useState(5);
    const history = useHistory();

    const handleRedirectToProduct = () => {
        history.push('/');
    };

    useEffect(() => {
        const interval = window.setInterval(() => {
            setDelayBeforeRedirect((oldDelayBeforeRedirect) => oldDelayBeforeRedirect - 1);
        }, 1000);

        return () => {
            window.clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (delayBeforeRedirect === 0) {
            handleRedirectToProduct();
        }
    }, [delayBeforeRedirect]);

    return (
        <Layout pageTitle={t('page.payment-success.meta-title')}>
            <SCenteredColumn>
                <h1>
                    <SBoldSecondaryText>{t('page.payment-success.title')}</SBoldSecondaryText>
                </h1>
                <p>
                    <SBoldPrimaryText>{t('page.payment-success.content')}</SBoldPrimaryText>
                </p>
                <SBoldPrimaryText>
                    {t('page.payment-success.delay-before-redirect', { delay: delayBeforeRedirect })}
                </SBoldPrimaryText>
            </SCenteredColumn>
        </Layout>
    );
};

export default PaymentSuccessPage;
