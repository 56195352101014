import React, { FC, ReactNode } from 'react';
import { SStepBox, SNumberCircle, SActions, STitle } from './styles';

type StepProps = {
    number: number;
    title: string;
    content: string;
    children?: ReactNode;
};

const Step: FC<StepProps> = ({ number, title, content, children }) => (
    <SStepBox>
        <SNumberCircle>{number}</SNumberCircle>
        <STitle>{title}</STitle>
        <p>{content}</p>
        <SActions>{children}</SActions>
    </SStepBox>
);

export default Step;
