import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SSubCard, SWrapper } from './styles';
import { RootState } from 'core/store';
import { applicationByContactIdSelector } from 'core/useCases/applications/selectors';
import ApplicationList from 'components/Application/ApplicationList';
import Application from 'core/models/application';
import { SBoldPrimaryText } from 'styles/components';
import useResponsive from 'hooks/responsive';
import CardWithTitle from 'components/CardWithTitle';

type LinkedApplicationsProps = {
    contactId: string;
};

const LinkedApplications: FC<LinkedApplicationsProps> = ({ contactId }) => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    const applications: Application[] = useSelector((state: RootState) =>
        applicationByContactIdSelector(state, contactId),
    );

    // eslint-disable-next-line no-irregular-whitespace
    if (!applications || !applications.length) {
        return null;
    }

    if (isMobile) {
        return (
            <>
                <SBoldPrimaryText>{t('common.applications')}</SBoldPrimaryText>
                <SSubCard>
                    <ApplicationList applicationsToDisplay={applications} truncate />
                </SSubCard>
            </>
        );
    } else {
        return (
            <SWrapper>
                <CardWithTitle label={t('common.applications')}>
                    <SSubCard>
                        <ApplicationList applicationsToDisplay={applications} truncate />
                    </SSubCard>
                </CardWithTitle>
            </SWrapper>
        );
    }
};

export default LinkedApplications;
