import styled from 'styled-components';
import { SFixedButton } from '../../styles/components';

type ButtonPlusProps = {
    isActive?: boolean;
};

export const SWrapper = styled(SFixedButton)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const SButtons = styled.div`
    position: absolute;
    bottom: 2.5rem;
    z-index: 0;
    display: flex;
    flex-direction: column;
    padding: 2rem 0 5rem 2rem;
    gap: 1rem;
    align-items: flex-end;
`;

export const SButtonPlus = styled.button<ButtonPlusProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    border-radius: 100px;
    background-color: ${({ theme }) => theme.color.primary.main};
    transition: transform 150ms ease-out;
    box-shadow: -8px 8px 16px 0 rgba(0, 0, 0, 0.1), -4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    transform: ${({ isActive }) => (isActive ? 'rotate(45deg)' : 'none')};
    outline: 0;
    z-index: 1;
`;
