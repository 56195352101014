import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CardStat from 'components/CardStat';
import DiagramSemiCircle, { DiagramData } from 'components/DiagramSemiCircle';
import { ReactComponent as Target } from 'images/icons/Target.svg';
import { eventsSelector } from 'core/useCases/events/selectors';
import EventType from 'core/enums/eventType';
import { DateRange, isDateInDateRange, substractNbOfDay } from 'utils/dateTimeFormat';
import EventStatus from 'core/enums/eventStatus';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { computeProgression } from 'core/useCases/dashboard/utils';
import { dashboardTresholdsSelector } from 'core/useCases/dashboardTresholds/selectors';

const CompletedInterviewDiagram: FC = () => {
    const { t } = useTranslation();
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const { completedInterviewLow, completedInterviewMedium, completedInterviewHigh } =
        useSelector(dashboardTresholdsSelector);
    const events = useSelector(eventsSelector);
    const [value, setValue] = useState<number>();
    const [progression, setProgression] = useState<string>('');

    const nbOfFilteredEvents = (dateRange: DateRange): number =>
        events.filter(
            (event) =>
                EventType.Interview === event.type &&
                EventStatus.Completed === event.status &&
                isDateInDateRange(new Date(event.date), dateRange),
        ).length;

    useEffect(() => {
        const total = nbOfFilteredEvents(dateRangeSelected);

        const { startDate, endDate } = dateRangeSelected;
        const previousWeekDateRange = {
            endDate: substractNbOfDay(endDate, 7),
            startDate: substractNbOfDay(startDate, 7),
        };
        const previousTotal = nbOfFilteredEvents(previousWeekDateRange);

        setValue(total);
        setProgression(previousTotal && total ? computeProgression(previousTotal, total) : '');
    }, [events, dateRangeSelected]);

    const data: DiagramData[] = [
        {
            color: '#C0E9D7',
            key: 'low',
            rangeMax: completedInterviewLow,
            rangeMin: 0,
        },
        {
            color: '#86D6B2',
            key: 'middle',
            rangeMax: completedInterviewMedium,
            rangeMin: completedInterviewLow,
        },
        {
            color: '#61C99A',
            key: 'high',
            rangeMax: completedInterviewHigh,
            rangeMin: completedInterviewMedium,
        },
    ];

    return value !== undefined ? (
        <CardStat
            icon={<Target />}
            progression={progression}
            title={t('diagram.interview-completed.title')}
            value={value}
        >
            <DiagramSemiCircle data={data} value={value} />
        </CardStat>
    ) : null;
};

export default CompletedInterviewDiagram;
