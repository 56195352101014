import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Activities from './Activities';
import Information from './Information';
import RecommendedContacts from './RecommendedContacts';
import { getContactLabel } from 'core/useCases/contacts/utils';
import { contactByIdSelector } from 'core/useCases/contacts/selectors';
import { RootState } from 'core/store';
import Breadcrumb from 'components/DetailsPage/Breadcrumb';
import DetailsPage from 'components/DetailsPage';
import { getNumericDate } from 'utils/dateTimeFormat';
import LinkedApplications from './LinkedApplications';
import useResponsive from 'hooks/responsive';
import { SBreadcrumbRight } from './styles';

type ContactDetailsProps = {
    id: string;
};

const ContactDetails: FC<ContactDetailsProps> = ({ id }) => {
    const { t } = useTranslation();
    const contact = useSelector((state: RootState) => contactByIdSelector(state, id));
    const { isMobile } = useResponsive();

    return (
        <div>
            {contact && (
                <DetailsPage
                    activities={<Activities contact={contact} />}
                    breadcrumb={
                        <>
                            {!isMobile && <Breadcrumb
                                linkLabel={t('contact-details.my-network')}
                                linkPath="/contacts"
                                secondLabel={getContactLabel(contact, t)}
                            />}
                            <SBreadcrumbRight>{`${t('contact-details.added')} ${getNumericDate(contact.createdDate)}`}</SBreadcrumbRight>
                        </>
                    }
                    information={<Information contact={contact} />}
                    secondaryActivities={<LinkedApplications contactId={id} />}
                    secondaryInformation={<RecommendedContacts contactId={id} />}
                />
            )}
        </div>
    );
};

export default ContactDetails;
