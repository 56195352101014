import Button from 'components/Button';
import React, { Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CheckedBlue } from 'images/icons/CheckedBlue.svg';
import { ReactComponent as UncheckedBlue } from 'images/icons/UncheckedBlue.svg';
import { ReactComponent as ChevronUp } from 'images/icons/ChevronUp.svg';
import { ReactComponent as ChevronDown } from 'images/icons/ChevronDown.svg';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import { ReactComponent as Filter } from 'images/icons/Filter.svg';
import onOutsideClick from 'utils/onOutsideClick';

import {
    SFilterButtonLabel,
    SFilterMenu,
    SFilterOption,
    SWrapper,
    SFilterColumn,
    SButtonWrapper,
    SPremiumDiv,
} from './style';
import { useHistory } from 'react-router-dom';

type Option = {
    component: ReactNode;
    value: string;
};

interface SelectFiltersProps {
    options: Option[];
    selectedOptionsValues: string[];
    setSelectedOptionsValues: Dispatch<SetStateAction<string[]>>;
    disabled?: boolean;
    isPremium: boolean;
}

const SelectFilters: FC<SelectFiltersProps> = ({
    disabled,
    options,
    selectedOptionsValues,
    setSelectedOptionsValues,
    isPremium,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = onOutsideClick(() => setIsOpen(false));
    const { t } = useTranslation();
    const history = useHistory();

    const onOptionClick = (option: Option) => {
        if (selectedOptionsValues.includes(option.value)) {
            setSelectedOptionsValues((value) => value.filter((optionValue) => optionValue !== option.value));
        } else {
            setSelectedOptionsValues((value) => [...value, option.value]);
        }
    };

    const firstColumnOptions = options.slice(0, 5);
    const secondColumnOptions = options.slice(5);

    const handleFilterClick = () => {
        if (isPremium) {
            setIsOpen((value) => !value);
        } else {
            history.push('/ask-subscription');
        }
    };

    const FilterButton = (
        <Button disabled={disabled} onClick={handleFilterClick} variant="outlined" secondary>
            <SFilterButtonLabel>
                <Filter />
                {`${t('common.filter', { count: selectedOptionsValues.length })} (${selectedOptionsValues.length})`}
                {!isPremium && (
                    <SPremiumDiv>
                        <Premium />
                    </SPremiumDiv>
                )}
            </SFilterButtonLabel>
            {isOpen ? <ChevronUp /> : <ChevronDown />}
        </Button>
    );

    return (
        <SWrapper ref={ref}>
            {FilterButton}
            {isOpen && (
                <SFilterMenu isOpen={isOpen}>
                    <SFilterColumn>
                        {firstColumnOptions.map((option) => (
                            <SFilterOption key={option.value} onClick={() => onOptionClick(option)}>
                                {selectedOptionsValues.includes(option.value) ? <CheckedBlue /> : <UncheckedBlue />}
                                {option.component}
                            </SFilterOption>
                        ))}
                    </SFilterColumn>
                    <SFilterColumn>
                        {secondColumnOptions.map((option) => (
                            <SFilterOption key={option.value} onClick={() => onOptionClick(option)}>
                                {selectedOptionsValues.includes(option.value) ? <CheckedBlue /> : <UncheckedBlue />}
                                {option.component}
                            </SFilterOption>
                        ))}
                    </SFilterColumn>
                    <SButtonWrapper>
                        <Button onClick={() => setSelectedOptionsValues([])} variant="text">
                            {t('common.reset')}
                        </Button>
                    </SButtonWrapper>
                </SFilterMenu>
            )}
        </SWrapper>
    );
};

export default SelectFilters;
