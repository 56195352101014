import React, { FC, ReactNode } from 'react';
import { SEmptyAddInfoWrapper } from './styles';
import { SBoldPrimaryText, SBoldSecondaryText, SCard, SSubCard } from 'styles/components';

type NoMessageProps = {
    icon: ReactNode;
    title: string;
    label: string;
    onClick: () => void;
};

const NoMessage: FC<NoMessageProps> = ({ icon, title, label, onClick }) => (
    <SCard>
        <SSubCard>
            <SEmptyAddInfoWrapper>
                {icon}
                <SBoldPrimaryText>{title}</SBoldPrimaryText>
                <button onClick={onClick} type="button">
                    <SBoldSecondaryText>{label}</SBoldSecondaryText>
                </button>
            </SEmptyAddInfoWrapper>
        </SSubCard>
    </SCard>
);

export default NoMessage;
