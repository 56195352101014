import LicenseActionsType from "./actionsTypes";
import License from "../../models/license";

export interface SetLicenseAction {
    type: LicenseActionsType.SET_LICENSE;
    payload: License | null;
}

export const SetLicense = (license: License | null): SetLicenseAction => ({
    payload: license,
    type: LicenseActionsType.SET_LICENSE,
});
