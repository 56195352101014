/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Task from 'core/models/task';
import { SetTasks } from 'core/useCases/tasks/taskActions';
import { tasksAlreadyFetchedSelector } from 'core/useCases/tasks/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';

export interface ResponseData {
    tasks: Task[];
}

const WithTasks: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const tasksAlreadyFetched = useSelector(tasksAlreadyFetchedSelector);

    const tasksFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/tasks`)) as ResponseData;

            dispatch(SetTasks(response.tasks));

            return response.tasks;
        } catch (error) {
            toast.error(t('common.error-occured'));

            dispatch(SetTasks([]));

            return [];
        }
    };

    useEffect(() => {
        if (!tasksAlreadyFetched) {
            setLoading(true);
            tasksFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithTasks;
