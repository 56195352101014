import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from 'core/models/contact';
import { SAddButton, SSubCards } from './styles';
import ContactSubCard from '../../ContactSubCard';
import CardWithTitle from 'components/CardWithTitle';
import { useDispatch, useSelector } from 'react-redux';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { SBoldSecondaryText } from 'styles/components';
import { ReactComponent as Plus } from 'images/blueIcons/Plus.svg';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';
import { toast } from 'react-toastify';
import { RootState } from 'core/store';
import { contactByIdSelector, contactsSelector } from 'core/useCases/contacts/selectors';

type GetResponseData = {
    recommendedContacts: Contact[];
};

const contactFetcher = async (contactId: string): Promise<GetResponseData> => {
    const response = (await GET<GetResponseData>(
        `${BACKEND_URL}/api/contact/recommended-contacts/${contactId}`,
    )) as GetResponseData;

    return response;
};

type RecommendedContactsProps = {
    contactId: string;
};

const RecommendedContacts: FC<RecommendedContactsProps> = ({ contactId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [recommendedContacts, setRecommendedContacts] = useState<Contact[]>([]);
    const contact = useSelector((state: RootState) => contactByIdSelector(state, contactId));
    // Use selector of contacts only to refresh recommended contact when contacts are modified
    const contacts = useSelector(contactsSelector);

    const openEditContactModal = () => {
        dispatch(
            OpenModal({
                defaultValues: { parent: contact },
                disabledFields: [DisabledField.ParentId],
                title: t('contact.add-contact'),
                type: ModalType.Contact,
            }),
        );
    };

    useEffect(() => {
        const asyncFuntion = async () => {
            try {
                const contactFetch = await contactFetcher(contactId);
                setRecommendedContacts(contactFetch.recommendedContacts);
            } catch (error) {
                toast.error(t('page.contact-details.fetch-details-error'));
            }
        };

        asyncFuntion();
    }, [contacts, contactId]);

    return (
        <CardWithTitle label={t('recommended-contacts.title')}>
            <SSubCards>
                {recommendedContacts.map((recommendedContact: Contact) => (
                    <ContactSubCard key={recommendedContact.id} contact={recommendedContact} />
                ))}
                <button onClick={openEditContactModal} type="button">
                    <SAddButton>
                        <Plus />
                        <SBoldSecondaryText>{t('contact.add-contact')}</SBoldSecondaryText>
                    </SAddButton>
                </button>
            </SSubCards>
        </CardWithTitle>
    );
};

export default RecommendedContacts;
