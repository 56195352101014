import styled, { css } from 'styled-components';

export interface BasicButtonProps {
    borderColor?: string;
    borderShade?: string;
    color?: string;
    fontFamily?: string;
    fontSize?: string;
    height?: string;
    margin?: string;
    shade?: string;
    uppercase?: boolean;
    large?: boolean;
    secondary?: boolean;
    withShadow?: boolean;
}

const sharedPlainButtonStyle = css<BasicButtonProps>`
    background-color: ${({ theme, secondary }) => (secondary ? theme.color.secondary.main : theme.color.primary.main)};
    color: ${({ theme }) => theme.color.white};

    &:hover {
        background-color: ${({ theme, secondary }) =>
            secondary ? theme.color.secondary.main : theme.color.primary.light};
    }
`;

const sharedBasicButtonStyle = css<BasicButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: ${({ theme }) => theme.button.height};
    padding-left: ${({ theme }) => theme.button.padding.horizontal};
    padding-right: ${({ theme }) => theme.button.padding.horizontal};
    font-weight: bold;
    border-radius: ${({ theme }) => theme.button.borderRadius};
    width: ${({ large }) => (large ? '100%' : 'auto')};
    box-shadow: ${({ withShadow }) =>
        withShadow ? '-1px 1px 5px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1)' : 'none'};

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
    }
`;

export const BasicButton = styled.button`
    ${sharedBasicButtonStyle}
`;

export const BasicLinkButton = styled.a`
    ${sharedBasicButtonStyle}
`;

export const SPlainButton = styled(BasicButton)`
    ${sharedPlainButtonStyle}
`;

export const SLinkButton = styled(BasicLinkButton)`
    ${sharedPlainButtonStyle}
`;

export const STextButton = styled(BasicButton)<BasicButtonProps>`
    background: none;
    color: ${({ theme, secondary }) => (secondary ? theme.color.secondary.main : theme.color.primary.main)};
`;

export const SPopinTextButton = styled(BasicButton)<BasicButtonProps>`
    width: 100%;
    padding: 1rem;
    height: 3.5rem;
    background-color: ${({ theme }) => theme.color.white};
    font-weight: normal;
    color: ${({ theme }) => theme.color.text.main};
    font-size: 1.2rem;
    white-space: nowrap;
    border-radius: 0;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey.lighter};
    }
`;

export const SOutlinedButton = styled(BasicButton)<BasicButtonProps>`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => `1px solid ${theme.color.secondary.main}`};
    border-radius: 5px;
    color: ${({ theme }) => theme.color.secondary.main};
    font-size: 14px;
    font-weight: 700;
    height: 39px;
    padding: 13px;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey.light2};
    }
`;

export const SOutlinedRedButton = styled(BasicButton)<BasicButtonProps>`
    background-color: ${({ theme }) => theme.color.white};
    border: ${({ theme }) => `1px solid ${theme.color.primary.main}`};
    border-radius: ${({ theme }) => theme.button.borderRadius};
    color: ${({ theme }) => theme.color.primary.main};
    font-size: 14px;
    font-weight: 700;
    height: 39px;
    padding: 13px;

    &:hover {
        background-color: ${({ theme }) => theme.color.grey.light2};
    }
`;
