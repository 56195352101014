import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const SWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.color.secondary.main};
`;

export const SContentHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.5rem;
`;

export const SNav = styled.nav`
    display: flex;
    align-items: center;
    gap: 3rem;
`;

export const SList = styled.ul`
    display: flex;
    align-items: center;
    gap: 5rem;
`;

export const SLink = styled(NavLink)`
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
    opacity: 0.5;

    &.active,
    &:hover {
        text-decoration: none;
        opacity: 1;
    }
`;
