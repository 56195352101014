import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SInner, SLogo, SSection, SText, STitle, SLoginLayout, SForm, SQuoteWrapper, SFormContent } from './styles';
import Logo from 'images/Logo.svg';
import Quotes from './Quotes';
import { SLinkButton } from 'components/Button/styles';

interface SessionProps {
    title: string;
}

const GetAccess: FC = () => {
    const { t } = useTranslation();

    return (
        <section>
            <STitle>{t('session.get-access.title')}</STitle>
            <SText>{t('session.get-access.text')}</SText>
            <SLinkButton href="https://www.myjob-booster.com/#contact" secondary>
                {t('session.get-access.action')}
            </SLinkButton>
        </section>
    );
};

const Session: FC<SessionProps> = ({ title, children }) => (
    <SLoginLayout>
        <SForm>
            <SFormContent>
                <SLogo alt="logo" src={Logo} />
                <SInner>
                    <SSection>
                        <STitle>{title}</STitle>
                        {children}
                    </SSection>
                    <GetAccess />
                </SInner>
            </SFormContent>
        </SForm>
        <SQuoteWrapper>
            <Quotes />
        </SQuoteWrapper>
    </SLoginLayout>
);
export default Session;
