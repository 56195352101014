import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { applicationsSelector } from 'core/useCases/applications/selectors';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { isDateInDateRange } from 'utils/dateTimeFormat';
import BarDiagram from './BarDiagram';
import { SCardWithTitleResponsive } from './styles';

const ApplicationDiagram: FC = () => {
    const { t } = useTranslation();
    const applications = useSelector(applicationsSelector);
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const [value, setValue] = useState<number>(0);

    useEffect(() => {
        const engagedApplications = applications.filter(
            (app) => app.engagmentDate && isDateInDateRange(new Date(app.engagmentDate), dateRangeSelected),
        );
        setValue(engagedApplications.length);
    }, [applications, dateRangeSelected]);

    return (
        <SCardWithTitleResponsive label={t('dashboard.engaged-application-number', { count: value })}>
            <BarDiagram value={value} />
        </SCardWithTitleResponsive>
    );
};

export default ApplicationDiagram;
