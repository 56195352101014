import styled from 'styled-components';

export const SButtonsContainer = styled.div`
    display: flex;
    justify-content: center;

    & > * {
        margin-left: 20px;
        margin-right: 20px;
    }
`;
