import ModalActionTypes, { ModalSettings } from './types';

export interface OpenModalAction {
    type: ModalActionTypes.OPEN_MODAL;
    payload: ModalSettings;
}

export interface CloseModalAction {
    type: ModalActionTypes.CLOSE_MODAL;
}

export const OpenModal = (settings: ModalSettings): OpenModalAction => ({
    payload: settings,
    type: ModalActionTypes.OPEN_MODAL,
});

export const CloseModal = (): CloseModalAction => ({
    type: ModalActionTypes.CLOSE_MODAL,
});
