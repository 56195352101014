import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const SLink = styled(Link)`
    color: blue;
    text-decoration: underline;
    cursor: pointer;
`;

export const SWrapper = styled.div`
    margin-bottom: 2rem;
`;

export const SLabel = styled.span`
    margin-left: 1rem;
`;
