/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import BACKEND_URL from 'constants/backendUrl';
import Button from 'components/Button';
import { SCenteredColumn } from 'styles/layout';
import { SBoldPrimaryText, SBoldSecondaryText } from 'styles/components';

const AskSubscriptionPage = () => {
    const { t } = useTranslation();

    const handleRedirectToPayment = () => {
        window.location.href = `${BACKEND_URL}/api/payment/session`;
    };

    return (
        <Layout pageTitle={t('page.ask-subscription.meta-title')}>
            <SCenteredColumn>
                <h1>
                    <SBoldSecondaryText>{t('page.ask-subscription.title')}</SBoldSecondaryText>
                </h1>
                <p>
                    <SBoldPrimaryText>{t('page.ask-subscription.content')}</SBoldPrimaryText>
                </p>
                <Button onClick={handleRedirectToPayment}>{t('page.ask-payment.payment-button')}</Button>
            </SCenteredColumn>
        </Layout>
    );
};

export default AskSubscriptionPage;
