import styled from 'styled-components';
import { SRow, SColumn } from 'styles/layout';
import { SCard as SCardGeneric } from 'styles/components';
import deviceSize from 'constants/deviceSize';

export const SHeader = styled(SRow)`
    padding: 2.7rem;
    gap: 2rem;
    background-color: ${({ theme }) => theme.color.grey.lighter};
    border-radius: 3px;
`;

export const SInner = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
`;

export const STitle = styled(SColumn)`
    font-weight: bold;
`;

export const SSup = styled.sup`
    margin: 1rem;
    font-size: 1.6rem;
    font-weight: normal;
`;

export const SValue = styled(SRow)`
    align-items: flex-start;
    font-size: 4.8rem;
    line-height: 1;
`;

export const SCard = styled(SCardGeneric)`
    width: 34rem;
    height: 32rem;
    padding: 0.5rem;
`;

export const SCardResponsive = styled(SCard)`
    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;
