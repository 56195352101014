import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import frLocale from 'date-fns/locale/fr';
import { getEndOfWeekDate, getNumericDate, getStartOfWeekDate, getWeekNumber } from 'utils/dateTimeFormat';
import { SLabel, SLabelWrapper, SPicker, SWrapper } from './styles';
import { ReactComponent as ChevronDown } from 'images/icons/ChevronDown.svg';
import { ReactComponent as ChevronUp } from 'images/icons/ChevronUp.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { SetDateRangeSelected } from 'core/useCases/dateRangeSelected/dateRangeSelectedAction';

registerLocale('fr', frLocale);

const WeekPicker: FC = () => {
    const { t } = useTranslation();
    const currentWeek = getWeekNumber(new Date());
    const location = useLocation();
    const [label, setLabel] = useState<string>('');
    const [dateSelected, setDateSelected] = useState<Date>(new Date());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const startDate = getStartOfWeekDate(dateSelected);
        const endDate = getEndOfWeekDate(dateSelected);

        dispatch(
            SetDateRangeSelected({
                endDate,
                startDate,
            }),
        );

        if (getWeekNumber(dateSelected) === currentWeek) {
            setLabel(t('common.this-week'));
        } else {
            setLabel(
                t('common.week-from-to', {
                    end: getNumericDate(endDate),
                    start: getNumericDate(startDate),
                }),
            );
        }
        setIsOpen(false);
    }, [dateSelected]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const defaultTime = queryParams.get('time');
        if (defaultTime) {
            const defaultDate = new Date();
            defaultDate.setTime(Number(defaultTime));
            setDateSelected(defaultDate);
        }
    }, []);

    return (
        <SWrapper>
            <button onClick={() => setIsOpen(!isOpen)} type="button">
                <SLabelWrapper>
                    <SLabel>{label}</SLabel>
                    {isOpen ? <ChevronUp /> : <ChevronDown />}
                </SLabelWrapper>
            </button>
            {isOpen && (
                <SPicker>
                    <ReactDatePicker
                        calendarStartDay={1}
                        endDate={dateSelected ? getEndOfWeekDate(dateSelected) : undefined}
                        locale="fr"
                        onCalendarClose={() => setIsOpen(false)}
                        onCalendarOpen={() => setIsOpen(true)}
                        onChange={(date: Date) => setDateSelected(date)}
                        startDate={dateSelected ? getStartOfWeekDate(dateSelected) : undefined}
                        inline
                    />
                </SPicker>
            )}
        </SWrapper>
    );
};

export default WeekPicker;
