import styled from 'styled-components';

interface STagContainerProps {
    color: string;
}

export const STagContainer = styled.div<STagContainerProps>`
    margin-top: -5px;
    background-color: ${(props) => props.color};
    border-radius: 50px;
    padding: 5px 0px;
    text-align: center;
    color: white;
    font-weight: 600;
    font-size: smaller;
`;
