import styled from 'styled-components';

export const SQuote = styled.blockquote`
    font-size: 2.4rem;
    font-weight: bold;
`;

export const SAuthor = styled.figcaption`
    font-size: 1.6rem;
    margin-top: 1rem;
`;
