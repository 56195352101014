import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Contacts from './Contacts';
import Contact from './Contact';
import Dashboard from './Dashboard';
import Task from './Task';
import Event from './Event';
import Home from './Home';
import Activities from './Activities';
import Applications from './Applications';
import Application from './Application';
import Agencies from './admin/Agencies';
import Candidates from './agencyAdmin/Candidates';
import Profile from './Profile';
import WithContacts from 'contexts/ContactsProvider';
import WithTasks from 'contexts/TasksProvider';
import WithEvents from 'contexts/EventsProvider';
import WithApplications from 'contexts/ApplicationsProvider';
import WithAgencies from 'contexts/AgenciesProvider';
import WithAgencyCandidates from 'contexts/AgencyCandidatesProvider';
import WithDashboardTresholds from 'contexts/DashboardTresholdsProvider';
import { userSelector } from 'core/useCases/session/selectors';
import { UserRole } from 'core/enums/userRole';
import LegalNoticePage from './LegalNotice';
import HelpPage from './Help';
import CGUPage from './CGU';
import WithSubscription from '../contexts/SubscriptionProvider';
import CandidateStatus from 'core/enums/candidateStatus';
import AskPayment from './AskPayment';
import AskSubscription from './AskSubscription';
import PaymentSuccess from './PaymentSuccess';
import WithCandidates from 'contexts/CandidatesProvider';

const CandidateRoutes: FC = () => (
    <WithSubscription>
        <WithContacts>
            <WithTasks>
                <WithEvents>
                    <WithApplications>
                        <WithDashboardTresholds>
                            <Switch>
                                <Route path="/ask-subscription" exact>
                                    <AskSubscription />
                                </Route>
                                <Route path="/dashboard" exact>
                                    <Dashboard />
                                </Route>
                                <Route path="/success" exact>
                                    <PaymentSuccess />
                                </Route>
                                <Route path="/contacts" exact>
                                    <Contacts />
                                </Route>
                                <Route path="/applications" exact>
                                    <Applications />
                                </Route>
                                <Route path="/activities" exact>
                                    <Activities />
                                </Route>
                                <Route path="/contact/:id" exact>
                                    <Contact />
                                </Route>
                                <Route path="/application/:id" exact>
                                    <Application />
                                </Route>
                                <Route path="/task/:id" exact>
                                    <Task />
                                </Route>
                                <Route path="/event/:id" exact>
                                    <Event />
                                </Route>
                                <Route path="/profile" exact>
                                    <Profile />
                                </Route>
                                <Route path="/legal-notice" exact>
                                    <LegalNoticePage />
                                </Route>
                                <Route path="/cgu" exact>
                                    <CGUPage />
                                </Route>
                                <Route path="/help" exact>
                                    <HelpPage />
                                </Route>
                                <Route path="/" exact>
                                    <Home />
                                </Route>
                                <Redirect to="/" />
                            </Switch>
                        </WithDashboardTresholds>
                    </WithApplications>
                </WithEvents>
            </WithTasks>
        </WithContacts>
    </WithSubscription>
);

const AdminRoutes: FC = () => (
    <WithCandidates>
        <WithAgencies>
            <Switch>
                <Route path="/" exact>
                    <Redirect to="/agencies" />
                </Route>
                <Route path="/agencies" exact>
                    <Agencies />
                </Route>
                <Route path="/candidates" exact>
                    <Candidates />
                </Route>
                <Route path="/profile" exact>
                    <Profile />
                </Route>
                <Route path="/legal-notice" exact>
                    <LegalNoticePage />
                </Route>
                <Route path="/help" exact>
                    <HelpPage />
                </Route>
                <Redirect to="/agencies" />
            </Switch>
        </WithAgencies>
    </WithCandidates>
);

const AgencyAdminRoutes: FC = () => (
    <WithAgencyCandidates>
        <Switch>
            <Route path="/" exact>
                <Redirect to="/candidates" />
            </Route>
            <Route path="/candidates" exact>
                <Candidates />
            </Route>
            <Route path="/profile" exact>
                <Profile />
            </Route>
            <Route path="/legal-notice" exact>
                <LegalNoticePage />
            </Route>
            <Route path="/help" exact>
                <HelpPage />
            </Route>
            <Redirect to="/candidates" />
        </Switch>
    </WithAgencyCandidates>
);

const AskPaymentRoutes: FC = () => (
    <Switch>
        <Route path="/" exact>
            <AskPayment />
        </Route>
        <Route path="/success" exact>
            <PaymentSuccess />
        </Route>
        <Route path="/profile" exact>
            <Profile />
        </Route>
        <Route path="/legal-notice" exact>
            <LegalNoticePage />
        </Route>
        <Route path="/help" exact>
            <HelpPage />
        </Route>
        <Redirect to="/" />
    </Switch>
);

const ConnectedRoutes: FC = () => {
    const user = useSelector(userSelector);

    if (
        user &&
        !user.hasFreeAccess &&
        !user.hasPaid &&
        (user.status === CandidateStatus.WithoutLicenseAfterTrialPeriod ||
            user.status === CandidateStatus.WithExpiredLicense)
    ) {
        return <AskPaymentRoutes />;
    }

    switch (user?.role) {
        case UserRole.Candidate:
            return <CandidateRoutes />;
        case UserRole.AgencyAdmin:
            return <AgencyAdminRoutes />;
        case UserRole.Admin:
            return <AdminRoutes />;
        default:
            throw Error('Wrong user role type enum in router');
    }
};

export default ConnectedRoutes;
