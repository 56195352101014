/* eslint-disable max-len */
import React, { FC } from 'react';
import { SActions } from './styles';
import { useSelector } from 'react-redux';
import { RootState } from 'core/store';
import EventStatus from 'core/enums/eventStatus';
import UpdateEventButton from './UpdateEventButton';
import DeleteEventButton from './DeleteEventButton';
import { eventByIdSelector } from 'core/useCases/events/selectors';

type EventActionsProps = {
    eventId: string;
};

const EventActions: FC<EventActionsProps> = ({ eventId }) => {
    const event = useSelector((state: RootState) => eventByIdSelector(state, eventId));

    if (!event) {
        return null;
    }

    return (
        <SActions>
            {EventStatus.Completed !== event.status && (
                <>
                    <UpdateEventButton eventId={eventId} />
                    <DeleteEventButton eventId={eventId} />
                </>
            )}
        </SActions>
    );
};

export default EventActions;
