import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import version from './version';
import './i18n';
import { loadTrackingScriptsIfAllowed } from 'config/axeptio';
import { launchAxeptio } from 'config/tracking';

const root = document.getElementById('root');

if (!root) {
    throw new Error('Missing root element');
}

const { amplitudeApiKey, axeptioApiKey, hotjarApiKey, sentryDsn, environment, ...props } = root.dataset;

Sentry.init({
    dsn: sentryDsn,
    environment,
    release: version ? `mjb-frontend@${version}` : undefined,
});

if (environment === 'production') {
    launchAxeptio(axeptioApiKey);
    loadTrackingScriptsIfAllowed(amplitudeApiKey || '', hotjarApiKey || '');
}

ReactDOM.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback="An error has occurred">
            <App {...props} />
        </Sentry.ErrorBoundary>
    </React.StrictMode>,
    root,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
