import React, { FC } from 'react';
import { STitle } from './styles';

interface TitleProps {
    text: string;
}

const Title: FC<TitleProps> = ({ text }) => <STitle>{text}</STitle>;

export default Title;
