import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SWrapper = styled(SRow)`
    gap: 1rem;
    border-radius: ${({ theme }) => theme.form.input.borderRadius};
    border: 1px solid ${({ theme }) => theme.color.grey.light};
    background-color: ${({ theme }) => theme.color.grey.light2};
    font-family: ${({ theme }) => theme.font};
    font-size: 14px;
    color: ${({ theme }) => theme.color.text.main};
    padding: 0 0.5rem;

    a {
        text-decoration: underline;
    }
`;
