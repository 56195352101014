/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SSubCard, SInfoLabel, SSubCards, SLink } from './styles';
import { SRow } from 'styles/layout';
import CardWithTitle from 'components/CardWithTitle';
import Event from 'core/models/event';
import { formatGoogleCalendarDate, getNumericDate, getTime, formatDateForOutlook } from 'utils/dateTimeFormat';
import EventActions from '../../EventActions';
import ApplicationSubjectItem from 'components/Application/ApplicationSubjectItem';
import ResourceInformation from 'components/DetailsPage/ResourceInformation';
import { computeUrlWithQueryParams } from 'utils/url';
import { addHours } from 'date-fns';
import { getContactDetailsLabel, getContactLabel } from 'core/useCases/contacts/utils';

type InformationProps = {
    event: Event;
};

const Information: FC<InformationProps> = ({ event }) => {
    const { t } = useTranslation();

    const {
        id,
        type,
        subject,
        mediaType,
        mediaDetails,
        status,
        date,
        application,
        contact,
        resources: persistedResources,
    } = event;
    const resources = persistedResources ? JSON.parse(persistedResources) : [];
    const mailSubject = t(`common.${type}-subject.enum.${subject}`);
    const label = `${t(`common.event-type.enum.${type}`)}: ${t(`common.${type}-subject.enum.${subject}`)}`;
    const baseUrl = 'https://calendar.google.com/calendar/u/0/r/eventedit';
    const startDate = new Date(date);
    const endDate = addHours(startDate, 1);
    const details = [
        `${t('common.contact')}: ${contact ? getContactDetailsLabel(contact, t) : t('common.no-contact')}`,
        `${t('common.media-type.label')}: ${t(`common.media-type.enum.${mediaType}`)}`,
        mediaDetails ? `${t('common.media-details.label')}: ${mediaDetails}` : '',
    ]
        .map((d) => d)
        .join('<br>');

    const GoogleQueryParams = [
        {
            name: 'dates',
            value: `${formatGoogleCalendarDate(startDate)}/${formatGoogleCalendarDate(endDate)}`,
        },
        {
            name: 'text',
            value: `MJB - ${t(`common.${type}-subject.enum.${subject}`)} - ${
                contact ? getContactLabel(contact, t) : t('common.no-contact')
            }`,
        },
        {
            name: 'details',
            value: encodeURIComponent(details),
        },
        {
            name: 'location',
            value: encodeURIComponent(mediaDetails ?? ''),
        },
    ];

    const outlookBaseUrl = 'https://outlook.live.com/calendar/deeplink/compose';

    const OutlookQueryParams = [
        {
            name: 'startdt',
            value: `${formatDateForOutlook(startDate)}`,
        },
        {
            name: 'enddt',
            value: `${formatDateForOutlook(endDate)}`,
        },
        {
            name: 'subject',
            value: encodeURIComponent(mailSubject ?? ''),
        },
        {
            name: 'body',
            value: encodeURIComponent(details ?? ''),
        },
        {
            name: 'location',
            value: encodeURIComponent(mediaDetails ?? ''),
        },
    ];

    // TODO add update
    return (
        <CardWithTitle actions={<EventActions eventId={id} />} label={label}>
            <SSubCards>
                <SSubCard>
                    <SRow>
                        <SInfoLabel>{t('common.media-type.label')}</SInfoLabel>
                        {t(`common.media-type.enum.${mediaType}`)}
                    </SRow>
                    {mediaDetails && (
                        <SRow>
                            <SInfoLabel>{t('common.media-details.label')}</SInfoLabel>
                            {mediaDetails}
                        </SRow>
                    )}
                    <SRow>
                        <SInfoLabel>{t('common.date')}</SInfoLabel>
                        {getNumericDate(date)}
                    </SRow>
                    <SRow>
                        <SInfoLabel>{t('common.time')}</SInfoLabel>
                        {getTime(date)}
                    </SRow>
                    <SRow>
                        <SInfoLabel>{t('common.status')}</SInfoLabel>
                        {t(`common.event-status.enum.${status}`)}
                    </SRow>
                    {application && (
                        <SRow>
                            <SInfoLabel>{t('application.label')}</SInfoLabel>
                            <ApplicationSubjectItem application={application} isCompanyBold={false} />
                        </SRow>
                    )}
                </SSubCard>
                <ResourceInformation resources={resources} />
            </SSubCards>
            <SLink href={computeUrlWithQueryParams(baseUrl, GoogleQueryParams)} rel="noopener" target="_blank">
                {t('event.export-to-calendar')}
            </SLink>
            <SLink href={computeUrlWithQueryParams(outlookBaseUrl, OutlookQueryParams)} rel="noopener" target="_blank">
                {t('event.export-to-outlook')}
            </SLink>
        </CardWithTitle>
    );
};

export default Information;
