import styled from 'styled-components';
import { SBoldPrimaryText, SCard } from 'styles/components';

export const SWrapper = styled(SCard)`
    flex: auto;
`;

export const SName = styled(SBoldPrimaryText)`
    font-size: 1.6rem;
`;
