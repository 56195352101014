import styled from 'styled-components';
import CardWithTitle from 'components/CardWithTitle';
import deviceSize from 'constants/deviceSize';

export const SCardWithTitleResponsive = styled(CardWithTitle)`
    width: 71rem;
    height: 100%;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;
