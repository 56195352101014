import styled from 'styled-components';

export const SLabel = styled.label`
    display: flex;
    flex-direction: row;
    gap: 1rem;

    margin-bottom: 3px;
    font-weight: bold;
    color: ${({ theme }) => theme.color.text.main};
`;
