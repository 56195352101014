import styled from 'styled-components';

export const BasicTabButton = styled.button`
    width: 100%;
    height: 3.6rem;
    padding-left: ${({ theme }) => theme.button.padding.horizontal};
    padding-right: ${({ theme }) => theme.button.padding.horizontal};
    font-weight: bold;
    border-radius: ${({ theme }) => theme.button.borderRadius};
    white-space: nowrap;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
        border: 1px solid ${({ theme }) => theme.color.secondary.main};
    }
`;

export const SPlainTabButton = styled(BasicTabButton)`
    background-color: ${({ theme }) => theme.color.secondary.main};
    color: ${({ theme }) => theme.color.white};
`;

export const STextTabButton = styled(BasicTabButton)`
    background: none;
    color: ${({ theme }) => theme.color.secondary.main};

    &:focus {
        outline: 0;
        border: 0;
    }
`;
