import React, { FC } from 'react';
import {
    BasicButtonProps,
    SPopinTextButton,
    STextButton,
    SPlainButton,
    SOutlinedButton,
    SOutlinedRedButton,
} from './styles';

export interface ButtonProps {
    disabled?: boolean;
    onClick?(): void;
    submit?: boolean;
    large?: boolean;
    secondary?: boolean;
    variant?: 'plain' | 'text' | 'popinText' | 'outlined' | 'outlinedRed';
    withShadow?: boolean;
}

const ButtonVariants = {
    outlined: SOutlinedButton,
    outlinedRed: SOutlinedRedButton,
    plain: SPlainButton,
    popinText: SPopinTextButton,
    text: STextButton,
};

const Button: FC<ButtonProps & BasicButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
    disabled = false,
    onClick,
    large = false,
    secondary = false,
    submit = false,
    variant = 'plain',
    withShadow = false,
    children,
}) => {
    const ButtonVariant = ButtonVariants[variant];

    return (
        <ButtonVariant
            disabled={disabled}
            large={large}
            onClick={onClick}
            secondary={secondary}
            type={submit ? 'submit' : 'button'}
            withShadow={withShadow}
        >
            {children}
        </ButtonVariant>
    );
};

export default Button;
