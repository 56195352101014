import styled from 'styled-components';
import CardWithTitle from 'components/CardWithTitle';
import { SSpacedRow } from 'styles/layout';
import { STitle as SGenericTitle } from '../../Typography/Title/styles';
import deviceSize from 'constants/deviceSize';

export const SCardWithTitle = styled(CardWithTitle)`
    width: auto;
`;

export const SWrapper = styled(SSpacedRow)`
    margin-top: 4.5rem;

    @media (max-width: ${deviceSize.mobile}px) {
        display: flex;
        flex-direction: column;
    }
`;

export const SList = styled.ul`
    li {
        list-style: inside;
    }
`;

export const STitle = styled(SGenericTitle)`
    margin-bottom: 2.5rem;
`;

export const SCongratsImg = styled.div`
    text-align: center;

    svg {
        height: auto;
        max-width: 10rem;
    }
`;

export const SVideoLinkContainer = styled.div`
    display: flex;
    flex-direction; row;
    margin: 1rem 0;

    & > a {
        text-decoration: underline;
    }
`;
