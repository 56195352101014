import Event from '../../models/event';
import { AddEventAction, DeleteEventAction, SetEventsAction, UpdateEventAction } from './eventActions';
import EventsActionTypes from './types';

export interface EventState {
    list: Event[];
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    list: [],
};

type Action = SetEventsAction | AddEventAction | UpdateEventAction | DeleteEventAction;

const eventsReducer = (state: EventState = init, action: Action): EventState => {
    switch (action.type) {
        case EventsActionTypes.SET_EVENTS:
            return { ...state, alreadyFetched: true, list: action.payload };
        case EventsActionTypes.ADD_EVENT:
            return { ...state, list: [...state.list, action.payload] };
        case EventsActionTypes.DELETE_EVENT:
            return { ...state, list: [...state.list.filter((a: Event) => a.id !== action.payload)] };
        case EventsActionTypes.UPDATE_EVENT: {
            const index = state.list.findIndex((a: Event) => a.id === action.payload.id);
            const newEvents = state.list;
            newEvents[index] = action.payload;
            return { ...state, list: [...newEvents] };
        }
        default:
            return state;
    }
};

export default eventsReducer;
