import Candidate from 'core/models/candidate';
import CandidateActionTypes from './types';

export interface SetCandidatesAction {
    type: CandidateActionTypes.SET_CANDIDATES;
    payload: Candidate[];
}

export interface AddCandidateAction {
    type: CandidateActionTypes.ADD_CANDIDATE;
    payload: Candidate;
}

export interface UpdateCandidateAction {
    type: CandidateActionTypes.UPDATE_CANDIDATE;
    payload: Candidate;
}

export interface UpdateSelectedCandidatesAction {
    type: CandidateActionTypes.UPDATE_SELECTED_CANDIDATES;
    payload: string[];
}

export interface DeleteSelectedCandidatesAction {
    type: CandidateActionTypes.DELETE_CANDIDATE;
    payload: string[];
}

export const AddCandidate = (candidate: Candidate): AddCandidateAction => ({
    payload: candidate,
    type: CandidateActionTypes.ADD_CANDIDATE,
});

export const UpdateCandidate = (candidate: Candidate): UpdateCandidateAction => ({
    payload: candidate,
    type: CandidateActionTypes.UPDATE_CANDIDATE,
});

export const SetCandidates = (candidate: Candidate[]): SetCandidatesAction => ({
    payload: candidate,
    type: CandidateActionTypes.SET_CANDIDATES,
});

export const UpdateSelectedCandidates = (selectedUsers: string[]): UpdateSelectedCandidatesAction => ({
    payload: selectedUsers,
    type: CandidateActionTypes.UPDATE_SELECTED_CANDIDATES,
});

export const DeleteSelectedCandidates = (selectedUsers: string[]): DeleteSelectedCandidatesAction => ({
    payload: selectedUsers,
    type: CandidateActionTypes.DELETE_CANDIDATE,
});
