/* eslint-disable max-len */
export const REGEXP = {
    EMAIL: {
        message: 'common.wrong-email-format',
        // eslint-disable-next-line no-control-regex
        value: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
    },
    PASSWORD: {
        message: 'common.wrong-password-format',
        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\s:])([^\s]){8,}$/i,
    },
    // rules : (+) 0 to 20 digit (with optionnal spaces between each digit)
    PHONE_NUMBER: {
        message: 'common.wrong-phone-number-format',
        value: /^(\+|00)?((\s|-)?\d){1,20}(\s)?$/i,
    },
};
