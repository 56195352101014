import React, { FC, useState } from 'react';
import { SInputs, SWrapper } from './styles';
import { ReactComponent as Add } from 'images/icons/Add.svg';
import { SColumn } from 'styles/layout';
import { useTranslation } from 'react-i18next';
import ResourceType from 'core/models/resource';
import Label from '../../Label';

interface AddResourceProps {
    onResourceAdded: (resource: ResourceType) => void;
}

const AddResource: FC<AddResourceProps> = ({ onResourceAdded }) => {
    const { t } = useTranslation();
    const [label, setLabel] = useState<string>('');
    const [url, setUrl] = useState<string>('');

    const addResource = () => {
        onResourceAdded({ label, url });
        setLabel('');
        setUrl('');
    };

    return (
        <SWrapper>
            <SInputs>
                <SColumn>
                    <Label name="label" text={t('common.resources.add.label.label')} />
                    <input
                        name="label"
                        onChange={(event) => setLabel(event.target.value)}
                        placeholder={t('common.resources.add.label.placeholder')}
                        value={label}
                    />
                </SColumn>
                <SColumn>
                    <Label name="value" text={t('common.resources.add.url.label')} />
                    <input
                        name="value"
                        onChange={(event) => setUrl(event.target.value)}
                        placeholder={t('common.resources.add.url.placeholder')}
                        value={url}
                    />
                </SColumn>
            </SInputs>
            <button onClick={addResource} title={t('common.resources.add.button-title')} type="button">
                <Add />
            </button>
        </SWrapper>
    );
};

export default AddResource;
