import styled from 'styled-components';
import CardWithTitle from 'components/CardWithTitle';
import deviceSize from 'constants/deviceSize';

export const SCardWithTitleResponsive = styled(CardWithTitle)`
    width: 34rem;
    height: 42rem;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
        height: 100%;
    }
`;

export const SEyeIcon = styled.div`
    position: relative;
    
    svg {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 35px;
        margin: auto;

        @media (max-width: ${deviceSize.mobile}px) {
            width: 32px;
            height: 32px;
        }
    }
`;
