import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from 'core/models/contact';
import { getJobAndCompanyOfContact, getContactLabel } from 'core/useCases/contacts/utils';
import { SBoldPrimaryText, SSubCard } from 'styles/components';
import { SName } from './styles';

type MainInformationProps = {
    contact: Contact;
    isRankAndParentDisplayed?: boolean;
};

const MainInformation: FC<MainInformationProps> = ({ contact }) => {
    const { t } = useTranslation();

    return (
        <SSubCard>
            <SName>{getContactLabel(contact, t)}</SName>
            <SBoldPrimaryText>{getJobAndCompanyOfContact(contact, t)}</SBoldPrimaryText>
        </SSubCard>
    );
};

export default MainInformation;
