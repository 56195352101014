/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { SActions } from './styles';
import UpdateApplicationButton from './UpdateApplicationButton';
import ApplicationStatus from 'core/enums/applicationStatus';
import { RootState } from 'core/store';
import { applicationByIdSelector } from 'core/useCases/applications/selectors';
import MultiActionsButton from '../../MultiActionsButton';
import { ReactComponent as Add } from 'images/icons/Add.svg';
import Button from 'components/Button';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import EventType from 'core/enums/eventType';

type ApplicationActionsProps = {
    applicationId: string;
};

const ApplicationActions: FC<ApplicationActionsProps> = ({ applicationId }) => {
    const application = useSelector((state: RootState) => applicationByIdSelector(state, applicationId));
    const dispatch = useDispatch();
    const { t } = useTranslation();

    if (!application) {
        return null;
    }

    const openTaskModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    application,
                },
                disabledFields: [DisabledField.ApplicationId],
                title: t('task-list.add-task'),
                type: ModalType.Task,
            }),
        );
    };

    const openInterviewModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    application,
                    type: EventType.Interview,
                },
                disabledFields: [DisabledField.ApplicationId],
                title: t('activities.add-interview'),
                type: ModalType.Event,
            }),
        );
    };
    return (
        <SActions>
            {[ApplicationStatus.New, ApplicationStatus.Engaged].includes(application.status) && (
                <>
                    <MultiActionsButton parentButtonContent={<Add />} iconButton>
                        <Button onClick={openTaskModal} variant="popinText">
                            {t('contact-details.popin-button-task')}
                        </Button>
                        <Button onClick={openInterviewModal} variant="popinText">
                            {t('contact-details.popin-button-interview')}
                        </Button>
                    </MultiActionsButton>
                    <UpdateApplicationButton applicationId={applicationId} />
                </>
            )}
        </SActions>
    );
};

export default ApplicationActions;
