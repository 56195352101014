/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { RootState } from 'core/store';
import { taskByIdSelector } from 'core/useCases/tasks/selectors';

type UpdateTaskButtonProps = {
    taskId: string;
};

const UpdateTaskButton: FC<UpdateTaskButtonProps> = ({ taskId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const task = useSelector((state: RootState) => taskByIdSelector(state, taskId));

    const openTaskModal = () => {
        dispatch(
            OpenModal({
                defaultValues: task,
                disabledFields: [DisabledField.ContactId, DisabledField.ApplicationId, DisabledField.Subject],
                title: t('task-list.update-task'),
                type: ModalType.Task,
            }),
        );
    };

    return (
        <button onClick={() => openTaskModal()} type="button">
            <Edit />
        </button>
    );
};

export default UpdateTaskButton;
