import React, { FC } from 'react';
import { STextTabButton, SPlainTabButton } from './styles';

export interface ButtonProps {
    disabled?: boolean;
    onClick?(): void;
    variant?: 'plain' | 'text';
}

const ButtonVariants = {
    plain: SPlainTabButton,
    text: STextTabButton,
};

const TabButton: FC<ButtonProps & React.HTMLProps<HTMLButtonElement>> = ({
    disabled = false,
    onClick,
    variant = 'plain',
    children,
}) => {
    const ButtonVariant = ButtonVariants[variant];

    return (
        <ButtonVariant disabled={disabled} onClick={onClick} type="button">
            {children}
        </ButtonVariant>
    );
};

export default TabButton;
