import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import ContactStatusEnum from 'core/enums/contactStatus';
import { SWrapper } from './styles';
import { defaultTheme } from 'styles/theme';
import { ReactComponent as Incomplete } from 'images/icons/IncompleteContact.svg';
import { ReactComponent as Engaged } from 'images/icons/EngagedContact.svg';
import { ReactComponent as New } from 'images/icons/NewContact.svg';
import { ReactComponent as Treated } from 'images/icons/TreatedContact.svg';

type ContactStatusProps = {
    status: ContactStatusEnum;
};

type ComputeStatus = (status: ContactStatusEnum) => { color: string; icon: ReactNode };

export const computeContactIconAndColorStatus: ComputeStatus = (status) => {
    switch (status) {
        case ContactStatusEnum.New:
            return {
                color: defaultTheme.color.contactStatus.new,
                icon: <New />,
            };
        case ContactStatusEnum.Engaged:
            return {
                color: defaultTheme.color.contactStatus.engaged,
                icon: <Engaged />,
            };
        case ContactStatusEnum.Thanked:
            return {
                color: defaultTheme.color.contactStatus.treated,
                icon: <Treated />,
            };
        case ContactStatusEnum.Incomplete:
            return {
                color: defaultTheme.color.contactStatus.incomplete,
                icon: <Incomplete />,
            };
        default:
            throw Error('Wrong contact status enum in compute color method');
    }
};

const ContactStatus: FC<ContactStatusProps> = ({ status }) => {
    const { t } = useTranslation();
    const computedStatus = computeContactIconAndColorStatus(status);

    return (
        <SWrapper color={computedStatus?.color}>
            {computedStatus?.icon}
            {t(`common.contact-status.enum.${status}`)}
        </SWrapper>
    );
};

export default ContactStatus;
