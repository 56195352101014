/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Edit } from 'images/icons/Edit.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { RootState } from 'core/store';
import { eventByIdSelector } from 'core/useCases/events/selectors';

type UpdateEventButtonProps = {
    eventId: string;
};

const UpdateEventButton: FC<UpdateEventButtonProps> = ({ eventId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const event = useSelector((state: RootState) => eventByIdSelector(state, eventId));

    const openUpdatingModal = () => {
        dispatch(
            OpenModal({
                defaultValues: event,
                disabledFields: [DisabledField.ContactId, DisabledField.ApplicationId, DisabledField.Subject],
                title: t('activities.update-this-interview'),
                type: ModalType.Event,
            }),
        );
    };

    return (
        <button onClick={() => openUpdatingModal()} type="button">
            <Edit />
        </button>
    );
};

export default UpdateEventButton;
