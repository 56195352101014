import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { SFormGroup } from 'components/Forms/lib/TextInput/styles';
import { ErrorMessage } from '@hookform/error-message';
import Label from 'components/Forms/lib//Label';
import ErrorMessageText from 'components/Forms/lib/ErrorMessageText';

export interface NumberInputProps {
    name: string;
    label?: string;
    required?: boolean;
    min?: number;
    max?: number;
}

const NumberInput: FC<NumberInputProps> = ({ name, label, required = false, min, max }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    return (
        <SFormGroup withError={!!errors[name]}>
            {label && <Label name={name} required={required} text={label} />}
            <Controller
                control={control}
                name={name}
                render={({ field: { onChange, ...rest } }) => (
                    <input
                        type="number"
                        {...rest}
                        max={max}
                        min={min}
                        onChange={(e) => {
                            onChange(parseInt(e.target.value, 10));
                        }}
                    />
                )}
            />

            {errors[name] && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorMessageText text={message} />}
                />
            )}
        </SFormGroup>
    );
};

export default NumberInput;
