import styled from 'styled-components';

export const SFileInputTitle = styled.div`
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 3px;
`;

export const SFileInputContainer = styled.div`
    border: ${({ theme }) => `1px dashed ${theme.color.grey.light}`};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    padding-bottom: 12px;
    padding-top: 12px;
`;

export const SNeedHelpContainer = styled.div`
    margin-bottom: 30px;
    height: 400px;
    overflow-y: scroll;
`;

export const SHelpContainer = styled.div`
    align-items: center;
    border: ${({ theme }) => `1px solid ${theme.color.grey.light2}`};
    border-radius: 5px;
    color: ${({ theme }) => theme.color.grey.main};
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    margin-right: 5px;
    padding: 13px;
`;

export const SHelpTitle = styled.span`
    display: flex;
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 12px;
`;

export const SHelpIconContainer = styled.div`
    align-items: center;
    display: flex;
    margin-left: 7px;
    margin-right: 18px;
`;

export const SHelpDescriptionContainer = styled.span`
    font-size: 11px;

    & > p {
        margin-bottom: 6px;

        & > strong {
            font-weight: 700;
        }
    }
    & > strong {
        font-weight: 700;
    }
`;

export const SHelpAnchor = styled.a`
    color: ${({ theme }) => theme.color.secondary.main};
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.color.secondary.main};
`;

export const SImportLoading = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 2rem 0;
    text-align: center;
`;
