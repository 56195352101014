import deviceSize from 'constants/deviceSize';
import styled from 'styled-components';
import { SRow } from 'styles/layout';

export const SActions = styled(SRow)`
    gap: 1rem;

    &:hover {
        fill: ${({ theme }) => theme.color.secondary.main};
    }

    @media (max-width: ${deviceSize.mobile}px) {
        margin-left: 10px;
    }
`;
