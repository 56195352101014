import EventMediaType from 'core/enums/eventMediaType';
import Event from 'core/models/event';

export const renderEventMediaText = (event: Event): string => {
    switch (event.mediaType) {
        case EventMediaType.Mobile:
            return event.contact.mobileNumber as string;
        case EventMediaType.Phone:
            return event.contact.phoneNumber as string;
        case EventMediaType.VideoConferencing:
        case EventMediaType.FaceToFace:
            return event?.mediaDetails as string;
        default:
            return '';
    }
};
