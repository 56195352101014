/* eslint-disable max-len */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DELETE } from 'utils/http';
import BACKEND_URL from 'constants/backendUrl';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { DeleteTask } from 'core/useCases/tasks/taskActions';
import { ReactComponent as Delete } from 'images/icons/Delete.svg';
import { useHistory, useLocation } from 'react-router-dom';
import { contactsFetcher } from 'contexts/ContactsProvider';

const deleteTaskRequest = async (taskId: string): Promise<void> => {
    await DELETE(`${BACKEND_URL}/api/task/${taskId}`);
};

type DeleteTaskButtonProps = {
    taskId: string;
};

const DeleteTaskButton: FC<DeleteTaskButtonProps> = ({ taskId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const isContactPage = pathname.split('/')[1] === 'contact';

    const deleteTask = async (id: string): Promise<void> => {
        try {
            await deleteTaskRequest(id);
            dispatch(DeleteTask(id));

            // TODO: for contact deletion button recheck, fetch only updated data
            await contactsFetcher(dispatch, t);

            toast.success(t('task-list.remove-task-success'));
            // No redirection if on contact page
            if (!isContactPage) {
                history.push('/activities');
            }
        } catch (error) {
            toast.error(t('task-list.remove-task-error'));
        }
    };

    return (
        <button onClick={() => deleteTask(taskId)} title={t('common.delete')} type="button">
            <Delete />
        </button>
    );
};

export default DeleteTaskButton;
