import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Contact from 'core/models/contact';
import { SSubCards } from './styles';
import ContactSubCard from 'components/Contact/ContactSubCard';
import CardWithTitle from 'components/CardWithTitle';
import { useSelector } from 'react-redux';
import { applicationByIdSelector } from 'core/useCases/applications/selectors';
import { RootState } from 'core/store';

type LinkedContactsProps = {
    applicationId: string;
};

const LinkedContacts: FC<LinkedContactsProps> = ({ applicationId }) => {
    const { t } = useTranslation();
    const application = useSelector((state: RootState) => applicationByIdSelector(state, applicationId));

    if (!application?.contacts || !application.contacts.length) {
        return null;
    }

    return (
        <CardWithTitle label={t('application.associated-contacts')}>
            <SSubCards>
                {application?.contacts.map((contact: Contact) => (
                    <ContactSubCard key={contact.id} contact={contact} />
                ))}
            </SSubCards>
        </CardWithTitle>
    );
};

export default LinkedContacts;
