import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { STitle } from './styles';
import CardWithTitle from 'components/CardWithTitle';
import { SLink } from 'styles/components';

const NeedHelp: FC = () => {
    const { t } = useTranslation();

    return (
        <CardWithTitle>
            <STitle>{t('home.need-help.title')}</STitle>
            <p>
                {t('home.need-help.text')} <SLink to="/help">{t('home.need-help.link')}</SLink> !
            </p>
        </CardWithTitle>
    );
};

export default NeedHelp;
