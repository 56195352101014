import styled from 'styled-components';
import deviceSize from 'constants/deviceSize';

export const STitle = styled.h1`
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1;
    @media (max-width: ${deviceSize.mobile}px) {
        text-align: center;
    }
`;
