import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardStat from 'components/CardStat';
import DiagramSemiCircle, { DiagramData } from 'components/DiagramSemiCircle';
import { ReactComponent as Rating } from 'images/icons/Rating.svg';
import { useSelector } from 'react-redux';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import { isDateInDateRange, substractNbOfDay } from 'utils/dateTimeFormat';
import { computeProgression } from 'core/useCases/dashboard/utils';
import { dashboardTresholdsSelector } from 'core/useCases/dashboardTresholds/selectors';

const NewContactDiagram: FC = () => {
    const { t } = useTranslation();
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const contacts = useSelector(contactsSelector);
    const { newContactLow, newContactMedium, newContactHigh } = useSelector(dashboardTresholdsSelector);
    const [value, setValue] = useState<number>();
    const [progression, setProgression] = useState<string>('');

    useEffect(() => {
        const total = contacts.filter((contact) =>
            isDateInDateRange(new Date(contact.createdDate), dateRangeSelected),
        ).length;

        const { startDate, endDate } = dateRangeSelected;
        const previousWeekDateRange = {
            endDate: substractNbOfDay(endDate, 7),
            startDate: substractNbOfDay(startDate, 7),
        };
        const previousTotal = contacts.filter((contact) =>
            isDateInDateRange(new Date(contact.createdDate), previousWeekDateRange),
        ).length;

        setValue(total);
        // Dont display progression if one value are null
        setProgression(previousTotal && total ? computeProgression(previousTotal, total) : '');
    }, [contacts, dateRangeSelected]);

    // Color in theme ?
    const data: DiagramData[] = [
        {
            color: '#FFCEC6',
            key: 'low',
            rangeMax: newContactLow,
            rangeMin: 0,
        },
        {
            color: '#FF9D8E',
            key: 'middle',
            rangeMax: newContactMedium,
            rangeMin: newContactLow,
        },
        {
            color: '#FF7F6B',
            key: 'high',
            rangeMax: newContactHigh,
            rangeMin: newContactMedium,
        },
    ];

    return value !== undefined ? (
        <CardStat
            icon={<Rating />}
            progression={progression}
            title={t('diagram.new-contact.title')}
            value={value}
        >
            <DiagramSemiCircle data={data} value={value} />
        </CardStat>
    ) : null;
};

export default NewContactDiagram;
