import styled, { css } from 'styled-components';
import { SRow } from 'styles/layout';

export const SGaugeWrapper = styled(SRow)`
    height: 2.7rem;

    svg {
        width: 24px;
        height: 24px;
    }
`;

type SItemProps = {
    ratio: number;
    firstItem: boolean;
    lastItem: boolean;
    backgroundColor: string;
};

export const SItem = styled.div<SItemProps>`
    display: flex;
    align-items: center;
    padding-left: 1rem;
    width: ${({ ratio }) => ratio}%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-left: solid 1px ${({ theme }) => theme.color.white};
    border-right: solid 1px ${({ theme }) => theme.color.white};
    ${(props) =>
        props.firstItem &&
        css`
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
        `}
    ${(props) =>
        props.lastItem &&
        css`
            border-top-right-radius: 9px;
            border-bottom-right-radius: 9px;
        `}
`;
