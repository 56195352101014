import Agency from 'core/models/agency';
import AgencyActionTypes from './types';

export interface SetAgenciesAction {
    type: AgencyActionTypes.SET_AGENCIES;
    payload: Agency[];
}

export interface AddAgencyAction {
    type: AgencyActionTypes.ADD_AGENCY;
    payload: Agency;
}

export interface UpdateAgencyAction {
    type: AgencyActionTypes.UPDATE_AGENCY;
    payload: Agency;
}

export const AddAgency = (agency: Agency): AddAgencyAction => ({
    payload: agency,
    type: AgencyActionTypes.ADD_AGENCY,
});

export const UpdateAgency = (agency: Agency): UpdateAgencyAction => ({
    payload: agency,
    type: AgencyActionTypes.UPDATE_AGENCY,
});

export const SetAgencies = (agency: Agency[]): SetAgenciesAction => ({
    payload: agency,
    type: AgencyActionTypes.SET_AGENCIES,
});
