import styled from 'styled-components';
import { SContainerField } from '../styles';

interface BaseFieldWrapperProps {
    withError?: boolean;
    semi?: boolean;
}

export const SFormGroup = styled(SContainerField)<BaseFieldWrapperProps>`
    width: ${({ semi }) => (semi ? '48%' : '100%')};
    & input,
    textarea {
        width: 100%;
        border-radius: ${({ theme }) => theme.form.input.borderRadius};
        border: 1px solid ${({ theme, withError }) => (withError ? theme.color.error.main : theme.color.grey.light)};
        font-family: ${({ theme }) => theme.font};
        font-size: 14px;
        color: ${({ theme }) => theme.color.text.main};
        padding: 8px;
        box-sizing: border-box;

        &::placeholder {
            color: ${({ theme }) => theme.color.text.light};
        }

        &:disabled {
            background-color: ${({ theme }) => theme.color.grey.lighter};
        }

        &:focus {
            border-color: ${({ theme, withError }) =>
                withError ? theme.color.primary.main : theme.color.secondary.main};
            outline: 0;
        }
    }
`;
