import styled from 'styled-components';

const SPriorityStatus = styled.div`
    background-color: ${({ theme }) => theme.color.priorityStatus};
    border-radius: 50%;
`;

export const SWrapper = styled.div`
    display: flex;
    width: 27px;
    height: 27px;
    justify-content: center;
    align-items: center;
`;

export const SHigh = styled(SPriorityStatus)`
    width: 15px;
    height: 15px;
`;

export const SUrgent = styled(SPriorityStatus)`
    width: 27px;
    height: 27px;
`;
