/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Event from 'core/models/event';
import { SetEvents } from 'core/useCases/events/eventActions';
import { eventsAlreadyFetchedSelector } from 'core/useCases/events/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';

export interface ResponseData {
    events: Event[];
}

const WithEvents: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const eventsAlreadyFetched = useSelector(eventsAlreadyFetchedSelector);

    const eventsFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/events`)) as ResponseData;

            dispatch(SetEvents(response.events));

            return response.events;
        } catch (error) {
            toast.error(t('common.error-occured'));

            dispatch(SetEvents([]));

            return [];
        }
    };

    useEffect(() => {
        if (!eventsAlreadyFetched) {
            setLoading(true);
            eventsFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithEvents;
