import styled from 'styled-components';
import { SContainerField } from '../styles';

interface BaseFieldWrapperProps {
    withError?: boolean;
    semi?: boolean;
}

export const SFormGroup = styled(SContainerField)<BaseFieldWrapperProps>`
    width: ${({ semi }) => (semi ? '48%' : '100%')};
`;
