import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { agenciesSelector } from 'core/useCases/agencies/selectors';
import Title from 'components/Typography/Title';
import FloatingButton from 'components/FloatingButton';
import Button from 'components/Button';
import Agency from 'core/models/agency';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { getUserLabel } from 'core/useCases/session/utils';
import { ModalType } from 'core/useCases/modal/types';
import { ReactComponent as Add } from 'images/icons/Add.svg';
import { STable } from 'styles/components';
import { SCenteredColumn, SColumn, SSpacedRowResponsive } from 'styles/layout';
import {
    SWrapper,
    SActions,
    SCard,
    SCardHeaderLeft,
    SCardHeader,
    SCardLine,
    SCardLineLeft,
    SCardLineRight,
} from './styles';
import useResponsive from 'hooks/responsive';

const AgencyList: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const agencies = useSelector(agenciesSelector);
    const { isMobile } = useResponsive();

    const openAgencyModal = () => {
        dispatch(
            OpenModal({
                title: t('agency.creation.label'),
                type: ModalType.Agency,
            }),
        );
    };
    const openAddLicensesModal = (agency: Agency) => {
        dispatch(
            OpenModal({
                defaultValues: { agencyId: agency.id, agencyName: agency.name },
                title: `${t('license.creation.label')} : ${agency.name}`,
                type: ModalType.License,
            }),
        );
    };

    return (
        <SWrapper>
            <SSpacedRowResponsive>
                <SColumn>
                    <Title text={t('page.agencies.title')} />
                    <span>
                        {agencies.length} {t('common.agencies')}
                    </span>
                </SColumn>
            </SSpacedRowResponsive>
            {isMobile ? (
                agencies.map((agency: Agency) => {
                    const {
                        id,
                        name,
                        admin,
                        numTotalLicenses,
                        numUsedLicenses,
                        numPendingLicenses,
                        numRemainingLicenses,
                    } = agency;
                    const adminLabel = getUserLabel(agency.admin, t);
                    return (
                        <SCard key={id}>
                            <SCardHeader>
                                <SCardHeaderLeft>{name}</SCardHeaderLeft>
                                <button
                                    onClick={() => openAddLicensesModal(agency)}
                                    title={t('license.add')}
                                    type="button"
                                >
                                    <Add />
                                </button>
                            </SCardHeader>
                            <SCardLine>
                                <SCardLineLeft>{t('agency.admin-name')}</SCardLineLeft>
                                <SCardLineRight>{adminLabel}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.email.label')}</SCardLineLeft>
                                <SCardLineRight>{admin.email}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.phone')}</SCardLineLeft>
                                <SCardLineRight>
                                    {admin.mobileNumber} <br />
                                    {admin.phoneNumber}
                                </SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('common.job.label')}</SCardLineLeft>
                                <SCardLineRight>{admin.job}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('agency.num-used-licenses')}</SCardLineLeft>
                                <SCardLineRight>{`${numUsedLicenses} / ${numTotalLicenses}`}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('agency.num-pending-licenses')}</SCardLineLeft>
                                <SCardLineRight>{numPendingLicenses}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('agency.num-remaining-licenses')}</SCardLineLeft>
                                <SCardLineRight>{numRemainingLicenses}</SCardLineRight>
                            </SCardLine>
                        </SCard>
                    );
                })
            ) : (
                <STable>
                    <thead>
                        <tr>
                            <th>{t('agency.agency-name')}</th>
                            <th>{t('agency.admin-name')}</th>
                            <th>{t('common.email.label')}</th>
                            <th>{t('common.phone')}</th>
                            <th>{t('common.job.label')}</th>
                            <th>
                                <SCenteredColumn>{t('agency.num-used-licenses')}</SCenteredColumn>
                            </th>
                            <th>
                                <SCenteredColumn>{t('agency.num-pending-licenses')}</SCenteredColumn>
                            </th>
                            <th>
                                <SCenteredColumn>{t('agency.num-remaining-licenses')}</SCenteredColumn>
                            </th>
                            <th aria-label={t('common.action')} />
                        </tr>
                    </thead>
                    <tbody className="table--agencies">
                        {agencies.map((agency: Agency) => {
                            const {
                                id,
                                name,
                                admin,
                                numTotalLicenses,
                                numUsedLicenses,
                                numPendingLicenses,
                                numRemainingLicenses,
                            } = agency;
                            const adminLabel = getUserLabel(agency.admin, t);
                            return (
                                <tr key={id}>
                                    <td>{name}</td>
                                    <td>{adminLabel}</td>
                                    <td>{admin.email}</td>
                                    <td>
                                        {admin.mobileNumber} <br />
                                        {admin.phoneNumber}
                                    </td>
                                    <td>{admin.job}</td>
                                    <td>
                                        <SCenteredColumn>{`${numUsedLicenses} / ${numTotalLicenses}`}</SCenteredColumn>
                                    </td>
                                    <td>
                                        <SCenteredColumn>{numPendingLicenses}</SCenteredColumn>
                                    </td>
                                    <td>
                                        <SCenteredColumn>{numRemainingLicenses}</SCenteredColumn>
                                    </td>
                                    <td>
                                        <SActions className="showOnRowHover">
                                            <button
                                                onClick={() => openAddLicensesModal(agency)}
                                                title={t('license.add')}
                                                type="button"
                                            >
                                                <Add />
                                            </button>
                                        </SActions>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </STable>
            )}
            <FloatingButton>
                <Button onClick={openAgencyModal} withShadow>
                    {t('agency.creation.label')}
                </Button>
            </FloatingButton>
        </SWrapper>
    );
};

export default AgencyList;
