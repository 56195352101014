import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { SNav, SList, SLink } from './style';

const DisconnectedActions: FC = () => {
    const { t } = useTranslation();

    return (
        <SNav>
            <SList>
                <li>
                    <SLink title={t('header.login')} to="/login">
                        <span>{t('header.login')}</span>
                    </SLink>
                </li>
            </SList>
        </SNav>
    );
};

export default DisconnectedActions;
