import Application from 'core/models/application';
import ApplicationActionTypes from './types';

export interface SetApplicationsAction {
    type: ApplicationActionTypes.SET_APPLICATIONS;
    payload: Application[];
}

export interface AddApplicationAction {
    type: ApplicationActionTypes.ADD_APPLICATION;
    payload: Application;
}

export interface UpdateApplicationAction {
    type: ApplicationActionTypes.UPDATE_APPLICATION;
    payload: Application;
}

export const AddApplication = (application: Application): AddApplicationAction => ({
    payload: application,
    type: ApplicationActionTypes.ADD_APPLICATION,
});

export const UpdateApplication = (application: Application): UpdateApplicationAction => ({
    payload: application,
    type: ApplicationActionTypes.UPDATE_APPLICATION,
});

export const SetApplications = (application: Application[]): SetApplicationsAction => ({
    payload: application,
    type: ApplicationActionTypes.SET_APPLICATIONS,
});
