import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { userProfileSelector } from 'core/useCases/session/selectors';
import ConnectedRoutes from './ConnectedRoutes';
import DisconnectedRoutes from './DisconnectedRoutes';
import { TrackingEventName } from 'utils/types/amplitude';
import { logEvent } from 'config/tracking';
import Modals from '../components/Modals';
import UserProfile from 'core/models/userProfile';

interface RoutesProps {
    user: UserProfile | null;
}

const Routes: FC<RoutesProps> = ({ user }) => {
    const { pathname } = useLocation();
    const userIsConnected = Boolean(user);

    useEffect(() => {
        logEvent({ name: TrackingEventName.PAGE_VIEW, params: { screen_name: pathname } });
    }, [logEvent, pathname]);

    return userIsConnected ? <ConnectedRoutes /> : <DisconnectedRoutes />;
};

const Router: FC = () => {
    const user = useSelector(userProfileSelector);
    return (
        <BrowserRouter>
            <Routes user={user} />
            <Modals />
        </BrowserRouter>
    );
};

export default Router;
