import React, { FC, ReactNode } from 'react';
import { TaskPriority as TaskPriorityEnum } from 'core/enums/taskPriority';
import { useTranslation } from 'react-i18next';
import { SWrapper, SHigh, SUrgent } from './styles';

type TaskPriorityIconProps = {
    priority: TaskPriorityEnum;
};

type ComputeStatus = (priority: TaskPriorityEnum, t: any) => ReactNode;

const computePriority: ComputeStatus = (priority, t) => {
    switch (priority) {
        case TaskPriorityEnum.Normal:
            return null;
        case TaskPriorityEnum.High:
            return <SHigh title={t(`common.task-priority.enum.${priority}`)} />;
        case TaskPriorityEnum.Urgent:
            return <SUrgent title={t(`common.task-priority.enum.${priority}`)} />;
        default:
            throw Error('Wrong task priority enum in compute priority method');
    }
};

const TaskPriorityIcon: FC<TaskPriorityIconProps> = ({ priority }) => {
    const { t } = useTranslation();
    const priorityIcon = computePriority(priority, t);

    return <SWrapper>{priorityIcon}</SWrapper>;
};

export default TaskPriorityIcon;
