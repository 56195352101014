import { ReactPortal, ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
    children: ReactNode;
}

const Portal = (props: PortalProps): ReactPortal => {
    const modalRoot = document.getElementById('modal') as HTMLElement;

    return createPortal(props.children, modalRoot);
};

export default Portal;
