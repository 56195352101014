import { CloseModalAction, OpenModalAction } from './modalAction';
import ModalActionTypes, { ModalSettings } from './types';

export interface ModalState {
    modal: ModalSettings | undefined;
}

const init = {
    modal: undefined,
};

type Action = OpenModalAction | CloseModalAction;

const modalReducer = (state: ModalState = init, action: Action): ModalState => {
    switch (action.type) {
        case ModalActionTypes.OPEN_MODAL:
            return { ...state, modal: action.payload };
        case ModalActionTypes.CLOSE_MODAL:
            return { ...state, modal: undefined };
        default:
            return state;
    }
};

export default modalReducer;
