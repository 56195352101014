import Task from 'core/models/task';
import { AddTaskAction, DeleteTaskAction, SetTasksAction, UpdateTasktAction } from './taskActions';
import TasksActionTypes from './types';

export interface TaskState {
    list: Task[];
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    list: [],
};

type Action = SetTasksAction | AddTaskAction | DeleteTaskAction | UpdateTasktAction;

const tasksReducer = (state: TaskState = init, action: Action): TaskState => {
    switch (action.type) {
        case TasksActionTypes.SET_TASKS:
            return { ...state, alreadyFetched: true, list: action.payload };
        case TasksActionTypes.ADD_TASK:
            return { ...state, list: [...state.list, action.payload] };
        case TasksActionTypes.DELETE_TASK:
            return { ...state, list: state.list.filter((a: Task) => a.id !== action.payload) };
        case TasksActionTypes.UPDATE_TASK: {
            const index = state.list.findIndex((a: Task) => a.id === action.payload.id);
            const newTasks = state.list;
            newTasks[index] = action.payload;
            return { ...state, list: [...newTasks] };
        }
        default:
            return state;
    }
};

export default tasksReducer;
