import Contact from 'core/models/contact';
import { TaskMediaType } from 'core/enums/taskMediaType';

export const renderTaskMediaText = (mediaType: TaskMediaType, contact: Contact): string => {
    switch (mediaType) {
        case TaskMediaType.Email:
            return contact.email as string;
        case TaskMediaType.Phone:
            return contact.phoneNumber as string;
        case TaskMediaType.Mobile:
            return contact.mobileNumber as string;
        default:
            return '';
    }
};
