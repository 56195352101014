export enum TrackingEventName {
    APPLICATION_CREATED = 'Candidature créée',
    CONTACT_CREATED = 'Contact crée',
    EVENT_CREATED = 'Entretien crée',
    PAGE_VIEW = 'Page vue',
    TASK_CREATED = 'Tâche créée',
}

export interface EventProps {
    name: TrackingEventName;
    params: Record<string, unknown>;
}
