import Task from 'core/models/task';
import { RootState } from 'core/store';
import { isAfter } from 'date-fns';

export const tasksSelector = (state: RootState): Task[] => state.tasks.list;

export const taskByIdSelector = (state: RootState, id: string): Task | undefined =>
    state.tasks.list.find((task: Task) => task.id === id);

export const tasksSortedByDeadlineSelector = (state: RootState): Task[] =>
    state.tasks.list.sort((a: Task, b: Task) => {
        if (isAfter(new Date(a.deadline), new Date(b.deadline))) {
            return 1;
        }
        return -1;
    });

export const tasksByContactIdSortedByDeadlineSelector = (state: RootState, id: string): Task[] =>
    state.tasks.list
        .filter((a: Task) => a?.contact && a.contact.id === id)
        .sort((a: Task, b: Task) => {
            if (isAfter(new Date(a.deadline), new Date(b.deadline))) {
                return 1;
            }
            return -1;
        });

export const tasksByApplicationIdSortedByDeadlineSelector = (state: RootState, id: string): Task[] =>
    state.tasks.list
        .filter((a: Task) => a?.application && a.application.id === id)
        .sort((a: Task, b: Task) => {
            if (isAfter(new Date(a.deadline), new Date(b.deadline))) {
                return 1;
            }
            return -1;
        });

export const tasksCount = (state: RootState): number => state.tasks.list.length;

export const tasksAlreadyFetchedSelector = (state: RootState): boolean => state.tasks.alreadyFetched;
