import frLocale from 'date-fns/locale/fr';
import React, { FC } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from '../ErrorMessageText';
import { SFormGroup } from './styles';
import Label from '../Label';
import 'react-datepicker/dist/react-datepicker.css';

interface DateOrTimeInputProps {
    control: any;
    minDate?: Date;
    placeholder?: string;
    name: string;
    label?: string;
    required?: boolean;
    semi?: boolean;
    typeTime?: boolean;
}

registerLocale('fr', frLocale);

const DateOrTimeInput: FC<DateOrTimeInputProps> = ({
    control,
    label,
    placeholder,
    name,
    required = false,
    semi = false,
    typeTime = false,
    ...props
}) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
    } = useFormContext();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => (
                <SFormGroup semi={semi} withError={!!errors[name]}>
                    {label && <Label name={name} required={required} text={label} />}
                    <ReactDatePicker
                        calendarStartDay={1}
                        dateFormat={typeTime ? 'HH:mm' : 'dd/MM/yyyy'}
                        locale="fr"
                        onChange={field.onChange}
                        placeholderText={placeholder ?? t('common.select-field-placeholder')}
                        selected={field.value}
                        {...(typeTime && {
                            showTimeSelect: true,
                            showTimeSelectOnly: true,
                            timeCaption: t('common.time'),
                            timeIntervals: 15,
                        })}
                        {...props}
                    />
                    {errors[name] && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => <ErrorMessageText text={message} />}
                        />
                    )}
                </SFormGroup>
            )}
            rules={{ required: required ? `${t('common.required-field')}` : false }}
        />
    );
};

export default DateOrTimeInput;
