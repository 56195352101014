import styled from 'styled-components';

type SWrapperProps = {
    color: string;
};

export const SWrapper = styled.span<SWrapperProps>`
    align-items: center;
    background-color: ${({ color }) => color};
    border-radius: 5px;
    border: solid 2px ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.white};
    display: inline-flex;
    font-size: 1rem;
    font-weight: 700;
    gap: 0.5rem;
    height: 2.5rem;
    padding: 1px 5px;
`;

export const SWrapperIcon = styled.span`
    width: 1.8rem;
    height: 1.6rem;

    svg {
        max-height: 1.4rem;
        width: auto;
    }
`;
