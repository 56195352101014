import Candidate from 'core/models/candidate';
import {
    AddCandidateAction,
    SetCandidatesAction,
    UpdateCandidateAction,
    UpdateSelectedCandidatesAction,
    DeleteSelectedCandidatesAction,
} from './candidateActions';
import CandidatesActionTypes from './types';

export interface AgencyState {
    list: Candidate[];
    alreadyFetched: boolean;
    selectedCandidatesIds: string[];
}

const init = {
    alreadyFetched: false,
    list: [],
    selectedCandidatesIds: [],
};

type Action =
    | SetCandidatesAction
    | AddCandidateAction
    | UpdateCandidateAction
    | DeleteSelectedCandidatesAction
    | UpdateSelectedCandidatesAction;

const agenciesReducer = (state: AgencyState = init, action: Action): AgencyState => {
    switch (action.type) {
        case CandidatesActionTypes.SET_CANDIDATES:
            return { ...state, alreadyFetched: true, list: action.payload };
        case CandidatesActionTypes.ADD_CANDIDATE:
            return { ...state, list: [action.payload, ...state.list] };
        case CandidatesActionTypes.UPDATE_CANDIDATE: {
            const index = state.list.findIndex((a: Candidate) => a.id === action.payload.id);
            const newCandidates = state.list;
            newCandidates[index] = action.payload;
            return { ...state, list: [...newCandidates] };
        }
        case CandidatesActionTypes.UPDATE_SELECTED_CANDIDATES:
            return { ...state, selectedCandidatesIds: action.payload };
        case CandidatesActionTypes.DELETE_CANDIDATE: {
            const newCandidates = state.list.filter((candidate) => !action.payload.includes(candidate.id));
            return { ...state, list: [...newCandidates], selectedCandidatesIds: [] };
        }

        default:
            return state;
    }
};

export default agenciesReducer;
