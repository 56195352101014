import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { SCardWithTitleResponsive } from './styles';
import { notStartedEventsSortedByDateSelector } from 'core/useCases/events/selectors';
import Event from 'core/models/event';
import { getCompleteDateWithShortDayLabel, getTime, isDateInDateRange } from 'utils/dateTimeFormat';
import { SBoldPrimaryText, STable } from 'styles/components';
import { renderEventMediaText } from 'core/useCases/events/utils';
import { getContactLabel } from 'core/useCases/contacts/utils';
import { computeUrlWithQueryParams } from 'utils/url';
import ActivityType from 'core/enums/activityType';

const NextInterviews: FC = () => {
    const { t } = useTranslation();
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const events = useSelector(notStartedEventsSortedByDateSelector);
    const [filteredEvents, setFilteredEvents] = useState<Event[]>([]);
    const { startDate } = dateRangeSelected;
    const params = [
        {
            name: 'type',
            value: String(ActivityType.Event),
        },
    ];

    if (!isDateInDateRange(new Date(), dateRangeSelected)) {
        params.push({
            name: 'time',
            value: String(startDate.getTime()),
        });
    }

    const seeAllPath = computeUrlWithQueryParams('/activities', params);

    useEffect(() => {
        setFilteredEvents(events.filter((event: Event) => isDateInDateRange(event.date, dateRangeSelected)));
    }, [events, dateRangeSelected]);

    return (
        <SCardWithTitleResponsive
            actions={
                <Link title={t('dashboard.see-all-events')} to={seeAllPath}>
                    {t('dashboard.see-all-events')}
                </Link>
            }
            label={t('dashboard.upcoming-event-number', { count: filteredEvents.length })}
        >
            <STable>
                <thead />
                <tbody>
                    {filteredEvents.slice(0, 3).map((event) => {
                        const { id, subject, contact, date, type } = event;

                        return (
                            <tr key={id}>
                                <td>
                                    {/* eslint-disable-next-line max-len */}
                                    <SBoldPrimaryText>{getCompleteDateWithShortDayLabel(date, t)}</SBoldPrimaryText>
                                    <br />
                                    {getTime(date)}
                                </td>
                                <td>
                                    {/* eslint-disable-next-line max-len */}
                                    <Link title={t(`common.${type}-subject.enum.${subject}`)} to={`/event/${id}`}>
                                        <SBoldPrimaryText>
                                            {t(`common.${type}-subject.enum.${subject}`)}
                                        </SBoldPrimaryText>
                                    </Link>
                                    <br />
                                    {renderEventMediaText(event)}
                                </td>
                                <td>
                                    <Link to={`/contact/${contact?.id}`}>
                                        <SBoldPrimaryText>
                                            {contact ? getContactLabel(contact, t) : null}
                                        </SBoldPrimaryText>
                                    </Link>
                                    <br />
                                    <span>{contact?.company}</span>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </STable>
        </SCardWithTitleResponsive>
    );
};

export default NextInterviews;
