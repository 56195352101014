import styled, { css } from 'styled-components';

interface BaseFieldWrapperProps {
    withError?: boolean;
}

export const sharedFieldStyles = css<BaseFieldWrapperProps>`
    width: 100%;
    border-radius: ${({ theme }) => theme.form.input.borderRadius};
    border: 1px solid ${({ theme, withError }) => (withError ? theme.color.error.main : theme.color.grey.light)};
    font-family: ${({ theme }) => theme.font};
    font-size: 14px;
    color: ${({ theme }) => theme.color.text.main};
    padding: 8px;
    box-sizing: border-box;
`;

export const SContainerField = styled.div`
    margin-bottom: 2rem;
`;
