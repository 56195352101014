import styled from 'styled-components';
import { SColumn, SSpacedRow } from 'styles/layout';

export const SToolbar = styled(SColumn)`
    width: 26rem;
`;

export const STitleContainer = styled(SSpacedRow)`
    span {
        font-weight: bold;
        font-size: 2rem;
        color: ${({ theme }) => theme.color.white};
        margin-bottom: 1rem;
    }
`;
