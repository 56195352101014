// import ContactInputs from '../../models/contact';
// import EventInputs from '../../models/event';
// import TaskInputs from '../../models/task';

export enum ModalType {
    Confirmation = 'confirmation',
    Contact = 'contact',
    Contacts = 'contacts',
    Task = 'task',
    Event = 'event',
    Application = 'application',
    ActivityReport = 'activity-report',
    ApplicationReport = 'application-report',
    TaskAdditionalInfo = 'task-additional-info',
    EventAdditionalInfo = 'event-additional-info',
    Agency = 'agency',
    License = 'license',
    Candidate = 'candidate',
    Information = 'information',
    DeleteCandidate = 'delete-candidate',
}

export enum DisabledField {
    ApplicationId = 'applicationId',
    ContactId = 'contactId',
    ParentId = 'parentId',
    Subject = 'subject',
}

export type ModalSettings = {
    type: ModalType;
    // defaultValues?: Partial<ContactInputs> | Partial<EventInputs> | Partial<TaskInputs>,
    // TODO Why i cant use type here => raise a build error in Modals/index.tsx
    defaultValues?: any;
    disabledFields?: Array<DisabledField>;
    title: string;
    cancelButtonLabel?: string;
    confirmAction?: () => void;
};

enum ModalActionTypes {
    OPEN_MODAL = 'OPEN_MODAL',
    CLOSE_MODAL = 'CLOSE_MODAL',
}

export default ModalActionTypes;
