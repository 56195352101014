/* eslint-disable react/jsx-no-useless-fragment */
import BACKEND_URL from 'constants/backendUrl';
import User from 'core/models/user';
import { setSessionUserAction } from 'core/useCases/session/logInAction';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GET } from 'utils/http';
import { clearSessionUserAction } from 'core/useCases/session/logOutAction';
import { setUserPropertiesForAmplitude } from 'config/tracking';

interface ResponseData {
    user: User;
}

export const sessionFetcher = async () => {
    const { user } = (await GET(`${BACKEND_URL}/api/auth/me`)) as ResponseData;
    return user;
};

const AuthInitializer: FC = ({ children }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        sessionFetcher()
            .then((user) => {
                dispatch(setSessionUserAction(user));
                setUserPropertiesForAmplitude(user.id);
            })
            .catch(() => dispatch(clearSessionUserAction()))
            .finally(() => setLoading(false));
    }, []);

    return <>{!loading && children}</>;
};

export default AuthInitializer;
