import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langFr from './translations/fr.json';

i18n.use(initReactI18next).init({
    interpolation: {
        escapeValue: false,
    },
    lng: 'fr',
    resources: {
        fr: { translation: langFr },
    },
});

export default i18n;
