import { endOfWeek, format, formatISO, getWeek, isBefore, startOfWeek } from 'date-fns';
import isAfter from 'date-fns/isAfter';

export type DateRange = {
    startDate: Date;
    endDate: Date;
};

export const concatDateTime = (date: Date, time: Date): Date => {
    const newDate = formatISO(date, { representation: 'date' });
    const newTime = formatISO(time, { representation: 'time' });
    const dateTime = `${newDate}T${newTime}`;

    return new Date(dateTime);
};

export const computeDateWithoutTime = (date: Date): Date => {
    const newDate = formatISO(date, { representation: 'date' });

    return new Date(`${newDate}T00:00:00Z`);
};

export const getCompleteDate = (date: Date, t: any) => {
    const castedDate = new Date(date);
    const dayLabel = t(`day.${format(castedDate, 'EEEE')}`);
    const dayNumber = format(castedDate, 'dd');
    const monthLabel = t(`month.${format(castedDate, 'LLLL')}`);

    return `${dayLabel} ${dayNumber} ${monthLabel}`;
};

export const getCompleteDateWithShortDayLabel = (date: Date, t: any) => {
    const castedDate = new Date(date);
    const dayLabel = t(`short-day.${format(castedDate, 'EEEE')}`);
    const dayNumber = format(castedDate, 'dd');
    const monthLabel = t(`month.${format(castedDate, 'LLLL')}`);

    return `${dayLabel} ${dayNumber} ${monthLabel}`;
};

export const getNumericDate = (date: Date) => format(new Date(date), 'dd/MM/yyyy');

export const getTime = (date: Date) => format(new Date(date), 'HH:mm');

export const isPastDate = (date: Date) => new Date(date).getTime() < new Date().getTime();

// weekStartsOn: 1 => start week on monday
export const getWeekNumber = (date: Date) => getWeek(date, { weekStartsOn: 1 });
export const getStartOfWeekDate = (date: Date) => startOfWeek(date, { weekStartsOn: 1 });
export const getEndOfWeekDate = (date: Date) => endOfWeek(date, { weekStartsOn: 1 });

export const isDateInDateRange = (date: Date, dateRange: DateRange): boolean => {
    const { startDate, endDate } = dateRange;
    const castedDate = new Date(date);

    return isAfter(castedDate, startDate) && isBefore(castedDate, endDate);
};

export const substractNbOfDay = (date: Date, nbDay: number): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - nbDay);

    return newDate;
};

export const formatGoogleCalendarDate = (date: Date) => formatISO(date, { format: 'basic' });

export const formatDateForOutlook = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};
