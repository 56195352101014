/* eslint-disable max-len */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { SActions } from './styles';
import UpdateTaskButton from './UpdateTaskButton';
import DeleteTaskButton from './DeleteTaskButton';
import TaskStatus from 'core/enums/taskStatus';
import { RootState } from 'core/store';
import { taskByIdSelector } from 'core/useCases/tasks/selectors';

type TaskActionsProps = {
    taskId: string;
};

const TaskActions: FC<TaskActionsProps> = ({ taskId }) => {
    const task = useSelector((state: RootState) => taskByIdSelector(state, taskId));

    if (!task) {
        return null;
    }

    return (
        <SActions>
            {TaskStatus.Completed !== task.status && (
                <>
                    <UpdateTaskButton taskId={taskId} />
                    <DeleteTaskButton taskId={taskId} />
                </>
            )}
        </SActions>
    );
};

export default TaskActions;
