import styled from 'styled-components';
import { sharedFieldStyles, SContainerField } from '../styles';

interface BaseFieldWrapperProps {
    withError?: boolean;
    semi?: boolean;
}

export const SFormGroup = styled(SContainerField)<BaseFieldWrapperProps>`
    width: ${({ semi }) => (semi ? '48%' : '100%')};
    .select {
        .react-select__control {
            ${sharedFieldStyles}
            min-height: auto;
            padding: 0 0 0 ${({ theme }) => theme.form.input.padding};
        }

        .react-select__control--is-focused {
            border-color: ${({ theme }) => theme.color.secondary.main};
            outline: 0;
        }

        .react-select__indicator {
            padding: 6px;
        }

        .react-select__value-container {
            font-family: ${({ theme }) => theme.font};
            font-size: 14px;
            color: ${({ theme }) => theme.color.text.main};
            padding: 0;
        }

        .react-select__placeholder {
            margin: 0;
            color: ${({ theme }) => theme.color.text.light};
        }

        .react-select__option--is-focused {
            background-color: ${({ theme }) => theme.color.grey.lighter};
        }

        .react-select__option--is-selected {
            background-color: ${({ theme }) => theme.color.secondary.main};
        }

        .react-select__single-value {
            color: ${({ theme }) => theme.color.text.main};
        }
    }
`;
