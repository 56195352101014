import styled from 'styled-components';
import { ReactComponent as QuoteOpen } from 'images/QuoteOpen.svg';
import { SColumn } from 'styles/layout';

export const SWrapper = styled(SColumn)`
    justify-content: center;
    text-align: center;
    height: 100%;

    > div {
        position: relative;
    }
`;

export const SQuoteContentWrapper = styled.div`
    max-width: 35rem;
    margin: auto;
    color: ${({ theme }) => theme.color.white};
`;

export const SQuoteOpen = styled(QuoteOpen)`
    position: absolute;
    top: -11rem;
    left: -15rem;
`;

export const SQuoteClose = styled(QuoteOpen)`
    position: absolute;
    bottom: -11rem;
    right: -12rem;
    transform: rotate(180deg);
`;
