import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StateBar from '../../StateBar';
import Gauge, { GaugeItem } from '../../Gauge';
import { applicationsSelector } from 'core/useCases/applications/selectors';
import ApplicationStatus from 'core/enums/applicationStatus';
import { computeApplicationIconAndColorStatus } from '../ApplicationStatus';

const ApplicationStateBar: FC = () => {
    const { t } = useTranslation();
    const applications = useSelector(applicationsSelector);

    const statusOrder = [
        ApplicationStatus.New,
        ApplicationStatus.Engaged,
        ApplicationStatus.Lost,
        ApplicationStatus.Win,
    ];

    const items: GaugeItem[] = statusOrder
        .map((label: ApplicationStatus) => ({
            label: t(`application.status.enum.${label}`),
            ...computeApplicationIconAndColorStatus(label),
            count: applications.filter((application) => label === application.status).length,
        }))
        .filter((item: GaugeItem) => item.count);

    return (
        <StateBar title={t('application.situation-analysis')}>
            <Gauge items={items} />
        </StateBar>
    );
};

export default ApplicationStateBar;
