import User from '../../models/user';
import SessionActionTypes from './types';

export interface SetSessionUserAction {
    type: SessionActionTypes.SET_SESSION_USER;
    payload: User;
}

export const setSessionUserAction = (user: User): SetSessionUserAction => ({
    payload: user,
    type: SessionActionTypes.SET_SESSION_USER,
});
