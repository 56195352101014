import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from 'core/useCases/session/selectors';
import { SSpacedRowWrapped } from 'styles/layout';
import { SInputWrapper } from '../styles';
import { SFormGroup } from 'components/Forms/lib/TextInput/styles';
import HintText from 'components/Forms/lib/Label';
import { UserRole } from 'core/enums/userRole';
import AgencyAdmin from 'core/models/agencyAdmin';

type ItemProps = {
    label: string;
    value: number;
};

const Item: FC<ItemProps> = ({ label, value }) => (
    <SFormGroup>
        <HintText text={label} />
        <input type="text" value={value} disabled />
    </SFormGroup>
);

const LicencesVisualisation: FC = () => {
    const { t } = useTranslation();
    const user = useSelector(userSelector);

    // This component if used only for agency admin
    if (!user || UserRole.AgencyAdmin !== user.role) {
        return null;
    }

    const {
        agency: { numPendingLicenses, numRemainingLicenses, numTotalLicenses, numUsedLicenses },
    } = user as AgencyAdmin;

    return (
        <SSpacedRowWrapped>
            <SInputWrapper>
                <Item label={t('agency.num-total-licenses')} value={numTotalLicenses} />
            </SInputWrapper>
            <SInputWrapper>
                <Item label={t('agency.num-used-licenses')} value={numUsedLicenses} />
            </SInputWrapper>
            <SInputWrapper>
                <Item label={t('agency.num-pending-licenses')} value={numPendingLicenses} />
            </SInputWrapper>
            <SInputWrapper>
                <Item label={t('agency.num-remaining-licenses')} value={numRemainingLicenses} />
            </SInputWrapper>
        </SSpacedRowWrapped>
    );
};

export default LicencesVisualisation;
