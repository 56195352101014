import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import ContactList from 'components/Contact/ContactList';
import ContactStateBar from 'components/Contact/ContactStateBar';

const Contacts: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.contact.meta-title')} stateBar={<ContactStateBar />}>
            <ContactList />
        </Layout>
    );
};

export default Contacts;
