import DashboardTresholds from '../../models/dashboardTresholds';
import DashboardTresholdsActionTypes from './types';

export interface SetDashboardTresholdsAction {
    type: DashboardTresholdsActionTypes.SET_DASHBOARD_TRESHOLDS;
    payload: DashboardTresholds;
}

export const SetDashboardTresholds = (dashboardTresholds: DashboardTresholds): SetDashboardTresholdsAction => ({
    payload: dashboardTresholds,
    type: DashboardTresholdsActionTypes.SET_DASHBOARD_TRESHOLDS,
});
