// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// eslint-disable-next-line import/prefer-default-export

import amplitude from 'amplitude-js';
import { EventProps } from 'tils/types/amplitude';

export const launchAmplitude = (amplitudeId: string): void => {
    amplitude.getInstance().init(amplitudeId);
};

export const setUserPropertiesForAmplitude = (userId: string): void => {
    if (amplitude) {
        const amp = amplitude.getInstance();
        amp.setUserId(userId);
    }
};

export const logEvent = ({ name, params }: EventProps): void => {
    if (amplitude) {
        amplitude.getInstance().logEvent(name, params);
    }
};

export const launchHotjar = (hotjarId: string): void => {
    /* eslint-disable */
    (function (h, o, t, j, a, r) {
        h.hj =
            h.hj ||
            function () {
                (h.hj.q = h.hj.q || []).push(arguments);
            };

        h._hjSettings = { hjid: hotjarId, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script');
        r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    /* eslint-disable */
};

export const launchAxeptio = (clientId): void => {
    window.axeptioSettings = {
        clientId: clientId,
        cookiesVersion: 'myjobbooster-fr',
    };
    (function (d, s) {
        var t = d.getElementsByTagName(s)[0],
            e = d.createElement(s);
        e.async = true;
        e.src = '//static.axept.io/sdk.js';
        t.parentNode.insertBefore(e, t);
    })(document, 'script');
};
