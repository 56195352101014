import styled from 'styled-components';
import { SCard, SSubCard } from 'styles/components';
import { SColumn, SRow } from 'styles/layout';
import { ReactComponent as Male } from 'images/icons/Male.svg';
import { ReactComponent as Female } from 'images/icons/Female.svg';

export const SWrapper = styled(SCard)`
    position: relative;
    align-items: center;
`;

export const SButtons = styled.div`
    display: flex;
    gap: 1rem;
    position: absolute;
    top: 2rem;
    right: 2rem;

    &:hover {
        fill: ${({ theme }) => theme.color.secondary.main};
    }
`;

export const SSubCards = styled(SColumn)`
    position: relative;
    width: 100%;
    margin-top: 2rem;
    gap: 1rem;
`;

export const SSecondaryInformation = styled(SSubCard)`
    gap: 1.2rem;
`;

export const SInfo = styled(SRow)`
    gap: 1rem;
`;

export const SImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.8rem;
    height: 1.8rem;
`;

export const SContactStatusWrapper = styled.div`
    position: absolute;
    top: -11px;
    right: 2rem;
`;

export const SMale = styled(Male)`
    width: 8rem;
    height: 8.2rem;
`;

export const SFemale = styled(Female)`
    width: 8rem;
    height: 8.2rem;
`;
