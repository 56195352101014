import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from 'core/useCases/session/selectors';
import { STitle, SSubTitle } from './styles';
import { ReactComponent as Coffee1 } from 'images/illustrations/Coffee1.svg';
import { ReactComponent as Coffee2 } from 'images/illustrations/Coffee2.svg';
import TopBanner from 'components/TopBanner';

const Welcome: FC = () => {
    const { t } = useTranslation();
    const user = useSelector(userSelector);

    if (!user) {
        return null;
    }

    return (
        <TopBanner>
            <Coffee1 />
            <STitle>
                {t('home.hello', { who: user.firstName })}
                <SSubTitle>{t('home.good-day-is-coming')}</SSubTitle>
            </STitle>
            <Coffee2 />
        </TopBanner>
    );
};

export default Welcome;
