/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';

const CGUPage = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.cgu.meta-title')}>
            <h1>{t('page.cgu.title')}</h1>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: t('page.cgu.text') }} />
        </Layout>
    );
};

export default CGUPage;
