import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from 'components/Forms/lib/ErrorMessageText';
import { SLink, SWrapper, SLabel } from './styles';

const CheckboxInput: FC = () => {
    const { t } = useTranslation();
    const name = 'hasValidatedUserAgreement';
    const {
        formState: { errors },
        register,
    } = useFormContext();

    return (
        <SWrapper>
            <input
                {...register(name, {
                    required: `${t('new-password-form.cgu.required')}`,
                })}
                type="checkbox"
            />
            <SLabel>
                {t('new-password-form.cgu.label1')} (
                <SLink rel="noopener noreferrer" target="_blank" title={t('new-password-form.cgu.label2')} to="/cgu">
                    {t('new-password-form.cgu.label2')}
                </SLink>
                )
            </SLabel>
            {errors[name] && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorMessageText text={message} />}
                />
            )}
        </SWrapper>
    );
};

export default CheckboxInput;
