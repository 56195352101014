import React, { FC } from 'react';
import ResourceType from 'core/models/resource';
import { SSubCard } from 'styles/components';
import { SWrapper } from './styles';

type ResourceInformationProps = {
    resources: Array<ResourceType>;
};

const ResourceInformation: FC<ResourceInformationProps> = ({ resources }) => {
    if (!resources || !resources.length) {
        return null;
    }

    return (
        <SSubCard>
            <SWrapper>
                {resources.map(({ url, label }) => (
                    // eslint-disable-next-line react/jsx-no-target-blank
                    <a key={`${url}${label}`} href={url} rel="noopener" target="_blank">
                        {label}
                    </a>
                ))}
            </SWrapper>
        </SSubCard>
    );
};

export default ResourceInformation;
