/* eslint-disable no-mixed-operators */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { dashboardTresholdsSelector } from 'core/useCases/dashboardTresholds/selectors';
import { SIndicator, SItem, SItemsWrapper, STriangle } from './styles';

type BarDiagramProps = {
    value: number;
};

const BarDiagram: FC<BarDiagramProps> = ({ value }) => {
    const { t } = useTranslation();
    const { engagedApplicationLow, engagedApplicationMedium, engagedApplicationHigh } =
        useSelector(dashboardTresholdsSelector);

    if (engagedApplicationLow === 0 && engagedApplicationMedium === 0 && engagedApplicationHigh === 0) {
        return <span>{t('dashboard.no-tresholds')}</span>;
    }

    const ratio1 = (engagedApplicationLow / engagedApplicationHigh) * 100;
    const ratio2 = ((engagedApplicationMedium - engagedApplicationLow) / engagedApplicationHigh) * 100;
    const ratio3 = ((engagedApplicationHigh - engagedApplicationMedium) / engagedApplicationHigh) * 100;

    return (
        <SItemsWrapper>
            {ratio1 > 0 && <SItem backgroundColor="#FFDDC3" ratio={ratio1} />}
            {ratio2 > 0 && <SItem backgroundColor="#FFBF8B" ratio={ratio2} />}
            {ratio3 > 0 && <SItem backgroundColor="#FFAC69" ratio={ratio3} />}
            <SIndicator offset={0}>0</SIndicator>
            {ratio1 > 0 && <SIndicator offset={ratio1}>{engagedApplicationLow}</SIndicator>}
            {ratio2 > 0 && <SIndicator offset={ratio1 + ratio2}>{engagedApplicationMedium}</SIndicator>}
            {ratio3 > 0 && <SIndicator offset={100}>{engagedApplicationHigh}</SIndicator>}
            <STriangle offset={Math.min(value / engagedApplicationHigh, 1) * 100} />
        </SItemsWrapper>
    );
};

export default BarDiagram;
