import React, { FC } from 'react';
import { SInfo } from './styles';

type TooltipProps = {
    label: string;
};

const Tooltip: FC<TooltipProps> = ({ label }) => <SInfo title={label} />;

export default Tooltip;
