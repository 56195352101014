import styled from 'styled-components';

type SLabelProps = {
    isBold: boolean;
    isCompleted: boolean;
};

export const SLabel = styled.span<SLabelProps>`
    ${({ isBold }) => (isBold ? 'font-weight: bold;' : '')};
    ${({ isCompleted }) => (isCompleted ? 'text-decoration: line-through;' : '')}
`;
