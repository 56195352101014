import React from 'react';
import { Link } from 'react-router-dom';
import BACKEND_URL from 'constants/backendUrl';
import User from 'core/models/user';
import { setSessionUserAction } from 'core/useCases/session/logInAction';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HttpError, POST } from 'utils/http';
import { toast } from 'react-toastify';
import { REGEXP } from 'constants/validationsForm';
import { TextInput } from 'components/Forms/lib';
import useModal from 'hooks/modal';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import { ReactComponent as Bloc } from 'images/Bloc.svg';
import ResetPasswordForm from '../ResetPasswordForm';
import Button from 'components/Button';
import { SLinks } from './styles';

type Inputs = {
    email: string;
    password: string;
};

interface ResponseData {
    user: User;
}

const loginFetcher = async (email: string, password: string) => {
    const response = (await POST(`${BACKEND_URL}/api/auth/login`, {
        email,
        password,
    })) as ResponseData;

    return response.user;
};

const LoginForm = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { closeModal, openModal, isOpen } = useModal();

    const methods = useForm<Inputs>();

    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        const { email, password } = values;
        try {
            const user = await loginFetcher(email, password);
            dispatch(setSessionUserAction(user));
        } catch (error) {
            toast.error(
                t(`login-form.${(error as HttpError).status === 401 ? 'user-has-no-licence' : 'authentication-error'}`),
            );
        }
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <TextInput
                        label={t('common.email.label')}
                        name="email"
                        pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                        placeholder={t('common.email.placeholder')}
                        type="email"
                        required
                    />
                    <TextInput
                        label={t('common.password.label')}
                        name="password"
                        placeholder={t('common.password.placeholder')}
                        type="password"
                        required
                    />
                    <Button onClick={openModal} variant="text" large>
                        {t('login-form.reset-password')}
                    </Button>
                    <Button large submit>
                        {t('login-form.submit')}
                    </Button>
                </form>
                <SLinks>
                    <Link rel="noopener noreferrer" target="_blank" title={t('legal-notice.label')} to="/legal-notice">
                        {t('legal-notice.label')}
                    </Link>
                    {'\u00a0-\u00a0'}
                    <Link rel="noopener noreferrer" target="_blank" title={t('cgu.label')} to="/cgu">
                        {t('cgu.acronym')}
                    </Link>
                </SLinks>
            </FormProvider>
            {isOpen && (
                <BaseModal
                    closeModal={() => closeModal()}
                    image={<Bloc />}
                    title={t('login-form.reset-password-modal-title')}
                >
                    <ResetPasswordForm closeModal={closeModal} />
                </BaseModal>
            )}
        </>
    );
};

export default LoginForm;
