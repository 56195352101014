import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { subscriptionSelector } from '../../../core/useCases/subscription/selector';
import License from '../../../core/models/license';
import { getNumericDate } from '../../../utils/dateTimeFormat';
import { userSelector } from 'core/useCases/session/selectors';
import { addMonths } from 'date-fns';

const CandidateSubscriptionView: FC = () => {
    const { t } = useTranslation();
    const subscription = useSelector(subscriptionSelector) as License | null;
    const user = useSelector(userSelector);

    if (subscription !== null && subscription.expirationDate) {
        return (
            <p>
                {t('profile.license.valid-license', {
                    expirationDate: getNumericDate(subscription.expirationDate),
                })}
            </p>
        );
    } else if (user !== null && user.firstLoginDate) {
        return (
            <p>
                {t('profile.license.invalid-license-trial', {
                    expirationDate: getNumericDate(addMonths(new Date(user.firstLoginDate), 1)),
                })}
            </p>
        );
    }

    return <p>{t('profile.license.invalid-license')}</p>;
};

export default CandidateSubscriptionView;
