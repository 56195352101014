import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import StateBar from '../../StateBar';
import Gauge, { GaugeItem } from '../../Gauge';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import ContactStatus from 'core/enums/contactStatus';
import { computeContactIconAndColorStatus } from '../ContactStatus';

const ContactStateBar: FC = () => {
    const { t } = useTranslation();
    const contacts = useSelector(contactsSelector);

    const statusOrder = [ContactStatus.Incomplete, ContactStatus.New, ContactStatus.Engaged, ContactStatus.Thanked];

    const items: GaugeItem[] = statusOrder
        .map((label: ContactStatus) => ({
            label: t(`common.contact-status.enum.${label}`),
            ...computeContactIconAndColorStatus(label),
            count: contacts.filter((contact) => label === contact.status).length,
        }))
        .filter((item: GaugeItem) => item.count);

    return (
        <StateBar title={t('contact-state-bar.title')}>
            <Gauge items={items} />
        </StateBar>
    );
};

export default ContactStateBar;
