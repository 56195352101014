import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import EventStatusEnum from 'core/enums/eventStatus';
import { defaultTheme } from 'styles/theme';
import { ReactComponent as NotCompleted } from 'images/icons/IncompleteContact.svg';
import { ReactComponent as Completed } from 'images/icons/TreatedContact.svg';
import { ReactComponent as Mobile } from 'images/whiteIcons/Mobile.svg';
import { ReactComponent as Email } from 'images/whiteIcons/Mail.svg';
import { ReactComponent as Inmail } from 'images/whiteIcons/Linkedin.svg';
import { ReactComponent as Phone } from 'images/whiteIcons/Phone.svg';
import { ReactComponent as Other } from 'images/whiteIcons/Other.svg';
import { SWrapper, SWrapperIcon } from './styles';

type EventStatusProps = {
    status: EventStatusEnum;
};

type ComputeStatus = (status: EventStatusEnum) => { color: string; icon?: ReactNode | undefined };

export const computeContactIconAndColorStatus: ComputeStatus = (status) => {
    switch (status) {
        case EventStatusEnum.Completed:
            return {
                color: defaultTheme.color.eventStatus.completed,
                icon: <Completed />,
            };
        case EventStatusEnum.NotStarted:
            return {
                color: defaultTheme.color.eventStatus.notCompleted,
                icon: <NotCompleted />,
            };
        case EventStatusEnum.Normal:
            return {
                color: defaultTheme.color.eventStatus.normal,
            };
        case EventStatusEnum.High:
            return {
                color: defaultTheme.color.eventStatus.high,
            };
        case EventStatusEnum.Urgent:
            return {
                color: defaultTheme.color.eventStatus.urgent,
            };
        case EventStatusEnum.Mobile:
            return {
                color: defaultTheme.color.eventStatus.mobile,
                icon: <Mobile />,
            };
        case EventStatusEnum.Phone:
            return {
                color: defaultTheme.color.eventStatus.phone,
                icon: <Phone />,
            };
        case EventStatusEnum.Email:
            return {
                color: defaultTheme.color.eventStatus.email,
                icon: <Email />,
            };
        case EventStatusEnum.Inmail:
            return {
                color: defaultTheme.color.eventStatus.inmail,
                icon: <Inmail />,
            };
        case EventStatusEnum.Other:
            return {
                color: defaultTheme.color.eventStatus.other,
                icon: <Other />,
            };
        default:
            throw Error('Wrong event status enum in compute color method');
    }
};

const EventStatus: FC<EventStatusProps> = ({ status }) => {
    const { t } = useTranslation();
    const computedStatus = computeContactIconAndColorStatus(status);

    return (
        <SWrapper color={computedStatus?.color}>
            {computedStatus.icon && <SWrapperIcon>{computedStatus.icon}</SWrapperIcon>}
            {t(`common.event-status.enum.${status}`)}
        </SWrapper>
    );
};

export default EventStatus;
