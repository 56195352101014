import styled from 'styled-components';
import { SBoldPrimaryText, SSubCard as SGenericSubCard } from 'styles/components';
import { SColumn } from 'styles/layout';

export const SSubCards = styled(SColumn)`
    gap: 1rem;
`;

export const SInfoLabel = styled(SBoldPrimaryText)`
    width: 10rem;
`;

export const SSubCard = styled(SGenericSubCard)`
    gap: 1.2rem;
`;
