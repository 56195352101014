import Task from 'core/models/task';
import TaskActionTypes from './types';

export interface SetTasksAction {
    type: TaskActionTypes.SET_TASKS;
    payload: Task[];
}

export interface AddTaskAction {
    type: TaskActionTypes.ADD_TASK;
    payload: Task;
}

export interface DeleteTaskAction {
    type: TaskActionTypes.DELETE_TASK;
    payload: string;
}

export interface UpdateTasktAction {
    type: TaskActionTypes.UPDATE_TASK;
    payload: Task;
}

export const AddTask = (task: Task): AddTaskAction => ({
    payload: task,
    type: TaskActionTypes.ADD_TASK,
});

export const DeleteTask = (id: string): DeleteTaskAction => ({
    payload: id,
    type: TaskActionTypes.DELETE_TASK,
});

export const SetTasks = (task: Task[]): SetTasksAction => ({
    payload: task,
    type: TaskActionTypes.SET_TASKS,
});

export const UpdateTask = (task: Task): UpdateTasktAction => ({
    payload: task,
    type: TaskActionTypes.UPDATE_TASK,
});
