import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { UpdateAgency } from 'core/useCases/agencies/agencyActions';
import { CloseModal } from 'core/useCases/modal/modalAction';
import Agency from 'core/models/agency';
import { POST } from 'utils/http';
import TextInput from 'components/Forms/lib/TextInput';

type Inputs = {
    agencyId: string;
    numLicensesToAllocate: number;
};

type LicenseFormProps = {
    closeModal(): void;
    defaultValues?: any;
};

const addLicencesRequest = async (inputs: Inputs, id: string): Promise<Agency> => {
    const response = (await POST<Agency>(`${BACKEND_URL}/api/agency/${id}/allocate-licenses`, inputs)) as Agency;

    return response;
};

const LicenseForm: FC<LicenseFormProps> = ({ closeModal, defaultValues }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const methods = useForm<Inputs>({
        defaultValues,
    });
    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            const agency = await addLicencesRequest(values, defaultValues.agencyId);
            dispatch(UpdateAgency(agency));
            dispatch(CloseModal());
            toast.success(t(`license.${defaultValues?.id ? 'update' : 'creation'}.success`));
        } catch {
            toast.error(t(`license.${defaultValues?.id ? 'update' : 'creation'}.error`));
            dispatch(CloseModal());
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <TextInput
                    label={t('common.add-license.label')}
                    name="numLicensesToAllocate"
                    placeholder={t('common.add-license.placeholder')}
                    type="number"
                    required
                    semi
                />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{t('common.add')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default LicenseForm;
