import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tip from './Tip';
import { SSpacedRowWrapped } from 'styles/layout';

const ListTip: FC = () => {
    const { t } = useTranslation();

    const a = t<string, string[]>('help.list-tips', { escapeValue: true, returnObjects: true });

    return (
        <SSpacedRowWrapped>
            {a.map((e, i) => (
                <Tip content={e} number={i + 1} />
            ))}
        </SSpacedRowWrapped>
    );
};

export default ListTip;
