import DashboardTresholds, { DEFAULT_TRESHOLDS } from 'core/models/dashboardTresholds';
import { SetDashboardTresholdsAction } from './actions';
import DashboardTresholdsActionTypes from './types';

export interface DashboardTresholdsState {
    value: DashboardTresholds;
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    value: DEFAULT_TRESHOLDS,
};

type Action = SetDashboardTresholdsAction;

const dashboardTresholdsReducer = (state: DashboardTresholdsState = init, action: Action): DashboardTresholdsState => {
    switch (action.type) {
        case DashboardTresholdsActionTypes.SET_DASHBOARD_TRESHOLDS:
            return { ...state, alreadyFetched: true, value: action.payload };
        default:
            return state;
    }
};

export default dashboardTresholdsReducer;
