import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Unchecked } from 'images/icons/Unchecked.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import { isPastDate } from 'utils/dateTimeFormat';
import ActivityType from 'core/enums/activityType';
import EventStatus from 'core/enums/eventStatus';
import { eventByIdSelector } from 'core/useCases/events/selectors';
import { RootState } from 'core/store';
import Button from 'components/Button';

type MarkEventAsCompleteButtonProps = {
    eventId: string;
    iconButton?: boolean;
};

const MarkEventAsCompleteButton: FC<MarkEventAsCompleteButtonProps> = ({ eventId, iconButton = true }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const event = useSelector((state: RootState) => eventByIdSelector(state, eventId));

    if (!event) {
        return null;
    }

    const { date, status } = event;
    const eventIsPast = isPastDate(date);

    if (!eventIsPast) {
        return null;
    }

    const openMarkTaskAsCompleteModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    activityType: ActivityType.Event,
                    id: eventId,
                    isAlreadyCompleted: EventStatus.Completed === status,
                },
                title: t('common.mark-as-complete'),
                type: ModalType.ActivityReport,
            }),
        );
    };

    return (
        <div>
            {iconButton ? (
                <button
                    onClick={() => openMarkTaskAsCompleteModal()}
                    title={t('common.mark-as-complete')}
                    type="button"
                >
                    <Unchecked />
                </button>
            ) : (
                <Button
                    onClick={() => openMarkTaskAsCompleteModal()}
                    title={t('common.mark-as-complete')}
                    type="button"
                    withShadow
                >
                    {t('common.mark-as-complete')}
                </Button>
            )}
        </div>
    );
};

export default MarkEventAsCompleteButton;
