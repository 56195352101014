import styled from 'styled-components';
import { SColumn } from 'styles/layout';

export const SStateBarContainer = styled.div`
    width: 100%;
    padding: 25px 0;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: 0 5px 20px 0 rgb(41 48 51 / 10%);
`;

export const SContainer = styled(SColumn)`
    gap: 2rem;
`;
