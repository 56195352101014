import React, { FC, Fragment, ReactNode } from 'react';
import ReactTooltip from 'react-tooltip';
import { SGaugeWrapper, SItem } from './styles';

export type GaugeItem = {
    icon: ReactNode;
    color: string;
    label: string;
    count: number;
    tooltip?: ReactNode;
};

type GaugeProps = {
    items: GaugeItem[];
};

const Gauge: FC<GaugeProps> = ({ items }) => {
    if (!items.length) {
        return null;
    }

    const totalCount = items
        .map((item) => item.count)
        .reduce((accumulator: number, currentValue: number): number => accumulator + currentValue);

    return (
        <SGaugeWrapper>
            {!!items.length &&
                items.map((item: GaugeItem, i: number) => {
                    const reactTooltipId = `react-tooltip-${item.label}`;

                    return (
                        <Fragment key={item.label}>
                            {/* eslint-disable-next-line no-mixed-operators */}
                            <SItem
                                backgroundColor={item.color}
                                data-for={reactTooltipId}
                                firstItem={!!(i === 0)}
                                lastItem={!!(i === items.length - 1)}
                                // eslint-disable-next-line no-mixed-operators
                                ratio={(item.count * 100) / totalCount}
                                data-tip
                            >
                                {item.icon}
                            </SItem>
                            <ReactTooltip
                                backgroundColor={item.color}
                                effect="solid"
                                id={reactTooltipId}
                                overridePosition={({ left, top }, _e, _t, node) => ({
                                    left: null === node ? left : Math.max(Math.min(left, window.innerWidth - node.offsetWidth), 0),
                                    top,
                                })}
                                place="bottom"
                            >
                                {item.tooltip || <span>{`${item.label}: ${item.count}`}</span>}
                            </ReactTooltip>
                        </Fragment>
                    );
                })}
        </SGaugeWrapper>
    );
};

export default Gauge;
