/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Agency from 'core/models/agency';
import { SetAgencies } from 'core/useCases/agencies/agencyActions';
import { agenciesAlreadyFetchedSelector } from 'core/useCases/agencies/selectors';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';

const WithAgencies: FC = ({ children }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const agenciesAlreadyFetched = useSelector(agenciesAlreadyFetchedSelector);

    const agenciesFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/agencies`)) as Agency[];

            dispatch(SetAgencies(response));

            return response;
        } catch (error) {
            toast.error(t('common.error-occured'));

            dispatch(SetAgencies([]));

            return [];
        }
    };

    useEffect(() => {
        if (!agenciesAlreadyFetched) {
            setLoading(true);
            agenciesFetcher();
            setLoading(false);
        }
    }, []);

    return <>{!loading && children}</>;
};

export default WithAgencies;
