import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardStat from 'components/CardStat';
import DiagramSemiCircle from 'components/DiagramSemiCircle';
import { ReactComponent as Talk } from 'images/icons/Talk.svg';
import { useSelector } from 'react-redux';
import { dateRangeSelectedSelector } from 'core/useCases/dateRangeSelected/selectors';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import { tasksSortedByDeadlineSelector } from 'core/useCases/tasks/selectors';
import { isDateInDateRange, substractNbOfDay } from 'utils/dateTimeFormat';
import { computeProgression } from 'core/useCases/dashboard/utils';
import { getEngagementDateOfContacts } from 'core/useCases/contacts/utils';
import { dashboardTresholdsSelector } from 'core/useCases/dashboardTresholds/selectors';

const FirstTaskDiagram: FC = () => {
    const { t } = useTranslation();
    const dateRangeSelected = useSelector(dateRangeSelectedSelector);
    const contacts = useSelector(contactsSelector);
    const tasks = useSelector(tasksSortedByDeadlineSelector);
    const { firstTaskLow, firstTaskMedium, firstTaskHigh } = useSelector(dashboardTresholdsSelector);
    const [firstTasksByContactId, setFirstTasksByContactId] = useState<Array<{ contactId: String; date: Date }>>([]);
    const [value, setValue] = useState<number>();
    const [progression, setProgression] = useState<string>('');

    useEffect(() => {
        setFirstTasksByContactId(getEngagementDateOfContacts(contacts, tasks));
    }, [contacts, tasks]);

    useEffect(() => {
        const total = firstTasksByContactId.filter((item) =>
            isDateInDateRange(new Date(item.date), dateRangeSelected),
        ).length;

        const { startDate, endDate } = dateRangeSelected;
        const previousDateRange = {
            endDate: substractNbOfDay(endDate, 7),
            startDate: substractNbOfDay(startDate, 7),
        };
        const previousTotal = firstTasksByContactId.filter((item) =>
            isDateInDateRange(new Date(item.date), previousDateRange),
        ).length;

        setValue(total);
        setProgression(previousTotal && total ? computeProgression(previousTotal, total) : '');
    }, [firstTasksByContactId, dateRangeSelected]);

    const firstTask = [
        {
            color: '#FFDDC3',
            key: 'low',
            rangeMax: firstTaskLow,
            rangeMin: 0,
        },
        {
            color: '#FFBF8B',
            key: 'middle',
            rangeMax: firstTaskMedium,
            rangeMin: firstTaskLow,
        },
        {
            color: '#FFAC69',
            key: 'high',
            rangeMax: firstTaskHigh,
            rangeMin: firstTaskMedium,
        },
    ];

    return value !== undefined ? (
        <CardStat icon={<Talk />} progression={progression} title={t('diagram.first-task.title')} value={value}>
            <DiagramSemiCircle data={firstTask} value={value} />
        </CardStat>
    ) : null;
};

export default FirstTaskDiagram;
