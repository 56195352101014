import styled from 'styled-components';
import { SColumn, SSpacedRow } from 'styles/layout';
import deviceSize from 'constants/deviceSize';

export const SWrapper = styled.div`
    display: flex;
    gap: 4rem;

    @media (max-width: ${deviceSize.mobile}px) {
        flex-direction: column;
    }
`;

export const STop = styled(SSpacedRow)`
    margin-bottom: 4rem;
`;

export const SLeft = styled(SColumn)`
    width: 32rem;
    gap: 2rem;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;

export const SRight = styled(SColumn)`
    flex: 1;
    gap: 2rem;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;
