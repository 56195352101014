import React, { FC, useState } from 'react';
import { ReactComponent as Plus } from 'images/icons/Plus.svg';
import { SButtonPlus, SButtons, SWrapper } from './styles';

const FloatingButton: FC = ({ children }) => {
    const [isActive, setActive] = useState(false);

    return (
        <SWrapper onMouseLeave={() => setActive(false)}>
            <SButtonPlus isActive={isActive} onClick={() => setActive(!isActive)} type="button">
                <Plus />
            </SButtonPlus>
            {isActive && <SButtons>{children}</SButtons>}
        </SWrapper>
    );
};

export default FloatingButton;
