import React, { FC } from 'react';
import { SBurger } from './styles';

interface BurgerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const Burger: FC<BurgerProps> = ({ open, setOpen }) => (
    <SBurger onClick={() => setOpen(!open)} open={open}>
        <div />
        <div />
        <div />
    </SBurger>
)

export default Burger;
