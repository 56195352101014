import Application from 'core/models/application';
import { AddApplicationAction, SetApplicationsAction, UpdateApplicationAction } from './applicationActions';
import ApplicationsActionTypes from './types';

export interface ApplicationState {
    list: Application[];
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    list: [],
};

type Action = SetApplicationsAction | AddApplicationAction | UpdateApplicationAction;

const applicationsReducer = (state: ApplicationState = init, action: Action): ApplicationState => {
    switch (action.type) {
        case ApplicationsActionTypes.SET_APPLICATIONS:
            return { ...state, alreadyFetched: true, list: action.payload };
        case ApplicationsActionTypes.ADD_APPLICATION:
            return { ...state, list: [...state.list, action.payload] };
        case ApplicationsActionTypes.UPDATE_APPLICATION: {
            const index = state.list.findIndex((a: Application) => a.id === action.payload.id);
            const newApplications = state.list;
            newApplications[index] = action.payload;
            return { ...state, list: [...newApplications] };
        }
        default:
            return state;
    }
};

export default applicationsReducer;
