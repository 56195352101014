import styled from 'styled-components';
import deviceSize from 'constants/deviceSize';

export const STabButtons = styled.div`
    display: flex;
    padding: 2px;
    border-radius: ${({ theme }) => theme.button.borderRadius};
    border: solid 1px ${({ theme }) => theme.color.secondary.light};
    background-color: ${({ theme }) => theme.color.white};

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
        overflow: scroll;
    }
`;
