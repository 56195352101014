import React, { FC, ReactNode } from 'react';
import { SHeader, SInner, SSup, STitle, SValue, SCardResponsive } from './styles';

type CardStatProps = {
    value: number;
    title: string;
    progression?: string;
    icon?: ReactNode;
    children: ReactNode;
};

const CardStat: FC<CardStatProps> = ({ icon, title, progression, value, children }) => (
    <SCardResponsive>
        <SHeader>
            {icon}
            <STitle>
                <SValue>
                    {value}
                    <SSup>{progression}</SSup>
                </SValue>
                {title}
            </STitle>
        </SHeader>
        <SInner>{children}</SInner>
    </SCardResponsive>
);

export default CardStat;
