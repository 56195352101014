import React, { FC } from 'react';
import { SHint } from './styles';

interface TextProps {
    text: string;
}

const HintText: FC<TextProps> = ({ text }) => <SHint>{text}</SHint>;

export default HintText;
