import React, { FC } from 'react';
import Container from 'components/layouts/common/Container';
import { SStateBarContainer, SContainer } from './styles';
import { SBoldPrimaryText } from 'styles/components';

interface StateBarProps {
    title: string;
}

const StateBar: FC<StateBarProps> = ({ title, children }) => (
    <SStateBarContainer>
        <Container>
            <SContainer>
                <SBoldPrimaryText>{title}</SBoldPrimaryText>
                {children}
            </SContainer>
        </Container>
    </SStateBarContainer>
);

export default StateBar;
