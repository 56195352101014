import Contact from 'core/models/contact';
import {
    AddContactAction,
    DeleteContactAction,
    SetContactsAction,
    UpdateContactAction,
    UpdateContactRankAction,
} from './contactActions';
import ContactsActionTypes from './types';

export interface ContactState {
    list: Contact[];
    alreadyFetched: boolean;
}

// TODO Add alreadyFetched and remove WithTask ?
const init = {
    alreadyFetched: false,
    list: [],
};

type Action =
    | SetContactsAction
    | AddContactAction
    | DeleteContactAction
    | UpdateContactAction
    | UpdateContactRankAction;

const contactsReducer = (state: ContactState = init, action: Action): ContactState => {
    switch (action.type) {
        case ContactsActionTypes.SET_CONTACTS:
            return { ...state, alreadyFetched: true, list: action.payload };
        case ContactsActionTypes.ADD_CONTACT:
            return { ...state, list: [...state.list, action.payload] };
        case ContactsActionTypes.DELETE_CONTACT:
            return { ...state, list: [...state.list.filter((c: Contact) => c.id !== action.payload)] };
        case ContactsActionTypes.UPDATE_CONTACT: {
            const index = state.list.findIndex((a: Contact) => a.id === action.payload.id);
            const newContacts = state.list;
            newContacts[index] = action.payload;
            return { ...state, list: [...newContacts] };
        }
        case ContactsActionTypes.UPDATE_CONTACT_RANK: {
            const index = state.list.findIndex((a: Contact) => a.id === action.payload.id);
            const newContacts = state.list;
            const updatedContact = newContacts[index];
            updatedContact.rank = action.payload.rank;
            newContacts[index] = updatedContact;
            return { ...state, list: [...newContacts] };
        }
        default:
            return state;
    }
};

export default contactsReducer;
