import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { STitle, STalk } from './styles';
import { ReactComponent as Good } from 'images/illustrations/Good.svg';
import TopBanner from 'components/TopBanner';

const HelpBanner: FC = () => {
    const { t } = useTranslation();

    return (
        <TopBanner>
            <STalk />
            <STitle>{t('page.help.banner-title')}</STitle>
            <Good />
        </TopBanner>
    );
};

export default HelpBanner;
