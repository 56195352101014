enum CandidateStatus {
    NeverConnected = 'just-created',
    InTrialPeriod = 'in-trial-period',
    WithoutLicenseAfterTrialPeriod = 'without-license-after-trial-period',
    WithPendingLicense = 'with-pending-license',
    WithValidLicense = 'with-valid-license',
    WithExpiredLicense = 'with-expired-license',
}

export default CandidateStatus;
