import styled from 'styled-components';
import { SColumn, SSpacedRow } from 'styles/layout';

export const SWrapper = styled(SColumn)`
    justify-content: center;
    text-align: center;
    background-color: ${({ theme }) => theme.color.secondary.main};

    > div {
        position: relative;
    }
`;

export const SContainer = styled(SSpacedRow)`
    padding: 2rem 0;
    align-items: center;
`;
