import { useEffect, useState } from 'react';
import useDebounce from './debounce';

export default function useResponsive() {
    const [state, setState] = useState({
      isDesktop: false,
      isMobile: false,
      isTablet: false,
      
    });
  
    const onResizeHandler = () => {
      const isDesktop = window.innerWidth > 990;
      const isMobile = window.innerWidth <= 768;
      const isTablet = window.innerWidth >= 768 && window.innerWidth <= 990;
      
      setState({ isDesktop, isMobile, isTablet });
    };

    const debouncedCall = useDebounce(onResizeHandler, 500);

    const Cleanup = () => {
      window.removeEventListener("resize", debouncedCall, false);
    };

    const Setup = () => {
      window.addEventListener("resize", debouncedCall, false);
    };

    useEffect(() => {
      onResizeHandler();
      Setup();

      return Cleanup;
    }, []);
  
    return state;
};
