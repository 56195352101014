import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import ActivityList from 'components/Activity/ActivityList';
import ActivityStateBar from 'components/Activity/ActivityStateBar';

const Activities: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.activities.meta-title')} stateBar={<ActivityStateBar />}>
            <ActivityList />
        </Layout>
    );
};

export default Activities;
