import styled from 'styled-components';

type SWrapperProps = {
    color: string;
};

export const SWrapper = styled.span<SWrapperProps>`
    display: inline-flex;
    align-items: center;
    height: 2.5rem;
    gap: 0.5rem;
    padding: 0 0.4rem;
    font-size: 1rem;
    font-weight: 700;
    border-radius: 2px;
    border: solid 2px ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ color }) => color};
    cursor: default;
`;
