import styled from 'styled-components';
import { SColumn } from 'styles/layout';

export const STipBox = styled(SColumn)`
    width: 100%;
    background-color: ${({ theme }) => theme.color.grey.lighter};
    padding: 0 2rem;
    margin-bottom: 3rem;
`;

export const SNumberCircle = styled.div`
    width: 40px;
    height: 40px;
    margin-top: -1.2rem;
    margin-bottom: 2rem;
    padding: 5px 0;
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.secondary.main};
    border: 3px solid ${({ theme }) => theme.color.primary};
    border-radius: 50px;
    z-index: 1;
`;

export const SText = styled.p`
    strong {
        font-weight: bold;
    }
`;
