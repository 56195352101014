import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Activities from './Activities';
import Information from './Information';
import { getApplicationLabel } from 'core/useCases/applications/utils';
import { applicationByIdSelector } from 'core/useCases/applications/selectors';
import { RootState } from 'core/store';
import Breadcrumb from 'components/DetailsPage/Breadcrumb';
import DetailsPage from 'components/DetailsPage';
import { getNumericDate } from 'utils/dateTimeFormat';
import LinkedContacts from './LinkedContacts';
import useResponsive from 'hooks/responsive';
import { SBreadcrumbRight } from './styles';

type ApplicationDetailsProps = {
    id: string;
};

const ApplicationDetails: FC<ApplicationDetailsProps> = ({ id }) => {
    const { t } = useTranslation();
    const application = useSelector((state: RootState) => applicationByIdSelector(state, id));
    const { isMobile } = useResponsive();

    return (
        <div>
            {application && (
                <DetailsPage
                    activities={<Activities application={application} />}
                    breadcrumb={
                        <>
                            {!isMobile && (
                                <Breadcrumb
                                    linkLabel={t('application.label')}
                                    linkPath="/applications"
                                    secondLabel={getApplicationLabel(application, t)}
                                />
                            )}
                            <SBreadcrumbRight>{`${t('common.added-at')} ${getNumericDate(application.createdDate)}`}</SBreadcrumbRight>
                        </>
                    }
                    information={<Information application={application} />}
                    secondaryInformation={<LinkedContacts applicationId={id} />}
                />
            )}
        </div>
    );
};

export default ApplicationDetails;
