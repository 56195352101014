import BACKEND_URL from 'constants/backendUrl';
import MultiActionsButton from '../MultiActionsButton';
import { userSelector } from 'core/useCases/session/selectors';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { POST } from 'utils/http';
import { clearSessionUserAction } from 'core/useCases/session/logOutAction';
import { Civility } from 'core/enums/civility';
import { ReactComponent as Male } from 'images/icons/Male.svg';
import { ReactComponent as Female } from 'images/icons/Female.svg';
import Button from '../Button';
import { SProfileActions } from './styles';

export const logOutFetcher = async () => {
    try {
        await POST(`${BACKEND_URL}/api/auth/logout`);
    } catch (error) {
        throw new Error('UNKNOWN_ERROR');
    }
};

const ProfileActions: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(userSelector);

    if (!user) {
        return null;
    }

    const logOut = async () => {
        try {
            await logOutFetcher();
            dispatch(clearSessionUserAction());
            history.push('/login');
        } catch (error) {
            // maybe store the error in redux store and have a way to display the 'global' errors in every pages
        }
        dispatch(clearSessionUserAction());
    };

    return (
        <SProfileActions>
            <MultiActionsButton
                parentButtonContent={Civility.Female === user.civility ? <Female /> : <Male />}
                iconButton
            >
                <Button onClick={() => history.push('/profile')} variant="popinText">
                    {t('profile.label')}
                </Button>
                <Button onClick={() => history.push('/help')} variant="popinText">
                    {t('help.label')}
                </Button>
                <Button onClick={() => history.push('/legal-notice')} variant="popinText">
                    {t('legal-notice.label')}
                </Button>
                <Button onClick={logOut} variant="popinText">
                    {t('header.logout')}
                </Button>
            </MultiActionsButton>
        </SProfileActions>
    );
};

export default ProfileActions;
