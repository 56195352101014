import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SFormGroup } from './styles';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from '../ErrorMessageText';
import HintText from '../HintText';
import Label from '../Label';

export interface TextInputProps {
    name: string;
    label?: string;
    validate?(value: string): boolean | string;
    disabled?: boolean;
    hint?: string;
    required?: boolean;
    placeholder?: string;
    errorMessage?: string;
    type?: 'text' | 'password' | 'email' | 'number';
    pattern?: { value: RegExp; message: string };
    semi?: boolean;
    isTextArea?: boolean;
    min?: number;
    max?: number;
    helper?: string;
}

const TextInput: FC<TextInputProps> = ({
    name,
    label,
    hint,
    type = 'text',
    disabled = false,
    placeholder,
    required = false,
    pattern,
    validate,
    helper,
    semi = false,
    isTextArea = false,
    ...rest
}) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        register,
    } = useFormContext();

    return (
        <SFormGroup semi={semi} withError={!!errors[name]}>
            {label && <Label helper={helper} name={name} required={required} text={label} />}
            {isTextArea ? (
                <textarea
                    {...register(name, {
                        pattern,
                        required: required ? `${t('common.required-field')}` : false,
                        validate,
                    })}
                    disabled={disabled}
                    rows={5}
                    {...(placeholder && { placeholder: t(placeholder) })}
                />
            ) : (
                <input
                    {...register(name, {
                        pattern,
                        required: required ? `${t('common.required-field')}` : false,
                        validate,
                    })}
                    disabled={disabled}
                    {...(placeholder && { placeholder: t(placeholder) })}
                    type={type}
                    {...rest}
                />
            )}
            {hint && <HintText text={hint} />}
            {errors[name] && (
                <ErrorMessage
                    errors={errors}
                    name={name}
                    render={({ message }) => <ErrorMessageText text={message} />}
                />
            )}
        </SFormGroup>
    );
};

export default TextInput;
