/* eslint-disable max-len */
export type QuoteProps = {
    quote: string;
    author: string;
};

const QUOTES: QuoteProps[] = [
    {
        author: 'Jack Canfield',
        quote: 'Ne t’inquiète pas de l’échec. Inquiète-toi de ce que tu manques si tu n’essayes même pas',
    },
    {
        author: 'Albert Einstein',
        quote: 'Je suis reconnaissant à tous ceux qui m’ont dit NON. C’est à grâce à eux que je suis moi-même',
    },
    {
        author: 'Abraham Lincoln',
        quote: 'Un objectif bien défini est à moitié atteint',
    },
    {
        author: 'Joel Brown',
        quote: 'La seule chose qui se dresse entre vous et votre rêve, c’est la volonté d’essayer et la conviction qu’il est réellement possible',
    },
    {
        author: 'Winston Churchill',
        quote: 'Un pessimiste voit la difficulté dans chaque opportunité, un optimiste voit l’opportunité dans chaque difficulté',
    },
    {
        author: 'Félix Leclerc',
        quote: 'Il y a plus de courage que de talent dans la plupart des réussites',
    },
    {
        author: 'Winston Churchill',
        quote: 'Le succès c’est d’aller d’échec en échec sans perdre son enthousiasme',
    },
    {
        author: 'Albert Schweitzer',
        quote: 'Le succès n’est pas la clé du bonheur. Le bonheur est la clé du succès. Si vous aimez ce que vous faites, vous réussirez',
    },
    {
        author: 'Alice Walker',
        quote: 'Le plus souvent, les gens abandonnent leur pouvoir car il pense qu’ils n’en ont pas',
    },
    {
        author: 'F. D. Roosevelt',
        quote: 'Les gagnants trouvent des moyens, les perdants des excuses',
    },
    {
        author: 'Oprah Winfrey',
        quote: 'La plus grande découverte de tous les temps c’est qu’une personne peut changer son futur, en changeant simplement son attitude',
    },
    {
        author: 'Sénèque',
        quote: 'Ce n’est pas parce que les choses sont difficiles que nous n’osons pas, c’est parce que nous n’osons pas qu’elles sont difficiles',
    },
    {
        author: 'Elbert Hubbard',
        quote: 'La plus grande erreur que puisse faire un homme est d’avoir peur d’en faire une',
    },
    {
        author: 'Jim Rohn',
        quote: 'Ce n’est pas le vent qui décide de votre destination, c’est l’orientation que vous donnez à votre voile. Le vent est pareil pour tous',
    },
    {
        author: 'Benjamin Franklin',
        quote: 'L’humanité se divise en trois catégories : ceux qui ne peuvent pas bouger, ceux qui peuvent bouger, et ceux qui bougent',
    },
    {
        author: 'Mark Twain',
        quote: 'Ils ne savaient pas que c’était impossible, alors ils I’ont fait',
    },
    {
        author: 'Abbé Pierre',
        quote: 'Le plus grand échec est de ne pas avoir le courage d’oser',
    },
    {
        author: 'Stephen Covey',
        quote: 'Je ne suis pas un produit de mes circonstances. Je suis un produit de mes décisions',
    },
    {
        author: 'Earl Nightingale',
        quote: 'Le courage ce n’est pas de commencer, ni de terminer, c’est de recommencer',
    },
    {
        author: 'Lao Tseu',
        quote: 'Quand je lâche ce que je suis, je deviens ce que je pourrais être',
    },
    {
        author: 'Jacques Prévert',
        quote: 'La meilleure façon de ne pas avancer, c’est de suivre une idée fixe',
    },
    {
        author: 'Yannick Etesse',
        quote: 'Pour pouvoir récolter, il faut accepter de se planter',
    },
    {
        author: 'Jim Rohn',
        quote: 'Si vous fermez la porte à tous les échecs, le succès restera dehors',
    },
    {
        author: 'Napoleon Hill',
        quote: 'Tout ce que l’esprit peut concevoir et croire, il peut le réaliser',
    },
    {
        author: 'Loren Eiseley',
        quote: 'Une vision sans action n’est qu’un rêve. L’action sans la vision mène nulle part. Une vision accompagnée de l’action peut changer le monde',
    },
    {
        author: 'Vince Lombardi',
        quote: 'Les gagnants ne lâchent jamais et les lâcheurs ne gagnent jamais',
    },
    {
        author: 'Peter Drucker',
        quote: 'La meilleure façon de prédire l’avenir c’est de le créer',
    },
    {
        author: 'Jeff Bezos',
        quote: 'Je savais que si j’échouais, je ne le regretterais pas ; mais je savais que la seule chose que je pourrais regretter était de ne pas essayer',
    },
    {
        author: 'Woody Allen',
        quote: 'Si vous n’échouez pas de temps en temps, c’est signe que vous ne faites rien d’innovant',
    },
    {
        author: 'Jack E. Addington',
        quote: 'N’acceptez jamais la défaite, vous êtes peut-être à un pas de la réussite',
    },
    {
        author: 'J. F. Kennedy',
        quote: 'En chinois, le mot crise est formé de deux caractères. L’un représente le danger. L’autre l’opportunité',
    },
    {
        author: 'Dalaï Lama',
        quote: 'Il n’y a personne qui ne soit né sous une mauvaise étoile, il n’y a que des gens qui ne savent pas lire le ciel',
    },
    {
        author: 'Molière',
        quote: 'Plus grand est l’obstacle, plus grande est la gloire de le surmonter',
    },
    {
        author: 'Joseph O’ Connor',
        quote: 'On a toujours le choix. On est même la somme de ses choix',
    },
    {
        author: 'Coluche',
        quote: 'Les portes de l’avenir sont ouvertes à ceux qui savent les pousser',
    },
    {
        author: 'Florence Nightingale',
        quote: 'J’attribue mon succès à ceci : Je n’ai jamais présenté ou accepté aucune excuse',
    },
    {
        author: 'Théocrite',
        quote: 'L’eau, goutte à goutte, creuse le roc',
    },
    {
        author: 'Michael Jordan',
        quote: ' J’ai échoué encore et encore et encore dans ma vie. Et c’est pourquoi j’ai réussi',
    },
    {
        author: 'Bruce Lee',
        quote: 'Un but n’est pas toujours destiné à être atteint, il sert souvent simplement comme quelque chose à viser',
    },
    {
        author: 'Hugo Girard',
        quote: 'Les 3 clés du succès: 1. Se fixer un objectif. 2. Y croire ardemment. 3. Travailler. Tout est possible!',
    },
    {
        author: 'Wayne Gretzky',
        quote: 'Tu manques 100% des coups que tu ne prends pas!',
    },
    {
        author: 'Tim Notke',
        quote: 'Le dur travail bat le talent lorsque le talent ne travaille pas assez fort',
    },
    {
        author: 'Peter J. Davies',
        quote: 'La motivation est, pour l’esprit, semblable à de la nourriture. Une assiette seule ne suffit pas',
    },
    {
        author: 'Sénèque',
        quote: 'Un athlète ne peut arriver en compétition très motivé s’il n’a jamais été mis à l’épreuve. ',
    },
    {
        author: 'François Proust',
        quote: 'Motiver, c’est inverser le sens de l’énergie',
    },
    {
        author: 'Corra Harris',
        quote: 'La plus brave chose que vous puissiez faire quand vous manquez de courage est d’exhorter les gens à l’être et de leur donner l’exemple',
    },
    {
        author: 'Proverbe japonais',
        quote: 'Le succès c’est tomber sept fois, se relever huit',
    },
    {
        author: 'Théodore de Banville',
        quote: 'Ceux qui ne font rien ne se trompent jamais',
    },
    {
        author: 'Charles de Montalembert',
        quote: 'Les difficultés ne sont pas faites pour abattre mais pour être abattues',
    },
    {
        author: 'Robert Warren Painter Jr',
        quote: 'De bonnes choses sont à venir sur ta route. Il ne faut simplement pas arrêter de marcher',
    },
    {
        author: 'Proverbe chinois',
        quote: 'Pour pouvoir contempler un arc-en-ciel, il faut d’abord endurer la pluie',
    },
    {
        author: 'Malik Yansane',
        quote: 'Courage, motivation et patience produisent la réussite',
    },
    {
        author: 'Beni Kiambi',
        quote: 'La motivation, c’est le refus de voir les gens devant soi',
    },
    {
        author: 'Marianne Williamson',
        quote: 'Rien ne vous emprisonne excepté vos pensées. Rien ne vous limite excepté vos peurs. Rien ne vous contrôle excepté vos croyances',
    },
    {
        author: 'Fabrice Grinda',
        quote: 'Quoique vous puissiez faire ou rêvez de faire, commencez-le',
    },
    {
        author: 'Source inconnue',
        quote: 'Chaque réussite commence avec la volonté d’essayer',
    },
    {
        author: 'Anne Bernard',
        quote: 'On ne construit rien sur des regrets, par contre on bâtit sur des résolutions',
    },
    {
        author: 'Emile-Auguste Alain',
        quote: 'Le pessimisme est affaire d’humeur, l’optimisme est affaire de volonté',
    },
    {
        author: 'Charles Fourier',
        quote: 'On commence par dire : cela est impossible pour se dispenser de le tenter, et cela devient impossible, en effet, parce qu’on ne le tente pas',
    },
    {
        author: 'Jean Jaures',
        quote: 'Il ne faut avoir aucun regret pour le passé, aucun remords pour le présent, et une confiance inébranlable pour l’avenir',
    },
    {
        author: 'Henri Matisse',
        quote: 'Il y a des fleurs partout pour qui veut bien les voir',
    },
    {
        author: 'Bruce Lee',
        quote: 'Ce n’est pas l’échec qui est criminel en soi, c’est le manque d’ambition. Plus difficile est l’objectif à atteindre, plus glorieuse est la défaite',
    },
    {
        author: 'Truman Capote',
        quote: 'L’échec est l’épice qui donne sa saveur à la réussite',
    },
    {
        author: 'Seth Godin',
        quote: 'Les seules personnes qui sont bien payées, sont celles qui ne suivent pas de guide, qui créent de l’art, qui sont innovantes, qui travaillent sans plan',
    },
    {
        author: 'Mae C. Jemison',
        quote: 'Ne laissez personne vous voler votre imagination, votre créativité ou votre curiosité. C’est votre place dans le monde, c’est votre vie',
    },
    {
        author: 'Tenzin Gyatzo, le 14 ème dalaï lama',
        quote: 'Souvenez-vous que ne pas obtenir ce que l’on veut peut parfois se révéler être un incroyable coup de chance',
    },
    {
        author: 'Og Mandino',
        quote: 'Je ne serai jamais dépassé par l’échec si ma volonté de réussir est assez forte',
    },
    {
        author: 'Auteur inconnu',
        quote: 'Le passé ne peut pas être changé. L’avenir est encore entre vos mains',
    },
    {
        author: 'Paulo Coelho',
        quote: 'Quand on ne peut revenir en arrière, on ne doit se préoccuper que de la meilleure façon d’aller de l’avant',
    },
    {
        author: 'Proverbe indien',
        quote: 'Si tu vois tout en gris, déplace l’éléphant !',
    },
    {
        author: 'Saint François d’Assise',
        quote: 'Commence par faire le nécessaire, puis fais ce qu’il est possible de faire et tu réaliseras l’impossible sans t’en apercevoir',
    },
    {
        author: 'Marc Lévy',
        quote: 'Il y a ceux qui voient les choses telles qu’elles sont et qui se demande pourquoi. Moi je les vois telles qu’elles pourraient être et je me dis pourquoi pas !',
    },
    {
        author: 'Samuel Johnson',
        quote: 'Les grands accomplissements sont réussis non par la force mais par la persévérance',
    },
    {
        author: 'Didier Quesne',
        quote: 'J’ai appris qu’il ne faut jamais rien regretter. Il faut tenir compte de ses erreurs, mais les regrets ne servent à rien, sinon à t’empêcher d’avancer',
    },
    {
        author: 'Marc Levy',
        quote: 'Rien n’est impossible, seules les limites de nos esprits définissent certaines choses comme inconcevables',
    },
    {
        author: 'Nelson Mandela',
        quote: 'Je ne perds jamais. Soit je gagne, soit j’apprends',
    },
    {
        author: 'Albert Einstein',
        quote: 'La vie c’est comme une bicyclette, il faut avancer pour ne pas perdre l’équilibre',
    },
    {
        author: 'Auteur inconnu',
        quote: 'La vie mettra des pierres sur ta route. A toi de décider d’en faire des murs ou des ponts',
    },
    {
        author: 'Bouddha',
        quote: 'Accepte ce qui est, laisse aller ce qui était, aie confiance en ce qui sera',
    },
    {
        author: 'Martin Luther King',
        quote: 'Croyez en vos rêves et ils se réaliseront peut-être. Croyez en vous et ils se réaliseront sûrement',
    },
    {
        author: 'Romain Rolland',
        quote: 'Quand on ose, on se trompe souvent. Quand on n’ose pas, on se trompe toujours',
    },
    {
        author: 'Mylène Muller',
        quote: 'N’attends pas d’être motivé pour agir, agis pour être motivé',
    },
    {
        author: 'Winston Churchill',
        quote: 'Il n’y a qu’une réponse à la défaite. Et c’est la victoire',
    },
    {
        author: 'Woody Allen',
        quote: 'Si vous n’échouez pas de temps à autre, c’est signe que vous ne faites rien d’innovant',
    },
    {
        author: 'Paulo Coelho',
        quote: 'La seule chose qui puisse empêcher un rêve d’aboutir, c’est la peur d’échouer',
    },
    {
        author: 'Antoine de Saint Exupéry',
        quote: 'Un pessimiste fait de ses occasions des difficultés, un optimiste fait de ses difficultés des occasions',
    },
    {
        author: 'Henri Ford',
        quote: 'Quand tout semble être contre vous, souvenez-vous que l’avion décolle face au vent, et non avec lui',
    },
    {
        author: 'Benjamin Franklin',
        quote: 'Vous trouverez la clé du succès sous le réveille-matin',
    },
    {
        author: 'Wayne Huizenga',
        quote: 'Certains rêvent de réussite, alors que d’autres se réveillent chaque matin pour la réaliser',
    },
    {
        author: 'Winston Churchill',
        quote: 'Là où se trouve une volonté, il existe un chemin',
    },
    {
        author: 'Thomas Fowell Buxton',
        quote: 'Avec un talent ordinaire et une persévérance extraordinaire on peut tout obtenir',
    },
    {
        author: 'Robert Collier',
        quote: 'Le succès est la somme des petits efforts répétés jour après jour',
    },
    {
        author: 'Colin Powell',
        quote: 'Il n’y a pas de secret pour réussir. C’est le résultat de la préparation, du travail et de ce que l’on apprend de ses échecs',
    },
    {
        author: 'Proverbe chinois',
        quote: 'La gloire n’est pas de ne jamais tomber, mais de se relever chaque fois que l’on tombe',
    },
    {
        author: 'Marcel Proust',
        quote: 'Il n’y a pas de réussites faciles ni d’échecs définitifs',
    },
    {
        author: 'Dale Carnegie',
        quote: 'La plupart des choses importantes dans le monde ont été accomplies par des personnes qui ont continué à essayer quand il semblait n’y avoir aucun espoir',
    },
    {
        author: 'Georges M. Moore',
        quote: 'Un gagnant est juste un perdant qui a tenté une fois de plus',
    },
    {
        author: 'Théocrite',
        quote: 'En persévérant, on arrive à tout',
    },
    {
        author: 'Paulo Coelho',
        quote: 'Ceux qui abandonnent ne gagnent jamais. Ceux qui gagnent n’abandonnent jamais',
    },
    {
        author: 'Auteur anonyme',
        quote: 'Ne perdez jamais espoir. Lorsque le soleil se couche, les étoiles apparaissent',
    },
    {
        author: 'Gandhi',
        quote: 'Si vous vivez un moment difficile, ne blâmez pas la vie. Vous êtes juste en train de devenir plus fort',
    },
    {
        author: 'Sénèque',
        quote: 'La vie n’est pas d’attendre que les orages passent, c’est d’apprendre comment danser sous la pluie',
    },
];

export default QUOTES;
