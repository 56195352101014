/* eslint-disable max-len */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import HelpBanner from 'components/Help/HelpBanner';
import ListTip from 'components/Help/ListTip';
import { SContact, SVideoContainer } from './styles';
import YoutubeEmbed from 'components/YoutubeEmbed';
import useResponsive from 'hooks/responsive';

const HelpPage = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    return (
        <Layout pageTitle={t('page.help.meta-title')} topBanner={<HelpBanner />}>
            <SVideoContainer>
                <YoutubeEmbed embedId="udIEg9XoJbE" width={isMobile ? 320 : 704} />
            </SVideoContainer>
            <ListTip />
            <p>{t('page.help.text')}</p>
            {/* eslint-disable-next-line react/no-danger */}
            <SContact dangerouslySetInnerHTML={{ __html: t('page.help.contact') }} />
        </Layout>
    );
};

export default HelpPage;
