import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    width: 100%;
    background-color: ${({ theme }) => theme.color.white};
    border-radius: 5px;
    box-shadow: -4px 5px 8px 0 rgba(0, 116, 151, 0.1), -1px 2px 4px 0 rgba(0, 116, 151, 0.1);
`;

export const SSubCard = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2rem;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.color.grey.lighter};
`;

export const SBoldPrimaryText = styled.span`
    font-weight: bold;
`;

export const SSecondaryText = styled.span`
    color: ${({ theme }) => theme.color.secondary.main};
`;

export const SBoldSecondaryText = styled(SSecondaryText)`
    font-weight: bold;
`;

export const SLightGreyText = styled.span`
    color: ${({ theme }) => theme.color.grey.light};
`;

export const SLink = styled(Link)`
    text-decoration: underline;
`;

export const SFixedButton = styled.div`
    position: sticky;
    bottom: 20px;
    right: 0;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
`;

// TODO use class for button display
export const STable = styled.table`
    width: 100%;
    font-size: 1.2rem;

    tr:nth-child(odd) td {
        background-color: ${({ theme }) => theme.color.grey.lighter};
    }

    tr:nth-child(even) td {
        background-color: ${({ theme }) => theme.color.white};
    }

    th {
        text-align: left;
        font-weight: 500;
        color: ${({ theme }) => theme.color.grey.light};
    }

    td,
    th {
        padding: 10px 5px;

        &:first-child,
        &:last-child {
            border-radius: 2px;
        }

        &:first-child {
            padding-left: 20px;
        }

        &:last-child {
            padding-right: 20px;
        }
    }

    tbody tr {
        z-index: 1;
        border: 1px solid transparent;
        td {
            .showOnRowHover {
                fill: '#000';
                position: relative;
                z-index: 0;
            }
            .visibilityHidden {
                visibility: hidden;
            }
        }
        &:hover {
            z-index: 1000;
            border-right: 1px solid ${({ theme }) => theme.color.grey.light2};
            border-left: 1px solid ${({ theme }) => theme.color.grey.light2};
            box-shadow: -1px 1px 5px 0 rgba(0, 116, 151, 0.1), 0 1px 2px 0 rgba(0, 116, 151, 0.1);
            td {
                border-top: 1px solid ${({ theme }) => theme.color.grey.light2};
                border-bottom: 1px solid ${({ theme }) => theme.color.grey.light2};
                .showOnRowHover {
                    fill: ${({ theme }) => theme.color.secondary.main};
                    z-index: 1;
                }
            }
        }
    }

    td {
        padding-top: 15px;
        padding-bottom: 15px;
        vertical-align: middle;

        &:not(:last-child) {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .table--task tr {
        td:first-child {
            width: 60px;
            max-width: 60px;
        }

        td:nth-child(2) {
            width: 180px;
            max-width: 180px;
        }

        td:nth-child(3) {
            width: 160px;
            max-width: 180px;
        }

        td:nth-child(4) {
            width: 160px;
            max-width: 160px;
        }

        td:nth-child(5) {
            width: 220px;
            max-width: 220px;
        }

        td:nth-child(6) {
            width: 220px;
            max-width: 220px;
        }

        td:nth-child(7) {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(8) {
            width: 50px;
            max-width: 50px;
        }
    }

    .table--event tr {
        td:first-child {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(2) {
            width: 180px;
            max-width: 180px;
        }

        td:nth-child(3) {
            width: 160px;
            max-width: 160px;
        }

        td:nth-child(4) {
            width: 160px;
            max-width: 160px;
        }

        td:nth-child(5) {
            width: 220px;
            max-width: 220px;
        }

        td:nth-child(6) {
            width: 220px;
            max-width: 220px;
        }

        td:nth-child(7) {
            width: 100px;
            max-width: 100px;
        }
    }

    .table--contact tr {
        td:first-child {
            width: 240px;
            max-width: 240px;
        }

        td:nth-child(2) {
            width: 90px;
            max-width: 90px;
        }

        td:nth-child(3) {
            width: 130px;
            max-width: 130px;
        }

        td:nth-child(4) {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(5) {
            width: 200px;
            max-width: 200px;
        }

        td:nth-child(6) {
            width: 180px;
            max-width: 180px;
        }

        td:nth-child(7) {
            width: 110px;
            max-width: 110px;
        }
    }

    .table--agencies tr {
        td:first-child {
            width: 130px;
            max-width: 130px;
        }

        td:nth-child(2) {
            width: 200px;
            max-width: 200px;
        }

        td:nth-child(3) {
            width: 160px;
            max-width: 160px;
        }

        td:nth-child(4) {
            width: 140px;
            max-width: 140px;
        }

        td:nth-child(5) {
            width: 120px;
            max-width: 120px;
        }

        td:nth-child(6) {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(7) {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(8) {
            width: 100px;
            max-width: 100px;
        }

        td:nth-child(8) {
            width: 60px;
            max-width: 60px;
        }
    }
    .table--candidates tr {
        td:first-child {
            width: 240px;
            max-width: 240px;
        }

        td:nth-child(2) {
            width: 140px;
            max-width: 140px;
        }

        td:nth-child(3) {
            width: 180px;
            max-width: 180px;
        }

        td:nth-child(4) {
            width: 140px;
            max-width: 140px;
        }

        td:nth-child(5) {
            width: 120px;
            max-width: 120px;
        }

        td:nth-child(6) {
            width: 120px;
            max-width: 120px;
        }

        td:nth-child(7) {
            width: 120px;
            max-width: 120px;
        }
    }
`;
