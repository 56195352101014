import React, { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'core/store';
import { modalSelector } from 'core/useCases/modal/selectors';
import BaseModal from './BaseModal/BaseModal';
import ContactForm from '../Forms/ContactForm';
import { CloseModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import TaskForm from 'components/Forms/TaskForm';
import EventForm from 'components/Forms/EventForm';
import { ReactComponent as File } from 'images/File.svg';
import { ReactComponent as Bloc } from 'images/Bloc.svg';
import { ReactComponent as Target } from 'images/Target.svg';
import { ReactComponent as Network } from 'images/Network.svg';
import { ReactComponent as CheckboxComplete } from 'images/CheckboxComplete.svg';
import { ReactComponent as People } from 'images/People.svg';
import AdditionalInformationForm from 'components/Forms/AdditionalInformationForm';
import ActivityType from 'core/enums/activityType';
import ActivityReportForm from '../Forms/ActivityReportForm';
import ApplicationReportForm from '../Forms/ApplicationReportForm';
import ApplicationForm from '../Forms/ApplicationForm';
import AgencyForm from '../admin/Agency/AgencyForm';
import LicenseForm from 'components/admin/LicenseForm';
import CandidateForm from 'components/admin/Candidate/CandidateForm';
import InformationModal from './InformationModal';
import ContactsForm from '../Forms/ContactsForm';
import Button from 'components/Button';
import { SButtonsContainer } from './styles';
import { useTranslation } from 'react-i18next';
import DeleteConfirmation from '../Forms/DeleteForm';

const computeImage = (type: ModalType): ReactNode => {
    switch (type) {
        // TODO Handle Application image
        case ModalType.Confirmation:
        case ModalType.Contact:
        case ModalType.Contacts:
            return <People />;
        case ModalType.Application:
        case ModalType.Agency:
        case ModalType.License:
        case ModalType.Candidate:
            return <File />;
        case ModalType.DeleteCandidate:
            return <People />;
        case ModalType.Task:
        case ModalType.TaskAdditionalInfo:
            return <Bloc />;
        // TODO Event can have many image for each event type
        case ModalType.Event:
        case ModalType.EventAdditionalInfo:
            return <Target />;
        case ModalType.ActivityReport:
        case ModalType.ApplicationReport:
            return <CheckboxComplete />;
        case ModalType.Information:
            return <Network />;
        default:
            throw Error('Wrong modal type in compute image method for modals');
    }
};

const Modals: FC = () => {
    const modalSettings = useSelector((state: RootState) => modalSelector(state));
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const closeModal = () => dispatch(CloseModal());

    if (!modalSettings) {
        return null;
    }

    const { cancelButtonLabel, confirmAction, defaultValues, disabledFields, title, type } = modalSettings;

    return (
        <BaseModal closeModal={() => dispatch(CloseModal())} image={computeImage(type)} title={title}>
            {ModalType.Contact === type && (
                <ContactForm closeModal={closeModal} defaultValues={defaultValues} disabledFields={disabledFields} />
            )}

            {ModalType.Contacts === type && <ContactsForm closeModal={closeModal} />}
            {ModalType.Task === type && (
                <TaskForm
                    cancelButtonLabel={cancelButtonLabel}
                    closeModal={closeModal}
                    defaultValues={defaultValues}
                    disabledFields={disabledFields}
                />
            )}
            {ModalType.Event === type && (
                <EventForm closeModal={closeModal} defaultValues={defaultValues} disabledFields={disabledFields} />
            )}
            {ModalType.TaskAdditionalInfo === type && (
                <AdditionalInformationForm
                    activityType={ActivityType.Task}
                    closeModal={closeModal}
                    defaultValues={defaultValues}
                />
            )}
            {ModalType.EventAdditionalInfo === type && (
                <AdditionalInformationForm
                    activityType={ActivityType.Event}
                    closeModal={closeModal}
                    defaultValues={defaultValues}
                />
            )}
            {ModalType.ActivityReport === type && <ActivityReportForm closeModal={closeModal} data={defaultValues} />}
            {ModalType.Application === type && (
                <ApplicationForm
                    closeModal={closeModal}
                    defaultValues={defaultValues}
                    disabledFields={disabledFields}
                />
            )}
            {ModalType.ApplicationReport === type && (
                <ApplicationReportForm closeModal={closeModal} data={defaultValues} />
            )}
            {ModalType.Agency === type && (
                <AgencyForm closeModal={closeModal} defaultValues={defaultValues} disabledFields={disabledFields} />
            )}
            {ModalType.Candidate === type && (
                <CandidateForm closeModal={closeModal} defaultValues={defaultValues} disabledFields={disabledFields} />
            )}
            {ModalType.DeleteCandidate === type && (
                <DeleteConfirmation closeModal={closeModal} confirmAction={confirmAction} />
            )}
            {ModalType.License === type && <LicenseForm closeModal={closeModal} defaultValues={defaultValues} />}
            {ModalType.Information === type && (
                <InformationModal closeModal={closeModal} defaultValues={defaultValues} />
            )}
            {ModalType.Confirmation === type && (
                <SButtonsContainer>
                    <Button onClick={closeModal} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            if (confirmAction) {
                                confirmAction();
                            }
                            closeModal();
                        }}
                    >
                        {t('common.confirm')}
                    </Button>
                </SButtonsContainer>
            )}
        </BaseModal>
    );
};

export default Modals;
