import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import AgencyList from 'components/admin/Agency/AgencyList';

const Agencies: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.dashboard.meta-title')}>
            <AgencyList />
        </Layout>
    );
};

export default Agencies;
