import { startOfTomorrow } from 'date-fns/esm';
import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { AddApplication, UpdateApplication } from 'core/useCases/applications/applicationActions';
import Application from 'core/models/application';
import Task from 'core/models/task';
import { SSpacedColumn } from 'styles/layout';
import { POST, PUT } from 'utils/http';
import TextInput from '../lib/TextInput';
import { DateOrTimeInput, SelectInput } from '../lib';
import { OptionsProps } from '../lib/SelectInput';
import { CloseModal, OpenModal } from 'core/useCases/modal/modalAction';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import { getContactLabel } from 'core/useCases/contacts/utils';
import ApplicationSource from 'core/enums/applicationSource';
import { enumKeys } from 'utils/common';
import SelectMultiInput from '../lib/SelectMultiInput';
import { TaskSubject } from 'core/enums/taskSubject';
import { TaskPriority } from 'core/enums/taskPriority';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import ResourcesInput from '../lib/ResourcesInput';
import { TrackingEventName } from 'utils/types/amplitude';
import { logEvent } from 'config/tracking';
import useResponsive from 'hooks/responsive';

type Inputs = {
    company: string;
    job: string;
    source: ApplicationSource;
    sourceDetails?: string;
    salary?: string;
    reference?: string;
    dateOfPublication?: Date;
    place?: string;
    contactIds: string[];
    comment?: string;
};

type ApplicationFormProps = {
    closeModal(): void;
    defaultValues?: Partial<Application>;
    disabledFields?: Array<DisabledField>;
};

type CreateApplicationResponse = {
    application: Application;
    task?: Task;
};

type UpdateApplicationResponse = {
    application: Application;
};

const createApplicationRequest = async (inputs: Inputs): Promise<CreateApplicationResponse> => {
    const response = (await POST<CreateApplicationResponse>(
        `${BACKEND_URL}/api/application`,
        inputs,
    )) as CreateApplicationResponse;

    return response;
};

const updateApplicationRequest = async (inputs: Inputs, id: string): Promise<Application> => {
    const response = (await PUT<UpdateApplicationResponse>(`${BACKEND_URL}/api/application/${id}`, {
        ...inputs,
    })) as UpdateApplicationResponse;

    return response.application;
};

const computeData = (application?: Partial<Application>): Partial<Inputs> | undefined => {
    if (!application) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, contacts, dateOfPublication, ...data } = application;
    const computedData = {
        ...data,
        contactIds: contacts?.map((contact) => contact.id),
        dateOfPublication: dateOfPublication ? new Date(dateOfPublication) : undefined,
    } as Partial<Inputs>;

    return computedData;
};

const ApplicationForm: FC<ApplicationFormProps> = ({ closeModal, defaultValues }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const contactList = useSelector(contactsSelector);
    const [contactsOptions, setContactsOptions] = useState<OptionsProps>([]);
    const [sourceOptions, setSourceOptions] = useState<OptionsProps>([]);

    const methods = useForm<Inputs>({
        defaultValues: computeData(defaultValues),
    });
    const { handleSubmit, control, watch, setValue } = methods;
    const selectedSource = watch('source');

    // TODO Handle first task after application creation
    const openFirstTaskModal = (newApplication: Application) => {
        dispatch(
            OpenModal({
                defaultValues: {
                    application: newApplication,
                    deadline: startOfTomorrow(),
                    priority: TaskPriority.Normal,
                    subject: TaskSubject.SendApplication,
                },
                disabledFields: [DisabledField.ApplicationId],
                title: t('task-list.add-first-task'),
                type: ModalType.Task,
            }),
        );
    };

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            if (defaultValues?.id) {
                // Update
                const updatedApplication = await updateApplicationRequest(values, defaultValues.id);
                dispatch(UpdateApplication(updatedApplication));
                dispatch(CloseModal());
            } else {
                // Creation
                const { application } = await createApplicationRequest(values);

                dispatch(AddApplication(application));
                openFirstTaskModal(application);
                logEvent({
                    name: TrackingEventName.APPLICATION_CREATED,
                    params: { applicationId: application.id, source: application.source },
                });
                dispatch(CloseModal());
            }
            toast.success(t(`application.${defaultValues?.id ? 'update' : 'creation'}.success`));
        } catch {
            toast.error(t(`application.${defaultValues?.id ? 'update' : 'creation'}.error`));
            dispatch(CloseModal());
        }
    });

    useEffect(() => {
        const sourceOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(ApplicationSource)) {
            sourceOptionsComputed.push({
                label: t(`application.source.enum.${ApplicationSource[value]}`),
                value: ApplicationSource[value],
            });
        }
        setSourceOptions(sourceOptionsComputed);
    }, [setSourceOptions]);

    useEffect(() => {
        const contacts = contactList.map((contact) => ({
            label: getContactLabel(contact, t),
            value: contact.id,
        }));
        setContactsOptions(contacts);
    }, [setContactsOptions, contactList]);

    useEffect(() => {
        if (ApplicationSource.MyNetwork === selectedSource) {
            setValue('reference', '');
        }
    }, [selectedSource, setValue]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SSpacedColumn>
                    <TextInput
                        label={t('common.company.label')}
                        name="company"
                        placeholder={t('common.company.placeholder')}
                        semi={!isMobile}
                        required
                    />
                    <TextInput
                        label={t('common.job.label')}
                        name="job"
                        placeholder={t('common.job.placeholder')}
                        semi={!isMobile}
                        required
                    />
                </SSpacedColumn>
                <SSpacedColumn>
                    <SelectInput
                        control={control}
                        label={t('application.source.label')}
                        name="source"
                        options={sourceOptions}
                        semi={!isMobile}
                        required
                    />
                    <TextInput
                        label={t('application.source-details.label')}
                        name="sourceDetails"
                        placeholder={t('application.source-details.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedColumn>
                <SSpacedColumn>
                    <TextInput
                        label={t('application.salary.label')}
                        name="salary"
                        placeholder={t('application.salary.placeholder')}
                        semi={!isMobile}
                    />
                    {!(ApplicationSource.MyNetwork === selectedSource) && (
                        <TextInput
                            label={t('application.reference.label')}
                            name="reference"
                            placeholder={t('application.reference.placeholder')}
                            semi={!isMobile}
                        />
                    )}
                </SSpacedColumn>
                <SSpacedColumn>
                    <TextInput
                        label={t('application.place.label')}
                        name="place"
                        placeholder={t('application.place.placeholder')}
                        semi={!isMobile}
                    />
                    <DateOrTimeInput
                        control={control}
                        label={t('application.date-of-publication')}
                        name="dateOfPublication"
                        semi={!isMobile}
                    />
                </SSpacedColumn>
                <SelectMultiInput
                    control={control}
                    label={t('application.associated-contacts')}
                    name="contactIds"
                    options={contactsOptions}
                />
                <TextInput
                    label={t('application.description.label')}
                    name="description"
                    placeholder={t('application.description.placeholder')}
                    isTextArea
                />
                <ResourcesInput name="resources" />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{defaultValues?.id ? t('common.update') : t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default ApplicationForm;
