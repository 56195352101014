import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { POST } from 'utils/http';
import { RadioImageField, TextInput } from '../lib';
import ApplicationStatus from 'core/enums/applicationStatus';
import Application from 'core/models/application';
import { CloseModal } from 'core/useCases/modal/modalAction';
import Mood1 from 'images/icons/Mood1.svg';
import Mood4 from 'images/icons/Mood4.svg';
import { UpdateApplication } from 'core/useCases/applications/applicationActions';

type Inputs = {
    comment: string;
    status: ApplicationStatus;
};

type ApplicationReportFormProps = {
    closeModal(): void;
    data: {
        id: string;
        comment: string;
        status: ApplicationStatus;
    };
};

type UpdateApplicationResponse = {
    application: Application;
};

const updateApplicationRequest = async (inputs: Inputs, id: string): Promise<Application> => {
    const response = (await POST<UpdateApplicationResponse>(`${BACKEND_URL}/api/application/mark-as-complete`, {
        ...inputs,
        id,
    })) as UpdateApplicationResponse;

    return response.application;
};

const ApplicationReportForm: FC<ApplicationReportFormProps> = ({ closeModal, data }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { id, comment, status } = data;

    const methods = useForm<Inputs>({
        defaultValues: { comment, status },
    });
    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            const updatedApplication = await updateApplicationRequest(values, id);
            dispatch(UpdateApplication(updatedApplication));
            dispatch(CloseModal());
            toast.success(t('application.update.success'));
        } catch (e) {
            toast.error(t('application.update.error'));
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <RadioImageField
                    choices={[
                        { image: Mood1, title: t('application.status.enum.lost'), value: ApplicationStatus.Lost },
                        { image: Mood4, title: t('application.status.enum.win'), value: ApplicationStatus.Win },
                    ]}
                    label={t('common.what-mood-after-application')}
                    name="status"
                    required
                />
                <TextInput
                    label={t('common.how-the-application-process-was')}
                    name="comment"
                    placeholder={t('common.write-a-comment')}
                    isTextArea
                />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{t('common.confirm')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default ApplicationReportForm;
