import Agency from 'core/models/agency';
import { AddAgencyAction, SetAgenciesAction, UpdateAgencyAction } from './agencyActions';
import AgenciesActionTypes from './types';

export interface AgencyState {
    list: Agency[];
    alreadyFetched: boolean;
}

const init = {
    alreadyFetched: false,
    list: [],
};

type Action = SetAgenciesAction | AddAgencyAction | UpdateAgencyAction;

const agenciesReducer = (state: AgencyState = init, action: Action): AgencyState => {
    switch (action.type) {
        case AgenciesActionTypes.SET_AGENCIES:
            return { ...state, alreadyFetched: true, list: action.payload };
        case AgenciesActionTypes.ADD_AGENCY:
            return { ...state, list: [...state.list, action.payload] };
        case AgenciesActionTypes.UPDATE_AGENCY: {
            const index = state.list.findIndex((a: Agency) => a.id === action.payload.id);
            const newAgencies = state.list;
            newAgencies[index] = action.payload;
            return { ...state, list: [...newAgencies] };
        }
        default:
            return state;
    }
};

export default agenciesReducer;
