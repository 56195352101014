import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface SMobileHeaderProps {
    open: boolean;
}

export const SMobileHeader = styled.nav<SMobileHeaderProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    transition: transform 0.3s ease-in-out;
    width: 100%;
    background-color: #fff;

    a {
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.2rem;
        text-decoration: none;
        transition: color 0.3s linear;
        font-size: 1.5rem;
        text-align: center;
    }
`;

export const SWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 5.5rem;
    background-color: ${({ theme }) => theme.color.secondary.main};
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SList = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SLink = styled(NavLink)`
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 600;
    color: ${({ theme }) => theme.color.secondary.main};
    opacity: 0.5;

    & path {
        fill: ${({ theme }) => theme.color.secondary.main};
    }

    &.active,
    &:hover {
        text-decoration: none;
        opacity: 1;
    }
`;

export const SProfileActions = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2rem;
`;
