import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { AddCandidate } from 'core/useCases/candidates/candidateActions';
import { CloseModal } from 'core/useCases/modal/modalAction';
import { userSelector } from 'core/useCases/session/selectors';
import AgencyAdmin from 'core/models/agencyAdmin';
import Candidate from 'core/models/candidate';
import { SSpacedRow } from 'styles/layout';
import { POST } from 'utils/http';
import TextInput from 'components/Forms/lib/TextInput';
import { OptionsProps } from 'components/Forms/lib/SelectInput';
import { SelectInput } from 'components/Forms/lib';
import { Civility } from 'core/enums/civility';
import { enumKeys } from 'utils/common';
import { REGEXP } from 'constants/validationsForm';
import { DisabledField } from 'core/useCases/modal/types';
import useResponsive from 'hooks/responsive';
import { UserRole } from 'core/enums/userRole';
import { agenciesSelector } from 'core/useCases/agencies/selectors';
import ToggleInput from 'components/Forms/lib/ToggleInput';

type Inputs = {
    civility: Civility;
    origin: string;
    email: string;
    firstName: string;
    job: string;
    lastName: string;
    mobileNumber: string;
    phoneNumber: string;
    agencyId: string;
};

type CandidateFormProps = {
    closeModal(): void;
    defaultValues?: Partial<Inputs>;
    disabledFields?: Array<DisabledField>;
};

const createCandidateRequest = async (inputs: Inputs, id: string): Promise<Candidate> => {
    const response = (await POST<Candidate>(`${BACKEND_URL}/api/agency/${id}/create-candidate`, inputs)) as Candidate;

    return response;
};

const CandidateForm: FC<CandidateFormProps> = ({ closeModal, defaultValues }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [civilityOptions, setCivilityOptions] = useState<OptionsProps>([]);
    const user = useSelector(userSelector) as AgencyAdmin;
    const agencies = useSelector(agenciesSelector);
    const agenciesOptions: OptionsProps = agencies.map((agency) => ({
        label: agency.name,
        value: agency.id,
    }));
    const isAdmin = UserRole.Admin === user.role;

    useEffect(() => {
        const civilityOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(Civility)) {
            civilityOptionsComputed.push({
                label: t(`common.civility.enum.${Civility[value]}`),
                value: Civility[value],
            });
        }
        setCivilityOptions(civilityOptionsComputed);
    }, [setCivilityOptions]);

    const methods = useForm<Inputs>({
        defaultValues,
    });
    const { handleSubmit, control } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            const agencyId = values.agencyId ?? user.agency.id;
            const candidate = await createCandidateRequest(values, agencyId);

            dispatch(AddCandidate(candidate));
            dispatch(CloseModal());
            toast.success(t('candidate.creation.success'));
        } catch {
            toast.error(t('candidate.creation.error'));
            dispatch(CloseModal());
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                {isAdmin && (
                    <SSpacedRow>
                        <SelectInput
                            control={control}
                            label={t('common.agency.label')}
                            name="agencyId"
                            options={agenciesOptions}
                            placeholder={t('common.agency.placeholder')}
                            semi={!isMobile}
                            required
                        />
                        <ToggleInput
                            control={control}
                            label={t('common.b2c.label')}
                            name="isB2C"
                            semi={!isMobile}
                            defaultValue
                        />
                    </SSpacedRow>
                )}
                <SSpacedRow>
                    <SelectInput
                        control={control}
                        label={t('common.civility.label')}
                        name="civility"
                        options={civilityOptions}
                        placeholder={t('common.civility.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.origin.label')}
                        name="origin"
                        placeholder={t('common.origin.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <SSpacedRow>
                    <TextInput
                        label={t('common.first-name.label')}
                        name="firstName"
                        placeholder={t('common.first-name.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.last-name.label')}
                        name="lastName"
                        placeholder={t('common.last-name.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <SSpacedRow>
                    <TextInput
                        label={t('common.mobile-number.label')}
                        name="mobileNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.mobile-number.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.phone-number.label')}
                        name="phoneNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.phone-number.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <SSpacedRow>
                    <TextInput
                        label={t('common.email.label')}
                        name="email"
                        pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                        placeholder={t('common.email.placeholder')}
                        semi={!isMobile}
                        type="email"
                        required
                    />
                    <TextInput
                        label={t('common.job.label')}
                        name="job"
                        placeholder={t('common.job.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default CandidateForm;
