import styled from 'styled-components';
import { SColumn } from 'styles/layout';
import deviceSize from 'constants/deviceSize';

export const SDiagramsColomn = styled(SColumn)`
    gap: 3rem;
`;

export const SDiagramsColomnResponsive = styled(SDiagramsColomn)`
    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
        gap: 1rem;
    }
`;

export const SWrapper = styled.div`
    margin: 4rem 0;
`;

export const SWrapperButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${({ theme }) => theme.button.height};
    padding-left: ${({ theme }) => theme.button.padding.horizontal};
    padding-right: ${({ theme }) => theme.button.padding.horizontal};
    font-weight: bold;
    border-radius: ${({ theme }) => theme.button.borderRadius};
    width: auto;
    position: relative;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &:focus {
        outline: 0;
    }
    background-color: ${({ theme }) => theme.color.secondary.main};
    color: ${({ theme }) => theme.color.white};
    text-decoration: inherit;
    gap: 1rem;
`;

export const SPremiumDiv = styled.div`
    background-color: ${({ theme }) => theme.color.premium.main};
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-70%, 15%);
    display: flex;
    justify-content: center;
    align-items: center;
`;
