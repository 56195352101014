/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BACKEND_URL from 'constants/backendUrl';
import { GET } from 'utils/http';
import DashboardTresholds from 'core/models/dashboardTresholds';
import { dashboardTresholdsAlreadyFetchedSelector } from 'core/useCases/dashboardTresholds/selectors';
import { SetDashboardTresholds } from 'core/useCases/dashboardTresholds/actions';

export interface ResponseData {
    dashboardSetting: DashboardTresholds;
}

const WithDashboardTresholds: FC = ({ children }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const dashboardTresholdsAlreadyFetched = useSelector(dashboardTresholdsAlreadyFetchedSelector);

    const dashboardTresholdsFetcher = async () => {
        try {
            const response = (await GET(`${BACKEND_URL}/api/dashboard-setting`)) as ResponseData;
            dispatch(SetDashboardTresholds(response.dashboardSetting));

            return response.dashboardSetting;
        } catch (error) {
            toast.error(t('common.error-occured'));
            return null;
        }
    };

    useEffect(() => {
        dashboardTresholdsFetcher();
    }, []);

    return <>{dashboardTresholdsAlreadyFetched && children}</>;
};

export default WithDashboardTresholds;
