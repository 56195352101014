import styled from 'styled-components';

type ButtonsContainerProps = {
    iconButton: boolean;
};

export const SWrapper = styled.div`
    width: fit-content;
    position: relative;
`;

export const SParentButton = styled.div`
    position: relative;
    z-index: 1;
`;

export const SButtonsContainer = styled.div<ButtonsContainerProps>`
    position: absolute;
    top: 0;
    right: 0;
    padding-top: ${({ iconButton }) => (iconButton ? '3rem' : '4.5rem')};
    z-index: 1;
`;

export const SButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: ${({ theme }) => theme.color.white};
    box-shadow: -8px 8px 16px 0 rgba(0, 0, 0, 0.1), -4px 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 2px;
`;
