import styled from 'styled-components';
import { SSubCard as SGenericSubCard } from 'styles/components';

export const SWrapper = styled.div`
    flex: auto;
`;

export const SButton = styled.div`
    margin: 2rem;
    align-self: start;
`;

export const SSubCard = styled(SGenericSubCard)`
    gap: 2rem;
`;
