import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Contact from 'core/models/contact';
import {
    getJobAndCompanyOfContact,
    getContactLabel,
    getParentOfContact,
    getRankOfContact,
} from 'core/useCases/contacts/utils';
import { SLightGreyText, SSubCard } from 'styles/components';
import { SName, SWrapper } from './styles';
import { SColumn } from 'styles/layout';
import { Civility } from 'core/enums/civility';
import { ReactComponent as Male } from 'images/icons/Male.svg';
import { ReactComponent as Female } from 'images/icons/Female.svg';

type ContactSubCardProps = {
    contact: Contact;
    isRankAndParentDisplayed?: boolean;
};

const ContactSubCard: FC<ContactSubCardProps> = ({ contact, isRankAndParentDisplayed = false }) => {
    const { t } = useTranslation();
    const { civility, id, parent } = contact;
    const contactLabel = getContactLabel(contact, t);
    const parentLabel = getParentOfContact(contact, t);

    return (
        <SSubCard>
            <SWrapper>
                {Civility.Male === civility ? <Male /> : <Female />}
                <SColumn>
                    <Link title={contactLabel} to={`/contact/${id}`}>
                        <SName>{contactLabel}</SName>
                    </Link>
                    <span>{getJobAndCompanyOfContact(contact, t)}</span>
                    {isRankAndParentDisplayed && (
                        <SLightGreyText>
                            {`${t('common.rank')} ${getRankOfContact(contact)}, `}
                            {parent ? (
                                <Link to={`/contact/${parent.id}`}>{parentLabel}</Link>
                            ) : (
                                <span>{parentLabel}</span>
                            )}
                        </SLightGreyText>
                    )}
                </SColumn>
            </SWrapper>
        </SSubCard>
    );
};

export default ContactSubCard;
