import { getNumericDate, getTime } from 'utils/dateTimeFormat';
import ActivityType from '../../enums/activityType';
import EventStatus from '../../enums/eventStatus';
import TaskStatus from '../../enums/taskStatus';
import Activity from '../../models/activity';
import Event from '../../models/event';
import Task from '../../models/task';
import { renderEventMediaText } from '../events/utils';
import { renderTaskMediaText } from '../tasks/utils';

export const computeActivityFromTask = (task: Task, t: any): Activity => {
    const { id, subject, deadline, executionDate, status, priority, mediaType, markAsCompleteComment, contact } = task;

    return {
        // If task is complete, execution date is not undefined
        date: TaskStatus.Completed === status ? (executionDate as Date) : deadline,

        id,

        markAsCompleteComment,

        mediaDetails: contact && mediaType && renderTaskMediaText(mediaType, contact),

        mediaType,

        priority,
        subject: t(`common.task-subject.enum.${subject}`),
        type: ActivityType.Task,
    };
};

export const computeActivityFromEvent = (event: Event, t: any): Activity => {
    const { id, type, subject, date, mediaType, mood, markAsCompleteComment } = event;

    return {
        date,
        id,
        markAsCompleteComment,
        mediaDetails: renderEventMediaText(event),
        mediaType,
        mood,
        subject: `${t(`common.event-type.enum.${type}`)}: ${t(`common.${type}-subject.enum.${subject}`)}`,
        type: ActivityType.Event,
    };
};

export const computeActivityDate = (activity: Activity): string => {
    const { date, type } = activity;
    let computedDate = getNumericDate(date);

    if (ActivityType.Event === type) {
        computedDate = computedDate.concat(` - ${getTime(date)}`);
    }

    return computedDate;
};

export const computeActivitiesFromTasksAndEvents = (
    tasks: Task[],
    events: Event[],
    t: any,
): {
    inProgressActivities: Activity[];
    finishedActivities: Activity[];
} => {
    const finishedEvents: Event[] = [];
    const inProgressEvents: Event[] = [];
    events.forEach((event) => {
        if (EventStatus.Completed === event.status) {
            finishedEvents.push(event);
        } else {
            inProgressEvents.push(event);
        }
    });

    const finishedTasks: Task[] = [];
    const inProgressTasks: Task[] = [];
    tasks.forEach((task) => {
        if (TaskStatus.Completed === task.status) {
            finishedTasks.push(task);
        } else {
            inProgressTasks.push(task);
        }
    });

    const finishedActivities: Activity[] = [];
    finishedEvents.forEach((event: Event) => finishedActivities.push(computeActivityFromEvent(event, t)));
    finishedTasks.forEach((task: Task) => finishedActivities.push(computeActivityFromTask(task, t)));

    const inProgressActivities: Activity[] = [];
    inProgressEvents.forEach((event: Event) => inProgressActivities.push(computeActivityFromEvent(event, t)));
    inProgressTasks.forEach((task: Task) => inProgressActivities.push(computeActivityFromTask(task, t)));

    return { finishedActivities, inProgressActivities };
};
