import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { applicationsSelector } from 'core/useCases/applications/selectors';
import { STable } from 'styles/components';
import Button from 'components/Button';
import FloatingButton from 'components/FloatingButton';
import Title from 'components/Typography/Title';
import Application from 'core/models/application';
import ApplicationStatus from 'core/enums/applicationStatus';
import MarkApplicationAsCompleteButton from '../MarkApplicationAsCompleteButton';
import {
    SCard,
    SCardHeader,
    SCardHeaderLeft,
    SCardHeaderStatus,
    SCardLine,
    SCardLineLeft,
    SCardLineRight,
    SColumnResponsive,
    SPremiumDiv,
    SRight,
    SRightResponsive,
    SSearchInput,
    SSpacedRowResponsive,
    SWrapper,
} from './styles';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import { getApplicationDateOfPublication, getApplicationSource } from 'core/useCases/applications/utils';
import ApplicationStatusComponent from '../ApplicationStatus';
import ApplicationActions from '../ApplicationActions';
import ApplicationSubjectItem from '../ApplicationSubjectItem';
import { ReactComponent as Checked } from 'images/icons/Checked.svg';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import useResponsive from 'hooks/responsive';
import SelectFilters from 'components/SelectFilters';
import { useHistory } from 'react-router-dom';
import { useIsPremium } from 'hooks/userIsPremium';

type ApplicationListProps = {
    applicationsToDisplay?: Application[];
    truncate?: boolean;
};

const ApplicationList: FC<ApplicationListProps> = ({ applicationsToDisplay, truncate = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const applications = applicationsToDisplay || useSelector(applicationsSelector);
    const { isMobile } = useResponsive();
    const [search, setSearch] = useState<string>('');
    const [filteredApplication, setFilteredApplication] = useState<Application[]>([]);
    const [filterOptionsValues, setFilterOptionsValues] = useState<string[]>([]);
    const history = useHistory();
    const isPremium = useIsPremium();

    const filterOptions = [
        {
            component: <ApplicationStatusComponent status={ApplicationStatus.Engaged} />,
            value: ApplicationStatus.Engaged.toString(),
        },
        {
            component: <ApplicationStatusComponent status={ApplicationStatus.Lost} />,
            value: ApplicationStatus.Lost.toString(),
        },
        {
            component: <ApplicationStatusComponent status={ApplicationStatus.New} />,
            value: ApplicationStatus.New.toString(),
        },
        {
            component: <ApplicationStatusComponent status={ApplicationStatus.Win} />,
            value: ApplicationStatus.Win.toString(),
        },
    ];

    const openApplicationModal = () => {
        dispatch(
            OpenModal({
                title: t('application.creation.label'),
                type: ModalType.Application,
            }),
        );
    };

    function sort(applicationsToSort: Application[]): Application[] {
        return applicationsToSort.sort(
            (applicationA: Application, applicationB: Application) =>
                new Date(applicationB.createdDate).getTime() - new Date(applicationA.createdDate).getTime(),
        );
    }

    useEffect(() => {
        let localApplications = [...applications];

        if (filterOptionsValues.length > 0) {
            localApplications = localApplications.filter((application) =>
                filterOptionsValues.includes(application.status),
            );
        }

        if (search) {
            const computedSearch = search.toLowerCase();
            localApplications = localApplications.filter((application) =>
                application.company.toLowerCase().includes(computedSearch),
            );
        }

        setFilteredApplication(sort(localApplications));
    }, [applications, search, filterOptionsValues]);

    const handleSearchClick = () => {
        if (!isPremium) {
            history.push('/ask-subscription');
        }
    };

    const SearchInput = (
        <SSearchInput>
            <input
                onChange={(event) => setSearch(event.target.value)}
                onClick={handleSearchClick}
                placeholder={t('application.search-for-a-application')}
                type="text"
                value={search}
            />
            {!isPremium && (
                <SPremiumDiv>
                    <Premium />
                </SPremiumDiv>
            )}
        </SSearchInput>
    );

    return (
        <SWrapper>
            {!truncate && (
                <SSpacedRowResponsive>
                    <SColumnResponsive>
                        <Title text={t('page.application.title')} />
                        <span>
                            {applications.length} {t('common.applications')}
                        </span>
                    </SColumnResponsive>
                    <SRightResponsive>
                        {SearchInput}
                        <SRight>
                            <SelectFilters
                                isPremium={isPremium}
                                options={filterOptions}
                                selectedOptionsValues={filterOptionsValues}
                                setSelectedOptionsValues={setFilterOptionsValues}
                            />
                        </SRight>
                    </SRightResponsive>
                </SSpacedRowResponsive>
            )}
            {isMobile ? (
                filteredApplication.map((application: Application, index: number) => {
                    const { id, status, sourceDetails, reference, place, salary } = application;

                    return (
                        <SCard key={id} isEven={index % 2 === 0}>
                            <SCardHeader>
                                <SCardHeaderLeft>
                                    {![ApplicationStatus.Win, ApplicationStatus.Lost].includes(status) ? (
                                        <MarkApplicationAsCompleteButton application={application} />
                                    ) : (
                                        <Checked />
                                    )}
                                    <SCardHeaderStatus>
                                        <ApplicationSubjectItem
                                            application={application}
                                            isCompleted={[ApplicationStatus.Win, ApplicationStatus.Lost].includes(
                                                status,
                                            )}
                                            isJobShown={false}
                                        />
                                        <ApplicationStatusComponent status={status} />
                                    </SCardHeaderStatus>
                                </SCardHeaderLeft>
                                <ApplicationActions applicationId={id} />
                            </SCardHeader>
                            <SCardLine>
                                <SCardLineLeft>{t('common.job.label')}</SCardLineLeft>
                                <SCardLineRight>{application.job}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.reference.label')}</SCardLineLeft>
                                <SCardLineRight>{reference}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.source.label')}</SCardLineLeft>
                                <SCardLineRight>{getApplicationSource(application, t)}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.source-details.label')}</SCardLineLeft>
                                <SCardLineRight>{sourceDetails}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.salary.label')}</SCardLineLeft>
                                <SCardLineRight>{salary}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.date-of-publication')}</SCardLineLeft>
                                <SCardLineRight>{getApplicationDateOfPublication(application)}</SCardLineRight>
                            </SCardLine>
                            <SCardLine>
                                <SCardLineLeft>{t('application.place.label')}</SCardLineLeft>
                                <SCardLineRight>{place}</SCardLineRight>
                            </SCardLine>
                        </SCard>
                    );
                })
            ) : (
                <STable>
                    <thead>
                        <tr>
                            <th aria-label={t('common.action')} />
                            <th>{t('common.subject.label')}</th>
                            <th>{t('application.reference.label')}</th>
                            <th>{t('application.salary.label')}</th>
                            {!truncate && (
                                <>
                                    <th>{t('application.source.label')}</th>
                                    <th>{t('application.source-details.label')}</th>
                                    <th>{t('application.date-of-publication')}</th>
                                </>
                            )}
                            <th>{t('application.place.label')}</th>
                            <th>{t('common.status')}</th>
                            <th aria-label={t('common.action')} />
                        </tr>
                    </thead>
                    <tbody className="table--application">
                        {filteredApplication.map((application: Application) => {
                            const { id, status, sourceDetails, reference, place, salary } = application;

                            return (
                                <tr key={id}>
                                    <td>
                                        {![ApplicationStatus.Win, ApplicationStatus.Lost].includes(status) ? (
                                            <MarkApplicationAsCompleteButton application={application} />
                                        ) : (
                                            <Checked />
                                        )}
                                    </td>
                                    <td>
                                        <ApplicationSubjectItem
                                            application={application}
                                            isCompleted={[ApplicationStatus.Win, ApplicationStatus.Lost].includes(
                                                status,
                                            )}
                                        />
                                    </td>
                                    <td>{reference}</td>
                                    <td>{salary}</td>
                                    {!truncate && (
                                        <>
                                            <td>{getApplicationSource(application, t)}</td>
                                            <td>{sourceDetails}</td>
                                            <td>{getApplicationDateOfPublication(application)}</td>
                                        </>
                                    )}
                                    <td>{place}</td>
                                    <td>
                                        <ApplicationStatusComponent status={status} />
                                    </td>
                                    <td>
                                        <div className="showOnRowHover">
                                            <ApplicationActions applicationId={id} />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </STable>
            )}
            {!truncate && (
                <FloatingButton>
                    <Button onClick={openApplicationModal} withShadow>
                        {t('application.creation.label')}
                    </Button>
                </FloatingButton>
            )}
        </SWrapper>
    );
};

export default ApplicationList;
