// import User from '../../models/user';
import User from '../../models/user';
import { SetSessionUserAction } from './logInAction';
import { ClearSessionUserAction } from './logOutAction';
import SessionActionTypes from './types';

export interface SessionState {
    user: User | null;
}

const init = {
    user: null,
};

type Action = SetSessionUserAction | ClearSessionUserAction;

const sessionReducer = (state: SessionState = init, action: Action): SessionState => {
    switch (action.type) {
        case SessionActionTypes.SET_SESSION_USER:
            return { ...state, user: action.payload };
        case SessionActionTypes.CLEAR_SESSION_USER:
            return { ...state, user: null };
        default:
            return state;
    }
};

export default sessionReducer;
