import React, { FC } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from '../ErrorMessageText';
import { SFormGroup } from './styles';
import Label from '../Label';

export type OptionsProps = { value: number | string; label: string }[];
interface SelectInputProps {
    control: any;
    name: string;
    label?: string;
    placeholder?: string;
    options: OptionsProps;
    required?: boolean;
    semi?: boolean;
    isDisabled?: boolean;
    helper?: string;
}

const SelectInput: FC<SelectInputProps> = ({
    control,
    label,
    placeholder,
    name,
    options,
    required = false,
    semi,
    isDisabled,
    helper,
}) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        setValue,
    } = useFormContext();

    return (
        <Controller
            // TODO get control with react hook form provider
            control={control}
            name={name}
            render={({ field: { onChange, value, ...rest } }) => (
                <SFormGroup semi={semi} withError={!!errors[name]}>
                    {label && <Label helper={helper} name={name} required={required} text={label} />}
                    <Select
                        {...rest}
                        className="select"
                        classNamePrefix="react-select"
                        isDisabled={isDisabled}
                        noOptionsMessage={() => t('common.no-options')}
                        onChange={(val) => {
                            if (!val) {
                                setValue(name, undefined);
                            }
                            onChange(val?.value || null);
                        }}
                        options={options}
                        placeholder={placeholder ?? t('common.select-field-placeholder')}
                        // value should be null instead of undefined to rerender the react-select component
                        value={value ? options.find((option) => option?.value === value) : null}
                        isClearable
                    />
                    {errors[name] && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => <ErrorMessageText text={message} />}
                        />
                    )}
                </SFormGroup>
            )}
            rules={{ required: required ? `${t('common.required-field')}` : false }}
        />
    );
};

export default SelectInput;
