import styled from 'styled-components';
import { SContainerField } from '../styles';

interface RadioImageFieldProps {
    checked?: boolean;
    disabled?: boolean;
}

export const SFormGroup = styled(SContainerField)`
    width: 100%;
    margin-bottom: 4rem;
`;

export const SRadioImageWrapper = styled.div`
    display: flex;
    width: 100%;
    min-height: 8rem;
    gap: 1rem;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 2rem;
    background-color: ${({ theme }) => theme.color.grey.lighter};
    border-radius: 4rem;
`;

export const SLabel = styled.label<RadioImageFieldProps>`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    opacity: ${({ checked }) => (checked ? 1 : 0.4)};

    &:hover {
        opacity: ${({ disabled }) => (disabled ? 'inerhit' : 1)};
    }

    input {
        position: fixed;
        height: 0;
        width: 0;
        appearance: none;
        border-color: transparent;

        &:focus {
            border: 0;
        }
    }
`;

export const SImage = styled.img`
    width: 4rem;
`;

export const SText = styled.span`
    font-size: 1rem;
`;
