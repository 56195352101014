import styled from 'styled-components';

export const SLoginLayout = styled.div`
    display: flex;

    @media (max-width: 768px) {
        margin-top: 6rem;
    }
`;

export const SForm = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
`;

export const SFormContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const SSection = styled.section`
    padding-bottom: 6rem;
    border-bottom: 1px solid;
    border-color: ${({ theme }) => theme.color.text.main};
`;

export const SInner = styled.div`
    width: 100%;
    max-width: 35rem;
`;

export const SLogo = styled.img`
    width: 100%;
    max-width: 30rem;
`;

export const SQuoteWrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    background-color: ${({ theme }) => theme.color.secondary.main};

    @media (max-width: 768px) {
        display: none;
    }
`;

export const STitle = styled.h1`
    margin: 6rem 0 3rem;
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
`;

export const SText = styled.p`
    text-align: center;
`;
