import React, { FC, ReactNode } from 'react';
import { SColumn } from 'styles/layout';
import { SLeft, SRight, STop, SWrapper } from './styles';

type DetailsPageProps = {
    breadcrumb: ReactNode;
    activities: ReactNode;
    secondaryActivities?: ReactNode;
    information: ReactNode;
    secondaryInformation: ReactNode;
};

const DetailsPage: FC<DetailsPageProps> = ({
    breadcrumb,
    activities,
    secondaryActivities,
    information,
    secondaryInformation,
}) => (
    <SColumn>
        <STop>{breadcrumb}</STop>
        <SWrapper>
            <SLeft>
                {information}
                {secondaryInformation}
            </SLeft>
            <SRight>
                {activities}
                {secondaryActivities}
            </SRight>
        </SWrapper>
    </SColumn>
);

export default DetailsPage;
