import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { SWrapper } from './styles';
import ResourceType from 'core/models/resource';

export type ResourceProps = ResourceType & {
    onRemoveResourceClicked: (resource: ResourceType) => void;
};

const Resource: FC<ResourceProps> = ({ url, label, onRemoveResourceClicked }) => {
    const { t } = useTranslation();

    return (
        <SWrapper>
            {/* eslint-disable-next-line react/jsx-no-target-blank */}
            <a href={url} rel="noopener" target="_blank">
                {label}
            </a>
            <button
                onClick={() => onRemoveResourceClicked({ label, url })}
                title={t('common.resources.remove.button-title')}
                type="button"
            >
                X
            </button>
        </SWrapper>
    );
};

export default Resource;
