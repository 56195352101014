import React, { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { startOfTomorrow } from 'date-fns';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { REGEXP } from 'constants/validationsForm';
import { Civility } from 'core/enums/civility';
import { AddContact, UpdateContact, UpdateContactRank } from 'core/useCases/contacts/contactActions';
import { AddTask } from 'core/useCases/tasks/taskActions';
import { contactsSelector } from 'core/useCases/contacts/selectors';
import Contact from 'core/models/contact';
import Task from 'core/models/task';
import { SSpacedColumn } from 'styles/layout';
import { GET, POST, PUT } from 'utils/http';
import TextInput from '../lib/TextInput';
import { SelectInput } from '../lib';
import { OptionsProps } from '../lib/SelectInput';
import { enumKeys } from 'utils/common';
import { CloseModal, OpenModal } from 'core/useCases/modal/modalAction';
import { DisabledField, ModalType } from 'core/useCases/modal/types';
import { TaskSubject } from 'core/enums/taskSubject';
import { TaskPriority } from 'core/enums/taskPriority';
import ContactStatus from 'core/enums/contactStatus';
import ResourcesInput from '../lib/ResourcesInput';
import { TrackingEventName } from 'utils/types/amplitude';
import { logEvent } from 'config/tracking';
import { contactsFetcher } from 'contexts/ContactsProvider';
import useResponsive from 'hooks/responsive';

type Inputs = {
    firsName?: string;
    lastName: string;
    mobileNumber?: string;
    phoneNumber?: string;
    email?: string;
    job?: string;
    company?: string;
    parentId?: string;
    civility: Civility;
    comments?: string;
};

type ContactFormProps = {
    closeModal(): void;
    defaultValues?: Partial<Contact>;
    disabledFields?: Array<DisabledField>;
};

type GetDescendantsResponse = {
    descendantIds: string[];
};

const getDescendantIdsRequest = async (id: string): Promise<string[]> => {
    const response = (await GET<GetDescendantsResponse>(
        `${BACKEND_URL}/api/contact/descendants/${id}`,
    )) as GetDescendantsResponse;

    return response.descendantIds;
};

type CreateContactResponse = {
    contact: Contact;
    task?: Task;
};

type UpdateContactResponse = {
    updatedContact: Contact;
    updatedRanks: Array<{
        id: string;
        rank: number;
    }>;
};

const createContactRequest = async (inputs: Inputs): Promise<CreateContactResponse> => {
    const response = (await POST<CreateContactResponse>(`${BACKEND_URL}/api/contact`, inputs)) as CreateContactResponse;

    return response;
};

const updateContactRequest = async (inputs: Inputs, id: string): Promise<UpdateContactResponse> => {
    const response = (await PUT<UpdateContactResponse>(`${BACKEND_URL}/api/contact/${id}`, {
        ...inputs,
    })) as UpdateContactResponse;

    return response;
};

const computeData = (contact?: Partial<Contact>): Partial<Inputs> | undefined => {
    if (!contact) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { parent, id, ...computedData } = contact;
    (computedData as Inputs).parentId = parent?.id;

    return computedData;
};

const ContactForm: FC<ContactFormProps> = ({ closeModal, defaultValues, disabledFields }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const contactList = useSelector(contactsSelector);
    const [parentOptions, setParentOptions] = useState<OptionsProps>([]);
    const [civilityOptions, setCivilityOptions] = useState<OptionsProps>([]);
    const [descendantIds, setDescendantIds] = useState<string[]>([]);

    const methods = useForm<Inputs>({
        defaultValues: computeData(defaultValues),
    });
    const { handleSubmit, control } = methods;

    const openFirstTaskModal = (newContact: Contact) => {
        // TODO Handle the default media type
        dispatch(
            OpenModal({
                cancelButtonLabel: t('common.ignore'),
                defaultValues: {
                    contact: newContact,
                    deadline: startOfTomorrow(),
                    priority: TaskPriority.Normal,
                    subject: TaskSubject.FirstContact,
                },
                disabledFields: [DisabledField.ContactId],
                title: t('task-list.add-first-task'),
                type: ModalType.Task,
            }),
        );
    };

    const openIncompleteContactCreatedModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    paragraph: t('contact.incomplete-contact-created.paragraph'),
                },
                title: t('contact.incomplete-contact-created.title'),
                type: ModalType.Information,
            }),
        );
    };

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            if (defaultValues?.id) {
                // Update
                const { updatedContact, updatedRanks } = await updateContactRequest(values, defaultValues.id);
                dispatch(UpdateContact(updatedContact));

                // Update rank of descendants
                if (updatedRanks && updatedRanks.length) {
                    for (const updatedRank of updatedRanks) {
                        dispatch(UpdateContactRank(updatedRank));
                    }
                }
                // If the status change from incomplete to new, add first task
                if (ContactStatus.Incomplete === defaultValues?.status && updatedContact.status === ContactStatus.New) {
                    openFirstTaskModal(updatedContact);
                } else {
                    dispatch(CloseModal());
                }
            } else {
                // Creation
                const { contact, task } = await createContactRequest(values);

                dispatch(AddContact(contact));
                if (task) {
                    dispatch(AddTask(task));
                }
                // Add first task if new contact is new and not incomplete
                if (ContactStatus.Incomplete !== contact.status) {
                    openFirstTaskModal(contact);
                } else {
                    openIncompleteContactCreatedModal();
                }
                logEvent({
                    name: TrackingEventName.CONTACT_CREATED,
                    params: { contactId: contact.id, contactStatus: contact.status },
                });
            }

            // TODO: for contact deletion button recheck, fetch only updated data
            await contactsFetcher(dispatch, t);

            toast.success(t(`contact-form.${defaultValues?.id ? 'update' : 'creation'}.success`));
        } catch {
            toast.error(t(`contact-form.${defaultValues?.id ? 'update' : 'creation'}.error`));
            dispatch(CloseModal());
        }
    });

    useEffect(() => {
        const civilityOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(Civility)) {
            civilityOptionsComputed.push({
                label: t(`common.civility.enum.${Civility[value]}`),
                value: Civility[value],
            });
        }
        setCivilityOptions(civilityOptionsComputed);
    }, [setCivilityOptions]);

    useEffect(() => {
        const fetchData = async (id: string) => {
            try {
                const descendantIdsFetched = await getDescendantIdsRequest(id);
                setDescendantIds(descendantIdsFetched);
            } catch (error) {
                toast.error(t('contact-form.descendants-fetch-error'));
            }
        };

        if (defaultValues?.id) {
            fetchData(defaultValues.id);
        }
    }, []);

    useEffect(() => {
        setParentOptions(
            contactList
                // During update, remove the contact himself of the list of parent list
                .filter((contact: Contact) => contact.id !== defaultValues?.id)
                .filter((contact: Contact) => !descendantIds.length || !descendantIds.includes(contact.id))
                .map((contact: Contact) => ({
                    label: `${contact.firstName} ${contact.lastName}`,
                    value: contact.id,
                })),
        );
    }, [setParentOptions, descendantIds]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SelectInput
                    control={control}
                    label={t('common.civility.label')}
                    name="civility"
                    options={civilityOptions}
                    placeholder={t('common.civility.placeholder')}
                    semi={!isMobile}
                    required
                />
                <SSpacedColumn>
                    <TextInput
                        label={t('common.first-name.label')}
                        name="firstName"
                        placeholder={t('common.first-name.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.last-name.label')}
                        name="lastName"
                        placeholder={t('common.last-name.placeholder')}
                        semi={!isMobile}
                        required
                    />
                </SSpacedColumn>
                <SSpacedColumn>
                    <TextInput
                        label={t('common.mobile-number.label')}
                        name="mobileNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.mobile-number.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.phone-number.label')}
                        name="phoneNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.phone-number.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedColumn>
                <TextInput
                    label={t('common.email.label')}
                    name="email"
                    pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                    placeholder={t('common.email.placeholder')}
                    semi={!isMobile}
                    type="email"
                    
                />
                <SSpacedColumn>
                    <TextInput
                        label={t('common.job.label')}
                        name="job"
                        placeholder={t('common.job.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.company.label')}
                        name="company"
                        placeholder={t('common.company.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedColumn>
                <TextInput
                    label={t('common.description.label')}
                    name="comments"
                    placeholder={t('common.description.placeholder')}
                    isTextArea
                />
                <ResourcesInput name="resources" />
                <SelectInput
                    control={control}
                    helper={t('common.intermediary.helper-select-input')}
                    isDisabled={disabledFields && disabledFields.includes(DisabledField.ParentId)}
                    label={t('common.intermediary.label')}
                    name="parentId"
                    options={parentOptions}
                    placeholder={t('common.intermediary.placeholder')}
                    semi={!isMobile}
                />
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{defaultValues?.id ? t('common.update') : t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default ContactForm;
