import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import LoginForm from 'components/Forms/LoginForm';
import Session from 'components/Session';

const LoginPage = () => {
    const { t } = useTranslation();

    return (
        <Session title={t('page.login.title')}>
            <Helmet>
                <title>{t('page.login.meta-title')}</title>
            </Helmet>
            <LoginForm />
        </Session>
    );
};

export default LoginPage;
