import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ReactComponent as Unchecked } from 'images/icons/Unchecked.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import Application from 'core/models/application';
import Button from 'components/Button';

type MarkApplicationAsCompleteButtonProps = {
    application: Application;
    isButton?: boolean;
};

const MarkApplicationAsCompleteButton: FC<MarkApplicationAsCompleteButtonProps> = ({ application, isButton }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { status } = application;

    const openMarkApplicationAsCompleteModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    id: application.id,
                    status: application.status,
                },
                title: t('application.mark-as-complete'),
                type: ModalType.ApplicationReport,
            }),
        );
    };

    const disabled = status === 'win' || status === 'lost';
    const disabledStyle = {
        cursor: 'not-allowed',
        opacity: 0.5,
    };

    const containerStyle = {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
    };

    return (
        <div style={containerStyle}>
            <button
                disabled={disabled}
                onClick={() => openMarkApplicationAsCompleteModal()}
                style={disabled ? disabledStyle : {}}
                title={t('application.mark-as-complete')}
                type="button"
            >
                {isButton ? <Button>{t('application.mark-as-complete')}</Button> : <Unchecked />}
            </button>
        </div>
    );
};

export default MarkApplicationAsCompleteButton;
