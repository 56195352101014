import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import DashboardComponent from 'components/Dashboard';

const Dashboard: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.dashboard.meta-title')}>
            <DashboardComponent />
        </Layout>
    );
};

export default Dashboard;
