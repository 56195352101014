import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import ActivityType from 'core/enums/activityType';
import Activity from 'core/models/activity';
import { SCircle, SRectangle, SCardWrapper, STimeline, SAddedContactRectangle, SAddedContactCircle } from './styles';
import { computeActivityDate } from 'core/useCases/activities/utils';
import { SBoldPrimaryText, SCard } from 'styles/components';
import { SRow } from 'styles/layout';
import ActivityReportVisualisation from 'components/Activity/ActivityReportVisualisation';

type FinishedActivitiesSummaryProp = {
    creationDetails?: { label: string; date: Date };
    activities: Activity[];
};

const FinishedActivitiesSummary: FC<FinishedActivitiesSummaryProp> = ({ activities }) => (
    <div>
        {!!activities.length &&
            activities.map((activity: Activity) => {
                const { id, subject, mediaDetails, type, mood, markAsCompleteComment } = activity;

                return (
                    <SRow key={id}>
                        <STimeline>
                            <SRectangle>
                                <SCircle />
                            </SRectangle>
                        </STimeline>
                        <SCardWrapper>
                            <SCard>
                                <Link title={subject} to={`/${ActivityType.Task === type ? 'task' : 'event'}/${id}`}>
                                    <SBoldPrimaryText>{subject}</SBoldPrimaryText>
                                </Link>
                                <span>
                                    {`${computeActivityDate(activity)}${mediaDetails ? ` | ${mediaDetails}` : ''}`}
                                </span>
                                <ActivityReportVisualisation comment={markAsCompleteComment} mood={mood} />
                            </SCard>
                        </SCardWrapper>
                    </SRow>
                );
            })}
        <SRow>
            <STimeline>
                <SAddedContactRectangle>
                    <SAddedContactCircle />
                </SAddedContactRectangle>
            </STimeline>
        </SRow>
    </div>
);

export default FinishedActivitiesSummary;
