import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from 'components/Tooltip';
import { SLabel } from './styles';

interface LabelProps {
    name?: string;
    text: string;
    required?: boolean;
    helper?: string;
}

const Label: FC<LabelProps> = ({ name, text, required, helper }) => {
    const { t } = useTranslation();

    return (
        <SLabel htmlFor={name}>
            {`${t(text)} ${required ? '*' : ''}`}
            {helper && <Tooltip label={helper} />}
        </SLabel>
    );
};

export default Label;
