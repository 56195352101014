import React, { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { ImportedContact } from 'components/Forms/ContactsForm';
import normalizeText from 'utils/normalizeText';
import { useTheme } from 'styled-components';
import { ReactComponent as Close } from 'images/icons/Close.svg';
import { ReactComponent as Checked } from 'images/icons/Checked.svg';
import { ReactComponent as CheckedBlue } from 'images/icons/CheckedBlue.svg';
import { ReactComponent as Unchecked } from 'images/icons/Unchecked.svg';
import { ReactComponent as UncheckedBlue } from 'images/icons/UncheckedBlue.svg';

import {
    SActionButtonContainer,
    SContactListContainer,
    SContactListElement,
    SContactListElementCheckBoxContainer,
    SContactListElementCompany,
    SContactListElementContentContainer,
    SContactListElementName,
    SContactListElementText,
    SContactListFooterContainer,
    SContactListSearch,
    SContactListSearchClearContainer,
    SContactListSearchContainer,
    SContactListSelectButton,
    SContactListSelectButtonContainer,
    SContactListSelectButtonContentContainer,
    SImportEmptyInfo,
} from './styles';

interface ContactSelectorProps {
    cancel: () => void;
    selectableContacts: ImportedContact[];
    submit: (selectedContacts: ImportedContact[]) => void;
}

const ContactSelector: FC<ContactSelectorProps> = ({ cancel, selectableContacts, submit }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [selectedContactsIndexes, setSelectedContactsIndexes] = useState<number[]>([]);

    const handleContactClick = useCallback(
        (index: number) => {
            if (selectedContactsIndexes.includes(index)) {
                setSelectedContactsIndexes(selectedContactsIndexes.filter((contactIndex) => contactIndex !== index));
            } else {
                setSelectedContactsIndexes([...selectedContactsIndexes, index]);
            }
        },
        [selectedContactsIndexes],
    );

    const [search, setSearch] = useState<string>('');

    const displayedContactsIndexes = useMemo(() => {
        if (search) {
            const indexList: number[] = [];
            selectableContacts.forEach((contact, index) => {
                if (
                    normalizeText(
                        `${contact.firstName} ${contact.lastName} ${contact.company || ''} ${contact.job || ''}`,
                    ).includes(normalizeText(search))
                ) {
                    indexList.push(index);
                }
            });

            return indexList;
        } else {
            return Array.from({ length: selectableContacts.length }, (x, i) => i);
        }
    }, [search]);

    const isSelectButtonInSelectAllMode = useMemo(() => {
        for (const value of displayedContactsIndexes) {
            if (!selectedContactsIndexes.includes(value)) {
                return true;
            }
        }

        return false;
    }, [displayedContactsIndexes, selectedContactsIndexes]);

    const handleSelectButtonClick = useCallback(() => {
        if (isSelectButtonInSelectAllMode) {
            const indexesToAdd = displayedContactsIndexes.filter((value) => !selectedContactsIndexes.includes(value));
            setSelectedContactsIndexes((array) => [...array, ...indexesToAdd]);
        } else {
            setSelectedContactsIndexes(
                selectedContactsIndexes.filter((value) => !displayedContactsIndexes.includes(value)),
            );
        }
    }, [displayedContactsIndexes, isSelectButtonInSelectAllMode, selectedContactsIndexes]);

    const onSubmit = useCallback(() => {
        submit(selectedContactsIndexes.map((selectedContactsIndex) => selectableContacts[selectedContactsIndex]));
    }, [selectedContactsIndexes]);

    return (
        <div>
            {selectableContacts.length > 0 ? (
                <>
                    <SContactListSearchContainer>
                        <SContactListSearch
                            onChange={(event) => setSearch(event.target.value)}
                            placeholder={t('contact.import.select-button-placeholder')}
                            value={search}
                        />
                        <SContactListSearchClearContainer>
                            <Button onClick={() => setSearch('')} type="button" variant="text">
                                <Close fill={theme.color.text.light} />
                            </Button>
                        </SContactListSearchClearContainer>
                    </SContactListSearchContainer>
                    <SContactListSelectButtonContainer>
                        <SContactListSelectButton
                            disabled={displayedContactsIndexes.length < 1}
                            onClick={handleSelectButtonClick}
                            variant="text"
                        >
                            <SContactListSelectButtonContentContainer>
                                {isSelectButtonInSelectAllMode ? (
                                    <>
                                        <UncheckedBlue />
                                        {t('contact.import.select-all')}
                                    </>
                                ) : (
                                    <>
                                        <CheckedBlue />
                                        {t('contact.import.unselect-all')}
                                    </>
                                )}
                            </SContactListSelectButtonContentContainer>
                        </SContactListSelectButton>
                        {t('contact.import.selected-contacts', { count: selectedContactsIndexes.length })}
                    </SContactListSelectButtonContainer>
                    {displayedContactsIndexes.length > 0 ? (
                        <SContactListContainer>
                            {displayedContactsIndexes.map((displayedContactsIndex) => (
                                <SContactListElement
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${displayedContactsIndex} - ${selectableContacts[displayedContactsIndex].firstName} ${selectableContacts[displayedContactsIndex].lastName}`}
                                    onClick={() => handleContactClick(displayedContactsIndex)}
                                >
                                    <SContactListElementCheckBoxContainer>
                                        {selectedContactsIndexes.includes(displayedContactsIndex) ? (
                                            <Checked />
                                        ) : (
                                            <Unchecked />
                                        )}
                                    </SContactListElementCheckBoxContainer>
                                    <SContactListElementContentContainer>
                                        <SContactListElementName>{`${selectableContacts[displayedContactsIndex].firstName} ${selectableContacts[displayedContactsIndex].lastName}`}</SContactListElementName>
                                        {selectableContacts[displayedContactsIndex].company && (
                                            <SContactListElementCompany>
                                                <SContactListElementCompany>
                                                    {selectableContacts[displayedContactsIndex].company}
                                                </SContactListElementCompany>
                                            </SContactListElementCompany>
                                        )}
                                        {selectableContacts[displayedContactsIndex].job && (
                                            <SContactListElementText>
                                                {selectableContacts[displayedContactsIndex].job}
                                            </SContactListElementText>
                                        )}
                                    </SContactListElementContentContainer>
                                </SContactListElement>
                            ))}
                        </SContactListContainer>
                    ) : (
                        <SImportEmptyInfo>{t('contact.import.no-contact-search')}</SImportEmptyInfo>
                    )}
                    <SContactListFooterContainer>
                        <SActionButtonContainer>
                            <Button onClick={() => cancel()} variant="text">
                                {t('common.cancel')}
                            </Button>
                            <Button disabled={selectedContactsIndexes.length < 1} onClick={onSubmit}>
                                {t('contact.import.label')}
                            </Button>
                        </SActionButtonContainer>
                    </SContactListFooterContainer>
                </>
            ) : (
                <SImportEmptyInfo>{t('contact.import.no-contact-imported')}</SImportEmptyInfo>
            )}
        </div>
    );
};

export default ContactSelector;
