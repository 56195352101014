import styled from 'styled-components';
import { sharedFieldStyles, SContainerField } from '../styles';

interface BaseFieldWrapperProps {
    withError?: boolean;
    semi?: boolean;
}

export const SFormGroup = styled(SContainerField)<BaseFieldWrapperProps>`
    width: ${({ semi }) => (semi ? '48%' : '100%')};

    input {
        ${sharedFieldStyles}

        &::placeholder {
            color: ${({ theme }) => theme.color.text.light};
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker {
        width: 350px;
        padding: 16px 32px;
        background-color: ${({ theme }) => theme.color.white};
        border-radius: ${({ theme }) => theme.form.input.borderRadius};
        border-top: 4px solid ${({ theme }) => theme.color.primary.main};
    }

    .react-datepicker--time-only {
        width: 200px;
        padding: 0;
    }

    .react-datepicker__time-container {
        float: none;
        width: auto;

        .react-datepicker__time-box {
            width: auto;
        }
    }

    .react-datepicker__current-month {
        margin-bottom: 16px;
        font-family: ${({ theme }) => theme.font};
        font-size: 14px;
        text-transform: capitalize;
    }

    .react-datepicker__day {
        width: 40px;
        margin: 0;
        font-family: ${({ theme }) => theme.font};
        font-size: 14px;
        font-weight: 500;
        line-height: 40px;
        border-radius: 50%;
    }

    .react-datepicker__day-names {
        display: flex;
        font-family: ${({ theme }) => theme.font};
        justify-content: space-between;
        text-transform: capitalize;
        div {
            color: ${({ theme }) => theme.color.primary.main};
            font-size: 16px;
        }
    }

    .react-datepicker__day--selected {
        background-color: ${({ theme }) => theme.color.primary.main};
        border-radius: 50%;
        color: ${({ theme }) => theme.color.white};
        font-weight: 700;
    }

    .react-datepicker__header {
        background-color: ${({ theme }) => theme.color.white};
        border: none;
    }

    .react-datepicker__navigation {
        margin: 16px 32px;
    }

    .react-datepicker__month {
        display: flex;
        flex-direction: column;
        margin: 8px 0;
    }

    .react-datepicker__month-container {
        width: 100%;
        background-color: ${({ theme }) => theme.color.white};
        border: none;
    }

    .react-datepicker__week {
        display: flex;
        justify-content: space-between;
    }

    .react-datepicker__time {
        font-family: ${({ theme }) => theme.font};
        font-size: 1.4rem;
        color: ${({ theme }) => theme.color.text.main};
    }
`;
