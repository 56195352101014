import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Application from 'core/models/application';
import { SLabel } from './styles';
import { getApplicationLabel } from 'core/useCases/applications/utils';

type ApplicationSubjectItemProps = {
    application?: Application;
    isCompanyBold?: boolean;
    isCompleted?: boolean;
    isJobShown?: boolean;
};

const ApplicationSubjectItem: FC<ApplicationSubjectItemProps> = ({
    application,
    isCompanyBold = true,
    isCompleted = false,
    isJobShown = true,
}) => {
    const { t } = useTranslation();

    if (!application) {
        return null;
    }
    return (
        <Link title={getApplicationLabel(application, t)} to={`/application/${application.id}`}>
            <SLabel isBold={isCompanyBold} isCompleted={isCompleted}>
                {application.company}
            </SLabel>
            {isJobShown && (
                <>
                    <br />
                    {application.job}
                </>
            )}
        </Link>
    );
};

export default ApplicationSubjectItem;
