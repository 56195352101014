import styled from 'styled-components';

export const SHeader = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
`;

export const SMain = styled.main`
    padding-top: 5.5rem;
`;
