import { useSelector } from 'react-redux';
import { userSelector } from 'core/useCases/session/selectors';
import Candidate from 'core/models/candidate';
import { useHistory } from 'react-router-dom';

export const useIsPremium = (): boolean => {
    const user = useSelector(userSelector) as Candidate | null;
    const history = useHistory();

    if (!user) {
        history.push('/login');
        return false;
    }

    const { hasPaid, isB2C } = user;

    return (isB2C && hasPaid) || !isB2C;
};
