import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SSubCard, SInfoLabel, SStatusWrapper, SSubCards } from './styles';
import { SRow } from 'styles/layout';
import CardWithTitle from 'components/CardWithTitle';
import Application from 'core/models/application';
import ApplicationStatus from '../../ApplicationStatus';
import {
    getApplicationDateOfPublication,
    getApplicationLabel,
    getApplicationSource,
} from 'core/useCases/applications/utils';
import ApplicationActions from '../../ApplicationActions';
import ResourceInformation from 'components/DetailsPage/ResourceInformation';

type InformationProps = {
    application: Application;
};

const Information: FC<InformationProps> = ({ application }) => {
    const { t } = useTranslation();
    const {
        salary,
        source,
        sourceDetails,
        dateOfPublication,
        place,
        status,
        id,
        resources: persistedResources,
    } = application;
    const label = getApplicationLabel(application, t);
    const resources = persistedResources ? JSON.parse(persistedResources) : [];

    // TODO add update
    return (
        <CardWithTitle actions={<ApplicationActions applicationId={id} />} label={label}>
            <SSubCards>
                <SSubCard>
                    <SStatusWrapper>
                        <ApplicationStatus status={status} />
                    </SStatusWrapper>
                    {salary && (
                        <SRow>
                            <SInfoLabel>{t('application.salary.label')}</SInfoLabel>
                            {salary}
                        </SRow>
                    )}
                    {source && (
                        <SRow>
                            <SInfoLabel>{t('application.source.label')}</SInfoLabel>
                            {getApplicationSource(application, t)}
                        </SRow>
                    )}
                    {sourceDetails && (
                        <SRow>
                            <SInfoLabel>{t('application.source-details.label')}</SInfoLabel>
                            {sourceDetails}
                        </SRow>
                    )}
                    {dateOfPublication && (
                        <SRow>
                            <SInfoLabel>{t('application.date-of-publication-short')}</SInfoLabel>
                            {getApplicationDateOfPublication(application)}
                        </SRow>
                    )}
                    {place && (
                        <SRow>
                            <SInfoLabel>{t('application.place.label')}</SInfoLabel>
                            {place}
                        </SRow>
                    )}
                </SSubCard>
                <ResourceInformation resources={resources} />
            </SSubCards>
        </CardWithTitle>
    );
};

export default Information;
