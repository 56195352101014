import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import useQueryParams from 'utils/queryParams';
import { Redirect } from 'react-router-dom';
import NewPasswordForm from 'components/Forms/NewPasswordForm';
import Session from 'components/Session';

type NewPasswordPageProps = {
    hasCGUCheckbox?: boolean;
};

const NewPasswordPage: FC<NewPasswordPageProps> = ({ hasCGUCheckbox = false }) => {
    const { t } = useTranslation();
    const { token, id } = useQueryParams() as { id?: number; token?: string };

    if (!token || !id) {
        return <Redirect to="/login" />;
    }

    return (
        <Session title={t('page.new-password.title')}>
            <Helmet>
                <title>{t('page.new-password.meta-title')}</title>
            </Helmet>
            <NewPasswordForm hasCGUCheckbox={hasCGUCheckbox} id={id} token={token} />
        </Session>
    );
};

export default NewPasswordPage;
