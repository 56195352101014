import Contact from 'core/models/contact';
import ContactActionTypes from './types';

export interface SetContactsAction {
    type: ContactActionTypes.SET_CONTACTS;
    payload: Contact[];
}

export interface AddContactAction {
    type: ContactActionTypes.ADD_CONTACT;
    payload: Contact;
}

export interface DeleteContactAction {
    type: ContactActionTypes.DELETE_CONTACT;
    payload: string;
}

export interface UpdateContactAction {
    type: ContactActionTypes.UPDATE_CONTACT;
    payload: Contact;
}

export const AddContact = (contact: Contact): AddContactAction => ({
    payload: contact,
    type: ContactActionTypes.ADD_CONTACT,
});

export const DeleteContact = (contactId: string): DeleteContactAction => ({
    payload: contactId,
    type: ContactActionTypes.DELETE_CONTACT,
});

export const UpdateContact = (contact: Contact): UpdateContactAction => ({
    payload: contact,
    type: ContactActionTypes.UPDATE_CONTACT,
});

export const SetContacts = (contact: Contact[]): SetContactsAction => ({
    payload: contact,
    type: ContactActionTypes.SET_CONTACTS,
});

export interface UpdateContactRankAction {
    type: ContactActionTypes.UPDATE_CONTACT_RANK;
    payload: {
        id: string;
        rank: number;
    };
}

export const UpdateContactRank = (payload: { id: string; rank: number }): UpdateContactRankAction => ({
    payload,
    type: ContactActionTypes.UPDATE_CONTACT_RANK,
});
