/* eslint-disable */
import Contact from 'core/models/contact';
import { RootState } from 'core/store';
import { Civility } from 'core/enums/civility';

export const contactsSelector = (state: RootState): Contact[] => state.contacts.list;

export const contactByIdSelector = (state: RootState, id: string | undefined): Contact | undefined =>
    state.contacts.list.find((contact: Contact) => contact.id === id);

export const contactsAlreadyFetchedSelector = (state: RootState): boolean => state.contacts.alreadyFetched;

const CSV_KEYS = {
    firstName: 'First Name',
    lastName: 'Last Name',
    comments: 'Notes',
    email: 'E-mail Address',
    phoneNumber: 'Business Phone',
    mobileNumber: 'Mobile Phone',
    company: 'Company',
    job: 'Job Title',
    civility: 'Gender (m/f)',
};
const CSV_CIVILITY_MALE_KEY = 'm';
const CSV_CIVILITY_FEMALE_KEY = 'f';

export const contactsCSVBuffer = (state: RootState): (string | undefined)[][] => {
    const formattedContacts = state.contacts.list.map((contact: Contact) => [
        contact.firstName || '',
        contact.lastName || '',
        contact.comments || '',
        contact.email || '',
        contact.phoneNumber || '',
        contact.mobileNumber || '',
        contact.company || '',
        contact.job || '',
        Civility.Female === contact.civility ? CSV_CIVILITY_FEMALE_KEY : CSV_CIVILITY_MALE_KEY,
    ]);

    formattedContacts.unshift(Object.keys(CSV_KEYS));

    return formattedContacts;
};
