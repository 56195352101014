import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import Welcome from 'components/Home/Welcome';
import Explanations from 'components/Home/Explanations';
import Quotes from 'components/Home/Quotes';
import NeedHelp from 'components/Home/NeedHelp';

const HomePage: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.home.meta-title')} topBanner={<Welcome />}>
            <Explanations />
            <br />
            <NeedHelp />
            <Quotes />
        </Layout>
    );
};

export default HomePage;
