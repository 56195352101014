import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export const SToastContainer = styled(ToastContainer).attrs({})`
    .Toastify__toast-container {
    }
    .Toastify__toast {
    }
    .Toastify__toast--error {
        background-color: ${({ theme }) => theme.color.error.main};
    }
    .Toastify__toast--warning {
        background-color: ${({ theme }) => theme.color.warning.main};
    }
    .Toastify__toast--success {
        background-color: ${({ theme }) => theme.color.success.main};
    }
    .Toastify__toast-body {
        font-weight: bold;
        white-space: pre-line;
    }
    .Toastify__progress-bar {
    }
`;
