import { HashLink as Link } from 'react-router-hash-link';
import React, { FC } from 'react';
import WeekPicker from 'components/WeekPicker';
import { SSpacedRowResponsive } from 'styles/layout';
import CompletedInterviewDiagram from './CompletedInterviewDiagram';
import FirstTaskDiagram from './FirstTaskDiagram';
import NewContactDiagram from './NewContactDiagram';
import TaskStatusDiagram from './TaskStatusDiagram';
import ApplicationDiagram from './ApplicationDiagram';
import NextInterviews from './NextInterviews';
import { SWrapper, SWrapperButton, SDiagramsColomnResponsive, SPremiumDiv } from './styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Settings } from 'images/icons/Settings.svg';
import { ReactComponent as Premium } from 'images/whiteIcons/Premium.svg';
import { useIsPremium } from 'hooks/userIsPremium';

const Dashboard: FC = () => {
    const { t } = useTranslation();
    const isPremium = useIsPremium();

    const SettingButton = (
        <Link title={t('common.settings')} to={!isPremium ? '/ask-subscription' : '/profile#dashboardTresholds'}>
            <SWrapperButton>
                <Settings />
                {t('dashboard.update-tresholds')}
                {!isPremium && (
                    <SPremiumDiv>
                        <Premium />
                    </SPremiumDiv>
                )}
            </SWrapperButton>
        </Link>
    );

    return (
        <>
            <SSpacedRowResponsive>
                <WeekPicker />
                {SettingButton}
            </SSpacedRowResponsive>
            <SWrapper>
                <SDiagramsColomnResponsive>
                    <SSpacedRowResponsive>
                        <TaskStatusDiagram />
                        <SDiagramsColomnResponsive>
                            <ApplicationDiagram />
                            <NextInterviews />
                        </SDiagramsColomnResponsive>
                    </SSpacedRowResponsive>
                    <SSpacedRowResponsive>
                        <NewContactDiagram />
                        <FirstTaskDiagram />
                        <CompletedInterviewDiagram />
                    </SSpacedRowResponsive>
                </SDiagramsColomnResponsive>
            </SWrapper>
        </>
    );
};

export default Dashboard;
