import styled from 'styled-components';
import { BasicButton } from 'components/Button/styles';
import deviceSize from 'constants/deviceSize';

export const SExportButtonResponsive = styled(BasicButton)`
    background-color: ${({ theme }) => theme.color.secondary.main};
    color: ${({ theme }) => theme.color.white};
    text-decoration: inherit;
    display: flex;
    gap: 1rem;

    @media (max-width: ${deviceSize.mobile}px) {
        width: 100%;
    }
`;
