import styled from 'styled-components';
import { SRow } from 'styles/layout';
import { SFormGroup } from 'components/Forms/lib/DateOrTimeInput/styles';

export const SWrapper = styled.div`
    position: relative;
`;

export const SLabel = styled.span`
    font-size: 1.6rem;
    font-weight: bold;
    color: ${({ theme }) => theme.color.secondary.main};
    text-transform: uppercase;
`;

export const SLabelWrapper = styled(SRow)`
    gap: 0.5rem;
`;

export const SPicker = styled(SFormGroup)`
    position: absolute;
    z-index: 10;
`;
