import BACKEND_URL from 'constants/backendUrl';
import { useTranslation } from 'react-i18next';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearSessionUserAction } from 'core/useCases/session/logOutAction';
import { POST } from 'utils/http';
import { SNav, SList, SLink } from './style';
import Button from '../Button';
import { userSelector } from 'core/useCases/session/selectors';
import { Civility } from 'core/enums/civility';
import { UserRole } from 'core/enums/userRole';
import { ReactComponent as Chart } from 'images/icons/Chart.svg';
import { ReactComponent as Network } from 'images/icons/Network.svg';
import { ReactComponent as File } from 'images/icons/File.svg';
import { ReactComponent as Male } from 'images/icons/Male.svg';
import { ReactComponent as Female } from 'images/icons/Female.svg';
import MultiActionsButton from '../MultiActionsButton';
import CandidateStatus from 'core/enums/candidateStatus';

interface ActionsProps {
    userRole: UserRole;
    userStatus: CandidateStatus;
    userHasPaid: boolean;
    userHasFreeAccess: boolean;
}

const logOutFetcher = async () => {
    try {
        await POST(`${BACKEND_URL}/api/auth/logout`);
    } catch (error) {
        throw new Error('UNKNOWN_ERROR');
    }
};

const CandidateActions: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <li>
                <SLink title={t('header.dashboard')} to="/dashboard">
                    <Chart />
                    <span>{t('header.dashboard')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('header.contact')} to="/contacts">
                    <Network />
                    <span>{t('header.contact')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('common.activities')} to="/activities">
                    <File />
                    <span>{t('common.activities')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('common.applications')} to="/applications">
                    <File />
                    <span>{t('common.applications')}</span>
                </SLink>
            </li>
        </>
    );
};

const AdminActions: FC = () => {
    const { t } = useTranslation();
    return (
        <>
            <li>
                <SLink title={t('header.candidates')} to="/candidates">
                    <Network />
                    <span>{t('header.candidates')}</span>
                </SLink>
            </li>
            <li>
                <SLink title={t('header.agencies')} to="/agencies">
                    <Network />
                    <span>{t('header.agencies')}</span>
                </SLink>
            </li>
        </>
    );
};

const AgencyAdminActions: FC = () => {
    const { t } = useTranslation();
    return (
        <li>
            <SLink title={t('header.candidates')} to="/candidates">
                <Network />
                <span>{t('header.candidates')}</span>
            </SLink>
        </li>
    );
};

const PaymentActions: FC = () => {
    const { t } = useTranslation();
    return (
        <li>
            <SLink title={t('header.activate')} to="/">
                <File />
                <span>{t('header.activate')}</span>
            </SLink>
        </li>
    );
};

const Actions: FC<ActionsProps> = ({ userRole, userStatus, userHasPaid, userHasFreeAccess }) => {
    if (
        !userHasFreeAccess &&
        !userHasPaid &&
        (userStatus === CandidateStatus.WithoutLicenseAfterTrialPeriod ||
            userStatus === CandidateStatus.WithExpiredLicense)
    ) {
        return <PaymentActions />;
    }

    switch (userRole) {
        case UserRole.Candidate:
            return <CandidateActions />;
        case UserRole.AgencyAdmin:
            return <AgencyAdminActions />;
        case UserRole.Admin:
            return <AdminActions />;
        default:
            throw Error('Wrong user role type enum in header actions');
    }
};

const ConnectedActions: FC = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const user = useSelector(userSelector);

    if (!user) {
        return null;
    }

    const logOut = async () => {
        try {
            await logOutFetcher();
            dispatch(clearSessionUserAction());
            history.push('/login');
        } catch (error) {
            // maybe store the error in redux store and have a way to display the 'global' errors in every pages
        }
        dispatch(clearSessionUserAction());
    };

    return (
        <SNav>
            <SList>
                <Actions
                    userHasFreeAccess={Boolean(user.hasFreeAccess)}
                    userHasPaid={Boolean(user.hasPaid)}
                    userRole={user.role as UserRole}
                    userStatus={user.status as CandidateStatus}
                />
                <li>
                    <MultiActionsButton
                        parentButtonContent={Civility.Female === user.civility ? <Female /> : <Male />}
                        iconButton
                    >
                        <Button onClick={() => history.push('/profile')} variant="popinText">
                            {t('profile.label')}
                        </Button>
                        <Button onClick={() => history.push('/help')} variant="popinText">
                            {t('help.label')}
                        </Button>
                        <Button onClick={() => history.push('/legal-notice')} variant="popinText">
                            {t('legal-notice.label')}
                        </Button>
                        <Button onClick={logOut} variant="popinText">
                            {t('header.logout')}
                        </Button>
                    </MultiActionsButton>
                </li>
            </SList>
        </SNav>
    );
};

export default ConnectedActions;
