import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import CandidateProfileComponent from 'components/Profile';

const CandidateProfile: FC = () => {
    const { t } = useTranslation();

    return (
        <Layout pageTitle={t('page.profile.meta-title')}>
            <CandidateProfileComponent />
        </Layout>
    );
};

export default CandidateProfile;
