import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Unchecked } from 'images/icons/Unchecked.svg';
import { OpenModal } from 'core/useCases/modal/modalAction';
import { ModalType } from 'core/useCases/modal/types';
import ActivityType from 'core/enums/activityType';
import TaskStatus from 'core/enums/taskStatus';
import { taskByIdSelector } from 'core/useCases/tasks/selectors';
import { RootState } from 'core/store';
import Button from 'components/Button';
import useResponsive from 'hooks/responsive';

type MarkTaskAsCompleteButtonProps = {
    taskId: string;
    iconButton?: boolean;
};

const MarkTaskAsCompleteButton: FC<MarkTaskAsCompleteButtonProps> = ({ taskId, iconButton = true }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const task = useSelector((state: RootState) => taskByIdSelector(state, taskId));
    const { isMobile } = useResponsive();
 
    if (!task) {
        return null;
    }

    const { status } = task;

    const openMarkTaskAsCompleteModal = () => {
        dispatch(
            OpenModal({
                defaultValues: {
                    activityType: ActivityType.Task,
                    id: taskId,
                    isAlreadyCompleted: TaskStatus.Completed === status,
                },
                title: t('task-list.mark-task-as-complete'),
                type: ModalType.ActivityReport,
            }),
        );
    };

    return (
        <div>
            {iconButton ? (
                <button
                    onClick={openMarkTaskAsCompleteModal}
                    title={t('task-list.mark-task-as-complete')}
                    type="button"
                >
                    <Unchecked />
                </button>
            ) : (
                <Button
                    large={isMobile}
                    onClick={openMarkTaskAsCompleteModal}
                    title={t('task-list.mark-task-as-complete')}
                    type="button"
                    withShadow
                >
                    {t('task-list.mark-task-as-complete')}
                </Button>
            )}
        </div>
    );
};

export default MarkTaskAsCompleteButton;
