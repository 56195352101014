import styled from 'styled-components';
import { SSpacedRow } from 'styles/layout';
import { SFormGroup } from '../../TextInput/styles';

export const SInputs = styled(SFormGroup)`
    display: flex;
    gap: 1rem;
    margin-bottom: 0;
`;

export const SWrapper = styled(SSpacedRow)`
    width: 100%;
    gap: 1rem;
    align-items: end;
`;
