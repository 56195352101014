import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
    borderRadius: '9px',
    button: {
        borderRadius: '9px',
        height: '4rem',
        padding: {
            horizontal: '1.4rem',
        },
    },
    color: {
        accent: {
            main: '#42A2E7',
        },
        applicationStatus: {
            engaged: '#FFAB67',
            lost: '#9769AC',
            new: '#FF7F6B',
            win: '#61C99A',
        },
        black: '#000000',
        contactStatus: {
            engaged: '#FFAB67',
            incomplete: '#3F85EB',
            new: '#FF7F6B',
            treated: '#61C99A',
        },
        error: {
            main: '#FA6977',
        },
        eventStatus: {
            completed: '#61C99A',
            email: '#169887',
            high: '#FA6977',
            inmail: '#15d5e1',
            mobile: '#7386e6',
            normal: '#FA6977',
            notCompleted: '#FF7F6B',
            other: '#b1b6c4',
            phone: '#7386e6',
            urgent: '#FA6977',
        },
        grey: {
            light: '#B1B6C4',
            light2: '#E6EFF5',
            lighter: '#F5F9FB',
            main: '#3A4452',
        },
        premium: {
            main: '#FC9FA8',
        },
        primary: {
            light: '#f2736d',
            lighter: '#FC9FA8',
            main: '#E94A41',
        },
        priorityStatus: '#FA6977',
        secondary: {
            dark: '#01567A',
            darker: '#374551',
            light: '#deebf2',
            main: '#007497',
        },
        success: {
            main: '#61C99A',
        },
        text: {
            light: '#B1B6C4',
            main: '#0F1A42',
        },
        typeMedia: {
            email: '#169887',
            faceToFace: '#2e5fb3',
            inmail: '#15d5e1',
            mobile: '#7386e6',
            other: '#b1b6c4',
            phone: '#7386e6',
            videoConferencing: '#37acdf',
        },
        warning: {
            main: '#fb994f',
        },
        white: '#ffffff',
    },
    font: 'Arimo',
    form: {
        input: {
            borderRadius: '5px',
            padding: '8px',
        },
    },
    layout: {
        width: '1124px',
    },
    modal: {
        borderRadius: '9px',
        padding: {
            bottom: '3rem',
            horizontal: '4.5rem',
            top: '5.2rem',
        },
        width: '51rem',
    },
};
