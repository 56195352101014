import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BACKEND_URL from 'constants/backendUrl';
import Button from 'components/Button';
import useResponsive from 'hooks/responsive';

const CandidateSubscriptionView: FC = () => {
    const { t } = useTranslation();
    const { isMobile } = useResponsive();

    const goToStripePortal = () => {
        window.location.href = `${BACKEND_URL}/api/payment/portal-session`;
    };

    return (
        <div>
            <Button className="button-center" large={isMobile} onClick={goToStripePortal} type="button">
                {t('profile.subscription.btn-show-subscription')}
            </Button>
        </div>
    );
};

export default CandidateSubscriptionView;
