import React from 'react';

interface UseModalReturnType {
    isOpen: boolean;
    openModal: () => void;
    closeModal: () => void;
    toggleModal: () => void;
}

const useModal = (initialState: boolean = false): UseModalReturnType => {
    const [isOpen, setIsOpen] = React.useState<boolean>(initialState);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return { closeModal, isOpen, openModal, toggleModal };
};

export default useModal;
