import styled from 'styled-components';
import { SSubCard as SGenericSubCard } from 'styles/components';
import deviceSize from 'constants/deviceSize';

export const SWrapper = styled.div`
    flex: auto;
`;

export const SButton = styled.div`
    margin: 2rem;
    align-self: start;
`;

export const SSubCard = styled(SGenericSubCard)`
    gap: 2rem;
    @media (max-width: ${deviceSize.mobile}px) {
        border-radius: 0;
        margin-left: -10px;
        width: 100vw;
    }
`;
