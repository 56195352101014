import Candidate from 'core/models/candidate';
import { RootState } from 'core/store';
import CandidateStatus from '../../enums/candidateStatus';

export const candidatesSelector = (state: RootState): Candidate[] => state.candidates.list;

export const candidatesLengthSelector = (state: RootState): number => state.candidates.list.length;

export const candidatesNeverConnectedSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.NeverConnected === candidate.status);

export const candidatesInTrialPeriodSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.InTrialPeriod === candidate.status);

export const candidatesWithoutLicenseAfterTrialPeriodSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.WithoutLicenseAfterTrialPeriod === candidate.status);

export const candidatesWithPendingLicenseSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.WithPendingLicense === candidate.status);

export const candidatesWithValidLicenseSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.WithValidLicense === candidate.status);

export const candidatesWithExpiredLicenseSelector = (state: RootState): Candidate[] =>
    state.candidates.list.filter((candidate) => CandidateStatus.WithExpiredLicense === candidate.status);

export const candidateByIdSelector = (state: RootState, id: string | undefined): Candidate | undefined =>
    state.candidates.list.find((candidate: Candidate) => candidate.id === id);

export const candidatesAlreadyFetchedSelector = (state: RootState): boolean => state.candidates.alreadyFetched;

export const selectedCandidatesSelector = (state: RootState): string[] => state.candidates.selectedCandidatesIds;
