import React, { FC } from 'react';
import { SMobileHeader, SWrapper } from './styles';
import Burger from '../Burger';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import logo from 'images/Logo-MyJobBooster-white.png';
import { useSelector } from 'react-redux';
import { isLoggedIn } from 'core/useCases/session/selectors';
import ConnectedActions from './ConnectedActions';
import DisconnectedActions from './DisconnectedActions';
import ProfileActions from './ProfileActions';

interface MobileHeaderProps {
    initialState?: boolean;
}

const MobileHeader: FC<MobileHeaderProps> = ({ initialState = false }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState<boolean>(initialState);
    const isLogged = useSelector(isLoggedIn);

    return (
        <SWrapper>
            <Burger open={open} setOpen={setOpen} />
            <Link title={t('header.dashboard')} to="/">
                <img alt={t('header.logo-title')} src={logo} width="173" />
            </Link>
            <ProfileActions />
            <SMobileHeader open={open}>
                {isLogged ? <ConnectedActions /> : <DisconnectedActions />}
            </SMobileHeader>
        </SWrapper>
    )
}
export default MobileHeader;
