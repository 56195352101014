import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CGUPage from './CGU';
import LegalNoticePage from './LegalNotice';
import LoginPage from './Login';
import NewPasswordPage from './NewPassword';

const DisconnectedRoutes: FC = () => (
    <Switch>
        <Route path="/login" exact>
            <LoginPage />
        </Route>
        <Route path="/new-password" exact>
            <NewPasswordPage hasCGUCheckbox />
        </Route>
        <Route path="/update-password" exact>
            <NewPasswordPage />
        </Route>
        <Route path="/cgu" exact>
            <CGUPage />
        </Route>
        <Route path="/legal-notice" exact>
            <LegalNoticePage />
        </Route>
        <Redirect to="/login" />
    </Switch>
);

export default DisconnectedRoutes;
