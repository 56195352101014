import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';
import BACKEND_URL from 'constants/backendUrl';
import { AddAgency, UpdateAgency } from 'core/useCases/agencies/agencyActions';
import { CloseModal } from 'core/useCases/modal/modalAction';
import Agency from 'core/models/agency';
import { SSpacedRow } from 'styles/layout';
import { POST, PUT } from 'utils/http';
import TextInput from 'components/Forms/lib/TextInput';
import { OptionsProps } from 'components/Forms/lib/SelectInput';
import { SelectInput } from 'components/Forms/lib';
import { Civility } from 'core/enums/civility';
import { enumKeys } from 'utils/common';
import { REGEXP } from 'constants/validationsForm';
import { DisabledField } from 'core/useCases/modal/types';
import useResponsive from 'hooks/responsive';

type UserProfile = {
    civility: Civility;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    mobileNumber: string;
    job: string;
};

type Inputs = {
    name: string;
    email: string;
    userProfile: UserProfile;
};

type AgencyFormProps = {
    closeModal(): void;
    defaultValues?: Partial<Agency>;
    disabledFields?: Array<DisabledField>;
};

type UpdateAgencyResponse = {
    agency: Agency;
};

const createAgencyRequest = async (inputs: Inputs): Promise<Agency> => {
    const response = (await POST<Agency>(`${BACKEND_URL}/api/agency`, inputs)) as Agency;

    return response;
};

const updateAgencyRequest = async (inputs: Inputs, id: string): Promise<Agency> => {
    const response = (await PUT<UpdateAgencyResponse>(`${BACKEND_URL}/api/agency/${id}`, {
        ...inputs,
    })) as UpdateAgencyResponse;

    return response.agency;
};

const computeData = (agency?: Partial<Agency>): Partial<Inputs> | undefined => {
    if (!agency) {
        return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...data } = agency;
    const computedData = {
        ...data,
    } as Partial<Inputs>;

    return computedData;
};

const AgencyForm: FC<AgencyFormProps> = ({ closeModal, defaultValues }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isMobile } = useResponsive();
    const [civilityOptions, setCivilityOptions] = useState<OptionsProps>([]);

    useEffect(() => {
        const civilityOptionsComputed: OptionsProps = [];
        for (const value of enumKeys(Civility)) {
            civilityOptionsComputed.push({
                label: t(`common.civility.enum.${Civility[value]}`),
                value: Civility[value],
            });
        }
        setCivilityOptions(civilityOptionsComputed);
    }, [setCivilityOptions]);

    const methods = useForm<Inputs>({
        defaultValues: computeData(defaultValues),
    });
    const { handleSubmit, control } = methods;

    const onSubmit = handleSubmit(async (values: Inputs) => {
        try {
            if (defaultValues?.id) {
                // Update
                const updatedAgency = await updateAgencyRequest(values, defaultValues.id);
                dispatch(UpdateAgency(updatedAgency));
                dispatch(CloseModal());
            } else {
                // Creation
                const agency = await createAgencyRequest(values);

                dispatch(AddAgency(agency));
                dispatch(CloseModal());
            }
            toast.success(t(`agency.${defaultValues?.id ? 'update' : 'creation'}.success`));
        } catch {
            toast.error(t(`agency.${defaultValues?.id ? 'update' : 'creation'}.error`));
            dispatch(CloseModal());
        }
    });

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit}>
                <SSpacedRow>
                    <TextInput
                        label={t('common.agency.label')}
                        name="name"
                        placeholder={t('common.agency.placeholder')}
                        semi={!isMobile}
                        required
                    />
                </SSpacedRow>
                <TextInput
                    label={t('common.email.label')}
                    name="email"
                    pattern={{ message: t(REGEXP.EMAIL.message), value: REGEXP.EMAIL.value }}
                    placeholder={t('common.email.placeholder')}
                    semi={!isMobile}
                    type="email"
                    required
                />
                <SelectInput
                    control={control}
                    label={t('common.civility.label')}
                    name="userProfile.civility"
                    options={civilityOptions}
                    placeholder={t('common.civility.placeholder')}
                    semi={!isMobile}
                />
                <SSpacedRow>
                    <TextInput
                        label={t('common.first-name.label')}
                        name="userProfile.firstName"
                        placeholder={t('common.first-name.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.last-name.label')}
                        name="userProfile.lastName"
                        placeholder={t('common.last-name.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <SSpacedRow>
                    <TextInput
                        label={t('common.mobile-number.label')}
                        name="userProfile.mobileNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.mobile-number.placeholder')}
                        semi={!isMobile}
                    />
                    <TextInput
                        label={t('common.phone-number.label')}
                        name="userProfile.phoneNumber"
                        pattern={{ message: t(REGEXP.PHONE_NUMBER.message), value: REGEXP.PHONE_NUMBER.value }}
                        placeholder={t('common.phone-number.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <SSpacedRow>
                    <TextInput
                        label={t('common.job.label')}
                        name="userProfile.job"
                        placeholder={t('common.job.placeholder')}
                        semi={!isMobile}
                    />
                </SSpacedRow>
                <BaseModal.BottomActions>
                    <Button onClick={() => closeModal()} variant="text">
                        {t('common.cancel')}
                    </Button>
                    <Button submit>{defaultValues?.id ? t('common.update') : t('common.create')}</Button>
                </BaseModal.BottomActions>
            </form>
        </FormProvider>
    );
};

export default AgencyForm;
