import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BaseModal from 'components/Modals/BaseModal/BaseModal';
import Button from 'components/Button';

type InformationModalProps = {
    closeModal(): void;
    defaultValues: { paragraph: string };
};

const InformationModal: FC<InformationModalProps> = ({ closeModal, defaultValues }) => {
    const { t } = useTranslation();
    const { paragraph } = defaultValues;

    if (!paragraph) {
        closeModal();
    }

    return (
        <>
            <p>{paragraph}</p>
            <BaseModal.BottomActions>
                <Button onClick={() => closeModal()} type="button">
                    {t('common.understand')}
                </Button>
            </BaseModal.BottomActions>
        </>
    );
};

export default InformationModal;
