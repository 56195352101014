import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ErrorMessageText from '../ErrorMessageText';
import { SFormGroup } from './styles';
import Label from '../Label';
import Toggle from 'react-toggle';

interface ToggleInputProps {
    control: any;
    name: string;
    label?: string;
    required?: boolean;
    semi?: boolean;
    helper?: string;
    defaultValue?: boolean;
}

const ToggleInput: FC<ToggleInputProps> = ({ control, label, defaultValue, name, required = false, semi, helper }) => {
    const { t } = useTranslation();
    const {
        formState: { errors },
        setValue,
    } = useFormContext();

    return (
        <Controller
            // TODO get control with react hook form provider
            control={control}
            defaultValue={defaultValue}
            name={name}
            render={({ field: { onChange, value, ...rest } }) => (
                <SFormGroup semi={semi} withError={!!errors[name]}>
                    {label && <Label helper={helper} name={name} required={required} text={label} />}
                    <Toggle
                        {...rest}
                        defaultChecked={value ?? defaultValue}
                        onChange={(val) => {
                            if (!val) {
                                setValue(name, false);
                            }
                            onChange(val.target.checked ?? false);
                        }}
                    />
                    {errors[name] && (
                        <ErrorMessage
                            errors={errors}
                            name={name}
                            render={({ message }) => <ErrorMessageText text={message} />}
                        />
                    )}
                </SFormGroup>
            )}
            rules={{ required: required ? `${t('common.required-field')}` : false }}
        />
    );
};

export default ToggleInput;
